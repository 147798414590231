import axios from "axios";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth'; 
import { unlink, getAuth, linkWithPopup, FacebookAuthProvider } from "firebase/auth";
import { GetAuthorizedFacebookPagesRequest, GetLinkedInstagramAccountRequest } from 'instagram-graph-api';

const domain = process.env.VUE_APP_USERPLATFORM_URL

const url = domain + "/api/v1/"

async function unlinkAccountWithFacebook() {
    var usr = await firebase.getCurrentUser()


    //const auth = getAuth();
    await unlink(usr, "facebook.com").then((result) => {


        console.log(result)
      }).catch((error) => {
        console.log(error)
      });
      
}

async function linkAccountWithFacebook() {
    const provider = new FacebookAuthProvider();
    provider.addScope('ads_management');
    provider.addScope('business_management');
    provider.addScope('instagram_basic');
    provider.addScope('instagram_content_publish');
    provider.addScope('pages_read_engagement');
    provider.addScope('pages_show_list');
    provider.addScope('pages_manage_posts');
    provider.addScope('public_profile');


    const auth = getAuth();
    var res = ""
    await linkWithPopup(auth.currentUser, provider).then((result) => {
        // Accounts successfully linked.
        const credential = FacebookAuthProvider.credentialFromResult(result);
        //const user = result.user;
        //console.log(user)
        console.log(credential)
        res = credential
      }).catch((error) => {
        if (error.code == 'auth/credential-already-in-use') {
            //console.log(FacebookAuthProvider.credentialFromError(error))
            res = FacebookAuthProvider.credentialFromError(error)
        } else {
            return null
        }
      });
      return res.accessToken
}

async function getFacebookUserID() {
    const auth = getAuth();
    return auth.currentUser.providerData.find(p => p.providerId == "facebook.com").uid
}

async function getAuthorizedFacebookPages(ACCESS_TOKEN) {
      var retVal = null
      const getAuthorizedFacebookPagesRequest = new GetAuthorizedFacebookPagesRequest(ACCESS_TOKEN);
      await getAuthorizedFacebookPagesRequest.execute().then((response) => {
        console.log(response)
          //FACEBOOK_PAGE_ID = response.data.data[0].id //response.getAuthorizedFacebookPages()[0].id;
          //PAGE_ACCESS_TOKEN = response.data.data[0].access_token
          retVal = response
          console.log(retVal)
      });
      return retVal
}

async function getLinkedInstagramAccount(ACCESS_TOKEN, FACEBOOK_PAGE_ID) {
    const getLinkedInstagramAccountRequest = new GetLinkedInstagramAccountRequest(ACCESS_TOKEN, FACEBOOK_PAGE_ID);
    var pageId
    await getLinkedInstagramAccountRequest.execute().then((response) => {
        pageId = response //.getInstagramPageId();
    });
    return pageId
}

async function postimagetoInsta() {
    var options = {
        method: "POST",
        url: "graph.facebook.com/104255718844257/media?image_url=https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png"
    }
    var result = await axios.request(options)
    return result
}

async function isLinkedWithFacebook() {
    const auth = getAuth();
    return auth.currentUser.providerData.some(p => p.providerId == "facebook.com")
}

async function isLoggedIn() {
    var result = await firebase.getCurrentUser()
    if (!result || !result.emailVerified){
        return false
    } else {
        return true
    }
}

async function getTeams() {
    var res = false
    var user = await firebase.auth().currentUser
    if(user) {
        await user.getIdTokenResult()
        .then(function ({
            claims
          }) {
            res = claims.teams
              
          })
    }
    res = res === "undefined" || res == null ? [] : res
    return res
}

/*async function getRoles() {
    var res = false
    var user = await firebase.auth().currentUser
    if(user) {
        await user.getIdTokenResult()
        .then(function ({
            claims
          }) {
            res = claims.roles
              
          })
    }
    res = res === "undefined" || res == null ? [] : res
    return res
}*/

async function getRole() {
    var role = false
    var user = await firebase.auth().currentUser
    if(user) {
        await user.getIdTokenResult()
        .then(function ({
            claims
          }) {
            role = claims.role
              
          })
    }
    role = role === "undefined" || role == null ? "None" : role
    return role
}

async function isAdmin() {
    //var roles = await getRoles()
    var role = await getRole()
    return role == "admin" ? true : false
    /*for(var i = 0; i < roles.length; i++) {
        if(roles[i] == "admin") {
            return true
        }
    }
    return false*/
}


async function logout() {
    var res = null
    firebase
      .auth()
      .signOut()
      .then(() => {
          res = true
      })
      .catch(error => {
          res = false
        alert(error.message);
      });
      return res
}

async function getprofile() {
    //console.log("getprofile")
    var user = await firebase.auth().currentUser
    return user;
}

async function getAccessToken() {
    var res = null
    var usr = await getprofile()
    //console.log(usr)
    await usr.getIdToken(/* forceRefresh */ true)
        .then(function(idToken) {
            res = idToken
        }).catch ((err) => {
            //console.log("hier")
            res = err.response
        }).catch ((err) => {
            //console.log("hier 2")
            res = err.response
        })
    return res;
}

async function makeURLRequest(options) {
    var result = null
    await axios.request(options).then(response => {
        result = response
    })
    . catch ((err) => {
        result = err.response
    })
    return result
}

async function updateToken(cookie, csrfmiddlewaretoken) {
    var jwt = await getAccessToken()
    var options = {
        method: "PUT", 
        "url": url + "updatetoken",
        headers: {
            Authorization: jwt,
            token: cookie,
            csrfmiddlewaretoken: csrfmiddlewaretoken
        } 
    }
    return makeURLRequest(options) 
}

async function forgotpassword(email) {
    var options = {
        method: "POST", 
        "url": url + "forgotpassword",
        headers: {
            email: email
        } 
    }
    return makeURLRequest(options)
}

async function updatepassword(token, password) {
    var options = {
        method: "PUT", 
        "url": url + "updatepassword",
        headers: {
            token: token,
            password: password
        } 
    }
    return makeURLRequest(options)
}

async function register(email, clubname, rank, ageband) {
    var options = {
        method: "POST", 
        "url": url + "register",
        headers: {
            email: email,
            clubname: clubname,
            rank: rank,
            ageband: ageband
        } 
    }
    return makeURLRequest(options)
}

async function resendverification(email) {
    var options = {
        method: "PUT", 
        "url": url + "resendverification",
        headers: {
            email: email
        } 
    }
    return makeURLRequest(options)
}

export const usermanager = {
    updateToken,
    isLoggedIn,
    isAdmin,
    logout,
    getAccessToken,
    linkAccountWithFacebook,
    unlinkAccountWithFacebook,
    isLinkedWithFacebook,
    forgotpassword,
    updatepassword,
    register,
    resendverification,
    getprofile,
    getTeams,
    postimagetoInsta,
    getFacebookUserID,
    getAuthorizedFacebookPages,
    getLinkedInstagramAccount,
    getRole
};