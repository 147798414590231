export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["guten Tag"])},
  "dictionary": {
    "goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tore"])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tor"])},
    "shots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schüsse"])},
    "shot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schuss"])},
    "shots_on_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torschüsse"])},
    "shot_on_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torschuss"])},
    "corners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eckstöße"])},
    "corner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eckstoß"])},
    "attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschlüsse"])},
    "attempts_on_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschlüsse aufs Tor"])},
    "headers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopfbälle"])},
    "headers_on_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopfbälle aufs Tor"])},
    "header_goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopfballtore"])},
    "opponent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegner"])},
    "assists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assists"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "all_opponents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Gegner"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
    "dataprivacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutz"])},
    "agbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AGBs"])},
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Rechte vorbehalten"])}
  },
  "sidemenu": {
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "matches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begegnungen"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team"])}
  },
  "home": {
    "registrationBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kostenlos registrieren"])},
    "header": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hol das beste aus Deiner Mannschaft"])},
      "line1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plane und analysiere Dein Spiel wie die Profis."])},
      "line2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine"])},
      "line2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aus einer neuen Perspektive"])},
      "rotation": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mannschaft"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video Analyse"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Highlights"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortschritte"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mannschaft"])}
      ]
    },
    "benefits": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was bietet Dir Kick17?"])},
      "summary_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir möchten mit Dir und Deinem Team in die nächste Liga aufsteigen."])},
      "summary_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kick17 bietet Dir moderne Hilfsmittel für jede Phase - vor, während & nach dem Spiel."])},
      "video": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Video-Zusammenfassung"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeichne das Spiel mit einer <b>beliebigen Kamera</b> auf und lade das Video hoch. \nUnser Algorithmus erstellt für Dich eine Zusammenfassung der spannendsten Szenen. \nSomit kannst Du mit Deinem Team gleich zum Punkt kommen und musst nicht lange suchen.\n Zusätzlich zu den automatisch erkannten Situationen, ist es möglich, die von Dir festgelegten Situationen in die Zusammenfassung einzubinden. Nutze hierfür den <b>Kick17-Timer</b> am Spielfeldrand und setze Marker noch während die Situation live passiert."])}
      },
      "report": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Spielbericht"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzlich zu der Zusammenfassung erhälst Du Statistiken zu Schüssen und Chancen, xG-Lite-Werten im direkten Vergleich zum gegnerischen Team. So erfährst Du, wer die besseren Chancen hatte und wo Dein Team Nachholbedarf hat."])}
      },
      "progress": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse & Team-Fortschritt"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du möchtest wissen, ob Dein Bauchgefühl über die Performance des Teams richtig liegt? \n Das Kick17-Dashboard zeigt Dir Deine Erfolge in Form von Statistiken und Heatmaps."])}
      }
    },
    "howto": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WIE FUNKTIONIERT KICK17?"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Buchstaben \"KI\" aus \"Kick17\" stehen für <b>künstliche Intelligenz</b>. \nDeine Videoaufnahme der letzten Begegnung wird automatisch auf die wichtigsten Szenen gekürzt und analysiert.<br/> Hierfür musst Du nur folgendes tun:"])},
      "camera": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action Cam aufstellen"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baue eine <strong>beliebige Action Cam</strong> (z.B. GoPro) an der Seitenlinie auf und starte ganz bequem die Aufzeichnung des Spiels. Das Mitschwenken der Kamera ist nich notwendig. Kick17 funktioniert mit jeder Kamera, daher bleiben Dir hohe Investitionskosten erspart."])}
      },
      "upload": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lade das Video hoch"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das wars schon. Den Rest erledigt Kick17 für Dich! Deine Video-Zusammenfassung und Analyse wird automatisch erarbeitet."])}
      }
    },
    "prices": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preise"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was kostet es Dich?"])},
      "subscriptions": {
        "amateur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einsteiger"])},
        "basis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basis"])},
        "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profi"])},
        "month_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class=\"text-small font-weight-normal ml-2\">/ Monat</span>"])},
        "individual_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class=\"text-small font-weight-normal ml-2\">Individuelles Angebot</span>"])},
        "teams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teams"])},
        "matches_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiele / Monat"])},
        "situation_marker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Situations-Marker"])},
        "video_summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video-Zusammenfassung"])},
        "match_statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiel-Statistiken"])},
        "saison_statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saison-Statistiken"])},
        "player_statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spieler-Statistiken"])},
        "competitor_analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wettbewerbsanalyse"])}
      },
      "tryout": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kostenlos testen"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du möchtest mehr erfahren? Kontaktiere uns für mehr Informationen. \nGerne zeigen wir mehr Details und analysieren für Dich das erste Video kostenlos."])}
      },
      "abo_details": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abo Details"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Jahres-Abonnements können wahlweise für 10 oder 12 Monate abgeschlossen werden. \n"])}
      }
    }
  },
  "matchlist": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herzlich Willkommen"])},
    "nomatches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hattest bisher noch keine Begegnungen."])},
    "opponent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegner"])},
    "createfirstmatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erste Begegnung anlegen"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anpfiff"])},
    "at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um"])},
    "startunknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spielbeginn unbekannt"])},
    "finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiel beendet"])},
    "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiel läuft"])}
  },
  "match": {
    "video_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begegnung mit %s am"])},
    "video_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
    "video_share": {
      "clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link in Zwischenablage kopieren"])},
      "newtab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video in neuem Tab öffnen"])},
      "instagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram bald verfügbar"])},
      "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook bald verfügbar"])}
    },
    "situations": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situationen"])},
      "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tor"])},
      "freekick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freistoß"])},
      "interesting_situation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interessante Situation"])},
      "attempt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schuss"])}
    },
    "report": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spielbericht"])},
      "goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tore"])},
      "is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ist"])},
      "should": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["soll"])},
      "attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schüsse"])},
      "on_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torschüsse"])},
      "corners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eckstöße"])}
    },
    "corners": {
      "left_side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linke Seite"])},
      "right_side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechte Seite"])}
    },
    "attempts": {
      "players_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["für diese Aktion wurden keine Spieler gefunden"])}
    }
  },
  "expected_goals": {
    "xg_curve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["xG-Kurve"])},
    "expected_goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erwartete Tore"])},
    "minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minute"])},
    "my_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Team"])}
  },
  "dashboard": {
    "myteam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mein Team"])},
    "analyzed_matches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfasste Begegnungen"])},
    "analyzed_situations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysierte Situationen"])},
    "last_matches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Begegnungen"])}
  },
  "feedback": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir wollen uns verbessern"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sage uns, was wir <strong>besser machen</strong> können oder wünsche Dir <strong>neue Funktionen.</strong> <br>Wir nehmen Deine Anfrage ernst und reagieren Zeitnah mit Verbesserungen."])},
    "your_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Feedback"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden"])},
    "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielen Dank für Dein Feedback"])},
    "positive_negative_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir freuen uns sowohl über positive, als auch negative Kritik."])},
    "more_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch mehr Feedback?"])}
  }
}