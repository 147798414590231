<template>



    <div class="container">
        <div class="row py-5 justify-content-center text-black p-5  mb-5">
            <p class="mb-0 font-weight-bold text-uppercase col-12 text-center">Das Spiel beginnt in</p>
        
            <div id="clock-c" class=" mr-auto countdown py-4 row justify-content-center">
                <div style="width: fit-content;">
                    <div v-if="days > 0" class="lefttime "><span class="h1 font-weight-bold">{{days}}</span> Tage &nbsp;&nbsp;</div>
                    <div v-if="days > 0 || (days == 0 && hours > 0)" class="lefttime "><span class="h1 font-weight-bold">{{hours}}</span> h&nbsp;&nbsp;</div>
                    <div class="lefttime "><span class="h1 font-weight-bold">{{minutes}}</span> min&nbsp;&nbsp;</div>
                    <div v-if="days == 0" class="lefttime "><span class="h1 font-weight-bold">{{seconds}}</span> s&nbsp;&nbsp;</div>    
                </div>
            </div>

        </div>
        <div class="row justify-content-center text-black p-5 text-center mb-5">
            
            
            <Button label="Ändern" class="p-button-outlined p-button-secondary col-12 col-md-6 col-lg-6 col-xl-6" @click="change()"/>
          
        </div>   

    </div>
    




</template>

<style scoped lang="scss">

@import '~bootstrap/scss/bootstrap.scss';

.lefttime {
    width: fit-content;
    float: left;
}

.signup-form {
  width: 100%;
}
.countdown {
    text-transform: uppercase;
    font-weight: bold;
}

.countdown span {
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    font-size: 3rem;
    margin-left: 0.8rem;
}

.countdown span:first-of-type {
    margin-left: 0;
}

.countdown-circles {
    text-transform: uppercase;
    font-weight: bold;
}

.countdown-circles span {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.countdown-circles span:first-of-type {
    margin-left: 0;
}


</style>

<script>


export default {
    components: {
        
    },
    data() {
        return {
            hours: 0,
            minutes: 0,
            seconds: 0,
            days: 0,
            matchbeginn: null,
            timer: null
        }
    },
    mounted() {
        this.timer = window.setTimeout(this.updateDateTime, 1000);
        this.matchbeginn = new Date(this.match.match_date)
    },
    async created () {
        this.matchbeginn = new Date(this.match.match_date)
    },
    computed: {
        match: function() {
            return this.$store.getters.getMatch
        },
        now: function() {
            return this.$store.getters.getNow
        }
    },
    methods: {
        updateDateTime() {
            this.$store.commit('setNow')
            var matchbegin = new Date(this.match.match_date)
            let totalSeconds = ((matchbegin - this.now)/1000).toFixed(0)


            this.days = Math.floor(Number(totalSeconds/(3600 *24))) 

            this.hours = Math.floor(Number((totalSeconds - (this.days * 24*60*60))/(60 * 60))) 

            


           
            
            totalSeconds %= 3600;
            this.minutes = Math.floor(totalSeconds / 60);
            this.seconds = totalSeconds % 60;
   
            this.timer = window.setTimeout(this.updateDateTime, 1000);
            if(this.days == 0 && this.hours == 0 && this.minutes == 0 && this.seconds < 1) {
                this.$store.commit('setNow')
            }

        },
        change() {
            this.$emit('toggleShowMatchSelector')
        }
    }
}

</script>