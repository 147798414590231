<template>

    <div class="col-12 md:col-6 lg:col-6 xl:col-3"  onmouseover="" style="cursor: pointer;">
        <div class="card mb-0" >
            <div class="flex justify-content-between">
            <div>
                
                <div class="text-900 font-medium text-xl" >{{title}}</div>
            </div>
                <div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
                    <i class="pi" :class="[icon]" style="fontSize: 2rem"></i>
                </div>
            </div>
            <span class="text-500"> &nbsp;
            </span>
            
        </div>
    </div>
</template>

<style scoped lang="scss">
@import '~bootstrap/scss/bootstrap.scss'; 
</style>

<script>


export default {
    components: {
        
    },
    props: ['title', 'icon'],
}

</script>