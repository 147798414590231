<template>
<div>
    <div class="container">
        <div class="row py-5">
            <div>
                <h1>AGBs – Allgemeine Geschäftsbedingungen</h1>
                
                <h3>§ 1 Geltungsbereich</h3>
                <p>Diese Nutzungsbedingungen stellen einen Nutzungsvertrag zwischen Dir (nachstehend “Nutzer” genannt) und <strong>{{ platform_name }}</strong> dar. <br>
                {{ platform_name }} ist eine Dienstleistung von <strong>{{ provider_name }}</strong> (nachstehend "Der Betreiber" genannt).</p>


                <h3>§ 2 Leistungsbeschreibung und Geltungsbereich</h3>
                <p>Der Betreiber stellt Fußball-Teams unter der Internetseite {{ domain }} eine virtuelle Plattform für Spielanalyse zur verfügung. 
                Als Fußball-Teams zählen sowohl eingetragene Vereine als auch Hobby-Teams.
                Auf der Internetseite können Fußballspiele im Vorfeld geplant werden und im Nachgang analysiert werden</p>


                <h3>§ 3 Änderungen der Nutzungsbedingungen</h3>
                <p>Der Betreiber darf die Allgemeinen Geschäftsbedingungen jederzeit ohne Nennung von Gründen ändern. 
                Der Betreiber wird dem Nutzer die Änderung der Allgemeinen Geschäftsbedingungen spätestens 14 Tage vor Inkrafttreten mitteilen. 
                Widerspricht der Nutzer den geänderten Bedingungen nicht innerhalb von 14 Tagen ab Zugang der Änderungsmitteilung, 
                dann gelten die geänderten Geschäftsbedingungen als akzeptiert. Widerspricht der Nutzer den geänderten Bedingungen fristgemäß, 
                so ist der Betreiber berechtigt, den Nutzungsvertrag zu kündigen.</p>


                <h3>§ 4 Registrierung und Abschluss des Nutzungsvertrages</h3>
                <p>Für die Nutzung der angebotenen Leistungen muss sich der Nutzer zunächst kostenlos mit Hilfe einer E-Mail-Adresse registrieren. <br>
                Es besteht kein Anspruch auf Abschluss eines Nutzungsvertrages. Der Betreiber kann ohne Angaben von Gründen eine Registrierung eines Nutzers ablehnen. <br>
                Der Registriervorgang ist abgeschlossen, sobald der Bestätigungslink, der nach der Anmeldung per E-Mail zugesendet wird, geklickt wurde.</p>

                <h3>§ 5 Gebühren, Vertragslaufzeit und Kündigung</h3>
                <p>Die Registrierung ist gebührenfrei.<br>
                Einzelne Dienste-Pakete werden nach Bezahlen einer Gebühr bereitgestellt. Die Wahl eines Paketes erfolgt spätestens nach dem Registriervorgang.
                Eine gebührenfreie Mitgliedschaft kann jederzeit ohne Einhaltung einer Frist via E-Mail gekündigt und das Nutzerkonto gelöscht werden. <br>
                Eine Gebührenpflichtige Mitgliedschaft kann nur zum Ende des Nutzungsvertrages 1 Monat im Voraus gekündigt werden.</p>


                <h3>§ 6 Sperrung</h3>
                <p>Der Betreiber darf einen Nutzer sperren, wenn dieser gegen die AGBs verstößt. <br>
                Ein gesperrter Nutzer ist nicht berechtigt, sich mit einer anderen E-Mail-Adresse erneut zu registrieren.</p>

                <h3>§ 7 Filmerlaubnis, Urheberrechte der Videos und Nutzungsermächtigung</h3>
                <p>Der Nutzer muss vor dem Hochladen eines Videos sicherstellen, dass er die notwendigen Nutzungsrechte des Videos hat.
                Nach dem Hochladen werden die Nutzungsrechte automatisch an den Betreiber erteilt.
                Der Betreiber ist berechtigt, das Videomaterial für Werbezwecke zu verwenden.
                </p>

                <h3>§ 8 Haftung</h3>
                <p>Der Betreiber übernimmt <strong>keine Haftung</strong> für Urheberrechtsverletzungen des Nutzers. Bei Verletzungen von §7 haftet der Nutzer.<br>
                Der Betreiber übernimmt <strong>keine Haftung</strong> für falsch ermittelte Kennzahlen. <br> <br>
                Der Betreiber garantiert keine ununterbrochene Bereitstellung der Internetseite.</p>


                <h3>§ 9 Datenschutz</h3>
                <p>
                Der Betreiber darf Daten verarbeiten, die während der Nutzung generiert werden.<br>
                Beim Verarbeiten der bereitgestellten Videos werden personenbezogene Statistiken zu einzelnen Spielern erhoben.<br>
                Bereitgestellte Videos werden bei Bedarf von Dritten (Dienstleistungsunternehmen) verarbeitet.<br>
                Der Betreiber darf Inhalte (nicht personenbezogen) für Werbezwecke verwenden.<br>
                Weitere Informationen zur Erhebung, Verarbeitung und Nutzung personenbezogener Daten sind im <router-link to="/datenschutz">Datenschutzhinweis</router-link> zu finden.</p>

                <h3>§ 10 Schlussbestimmungen</h3>
                <p>Die Unwirksamkeit einer Bestimmung der Nutzungsbedingungen hat keine Auswirkungen auf die Gültigkeit der übrigen Bestimmungen. 
                Die unwirksame Bestimmung wird durch eine anderweitige gesetzlich zulässige Regelung ersetzt, 
                die dem mit der unwirksamen Bestimmung gewollten Zweck wirtschaftlich möglichst nahekommt. 
                Die vorstehenden Bestimmungen gelten entsprechend für den Fall, dass sich der Vertrag als lückenhaft erweist.</p>

                <h3></h3>
                <p></p>

               
            
            </div>
        </div>
    </div>
</div>
</template>

<script>


export default {
  

  name: 'app',
  components: {
  },
  data () {
    return {}

  },
  async mounted(){
    
  }, 
  async created () {

  },
  async beforeMount() {
    
    
  },
  computed: {


    provider_name: function() {
      return process.env.VUE_APP_PROVIDER_NAME
    },
    platform_name: function() {
      return process.env.VUE_APP_PLATFORM_NAME
    },
    domain: function() {
        return process.env.VUE_APP_DOMAIN
    }
    
    

    
    
  },
  methods: {}
}
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/bootstrap.scss'; 
h3 {
    margin-top: 2em;
}
</style>