export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["good day"])},
  "dictionary": {
    "goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gates"])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal"])},
    "shots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shots"])},
    "shot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shot"])},
    "shots_on_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shots"])},
    "shot_on_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shot"])},
    "corners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corner kicks"])},
    "corner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["corner"])},
    "attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["degrees"])},
    "attempts_on_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completion on the gate"])},
    "headers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Head balls"])},
    "headers_on_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Head balls on the gate"])},
    "header_goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Header"])},
    "opponent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent"])},
    "assists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assists"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save on computer"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abort"])},
    "all_opponents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All opponents"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["imprint"])},
    "dataprivacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["privacy"])},
    "agbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General terms and conditions"])},
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rights reserved"])}
  },
  "sidemenu": {
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "matches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encounters"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["team"])}
  },
  "home": {
    "registrationBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register for free"])},
    "header": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get the best of your team"])},
      "line1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plan and analyze your game like the professionals."])},
      "line2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yours"])},
      "line2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From a new perspective"])},
      "rotation": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["team"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video analysis"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highlights"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progress"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["team"])}
      ]
    },
    "benefits": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What does Kick17 offer you?"])},
      "summary_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We would like to move up to the next league with you and your team."])},
      "summary_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kick17 offers you modern aids for every phase - before, during & after the game."])},
      "video": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your video summary"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Draw the game with a <b> arbitrary camera </b> and upload the video. \n Our algorithm creates a summary of the most exciting scenes for you. \n So you can get to the point with your team and don't have to search long. \n  In addition to the automatically recognized situations, it is possible to integrate the situations you defined in the summary. Use the <b> Kick17-timer </b> on the edge of the field and still use Marker while the situation happens live."])}
      },
      "report": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your game report"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In addition to the summary, you get statistics on shots and opportunities, XG-Lite values in direct comparison to the opposing team. This is how you will learn who had the better chances and where your team has catching up to do."])}
      },
      "progress": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analysis & team progress"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to know if your gut feeling about the performance of the team is correct? \n  The Kick17 dashboard shows you your successes in the form of statistics and heat maps."])}
      }
    },
    "howto": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How does Kick17 work?"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The letters \"Ki\" from \"Kick17\" stand for <b> artificial intelligence </b>. \n Your video recording of the last encounter is automatically shortened and analyzed. <br/> You only have to do the following:"])},
      "camera": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set up action cam"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Build up a <strong> arbitrary action cam </strong> (e.g. GoPro) on the sidelines and start recording the game very conveniently. The camera is not necessary. Kick17 works with every camera, so you are spared high investment costs."])}
      },
      "upload": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Up the video up"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["That's it. Kick17 does the rest for you! Your video summary and analysis will be developed automatically."])}
      }
    },
    "prices": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prices"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What does it cost you?"])},
      "subscriptions": {
        "amateur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginner"])},
        "basis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base"])},
        "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["professional"])},
        "month_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<Span Class = \"Text-Small Font-Weight-Normal ML-2\">/ month </span>"])},
        "individual_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<Span Class = \"Text-Small Font Weight-Normal ML-2\"> Individual offer </span>"])},
        "teams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team"])},
        "matches_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Games / month"])},
        "situation_marker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your situation marker"])},
        "video_summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video summary"])},
        "match_statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play statistics"])},
        "saison_statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Season statistics"])},
        "player_statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Player statistics"])},
        "competitor_analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Competitive analysis"])}
      },
      "tryout": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try it for free"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to learn more? Contact us for more information. \n We are happy to show more details and analyze the first video for you free of charge."])}
      },
      "abo_details": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription details"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The annual subscriptions can be completed for 10 or 12 months."])}
      }
    }
  },
  "matchlist": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warm welcome"])},
    "nomatches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So far you have had no encounters."])},
    "opponent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opponent"])},
    "createfirstmatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create first encounter"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kick -off"])},
    "at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Around"])},
    "startunknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start of the game unknown"])},
    "finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game over"])},
    "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game runs"])}
  },
  "match": {
    "video_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encounter with %s on"])},
    "video_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["comment"])},
    "video_share": {
      "clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy link in clipboard"])},
      "newtab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open video in a new tab"])},
      "instagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram available soon"])},
      "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook available soon"])}
    },
    "situations": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situations"])},
      "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Goal"])},
      "freekick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free kick"])},
      "interesting_situation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interesting situation"])},
      "attempt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shot"])}
    },
    "report": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game report"])},
      "goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gates"])},
      "is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["is"])},
      "should": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["target"])},
      "attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shots"])},
      "on_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shots"])},
      "corners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corner kicks"])}
    },
    "corners": {
      "left_side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left side"])},
      "right_side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["right side"])}
    },
    "attempts": {
      "players_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No players were found for this action"])}
    }
  },
  "expected_goals": {
    "xg_curve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XG curve"])},
    "expected_goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expected goals"])},
    "minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minute"])},
    "my_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Team"])}
  },
  "dashboard": {
    "myteam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Team"])},
    "analyzed_matches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Captured encounters"])},
    "analyzed_situations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyzed situations"])},
    "last_matches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last encounters"])}
  },
  "feedback": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We want to improve"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tell us what we can do better </strong> or wish you <strong> new functions. </strong> <br> We take your request seriously and react promptly with improvements."])},
    "your_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your feedback"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks for your feedback"])},
    "positive_negative_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We look forward to both positive and negative criticism."])},
    "more_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More feedback?"])}
  }
}