<template>

    <div class="container">
      <h1>Impressum</h1>

      <h4>Angaben gemäß § 5 TMG:</h4>
      
      <br>
      <p><strong>RP Software Engineering UG (haftungsbeschränkt)</strong><br>
      Admiralbogen 15<br>
      c/o Roman Pass<br>
      80939 München<br>

      Geschäftsführer: Roman Pass<br>
      Registernummer: HRB 274213<br>

      Registergericht: Amtsgericht München</p>

      

      <h2 id="m56">Kontaktmöglichkeiten</h2><p><strong>E-Mail-Adresse:</strong> <a href="mailto:contact@romanpass.de">contact@romanpass.de</a></p>
      <p><strong>Telefon:</strong> +49 (0) 170 400 3819</p>
      <h2 id="m169">Bildnachweise</h2><p><strong>Bildquellen und Urheberrechtshinweise:</strong> </p>
      <p>Photo by Franki Chamaki on Unsplash / <a href="https://unsplash.com/@franki?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" target="_blank">https://unsplash.com/@franki?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText</a></p>
      <p>Photo by Carlos Muza on Unsplash / <a href="https://unsplash.com/@kmuza?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" target="_blank">https://unsplash.com/@kmuza?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText</a></p>
      <p>Photo by Mike from Pexels / <a href="https://www.pexels.com/@mike-468229?utm_content=attributionCopyText&utm_medium=referral&utm_source=pexels" target="_blank">https://www.pexels.com/@mike-468229?utm_content=attributionCopyText&utm_medium=referral&utm_source=pexels</a></p>
      <a href='https://de.freepik.com/fotos/sport'>Sport Foto erstellt von freepik - de.freepik.com</a>
      <p class="seal"><a href="https://datenschutz-generator.de/?l=de" title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken." target="_blank" rel="noopener noreferrer nofollow">Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas Schwenke</a></p>
      
    </div>

</template>

<style scoped lang="scss">
@import '~bootstrap/scss/bootstrap.scss';
</style>