<template>
    


    <div v-if="matchList != null">
            <table class="table borderless">
                
                <tbody>
                    

                    <tr>
                        <th scope="row">{{myteam.clubname}}</th>
                        <th></th>
                        <th>{{this.$i18n.t('dictionary.all_opponents')}}</th>
                    </tr>
                    <tr>
                        <td>2.4<!--{{Number(allMyGoals / matchList.length).toFixed(1)}}--></td>
                        <th scope="row">&Oslash; {{this.$i18n.t('dictionary.goals')}} ({{this.$i18n.t('match.report.is')}})</th>
                        <td>{{Number(allTheirGoals / matchList.length).toFixed(1)}}</td>
                    </tr>
                    <tr>
                        <td>{{Number(allMyXG / matchList.length).toFixed(1)}}</td>
                        <th scope="row">&Oslash; {{this.$i18n.t('dictionary.goals')}} ({{this.$i18n.t('match.report.should')}})</th>
                        <td>1.7<!--{{Number(allTheirXG / matchList.length).toFixed(1)}}--></td>
                    </tr>

                    <tr>
                        <td>{{Number(allMyShotsOnTarget / matchList.length).toFixed(1)}}</td>
                        <th scope="row">&Oslash; {{this.$i18n.t('dictionary.shots_on_target')}}</th>
                        <td>{{Number(allTheirShotsOnTarget / matchList.length).toFixed(1)}}</td>
                    </tr>

                    <tr>
                        <td>{{Number(myAssists / matchList.length).toFixed(1)}}</td>
                        <th scope="row">&Oslash; {{this.$i18n.t('dictionary.assists')}}</th>
                        <td>{{Number(theirAssists / matchList.length).toFixed(1)}}</td>
                    </tr>

                </tbody>
            </table>
        
    </div>


</template>


<style scoped lang="scss">
@import '~bootstrap/scss/bootstrap.scss';
    .clublogo {
        width: 40px;
        height: 40px;
    }

    .table td{
        text-align: center;   
    }

    .table th{
        text-align: center;   
    }

    .borderless td, .borderless th {
        border: none;
    }

</style>

<script>
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { analytics } from '@/services';

export default defineComponent({
    
    setup() {

        const store = useStore()

        var dangerousSituations = computed(() => {
            if(matchList.value == null) {return null}
			var situations = []
			matchList.value.forEach(m => situations = situations.concat(m.situations))
			var dangerousSits = situations.filter(s => analytics.isStandardSituation(s))
			dangerousSits = dangerousSits.filter(sit => sit.situationPositions.some(s => s.action_labels.includes("Freistoß") == false || analytics.isFreekickSituationDangerous(sit)))
			return dangerousSits
        })

        var matchList = computed(() => {
            return store.getters.getMatchList.filter(m => m.situationsReady == true)
        })

        var myteam = computed(() => {
            return store.getters.getTeam != null ? store.getters.getTeam : ""
        })

        var allSituationPositions = computed(() => {
            return store.getters.getAllSituationPositions
        })

        var allMyGoals = computed(() => {
            return Number(matchList.value.map(m => m.creator_team_goals).reduce(function(pv, cv) { return pv + cv; }, 0))
        })
        var allTheirGoals = computed(() => {
            return Number(matchList.value.map(m => m.opponent_team_goals).reduce(function(pv, cv) { return pv + cv; }, 0))
        })

        var allMyXG = computed(() => {
            return allSituationPositions.value.filter(sitpo => sitpo.isInCreatorTeam == true).map(s => s.xG).reduce(function(pv, cv) { return pv + cv; }, 0)
        })
        var allTheirXG = computed(() => {
            return allSituationPositions.value.filter(sitpo => sitpo.isInCreatorTeam == false).map(s => s.xG).reduce(function(pv, cv) { return pv + cv; }, 0)
        })

        var allMyShotsOnTarget = computed(() => {
            return allSituationPositions.value.filter(sitpo => sitpo.isInCreatorTeam == true && sitpo.action_labels.includes("Torschuss")).length
        })
        var allTheirShotsOnTarget  = computed(() => {
            return allSituationPositions.value.filter(sitpo => sitpo.isInCreatorTeam == false && sitpo.action_labels.includes("Torschuss")).length
        })

        var myAssists  = computed(() => {
            return allSituationPositions.value.filter(sitpo => sitpo.isInCreatorTeam == true && sitpo.action_labels.includes("Assist")).length
        })
        var theirAssists  = computed(() => {
            return allSituationPositions.value.filter(sitpo => sitpo.isInCreatorTeam == false && sitpo.action_labels.includes("Assist")).length
        })

        var mySuccessfulFreekickSituations = computed(() => {
            return myFreekickSituations.value.filter(sit => sit.situationPositions.some(sitpo => sitpo.isInCreatorTeam == true && sitpo.action_labels.includes("Tor")))
        })

        var myFreekickSituations = computed(() => {
            return allFreekickSituations.value.filter(sit => sit.situationPositions.some(sitpo => sitpo.isInCreatorTeam == true && sitpo.action_labels.includes("Freistoß")))
        })

        var theirSuccessfulFreekickSituations = computed(() => {
            return myFreekickSituations.value.filter(sit => sit.situationPositions.some(sitpo => sitpo.isInCreatorTeam == false && sitpo.action_labels.includes("Tor")))
        })

        var theirFreekickSituations = computed(() => {
            return allFreekickSituations.value.filter(sit => sit.situationPositions.some(sitpo => sitpo.isInCreatorTeam == false && sitpo.action_labels.includes("Freistoß")))
        })

        var allFreekickSituations = computed(() => {
            var freekickSituations = []
            freekickSituations = dangerousSituations.value.filter(sit => {
                
                var sitpos = sit.situationPositions.filter(sitpo => sitpo.action_labels.includes("Freistoß"))
                return sitpos.length > 0
            })
            return freekickSituations
        })



         var mySuccessfulPenaltySituations = computed(() => {
            return myPenaltySituations.value.filter(sit => sit.situationPositions.some(sitpo => sitpo.isInCreatorTeam == true && sitpo.action_labels.includes("Tor")))
        })

        var myPenaltySituations = computed(() => {
            return allPenaltySituations.value.filter(sit => sit.situationPositions.some(sitpo => sitpo.isInCreatorTeam == true && sitpo.action_labels.includes("Elfmeter")))
        })

        var theirSuccessfulFPenaltySituations = computed(() => {
            return myPenaltySituations.value.filter(sit => sit.situationPositions.some(sitpo => sitpo.isInCreatorTeam == false && sitpo.action_labels.includes("Tor")))
        })

        var theirPenaltySituations = computed(() => {
            return allPenaltySituations.value.filter(sit => sit.situationPositions.some(sitpo => sitpo.isInCreatorTeam == false && sitpo.action_labels.includes("Elfmeter")))
        })

        var allPenaltySituations = computed(() => {
            var PenaltySituations = []
            PenaltySituations = dangerousSituations.value.filter(sit => {
                
                var sitpos = sit.situationPositions.filter(sitpo => sitpo.action_labels.includes("Elfmeter"))
                return sitpos.length > 0
            })
            return PenaltySituations
        })



        var mySuccessfulCornerSituations = computed(() => {
            return myCornerSituations.value.filter(sit => sit.situationPositions.some(sitpo => sitpo.isInCreatorTeam == true && sitpo.action_labels.includes("Tor")))
        })

        var myCornerSituations = computed(() => {
            return allCornerSituations.value.filter(sit => sit.situationPositions.some(sitpo => sitpo.isInCreatorTeam == true && sitpo.action_labels.includes("Eckstoß")))
        })

        var theirSuccessfulCornerSituations = computed(() => {
            return myCornerSituations.value.filter(sit => sit.situationPositions.some(sitpo => sitpo.isInCreatorTeam == false && sitpo.action_labels.includes("Tor")))
        })

        var theirCornerSituations = computed(() => {
            return allCornerSituations.value.filter(sit => sit.situationPositions.some(sitpo => sitpo.isInCreatorTeam == false && sitpo.action_labels.includes("Eckstoß")))
        })

        var allCornerSituations = computed(() => {
            var CornerSituations = []
            CornerSituations = dangerousSituations.value.filter(sit => {
                
                var sitpos = sit.situationPositions.filter(sitpo => sitpo.action_labels.includes("Eckstoß"))
                return sitpos.length > 0
            })
            return CornerSituations
        })



        return {
            allFreekickSituations,
            myFreekickSituations,
            mySuccessfulFreekickSituations,
            theirSuccessfulFreekickSituations,
            theirFreekickSituations,
            mySuccessfulPenaltySituations,
            myPenaltySituations,
            theirSuccessfulFPenaltySituations,
            theirPenaltySituations,
            theirCornerSituations,
            theirSuccessfulCornerSituations,
            myCornerSituations,
            mySuccessfulCornerSituations,


            matchList,
            myteam,
            allMyGoals,
            allTheirGoals,
            allMyXG,
            allTheirXG,
            allMyShotsOnTarget,
            allTheirShotsOnTarget,
            theirAssists,
            myAssists
        }


    }
});


</script>