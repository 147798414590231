<template>
<Toast />

<Dialog :header="'Video Teilen'" v-model:visible="displayBasic" :dismissableMask="true" :style="{width: '50vw'}" :modal="true">
        <div class="container">
            <div class="row">
                <div class="row-12">
                        <Button icon="pi pi-link" class="p-button-rounded p-button-outlined p-button-secondary" @click="linkToClipboard" v-tooltip.right="$t('match.video_share.clipboard')"/>
                        <Button icon="pi pi-external-link" class="ml-2 p-button-rounded p-button-outlined p-button-secondary" @click="videoInNewTab" v-tooltip.right="$t('match.video_share.newtab')"/>
                        <Button icon="pi pi-instagram" class="ml-2 p-button-rounded p-button-outlined p-button-secondary" disabled="disabled" v-tooltip.right="$t('match.video_share.instagram')"/>
                        <Button icon="pi pi-facebook" class="ml-2 p-button-rounded p-button-outlined p-button-secondary" disabled="disabled" v-tooltip.right="$t('match.video_share.facebook')"/>
                        
                    </div>

            </div>
            
                
            </div>
        
        <template #footer>
            <Button label="Abbrechen" icon="pi pi-times" @click="displayBasic = false" class="p-button-secondary p-button-text"/>
        </template>
    </Dialog>

    <div class="container-fluid">
        <div class="row justify-content-center">
        
            <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
                <video-player v-if="match != null" :showBoard="showBirdPerspective" :selectedSituation="selectedSituation == null ? null : selectedSituation.uuid" @addVideo="uploadVisible = !uploadVisible" @ready="childVal => {this.videoMounted = childVal}" @playing="videoplaying" @toggleBirdPerspective="showBirdPerspective = !showBirdPerspective" ref="myVideoPlayer"/>
                <h4 class="col-12">Begegnung mit {{match.opponent_team.clubname}} am {{matchDate}} <span v-if="selectedSituation != null">- {{videoSituationText}}</span></h4>
                

                <div v-if="match.situations != null && match.situations.length != 0 && match.shortVideosReady" class="row">

                    <div class="row-12">
                        <Button type="button" style="padding: 0.5rem !important; height: 2.357rem;"  class="ml-2 p-button-rounded p-button-outlined p-button-secondary" @click="toggleIsFavorite()" v-tooltip.right="'Merken'">
                            <i v-bind:style="selectedSituation != null && selectedSituation.isFavorite == true ? 'color: gold;' : 'color: grey; opacity: 0.3;'" class="fas fa-star"></i>
                        </Button>

                        
                        <Button icon="pi pi-share-alt" class="ml-2 p-button-rounded p-button-outlined p-button-secondary" @click="displayBasic = true" v-tooltip.right="'Teilen'"/>
              
                    </div>
                </div>
                
                <div class="col-12 py-5" v-if="match.shortVideosReady">
                    <h5>Kommentar</h5>
                    <Textarea v-if="selectedSituation != null" style="width: 100%;" v-model="selectedSituation.comment" rows="5" cols="30" @keydown="commentSaved = false"/>
                    <Button label="Speichern" class="p-button-outlined p-button-secondary" @click="saveComment()"/>
                    {{commentSaved == false ? 'Kommentar nicht gespeichert' : ''}}
                </div>

                <!--<div class="immo_card_icon">
                    <i class="fas fa-star" v-if="selectedSituation != null && selectedSituation.isFavorite==true" style="color: gold;" ></i>
                    <i class="fas fa-star" v-else style="color: grey; opacity: 0.3;"></i>
                    <i class="fas fa-comment-alt"></i>
                </div>

                <div v-if="match.situations != null && match.situations.length != 0" class="row">
                    <h5 class="col-12">Video teilen</h5>
                    <div class="row-12">
                        <Button icon="pi pi-link" class="p-button-rounded p-button-outlined p-button-secondary" @click="linkToClipboard" v-tooltip.right="'Link in Zwischenablage kopieren'"/>
                        <Button icon="pi pi-external-link" class="ml-2 p-button-rounded p-button-outlined p-button-secondary" @click="videoInNewTab" v-tooltip.right="'Video in neuem Tab öffnen'"/>
                        <Button icon="pi pi-instagram" class="ml-2 p-button-rounded p-button-outlined p-button-secondary" disabled="disabled" v-tooltip.right="'Instagram bald verfügbar'"/>
                        <Button icon="pi pi-facebook" class="ml-2 p-button-rounded p-button-outlined p-button-secondary" disabled="disabled" v-tooltip.right="'Facebook bald verfügbar'"/>
                        
                    </div>
                </div>-->
            </div>

            <div v-if="match.situations != null && match.situations.length != 0" class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <TabView ref="tabview1" @tab-click="open" :scrollable="true">
                    <TabPanel :header="tabnames[0]" v-if="match.situations != null && match.situations.length != 0">
                        
                        <ScrollPanel style="width: 100%; height: 600px">
                            <ShortVideoList v-if="match.shortVideosReady == true" style="width: 100%;"  @situationClick="situationClick" :selectedSituation="selectedSituation == null ? null : selectedSituation.uuid"></ShortVideoList>
                            <div v-else-if="match.shortVideosReady != true && match.situations.some(s => s.isCustom == true)">
                                <VideoCalibration v-if="match != null && (match.matchVideoFrame == null || match.matchVideoFrame.length < 3)" :timestamp="currentVideoTimestamp + previousVideosDurations"></VideoCalibration>
                                <SituationList style="width: 100%;" v-if="match.shortVideosReady != true && match.matchVideoFrame != null && match.matchVideoFrame.length > 2" ></SituationList>

                            </div>
                            <div v-else>
                                <p style="font-weight: bold;"><i class="far fa-clock" style="color: blue;"></i> Das Video wird derzeit zusammengefasst und analysiert<br></p>
                            </div>
                            
                            
                        </ScrollPanel>
                    </TabPanel>
                    <TabPanel :header="tabnames[1]">
                        <MatchStats v-if="match != null && match.situations.length != 0"></MatchStats>
                    </TabPanel>
                    <TabPanel :header="tabnames[2]" v-if="match != null && match.situationsReady == true">
                        <Corner2Shot ref="corner2shots"></Corner2Shot>
                    </TabPanel>
                    <TabPanel :header="tabnames[3]" v-if="match.situationsReady">
                        
                            
                                <div id="shotscontainer" class="w-100" style="position: relative">
                                    <ShotsHeatmap ref="shots" :selectedPlayer="selectedPlayer" :showAction="shotSelection.name" :creator_team="isSelectedCreatorTeam"></ShotsHeatmap>
                                    <!--<Heatmap v-if="shotsloaded" :creator_team="isSelectedCreatorTeam" :show="showHeatmap"></Heatmap>-->
                                </div>
                            
                            <div class="row">
                                <h5>Team</h5>
                                <SelectButton v-model="selectedCreatorTeam" :options="teamSelectOptions" optionLabel="name"/>
                            </div>
                            <div class="row">
                                
                                    
                                    <h5 class="mt-5">Aktion</h5>
                                    
                                    <SelectButton v-model="shotSelection" :options="shotSituationOptions" dataKey="name">
                                        <template #option="slotProps">
                                            <i :class="slotProps.option.icon"></i> &nbsp; {{slotProps.option.name}}
                                        </template>
                                    </SelectButton> 
                                    <!--<SelectButton v-model="shotSelection" :options="passSituationOptions" dataKey="name">
                                        <template #option="slotProps">
                                            <i :class="slotProps.option.icon"></i> &nbsp; {{slotProps.option.name}}
                                        </template>
                                    </SelectButton>-->
                                    
                            </div>
                            <div class="row mt-3">
                                <div class="w-50">
                                    <Dropdown v-if="myteam != null && isSelectedCreatorTeam" v-model="selectedPlayer" emptyMessage="für diese Aktion wurden keine Spieler gefunden" :options="playerSelection" optionLabel="uuid" placeholder="Spieler" :showClear="true">
                                        <template #option="slotProps">
                                            <div class="country-item">
                                                <div>{{slotProps.option.firstname.charAt(0)}}. {{slotProps.option.lastname}}</div>
                                            </div>
                                        </template>
                                        <template #value="slotProps" >
                                            <div v-if="slotProps.value">{{slotProps.value.firstname.charAt(0)}}. {{slotProps.value.lastname}}</div>
                                        </template>
                                    </Dropdown>
                                </div>
                            </div>
                        
                    </TabPanel>
                    <TabPanel :header="tabnames[4]" v-if="match != null && match.situationsReady == true">
                        <PlayerStatistics></PlayerStatistics>
                        <!--<ShotDefends></ShotDefends>-->
                    </TabPanel>
                    <TabPanel :header="tabnames[5]" v-if="match != null && match.situationsReady == true">
                        <xGCurve></xGCurve>
                    </TabPanel>
                </TabView>
                           
                           
                        
            </div>
        </div>
        
    


 
    

    <div class="container" v-if="this.match.videos.length == 0">
        <div class="row">
            <div class="text-center font-weight-bold" style="width: 100%;">Noch kein Video vorhanden</div></div>
        <div class="row">
            
            <div class="col-lg-8 mx-auto">
                <div class="card shadow mb-4">
                    <div class="card-body">
                         <!--<vue-dropzone :options="dropzoneOptions" :id="dropzoneID" v-on:vdropzone-sending="dropZoneSendingEvent" v-on:vdropzone-file-added="fileAdded" v-on:vdropzone-success="uploadDone"></vue-dropzone>-->
                    </div>
                </div>
            </div>
        </div>
    </div>

    



    <div class="bg-light">
        <div class="container">
            
            <div class="row py-5" v-if="uploadVisible">
                <div class="col-lg-8 mx-auto">
                    <div class="card shadow mb-4">
                        <div class="card-body">


                        <FileUpload mode="advanced" :fileLimit="1"  accept=".mp4, .AVI, .WMV, .AVCHD" @upload="uploadDone" @before-send="beforeSend" @progress="progress" :auto="true" :url="uploadURL" :multiple="false" :showUploadButton="false" :showCancelButton="false" :chooseLabel="'Video Hochladen'">
                            <template #empty>
                                <p>Hochladen durch Drag and drop.</p>
                            </template>
                        </FileUpload>

                            <!--<vue-dropzone :options="dropzoneOptions" :id="dropzoneID" v-on:vdropzone-sending="dropZoneSendingEvent" v-on:vdropzone-file-added="fileAdded" v-on:vdropzone-success="uploadDone"></vue-dropzone>-->
                        </div>
                    </div>
                </div>
            </div>

            <div class="row py-5" v-if="allPositions != null && allPositions.length > 0">
                <div id="heatmapContainer" style="width: 100%;">
                   
                    <CurrentPositions ref="currentPosition" style="z-index: 30;" :visibility="showCurrentPositions" :container="'heatmapContainer'" :frames_data="allPositions" :current_second="currentVideoTimestamp" :colors="clubColors"></CurrentPositions>
                </div>
                <div style="margin-top: 1em">
                Tools:
                    <Button class="p-button-outlined p-button-secondary"  @click="addOffsideLine()">| Linie</Button>
                    <Button class="p-button-outlined p-button-secondary"  @click="addCircle()">Kreis</Button>
                    <Button class="p-button-outlined p-button-secondary"  @click="addRectangle()">Rechteck</Button>
                </div>
            </div>



            
           
            <div class="row text-center " v-if="match.situations == null || match.situations.length == 0">
                <div class="w-100 text-center">
                    <span class="">Noch keine Situationen erfasst. <br> 
                    Bitte habe etwas Geduld. Unser System erfasst die interessantesten Situationen und erstellt eine Zusammenfassung Deines Videos.</span>
                </div>
            </div>
            
            
        </div>
    </div>
    
    

</div>
</template>

<style scoped lang="scss">

@import '~bootstrap/scss/bootstrap.scss';


</style>

<script>

import { kick17 } from '@/services';
import VideoPlayer from "./VideoPlayer.vue";
import SituationList from './SituationList'
import MatchStats from './MatchStats'
import CurrentPositions from './CurrentPositions'
import ShortVideoList from './ShortVideoList'
import ShotsHeatmap from '../Dashboard/ShotsHeatmap'
import Corner2Shot from '../Dashboard/Corner2Shot' 
import PlayerStatistics from '../Dashboard/PlayerStatistics' 
import xGCurve from './xGCurve'
//import ShotDefends from '../Dashboard/ShotDefends' 
//import Heatmap from './Heatmap'
import VideoCalibration from './VideoCalibration'
import { getAnalytics, logEvent } from "firebase/analytics";

export default {
    components: {
        VideoPlayer,
        SituationList,
        CurrentPositions,
        ShortVideoList,
        ShotsHeatmap,
        //Heatmap,
        MatchStats,
        VideoCalibration,
        Corner2Shot,
        PlayerStatistics,
        xGCurve
        //ShotDefends
    },
    data() {
        return {
            selectedPlayer: null,
            showBirdPerspective: false,
            allPositions: null,
            uploadVisible: false,
            editResult: false,
            //imagePath: "",
            videoSource: "",
            dropzoneID: "dropzoneRB",
            dropzoneOptions: kick17.getVideoUploadDropzoneOptions(),
            creator_team_goals: 0,
            opponent_team_goals: 0,
            shotsloaded: false,
            selectedCreatorTeam: true,
            shotSelection: {name: "Schuss", icon: "fa-brands fa-hubspot"},
            currentVideoTimestamp: 0,
            showCurrentPositions: true,
            showHeatmap: false,
            actionOfPlayer: null,
            shotSituationOptions: [
                {name: "Schuss", icon: "fa-brands fa-hubspot"},
                {name: "Torschuss", icon: "fa-solid fa-bolt"},
				{name: "Tor", icon: "fas fa-futbol"}
			],
            passSituationOptions: [
                {name: "Zuspiel", icon: "pi pi-arrow-up"},
                {name: "Angekommen", icon: "pi pi-arrow-circle-up"},
				{name: "Assist", icon: "fas fa-futbol"}
			],
            tabnames: [this.$i18n.t('match.situations.title'), this.$i18n.t('match.report.title'), this.$i18n.t('dictionary.corners'), this.$i18n.t('dictionary.attempts'), this.$i18n.t('home.prices.subscriptions.player_statistics'), this.$i18n.t('expected_goals.xg_curve')],

            clubColors: ['#314FCD', '#0DE8C0', '#E80D67', '#e80dc9', '#e8be0d'],
            displayBasic: false,
            commentSaved: true
        }
    },
    watch: {
        selectedCreatorTeam: function(newVal) {
            const analytics = getAnalytics()
            logEvent(analytics, 'match_overview_team_selection', {"team": newVal == true ? "creator_team" : "opponent_team"})
        },
        shotSelection: function(newVal) {
            const analytics = getAnalytics()
            logEvent(analytics, 'match_overview_show_action_label', {"target_action_label": newVal.name})
        },

        match: function (newVal) {
            
            if(newVal != null) {
                this.refreshVideos()
            }
        }
  },
    mounted() {
        if(this.match.videos != null && this.match.videos.length != 0) {
            this.refreshVideos()
        }
        window.addEventListener("resize", this.resizedPlayer);
        
    },
    unmounted() {
        window.removeEventListener("resize", this.resizedPlayer);
    },
    
    async created() {
        this.$store.commit('selectSituationUuid', null)
        if(this.videos != null && this.videos.video_file_name != null) {
            this.refreshVideos()
            
        }
        
        
        this.uploadURL = kick17.getMatchVideoUploadURL()
        this.accesstoken = await kick17.getAccessToken()
        this.creator_team_goals = this.match.creator_team_goals
        this.opponent_team_goals = this.match.opponent_team_goals

        this.selectedCreatorTeam = this.teamSelectOptions[0]
        var selectedSit = this.match.situations != null && this.match.situations.length != 0 && this.match.shortVideosReady == true ? this.match.situations.find(s => s.video != null) : null
        if(selectedSit != null) {
            this.$store.commit('selectSituationUuid', selectedSit.uuid)
        }
        //this.shotSelection = this.shotSelectOptions[0]
    },
    computed: {
        selectedSituation: function() {
            return this.$store.getters.getSelectedSituation
        },
        playerSelection: function() {
			if (this.shotSelection.name == "Schuss") {
				return this.$store.getters.getPlayersWithShots
			}
			else if (this.shotSelection.name == "Torschuss") {
				return this.$store.getters.getPlayersWithShotsOnTarget
			}
			else if (this.shotSelection.name == "Tor") {
				return this.$store.getters.getPlayersWithGoals
			}
            else if(this.shotSelection.name.includes("Pass") || this.shotSelection.name == "Angekommen") {
				return this.$store.getters.getPlayersWithPass
			}
			else if(this.shotSelection.name.includes("Assist")) {
				return this.$store.getters.getPlayersWithAssist
			}
			return this.$store.getters.getTeam.players
        },
        match: function() {
            return this.$store.getters.getMatch
        },
        myteam: function() {
            return this.$store.getters.getTeam
        },
        teamSelectOptions: function() {
            let options = [
                {name: this.match.creator_team.clubname, value: true},
                {name: this.match.opponent_team.clubname, value: false}
            ]
            return options
        },
        isSelectedCreatorTeam: function() {
            return this.selectedCreatorTeam.value
        },
        shotSelectOptions: function() {
            var allSitPos = kick17.getAllSitPos(this.match)
            allSitPos = allSitPos.filter(sitp => sitp.isInCreatorTeam == this.isSelectedCreatorTeam)
            allSitPos = allSitPos.filter(kick17.sitpoHasActions)
            var labels = this.getAllActionLabels(allSitPos)
            labels = labels.filter(this.whitelabeledActions)
            return labels //this.getAllActionLabels(allSitPos)
        },
        matchDate: function() {
            var date = new Date(this.match.match_date);
            return date.getDate().toString().padStart(2, '0') + '.' + (date.getMonth() + 1).toString().padStart(2, '0') + '.' + date.getFullYear()
        },

        videoSituationText: function() {
            if(this.selectedSituation != null) {
                return this.selectedSituation.label[0] + " " + this.getMinute(this.selectedSituation) + "'"
            }
            return ""
        }
    },
    methods: {
        async saveComment() {
            var res = await kick17.setComment(this.match.uuid, this.selectedSituation.uuid, this.selectedSituation.comment)
            if(res.status == 200) {
                this.$store.commit('setMatch', res.data)
                this.$toast.add({severity:'success', summary: 'Kommentar gespeichert', detail:'', life: 2000});
                this.commentSaved = true
            }
        },
        async toggleIsFavorite() {
            var res = await kick17.changeFavorite(this.match.uuid, this.selectedSituation.uuid, !this.selectedSituation.isFavorite)
            if(res.status == 200) {
                this.$store.commit('setMatch', res.data)
            }
        },
        getMinute(situation) {
            return Number(kick17.getMinuteOfSituation(situation, this.match))+1
        },
        resizedPlayer() {
            this.$refs.myVideoPlayer.refreshAllMarkers()
        },
        linkToClipboard() {
            navigator.clipboard.writeText(kick17.getSituationVideoURL() + this.selectedSituation.uuid + ".mp4")
            this.$toast.add({severity:'success', summary: 'Link in Zwischenablage kopiert', detail:'', life: 2000});
        },
        videoInNewTab() {
            window.open(kick17.getSituationVideoURL() + this.selectedSituation.uuid + ".mp4", '_blank').focus()

        },
        videoplaying(childVal) {
            this.currentVideoTimestamp = childVal
            this.previousVideosDurations = this.$refs.myVideoPlayer.previousVideosDurations
        },
        async open(e) {
            const analytics = getAnalytics()
            logEvent(analytics, 'match_overview_tab', {"tab_name": this.tabnames[e.index]})
            setTimeout(function(){
               if(e.index==3) {
                    this.$refs.shots.initContainer()
                }
                else if(e.index==2) {
                    this.$refs.corner2shots.initContainer()
                }
                
            }.bind(this), 0);
            
            
        },
        getAllActionLabels(allSitpos) {
            var actionLabels = []
            for(var i = 0; i < allSitpos.length; i++) {
                actionLabels = actionLabels.concat(allSitpos[i].action_labels)
            }
            actionLabels = [...new Set(actionLabels)];
            return actionLabels
        },
        
        
       

        whitelabeledActions(actionlabel) {
            var whitelabeledArr = ["Ballgewinn", "Ballverlust", "Flanke", "Schuss", "Torschuss", "Tor", "Block", "Assist"]
            return whitelabeledArr.includes(actionlabel)
            //actionlabel.some(r=> whitelabeledArr.includes(r))
            //return whitelabeledArr.find(s => s == actionlabel) != -1
        },

        refreshVideos() {
            var sourceArr = []
            for(var i = 0; i < this.match.videos.length; i++) {
                var source = {
                    type: "video/mp4",
                    src: kick17.getVideoURL() + this.match.videos[i]
                    //poster: image
                }
                sourceArr.push(source)
            }
            //this.videoOptions.sources = sourceArr
           
        },
        situationClick(situation) {
            if(this.match.shortVideosReady != true) {
                var newTimestamp = this.customMarkerVideoTimestamp(situation)
                this.$refs.myVideoPlayer.setTime(newTimestamp)
                this.currentVideoTimestamp = newTimestamp
            } else {
                this.$store.commit('selectSituationUuid', situation.uuid)
            }
            
        },
        customMarkerVideoTimestamp(situation) {
            var matchBegin = new Date(this.match.match_date)
            var sitTimestamp = new Date(situation.custom_marker)
            return (sitTimestamp - matchBegin) > situation.situation_start ? (sitTimestamp - matchBegin) : situation.situation_start
        },
        async getAllPositions() {
            var result = await kick17.getAllPositions(this.match.analytics_id)
            
            this.allPositions = result.data // Vue.nonreactive(result.data)
        },
        addOffsideLine() {
            this.$refs.currentPosition.addOffsideLine()
        },
        addCircle() {
            this.$refs.currentPosition.addCircle()
        },
        addRectangle() {
            this.$refs.currentPosition.addRectangle()
        },
        addSituation() {
            alert("add Situation")
        },
        async beforeSend(request) {
            request.xhr.setRequestHeader("matchUuid", this.match.uuid);
            request.xhr.setRequestHeader("Authorization", this.accesstoken);
            return request
        },

        uploadDone(response) {
            if(response.xhr.status==200) {
                this.$store.commit('addVideoToMatch', response.xhr.responseText)
                this.uploadVisible = false
            }
        }
    }
}

</script>