
function isFreekickSituationDangerous(situation) {
    var sitpo = situation.situationPositions.find(s => s.action_labels.includes("Freistoß"))
    if(sitpo === undefined) {return false}
    var curX = situation.direction == "CREATOR_LEFT_TO_RIGHT" ? sitpo.x : 100 - sitpo.x
    curX = situation.isInCreatorTeam == true ? curX : 100 - curX
    return curX > 70 ? true : false
}

function isStandardSituation(situation) {
    return situation.situationPositions.some(sitpo => {
        return sitpo.action_labels.some(l => dangerousStandards.includes(l))
    })
}

var dangerousStandards = ["Freistoß", "Elfmeter", "Eckstoß"]


function getAllSituationSeconds(situation) {
    return [...new Set(situation.situationPositions.map(s => s.second))].sort(function(a, b){
        return a - b;
    })
}

function isPassSuccessful(situation, curSitpo) {
    if(situation.situationPositions.length < 2) {return false}
    if(curSitpo.action_labels.includes("Angekommen")) {return true}
    if(curSitpo.action_labels.includes("Ballverlust")) {return false}
    var allseconds = getAllSituationSeconds(situation)
    var curSecondIndex = allseconds.findIndex(a => a == curSitpo.second)
    var otherSitposWithActions = situation.situationPositions.filter(s => s.isInCreatorTeam == curSitpo.isInCreatorTeam && s.action_labels.length != 0 && s.uuid != curSitpo.uuid)
    if(otherSitposWithActions.length == 0) {return false}
    var blockSitpo = situation.situationPositions.find(s => s.isInCreatorTeam != curSitpo.isInCreatorTeam && (s.action_labels.includes("Block") || s.action_labels.includes("Ballannahme") || s.action_labels.includes("Ballgewinn")) )
    if(blockSitpo != null) {
        if(allseconds[curSecondIndex] == blockSitpo.second || allseconds[curSecondIndex + 1] == blockSitpo.second){
            return false
        }
    }
    var sitpos = situation.situationPositions.filter(s => s.isInCreatorTeam == curSitpo.isInCreatorTeam && s.player_uuid != curSitpo.player_uuid && (s.second == allseconds[curSecondIndex] || s.second == allseconds[curSecondIndex+1]))
    return sitpos != null ? true : false
}

const isPassAssist = function(situation, curSitpo) {
    if(curSitpo.action_labels.includes("Assist")) {return true}
    

    var goalSitpo = situation.situationPositions.find(s => s.action_labels.includes("Tor"))
    if (goalSitpo == null) {return false}
    var allseconds = getAllSituationSeconds(situation)
    var curSecondIndex = allseconds.findIndex(a => a == curSitpo.second)
    if(goalSitpo.second == allseconds[curSecondIndex] && goalSitpo.player_uuid != curSitpo.player_uuid) {
        return true
    }
    return false
}

var standardSizes = {
    pitchWidth: 68, //meter
    pitchHeight: 105, //meter
    fuenfmeterraumHeight: 5.5,
    fuenfmeterraumWidth: 18.32,
    goalWidth: 7.32,
    sechzehnerWidth: 40.32,
    sechzehnerHeight: 16.5,
    kreisRadius: 9.15
}

const getSituationLabels = function(situation) {
    var labels = []
    situation.situationPositions.forEach(sitpo => {
        labels = labels.concat(sitpo.action_labels)
    })
    return [...new Set(labels)];
}

const getGoalDistance = function(posXPercent, posYPercent) {
    var torMitte = [0, standardSizes.pitchWidth * 0.5];
    var position = [posXPercent * standardSizes.pitchHeight / 100, posYPercent * standardSizes.pitchWidth / 100]
    var dist = Math.sqrt(Math.pow(torMitte[0]-position[0], 2) + Math.pow(torMitte[1] - position[1], 2));
    return dist.toFixed(1)
}

const getPlayerDistance = function(x_p1, y_p1, x_p2, y_p2) {
    var player1 = [x_p1 * standardSizes.pitchHeight / 100, y_p1 * standardSizes.pitchWidth / 100];
    var player2 = [x_p2 * standardSizes.pitchHeight / 100, y_p2 * standardSizes.pitchWidth / 100];
    var dist = Math.sqrt(Math.pow(player1[0]-player2[0], 2) + Math.pow(player1[1] - player2[1], 2));
    return dist.toFixed(1)
}


export const analytics = {
    isFreekickSituationDangerous,
    isStandardSituation,
    dangerousStandards,
    isPassSuccessful,
    isPassAssist,
    standardSizes,
    getGoalDistance,
    getPlayerDistance,
    getSituationLabels
};