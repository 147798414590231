export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bom dia"])},
  "dictionary": {
    "goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portões"])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meta"])},
    "shots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiros"])},
    "shot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tomada"])},
    "shots_on_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiros"])},
    "shot_on_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tomada"])},
    "corners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escanteio"])},
    "corner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["canto"])},
    "attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["graus"])},
    "attempts_on_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conclusão no portão"])},
    "headers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolas de cabeça"])},
    "headers_on_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolas de cabeça no portão"])},
    "header_goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cabeçalho"])},
    "opponent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oponente"])},
    "assists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auxilia"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salve no computador"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abortar"])},
    "all_opponents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os oponentes"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casa"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentários"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sair"])},
    "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["imprimir"])},
    "dataprivacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["privacidade"])},
    "agbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termos e Condições Gerais"])},
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os direitos reservados"])}
  },
  "sidemenu": {
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Painel"])},
    "matches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encontros"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["equipe"])}
  },
  "home": {
    "registrationBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registre -se gratuitamente"])},
    "header": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtenha o melhor da sua equipe"])},
      "line1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planeje e analise seu jogo como os profissionais."])},
      "line2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu"])},
      "line2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De uma nova perspectiva"])},
      "rotation": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["equipe"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise de vídeo"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destaques"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progresso"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["equipe"])}
      ]
    },
    "benefits": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O que o Kick17 oferece a você?"])},
      "summary_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gostaríamos de subir para a próxima liga com você e seu time."])},
      "summary_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O Kick17 oferece ajuda moderna para todas as fases - antes, durante e depois do jogo."])},
      "video": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu resumo de vídeo"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desenhe o jogo com uma câmera arbitrária <B> e faça o upload do vídeo. \n Nosso algoritmo cria um resumo das cenas mais emocionantes para você. \n Para que você possa chegar ao ponto com sua equipe e não precisa pesquisar por muito tempo. \n  Além das situações reconhecidas automaticamente, é possível integrar as situações que você definiu no resumo. Use o <b> kick17-timer </b> na borda do campo e ainda use o marcador enquanto a situação acontece ao vivo."])}
      },
      "report": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu relatório de jogo"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Além do resumo, você obtém estatísticas sobre fotos e oportunidades, valores XG-Lite em comparação direta com a equipe adversária. É assim que você aprenderá quem teve as melhores chances e onde sua equipe está alcançando."])}
      },
      "progress": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Análise e progresso da equipe"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gostaria de saber se o seu pressentimento sobre o desempenho da equipe está correto? \n  O painel Kick17 mostra seus sucessos na forma de estatísticas e mapas de calor."])}
      }
    },
    "howto": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Como funciona o kick17?"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As letras \"ki\" de \"kick17\" representam <b> inteligência artificial </b>. \n Sua gravação em vídeo do último encontro é reduzida e analisada automaticamente. <br/> Você só precisa fazer o seguinte:"])},
      "camera": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configurar a câmera de ação"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construa uma câmera de ação arbitrária <strong> </strong> (por exemplo, GoPro) à margem e comece a gravar o jogo de maneira muito conveniente. A câmera não é necessária. O Kick17 funciona com todas as câmeras, então você é poupado de altos custos de investimento."])}
      },
      "upload": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subir o vídeo"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["É isso. Kick17 faz o resto para você! Seu resumo e análise de vídeo serão desenvolvidos automaticamente."])}
      }
    },
    "prices": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preços"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O que isso custa?"])},
      "subscriptions": {
        "amateur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Principiante"])},
        "basis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base"])},
        "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profissional"])},
        "month_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<Span class = \"text-small-weight-normal ml-2\">/ mês </span>"])},
        "individual_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<Span class = \"text-small-font-normal ml-2\"> oferta individual </span>"])},
        "teams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equipe"])},
        "matches_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jogos / mês"])},
        "situation_marker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu marcador de situação"])},
        "video_summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumo do vídeo"])},
        "match_statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play Statistics"])},
        "saison_statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estatísticas da temporada"])},
        "player_statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estatísticas do jogador"])},
        "competitor_analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analise competitiva"])}
      },
      "tryout": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experimente Grátis"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Você quer aprender mais? Contacte-nos para mais informações. \n Estamos felizes em mostrar mais detalhes e analisar o primeiro vídeo para você gratuitamente."])}
      },
      "abo_details": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhes da assinatura"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As assinaturas anuais podem ser concluídas por 10 ou 12 meses."])}
      }
    }
  },
  "matchlist": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boas vindas calorosas"])},
    "nomatches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Até agora você não teve encontros."])},
    "opponent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oponente"])},
    "createfirstmatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crie o primeiro encontro"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Começo"])},
    "at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por aí"])},
    "startunknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Começo do jogo desconhecido"])},
    "finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fim de jogo"])},
    "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jogo de jogo"])}
  },
  "match": {
    "video_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encontro com %s em"])},
    "video_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comente"])},
    "video_share": {
      "clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar link em área de transferência"])},
      "newtab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abra o vídeo em uma nova guia"])},
      "instagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram disponível em breve"])},
      "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook disponível em breve"])}
    },
    "situations": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situações"])},
      "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meta"])},
      "freekick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kick grátis"])},
      "interesting_situation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situação interessante"])},
      "attempt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tomada"])}
    },
    "report": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relatório do jogo"])},
      "goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portões"])},
      "is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["é"])},
      "should": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alvo"])},
      "attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiros"])},
      "on_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiros"])},
      "corners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escanteio"])}
    },
    "corners": {
      "left_side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lado esquerdo"])},
      "right_side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lado direito"])}
    },
    "attempts": {
      "players_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenhum jogador foi encontrado para esta ação"])}
    }
  },
  "expected_goals": {
    "xg_curve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curva XG"])},
    "expected_goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objetivos esperados"])},
    "minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minuto"])},
    "my_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meu time"])}
  },
  "dashboard": {
    "myteam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meu time"])},
    "analyzed_matches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encontros capturados"])},
    "analyzed_situations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analisou situações"])},
    "last_matches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimos encontros"])}
  },
  "feedback": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Queremos melhorar"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conte -nos o que podemos fazer melhor </strong> ou desejar -lhe <strong> novas funções. </strong> <br> Levamos sua solicitação a sério e reagimos imediatamente com melhorias."])},
    "your_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seu retorno"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandar"])},
    "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obrigado pelo seu feedback"])},
    "positive_negative_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estamos ansiosos por críticas positivas e negativas."])},
    "more_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais feedback?"])}
  }
}