import { createStore } from 'vuex'

// Create a new store instance.
const editorStore = createStore({
  state () {
    return {

      match: null,
      team: null,
      situationPlayersList: [],
      isPlayEditing: false,
      isPlaying: false
    }
  },

  mutations: {
    isPlaying(state, isplaying) {
      state.isPlaying = isplaying
    },
    setTeam (state, team) {
      state.team = team
    },
    isPlayEditing (state, yes) {
      state.isPlayEditing = yes
    },

    setMatch (state, match) {
      if(match.situations != null && match.situations.length > 1) {
        
        match.situations = match.situations.sort(function(a, b){
            return a.situation_start - b.situation_start;
        })
      }
      state.match = match
     
    },
    setSituationPlayersList (state, situationPlayersList) {
      state.situationPlayersList = situationPlayersList
    }
  },
  getters: {
    isPlaying (state) {
      return state.isPlaying
    },
    isPlayEditing (state) {
      return state.isPlayEditing
    },
    getSituationPlayersList (state) {
      return state.situationPlayersList
    },
    getMatch(state) {
      return state.match
    },

    getAllSituations(state, getters) {
      var match = getters.getMatch
      if(match) {
        return match.situations
      } else {
        return []
      }

    },
    getAllSituationPositions(state, getters) {
        var allSituationPositions = []
        getters.getAllSituations.forEach(sit => allSituationPositions = allSituationPositions.concat(sit.situationPositions))
        return allSituationPositions
    },
    getTeam(state) {
      return state.team
    },

  }
})

export default editorStore