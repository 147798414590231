<template>
    


    <div v-if="matchList != null">
            <table class="table borderless">
                
                <tbody>
                    

                    <tr>
                        <th scope="row"></th>
                        <th>Tor</th>
                        <th>Gegentor</th>
                    </tr>
                    <tr>
                        <th scope="row">Freistoß</th>
                        <td>{{myFreekickSituations.length == 0 ? 0 : Number(100 * mySuccessfulFreekickSituations.length / myFreekickSituations.length).toFixed(0)}}% ({{mySuccessfulFreekickSituations.length}}/{{myFreekickSituations.length}})</td>
                        <td>{{theirFreekickSituations.length == 0 ? 0 : Number(100 * theirSuccessfulFreekickSituations.length / theirFreekickSituations.length).toFixed(0)}}% ({{theirSuccessfulFreekickSituations.length}}/{{theirFreekickSituations.length}})</td>
                    </tr>
                    <tr>
                        <th scope="row">Elfmeter</th>
                        <td>{{myPenaltySituations.length == 0 ? 0 :Number(100 * mySuccessfulPenaltySituations.length / myPenaltySituations.length).toFixed(0)}}% ({{mySuccessfulPenaltySituations.length}}/{{myPenaltySituations.length}})</td>
                        <td>{{theirPenaltySituations.length == 0 ? 0 : Number(100 * theirSuccessfulFPenaltySituations.length / theirPenaltySituations.length).toFixed(0)}}% ({{theirSuccessfulFPenaltySituations.length}}/{{theirPenaltySituations.length}})</td>
                    </tr>
                    <tr>
                        <th scope="row">Eckstoß</th>
                        <td>{{myCornerSituations.length == 0 ? 0 : Number(100 * mySuccessfulCornerSituations.length / myCornerSituations.length).toFixed(0)}}% ({{mySuccessfulCornerSituations.length}}/{{myCornerSituations.length}})</td>
                        <td>{{theirCornerSituations.length == 0 ? 0 : Number(100 * theirSuccessfulCornerSituations.length / theirCornerSituations.length).toFixed(0)}}% ({{theirSuccessfulCornerSituations.length}}/{{theirCornerSituations.length}})</td>
                    </tr>
                    

                    
                    
                </tbody>
            </table>
        
    </div>


</template>


<style scoped lang="scss">
@import '~bootstrap/scss/bootstrap.scss';
    .clublogo {
        width: 40px;
        height: 40px;
    }

    .table td{
        text-align: center;   
    }

    .table th{
        text-align: center;   
    }

    .borderless td, .borderless th {
        border: none;
    }

</style>

<script>
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { analytics } from '@/services';

export default defineComponent({
    
    setup() {

        const store = useStore()

        var dangerousSituations = computed(() => {
            if(matchList.value == null) {return null}
			var situations = []
			matchList.value.forEach(m => situations = situations.concat(m.situations))
			var dangerousSits = situations.filter(s => analytics.isStandardSituation(s))
			dangerousSits = dangerousSits.filter(sit => sit.situationPositions.some(s => analytics.isFreekickSituationDangerous(sit) || s.action_labels.includes("Freistoß") == false))
			return dangerousSits
        })

        var matchList = computed(() => {
            return store.getters.getMatchList
        })

        var mySuccessfulFreekickSituations = computed(() => {
            return myFreekickSituations.value.filter(sit => sit.situationPositions.some(sitpo => sitpo.isInCreatorTeam == true && sitpo.action_labels.includes("Tor")))
        })

        var myFreekickSituations = computed(() => {
            return allFreekickSituations.value.filter(sit => sit.situationPositions.some(sitpo => sitpo.isInCreatorTeam == true && sitpo.action_labels.includes("Freistoß")))
        })

        var theirSuccessfulFreekickSituations = computed(() => {
            return theirFreekickSituations.value.filter(sit => sit.situationPositions.some(sitpo => sitpo.isInCreatorTeam == false && sitpo.action_labels.includes("Tor")))
        })

        var theirFreekickSituations = computed(() => {
            return allFreekickSituations.value.filter(sit => sit.situationPositions.some(sitpo => sitpo.isInCreatorTeam == false && sitpo.action_labels.includes("Freistoß")))
        })

        var allFreekickSituations = computed(() => {
            var freekickSituations = []
            freekickSituations = dangerousSituations.value.filter(sit => {
                
                var sitpos = sit.situationPositions.filter(sitpo => sitpo.action_labels.includes("Freistoß"))
                return sitpos.length > 0
            })
            return freekickSituations
        })



         var mySuccessfulPenaltySituations = computed(() => {
            return myPenaltySituations.value.filter(sit => sit.situationPositions.some(sitpo => sitpo.isInCreatorTeam == true && sitpo.action_labels.includes("Tor")))
        })

        var myPenaltySituations = computed(() => {
            return allPenaltySituations.value.filter(sit => sit.situationPositions.some(sitpo => sitpo.isInCreatorTeam == true && sitpo.action_labels.includes("Elfmeter")))
        })

        var theirSuccessfulFPenaltySituations = computed(() => {
            return theirPenaltySituations.value.filter(sit => sit.situationPositions.some(sitpo => sitpo.isInCreatorTeam == false && sitpo.action_labels.includes("Tor")))
        })

        var theirPenaltySituations = computed(() => {
            return allPenaltySituations.value.filter(sit => sit.situationPositions.some(sitpo => sitpo.isInCreatorTeam == false && sitpo.action_labels.includes("Elfmeter")))
        })

        var allPenaltySituations = computed(() => {
            var PenaltySituations = []
            PenaltySituations = dangerousSituations.value.filter(sit => {
                
                var sitpos = sit.situationPositions.filter(sitpo => sitpo.action_labels.includes("Elfmeter"))
                return sitpos.length > 0
            })
            return PenaltySituations
        })



        var mySuccessfulCornerSituations = computed(() => {
            return myCornerSituations.value.filter(sit => sit.situationPositions.some(sitpo => sitpo.isInCreatorTeam == true && sitpo.action_labels.includes("Tor")))
        })

        var myCornerSituations = computed(() => {
            return allCornerSituations.value.filter(sit => sit.situationPositions.some(sitpo => sitpo.isInCreatorTeam == true && sitpo.action_labels.includes("Eckstoß")))
        })

        var theirSuccessfulCornerSituations = computed(() => {
            return theirCornerSituations.value.filter(sit => sit.situationPositions.some(sitpo => sitpo.isInCreatorTeam == false && sitpo.action_labels.includes("Tor")))
        })

        var theirCornerSituations = computed(() => {
            return allCornerSituations.value.filter(sit => sit.situationPositions.some(sitpo => sitpo.isInCreatorTeam == false && sitpo.action_labels.includes("Eckstoß")))
        })

        var allCornerSituations = computed(() => {
            var CornerSituations = []
            CornerSituations = dangerousSituations.value.filter(sit => {
                
                var sitpos = sit.situationPositions.filter(sitpo => sitpo.action_labels.includes("Eckstoß"))
                return sitpos.length > 0
            })
            return CornerSituations
        })



        return {
            allFreekickSituations,
            myFreekickSituations,
            mySuccessfulFreekickSituations,
            theirSuccessfulFreekickSituations,
            theirFreekickSituations,
            mySuccessfulPenaltySituations,
            myPenaltySituations,
            theirSuccessfulFPenaltySituations,
            theirPenaltySituations,
            theirCornerSituations,
            theirSuccessfulCornerSituations,
            myCornerSituations,
            mySuccessfulCornerSituations,
            matchList
        }


    }
});


</script>