<template>
  <div>

    <div id="loginBanner" class="bg-cover py-5 text-center" style="border-radius: 0;">
      <div class="container py-5 text-center text-white">
        <img class="center" style="width: 7%;" src="../../assets/kick17.png">

        <div class="font-weight-bold mb-0 text-uppercase" style="display: inline-block; text-align: left;">Kick17 <br>
          <small style="font-size: 65%; float: left;">Be professional!</small>
        </div>

      </div>
    </div>

    <div class="container">
      <div class="row justify-content-center py-5">

        <div class="signup-form" v-if="LoginFormVisible">

          <h5 class="card-title text-center">Log In</h5>
          <div class="form-signin form-inline">

            <div class="form-label-group">
              <span class="p-float-label">
                <InputText id="email" class="w-100" type="text" v-model="email" />
                <label for="email">E-Mail</label>
              </span>
            </div>
            <div class="form-label-group">
              <span class="p-float-label w-100">
                <Password id="password" class="w-100" v-model="loginform.password" toggleMask v-on:keyup.enter="login()"
                  :feedback="false" inputStyle="width: 100%;"></Password>
                <label for="password">Passwort</label>
              </span>
            </div>



            <Button label="Login" class="p-button-raised w-100 mb-3" @click="login()" :loading="loading" />

            <div>
              <Button label="Registrieren" style="float: right" class="p-button-text p-button-plain"
                @click="showSignUpForm()" />
              <Button label="Passwort vergessen" class="p-button-text p-button-plain" @click="showResetPWForm()" />
            </div>

          </div>
        </div>

        <div class="signup-form" v-if="ResetPWFormVisible">
          <h5 class="card-title text-center">Password zurücksetzen</h5>
          <div class="form-signin">

            <div class="form-label-group">
              <span class="p-float-label">
                <InputText id="email" class="w-100" type="text" v-model="email" />
                <label for="email">E-Mail</label>
              </span>
            </div>

            <Button label="Passwort zurücksetzen" class="p-button-raised w-100 mb-3" @click="resetPassword()"
              :loading="loading" />

            <div>
              <Button label="Login" class="p-button-text p-button-plain" @click="showLoginForm()" />
            </div>
          </div>
        </div>

        <div class="signup-form" v-if="RegisterFormVisible">

          <h5 class="card-title text-center">Kostenlos registrieren</h5>
          <div class="form-signin">


            <div class="form-label-group">
              <span class="p-float-label">
                <InputText id="email" class="w-100" type="text" v-model="email" />
                <label for="registeremail" class="formlabel">E-Mail</label>
              </span>
              <InlineMessage v-if="emailFormatOK == false && email!=''">E-Mail ungültig</InlineMessage>
            </div>

            <div class="form-label-group">
              <span class="p-float-label">
                <Password id="password" class="w-100" v-model="registerform.password" toggleMask :feedback="false"
                  inputStyle="width: 100%;"></Password>
                <label for="password">Passwort</label>
              </span>
              <InlineMessage severity="warn" v-if='passwordValidation.errors.length > 0 && !submitted'>
                <div class="card-title" style="font-weight: bolder; font-size: x-small;">Passwortregeln:</div>
                <ul>
                  <li style="font-size: x-small;" v-for='(error, index) in passwordValidation.errors'
                    v-bind:key="index">{{error}}</li>
                </ul>
              </InlineMessage>
            </div>

            <div class="form-label-group">
              <span class="p-float-label">
                <Password id="password2" class="w-100" v-model="registerform.password_2" toggleMask :feedback="false"
                  inputStyle="width: 100%;"></Password>
                <label for="password2">Passwort Wiederholen</label>
              </span>
            </div>


            <div class="matches" style="color: red;" v-if='notSamePasswords'>
              <p>Die Passwörter müssen übereinstimmen!</p>
            </div>

            <div class="form-label-group">
              <span class="p-float-label">
                <InputText id="registerinputFirstName" class="w-100" type="text" v-model="registerform.firstname" />
                <label for="registerinputFirstName">Vorname</label>
              </span>
            </div>

            <div class="form-label-group">
              <span class="p-float-label">
                <InputText id="registerinputLastName" class="w-100" type="text" v-model="registerform.lastname" />
                <label for="registerinputLastName">Nachname</label>
              </span>
            </div>

            <div class="form-label-group">
              <span class="p-float-label">
                <InputText id="registerinputClub" class="w-100" type="text" v-model="registerform.club" />
                <label for="registerinputClub">Dein Club</label>
              </span>
            </div>

            <div class="form-label-group">
              <Dropdown class="w-100" v-model="registerform.ageband" :options="agebands" optionLabel="name"
                optionValue="name" placeholder="Wähle eine Altersklasse" />
            </div>

            <div class="form-label-group">
              <Dropdown class="w-100" v-model="registerform.team" :options="teams" optionLabel="name"
                optionValue="value" placeholder="Wähle ein Team" />
            </div>




            <div class="form-check">
              <input class="form-check-input" type="checkbox" v-model="agb_accepted" id="flexCheckDefault">
              <label class="form-check-label" for="flexCheckDefault">
                Ich akzeptiere die <a href="#/agbs" target="_blank" class="link-dark">AGBs</a>
              </label>
            </div>



            <Button :disabled="!registrationDataComplete" label="Registrieren" class="p-button-raised w-100 mb-3"
              @click="register()" :loading="loading" />

            <div>
              <Button label="Login" class="p-button-text p-button-plain" @click="showLoginForm()" />
            </div>

          </div>
        </div>

        <div class="signup-form" v-if="registrationSuccessfulFormVisible">
          <div class="row justify-content-center">
            <div class="signup-form">
              <h5 class="card-title text-center">Dein Registrierungslink wurde versendet
                <i class="far fa-paper-plane" style="color: green;"></i>
              </h5>
              <div class="text-center">Bitte prüfe Dein Postfach und klicke auf den Bestätigungslink. (In manchen Fällen
                landet die E-Mail im Spamordner)</div>
              <div class="text-center py-3">
                <button type="button" class="btn btn-outline-dark" @click="showLoginForm()">Login</button>
              </div>
            </div>
          </div>
        </div>

        <div class="signup-form" v-if="passwortResetSuccessfulFormVisible">
          <div class="row justify-content-center">
            <div class="signup-form">
              <h5 class="card-title text-center">Eine E-Mail zum Zurücksetzen des Passworts wurde versendet
                <i class="far fa-paper-plane" style="color: green;"></i>
              </h5>
              <div class="text-center">Bitte prüfe Dein Postfach. (In manchen Fällen landet die E-Mail im Spamordner)
              </div>
              <div class="text-center py-3">
                <button type="button" class="btn btn-outline-dark" @click="showLoginForm()">Login</button>
              </div>
            </div>
          </div>
        </div>


        <div class="signup-form" v-if="userNotConfirmedFormVisible">
          <div class="row justify-content-center">
            <div class="signup-form">
              <h5 class="card-title text-center">Dein Account wurde noch nicht bestätigt
                <i class="far fa-meh" style="color: red;"></i>
              </h5>
              <div class="text-center">Bitte prüfe Dein Postfach und klicke auf den Bestätigungslink. (In manchen Fällen
                landet die E-Mail im Spamordner)</div>
              <div class="text-center py-3">
                <Button label="Login" class="p-button-raised w-100 mb-3"
                  @click="userNotConfirmedFormVisible=false, LoginFormVisible = true" />
                <Button label="Ich habe keine E-Mail erhalten" class="p-button-raised w-100 mb-3"
                  @click="resendEmailVerification()" :loading="loading" />
              </div>
            </div>
          </div>
        </div>

        <div class="signup-form" v-if="emailNotSentFormVisible">
          <div class="row justify-content-center">
            <div class="signup-form">
              <h5 class="card-title text-center">Die E-Mail konnte nicht anDich gesendet werden.
                <i class="far fa-paper-plane" style="color: red;"></i>
              </h5>
              <div class="text-center">Bitte kontaktiere uns via info@kick17.de</div>
              <div class="text-center py-3">
                <button type="button" class="btn btn-outline-dark" @click="showLoginForm()">Login</button>
              </div>
            </div>
          </div>
        </div>




      </div>
    </div>

  </div>

</template>
<style scoped lang="scss">
@import '~bootstrap/scss/bootstrap.scss'; 

@import './signup.css';

.btn-link {
  color: #545b62
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.signup-form {
  width: 100%;
}


#loginBanner {
  background-color:white;
  /*background: url('../../assets/freekick.jpg') black no-repeat center center scroll;*/
}


</style>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/auth';  

import { usermanager } from '@/services';
import { kick17 } from '@/services';



export default {
  name: 'app',
  
  data() {
    
    return {
        agb_accepted: false,
        loading: false,
        email: '',
        LoginFormVisible: true,
        RegisterFormVisible: false,
        ResetPWFormVisible: false,
        registrationSuccessfulFormVisible: false,
        passwortResetSuccessfulFormVisible: false,
        userNotConfirmedFormVisible: false,
        emailNotSentFormVisible: false,
        loginform: {
          password: ''
        },
        registerform: {
          firstname: '',
          lastname: '',
          password: '',
          password_2: '',
          club: '',
          team: '',
          ageband: ''
        },
        passwortrules: [
          { message:'Kleine Buchstaben', regex:/[a-z]+/ },
          { message:"Große Buchstaben",  regex:/[A-Z]+/ },
          { message:"Mindestens 8 Zeichen.", regex:/.{8,}/ },
          { message:"Mindestens 1 Ziffer", regex:/[0-9]+/ }
        ],
        passwordVisible:false,
        submitted:false,
        password:'',
        checkPassword2:'',
        teams: kick17.getAllTeamClasses().data,
        agebands: kick17.getAgebands().data,

        passwordFormatOK: true,
        reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
      
    }
  },
  async created() {
    var params = this.$route.query
    if(!(params.LoginFormVisible === !undefined) && !(params.RegisterFormVisible === !undefined)) {
        this.LoginFormVisible = params.LoginFormVisible == "false" ? false : true
        this.RegisterFormVisible = params.RegisterFormVisible == "true" ? true :false
    } 
  },
  computed: {
    emailFormatOK: function(){
      var returnValue = (this.checkEmail() == true)? true : false
      return returnValue
    }, 

    notSamePasswords: function () {
			if (this.passwordsFilled) {
				return (this.registerform.password !== this.registerform.password_2)
			} else {
				return false
			}
		},
    registrationDataComplete: function() {
      if(this.emailFormatOK && this.passwordValidation.valid == true && this.notSamePasswords!==true && this.agb_accepted==true && this.nameFilled == true) {
        return true 
      } else {
        return false
      }
    },
    passwordsFilled: function() {
			return (this.registerform.password !== '' && this.registerform.password_2 !== '')
		},

    nameFilled: function() {
      return (this.registerform.firstname !== '' && this.registerform.lastname !== '')
    },
		
		
		passwordValidation: function() {
			let errors = []
			for (let condition of this.passwortrules) {
				if (!condition.regex.test(this.registerform.password)) {
					errors.push(condition.message)
				}
			}
			if (errors.length === 0) {
				return { valid:true, errors }
			} else {
				return { valid:false, errors }
			}
		}
	},

  methods: {
    checkEmail(){
      if(this.email == null || this.email == '')
      {
        return false
      }
      else if(!this.reg.test(this.email))
      {
        return false
      }
      return true
    },
    checkPassword() {
      if (this.registerform.password == this.registerform.password_2 && this.registerform.password != "") {
        return true
      }
      return false
    },
    
    async resetPassword() {
      this.loading = true
      var result = await usermanager.forgotpassword(this.email)
      
      if(result.status == 200) {
        this.showPasswortResetSentEmailForm()
      } else {
        //Swal.fire("Fehler!", result.data.message, "error")
      }
      this.loading = false
      
    },

    async resendEmailVerification() {
      this.loading = true
      var result = await usermanager.resendverification(this.email)
      
      if(result.status == 200) {
        this.showRegistrationSuccessfulForm()
      } else {
        this.showEmailNotSentFormVisibleForm()
      }
      this.loading = false
    },
    
    

    

    async register() {
      if(this.registrationDataComplete == true) {
        this.loading = true
        await firebase.auth().createUserWithEmailAndPassword(this.email, this.registerform.password)
            .then(data => {
              data.user
                .updateProfile({
                  firstName: this.registerform.firstname,
                  lastName: this.registerform.lastname,
                  displayName: this.registerform.firstname + " " + this.registerform.lastname
                })
                .then(() => {
                  
                  //data.user.sendEmailVerification().then(this.showRegistrationSuccessfulForm()).catch(function() {
                    // An error happened.
                  //});

                }).catch(err => {
                  this.error = err.message;
                });
            })
            .catch(err => {
              this.registrationFailed(err)
              
            });
            await usermanager.register(this.email, this.registerform.club, this.registerform.team, this.registerform.ageband)
            this.showRegistrationSuccessfulForm()
            this.loading = false
      } else {
        alert("Error")
      }
    },

    showUserNotConfirmed() {
      this.LoginFormVisible = false
      this.userNotConfirmedFormVisible = true
      this.emailNotSentFormVisible = false
    },

    async login() {
      this.loading = true
      firebase
      .auth()
      .signInWithEmailAndPassword(this.email, this.loginform.password)
      .then((userCredential) => {

        if(userCredential.user.emailVerified) {
          userCredential.user.getIdTokenResult()
            .then(function ({
                claims
              }) {
                if(claims.role == "editor") {
                  this.$router.push('/adminvideos');
                }
                else {
                  this.$router.push('/begegnungen');
                }
                  
              }.bind(this))
              
          
        } else {
          this.showUserNotConfirmed(userCredential)
        }
        
      })
      .catch(error => {
        console.log(error)
        //Swal.fire("Fehler!", "Falsches Passwort", "error")
      });
      this.loading = false
    },
    showSignUpForm() {
      this.LoginFormVisible = false
      this.RegisterFormVisible = true
      this.ResetPWFormVisible = false
      this.registrationSuccessfulFormVisible = false
      this.userNotConfirmedFormVisible = false
      this.emailNotSentFormVisible = false
    },
    showLoginForm() {
      this.LoginFormVisible = true
      this.RegisterFormVisible = false
      this.ResetPWFormVisible = false
      this.registrationSuccessfulFormVisible = false
      this.userNotConfirmedFormVisible = false
      this.emailNotSentFormVisible = false
    },
    showResetPWForm() {
      this.LoginFormVisible = false
      this.RegisterFormVisible = false
      this.ResetPWFormVisible = true
      this.registrationSuccessfulFormVisible = false
      this.userNotConfirmedFormVisible = false
      this.emailNotSentFormVisible = false
    },
    showRegistrationSuccessfulForm() {
      this.LoginFormVisible = false
      this.RegisterFormVisible = false
      this.ResetPWFormVisible = false
      this.registrationSuccessfulFormVisible = true
      this.registerform.firstName = ""
      this.registerform.lastName = ""
      this.registerform.password_2 = ""
      this.agb_accepted = false
      this.userNotConfirmedFormVisible = false
      this.emailNotSentFormVisible = false
    },
    showPasswortResetSentEmailForm() {
      this.LoginFormVisible = false
      this.RegisterFormVisible = false
      this.ResetPWFormVisible = false
      this.registrationSuccessfulFormVisible = false
      this.passwortResetSuccessfulFormVisible = true
      this.emailNotSentFormVisible = false
    },

    registrationFailed() {
      //Swal.fire("Fehlgeschlagen!", "Für diese E-Mail existiert bereits ein Account", "error")
      this.RegisterFormVisible = false
      this.showLoginForm()
      this.registerform.firstName = ""
      this.registerform.lastName = ""
      this.registerform.password_2 = ""
      this.agb_accepted = false
      this.userNotConfirmedFormVisible = false
      this.emailNotSentFormVisible = false
    },
    showEmailNotSentFormVisibleForm() {
      this.LoginFormVisible = false
      this.RegisterFormVisible = false
      this.ResetPWFormVisible = false
      this.registrationSuccessfulFormVisible = false
      this.passwortResetSuccessfulFormVisible = false
      this.emailNotSentFormVisible = true
      this.userNotConfirmedFormVisible = false
    }
    


  }
}
</script>