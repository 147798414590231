<template>


    <svg v-if="pitchHeight != null" id="pitch-img" class="svg-aside" v-bind:style="{width: pitchWidth + 'px',  height: showSize != null ? showSize +'px' : pitchHeight + 3 * goalHeight + 'px', transform: (horizontal == true ? 'rotate(-90deg) translateY(' + (pitchHeight + 3* goalHeight - pitchWidth)/2 + 'px) translateX(' + (pitchHeight + 3* goalHeight - pitchWidth)/2 + 'px' : '')}">
        <!-- The pitch -->
        <rect class="pitch-svg" style="stroke: black;" v-bind:style="{fill: pitchColor != null ? pitchColor : '#31AA52'}" :width="pitchWidth" :height="pitchHeight" :x="x_offset" :y=goalHeight></rect>
        

        <!-- Penalty area -->
        <circle class="pitch-svg" style="stroke: black;" v-bind:style="{fill: pitchColor != null ? pitchColor : '#31AA52'}" :cx="pitchWidth / 2" :cy="penaltyPointY + goalHeight" :r="circleRadius"></circle>
        <rect class="pitch-svg" style="stroke: black;" v-bind:style="{fill: pitchColor != null ? pitchColor : '#31AA52'}" :width="sechzehnerWidth" :height="sechzehnerHeight" :x="(pitchWidth - sechzehnerWidth)/ 2 + x_offset" :y="goalHeight"></rect>
        <rect class="pitch-svg" style="stroke: black;" v-bind:style="{fill: pitchColor != null ? pitchColor : '#31AA52'}" :width="fuenferWidth" :height="fuenferHeight" :x="(pitchWidth - fuenferWidth)/ 2 + x_offset" :y="goalHeight"></rect>
        <circle class="pitch-svg" v-bind:style="{fill: pitchColor != null ? pitchColor : '#31AA52'}" :cx="pitchWidth / 2" :cy="penaltyPointY + goalHeight" r=2 style="fill: black; stroke: black;"></circle>

        <!-- Penalty area 2 -->
        <circle class="pitch-svg" style="stroke: black;" v-bind:style="{fill: pitchColor != null ? pitchColor : '#31AA52'}" :cx="pitchWidth / 2" :cy="goalHeight + pitchHeight - penaltyPointY" :r="circleRadius"></circle>
        <rect class="pitch-svg" style="stroke: black;" v-bind:style="{fill: pitchColor != null ? pitchColor : '#31AA52'}" :width="sechzehnerWidth" :height="sechzehnerHeight" :x="(pitchWidth - sechzehnerWidth)/ 2 + x_offset" :y="goalHeight + pitchHeight - sechzehnerHeight" ></rect>
        <rect class="pitch-svg" style="stroke: black;" v-bind:style="{fill: pitchColor != null ? pitchColor : '#31AA52'}" :width="fuenferWidth" :height="fuenferHeight" :x="(pitchWidth - fuenferWidth)/ 2 + x_offset" :y="goalHeight + pitchHeight - fuenferHeight"></rect>
        <circle class="pitch-svg" v-bind:style="{fill: pitchColor != null ? pitchColor : '#31AA52'}" :cx="pitchWidth / 2" :cy="2*goalHeight + pitchHeight - penaltyPointY" r=2 style="fill: black; stroke: black;"></circle>


       
        <!-- Centre circle -->
        <circle class="pitch-svg" :cx="pitchCenter.x" :cy="pitchCenter.y + goalHeight" :r="circleRadius" style="fill-opacity:0; stroke: black; fill: black;"></circle>
        <line :x1="x_offset" :y1="pitchCenter.y + goalHeight" :x2="pitchWidth + x_offset" :y2="pitchCenter.y + goalHeight" style="stroke-width: 1; stroke: black;"/>
        <circle class="pitch-svg" :cx="pitchCenter.x" :cy="pitchCenter.y + goalHeight" r=2 style="fill: black; stroke: black;"></circle>

        <!-- Goal -->
        <rect class="pitch-svg" :width="goalWidth" :height="goalHeight" :x="(pitchWidth - goalWidth)/ 2 + x_offset" y=0 style="fill:lightgray; fill-opacity:0.5; stroke: black;"></rect>
        <rect class="pitch-svg" :width="goalWidth" :height="goalHeight" :x="(pitchWidth - goalWidth)/ 2 + x_offset" :y="pitchHeight + goalHeight" style="stroke: black; fill:lightgray;fill-opacity:0.5;"></rect>
        
    </svg>

</template>

<script>
import { analytics } from '@/services';

export default {
    components: {
        
    },
    props: ['myPitchHeight', 'horizontal', 'showSize', 'pitchColor'],
    data() {
        return {
            //https://www.bfv.de/binaries/content/assets/inhalt/spielbetrieb-verbandsleben/schiedsrichter/gruppen/schwaben/donau/8313-westschwaben/flex-herren-donau-01.05.19-2.pdf

            x_offset: 0
        }
    },
    watch: {
        pitchHeight() {
            this.$emit('pitchresized')
        }
    },
    mounted() {
        /*var svg = document.querySelector('#pitch-img');
        let data = (new XMLSerializer()).serializeToString(svg)
        let svgBlob = new Blob([data], {type: 'image/svg+xml;charset=utf-8'})
        let url = URL.createObjectURL(svgBlob)
        let a = document.createElement('a')

        a.setAttribute('download', 'image.svg')
        a.setAttribute('href', url)
        a.setAttribute('target', '_blank')

        a.click()*/
    },
    async created () {
        
    },
    computed: {
        standardSizes: function() {
            return analytics.standardSizes
        },
        pitchProportion: function() {
            return this.standardSizes.pitchHeight / this.standardSizes.pitchWidth
        },
        goalHeight: function() {
            return 5
        },
        goalWidth: function() {
            return this.pitchWidth * this.standardSizes.goalWidth / this.standardSizes.pitchWidth
        },
        pitchWidth: function() {
            var res = this.horizontal == true ? this.pitchHeight / this.pitchProportion : this.myPitchHeight
            return res
        },
        pitchHeight: function() {
            return  this.horizontal == true ? this.myPitchHeight : this.myPitchHeight * this.pitchProportion
        },
        sechzehnerHeight: function() {
            return this.standardSizes.sechzehnerHeight * this.pitchHeight / this.standardSizes.pitchHeight
        },
        sechzehnerWidth: function() {
            return this.pitchWidth * this.standardSizes.sechzehnerWidth / this.standardSizes.pitchWidth
        },
        penaltyPointY: function() {
            return 11 * this.pitchHeight / this.standardSizes.pitchHeight
        },
        fuenferHeight: function() {
            return this.standardSizes.fuenfmeterraumHeight * this.pitchHeight / this.standardSizes.pitchHeight
        },
        fuenferWidth: function() {
            return this.standardSizes.fuenfmeterraumWidth * this.pitchWidth / this.standardSizes.pitchWidth
        },
        pitchCenter: function() {
            return {x: this.pitchWidth / 2, y: this.pitchHeight / 2}
        },
        circleRadius: function() {
            return this.standardSizes.kreisRadius * this.pitchHeight / this.standardSizes.pitchHeight
        }
    },
    methods: {
        getWidthHeightProportion() {
            return 1 / this.pitchProportion
        }
    }
}

</script>

<style scoped>
.pitch-svg {
    stroke-width: 1;
    stroke: black;
}
.svg-aside {
    align: center;
    top: 0;
    position: absolute;
}
</style>