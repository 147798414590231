<template>
<div>
  <Spinner v-if="loaded == false"></Spinner>
  <div v-else-if="matchList.length == 0" class="layout-main m-auto md:col-7 ">
   <div class="container py-5">
                <div class="row py-5">
                    <div class="col-lg-7 mx-auto">

                        <div >
                            <div>
                                <h4 class="text-center">{{ $t('matchlist.welcome') }}
                                     
                                </h4>
                                <div class="text-center">{{ $t('matchlist.nomatches') }}</div>
                                
                                <div class="text-center py-3">
                                  <Button class="p-button-outlined p-button-secondary" @click="addMatch()">{{ $t('matchlist.createfirstmatch') }}</Button>
                              </div>

                            </div>
                        </div>
                    </div>
                </div>        
            </div>
  </div>
  <div v-else class="layout-main-container ">
    <div class="layout-main m-auto md:col-7" style="padding-left: 2rem; padding-right: 2rem; padding-top: 2rem;">
      <div class="grid">
        <div class="col-12 md:col-6 lg:col-6 xl:col-3" v-for=" (m, index) in matchList" v-bind:key="index" onmouseover="" style="cursor: pointer;" @click="showMatchDetails(m.uuid)">
          <div class="card mb-0" >
            <div class="flex justify-content-between">
              <div>
                <span class="block text-500 font-medium">{{getTeamName(m.opponent_team)}}</span>
                <div class="text-900 font-medium text-xl" v-if="m.isFinished != false">{{m.creator_team_goals}} : {{m.opponent_team_goals}}</div>
              </div>
              <div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
                
                <img class="z-depth-2 float-right" style="width: 100px; max-width: 100%; background-color: white;"  :src="imagePath + m.opponent_team.icon" @error="defaultLogo" alt="" data-holder-rendered="true">
             
              </div>
            </div>
            
            
            <span class="text-500" v-if="matchInFuture(m.match_date)"> {{ $t('matchlist.start') }}: {{(new Date(m.match_date)).getDate()}}.{{(new Date(m.match_date)).getMonth()}}.{{(new Date(m.match_date)).getFullYear()}}
              {{ $t('matchlist.at') }} {{(new Date(m.match_date)).getHours()}}:{{(new Date(m.match_date)).getMinutes()}} Uhr
            </span>
            <span v-else-if="m.match_date == null">{{ $t('matchlist.startunknown') }}</span>
            <span v-else-if="matchInFuture(m.match_date) == false && m.isFinished != true"><i class="far fa-futbol rotate text-success"></i> {{ $t('matchlist.ongoing') }}</span>
            <span v-else>{{ $t('matchlist.finished') }}</span>
          </div>
        </div>
      </div>
		</div>
  </div>
  <!--<button v-if="matchList==null || matchList.length == 0" style="right: 2em; position: fixed; bottom: 2em;" type="button" class="btn btn-outline-dark btn-circle btn-xl pulse-button" @click="addMatch()"><i class="pi pi-plus" style="fontSize: 2rem"></i></button>-->
  <button v-if="matchList != null && matchList.length != 0" style="right: 2em; position: fixed; bottom: 2em;" type="button" class="btn btn-outline-dark btn-circle btn-xl" @click="addMatch()"><i class="pi pi-plus" style="fontSize: 2rem"></i></button>

</div>
</template>

<style scoped lang="scss">
@import '~bootstrap/scss/bootstrap.scss'; 

.jumbotron {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.rotate {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    border-radius: 35px;
    font-size: 24px;
    line-height: 1.33;
}

.btn-circle {
    width: 30px;
    height: 30px;
    padding: 6px 0px;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.42857;
}
.pulse-button {
  
 
  -webkit-animation: pulse 1.5s infinite;
}
.pulse-button:hover {
  -webkit-animation: none;
}

/*@-webkit-keyframes pulse {
  0% {
    @include transform(scale(.9));
  }
  70% {
    @include transform(scale(1));
    box-shadow: 0 0 0 50px rgba(#5a99d4, 0);
  }
    100% {
    @include transform(scale(.9));
    box-shadow: 0 0 0 0 rgba(#5a99d4, 0);
  }
}*/

</style>

<script>
import { kick17 } from '@/services';
import Spinner from './Basics/Spinner'

export default {
  name: 'app',
    components: {
      Spinner
    },
    data() {
      return {
        loaded: false,
        matchData: {
          club_opponent: null,
          ageband_opponent: null,
          team_opponent_class: null,
          date: ""
        },
        //myclub: null,
        imagePath:"",
        selectedFile: null,
        downloadedImages: [],
        profileReady : false,
        myteam: null,
        clubLogo: null,
        allClubs: null,
        allAgebands: null,
        allTeamClasses: null
        
      }
    },
  async created () {
    //console.log(process.env.VUE_APP_USERPLATFORM_URL)
    //this.loadMyProfile()
    await this.createMatchList()

    this.imagePath = kick17.getClubLogoReadURL()
    //var res = await kick17.getmyclub()
    //this.myclub = res.data
  },
  computed: {
    matchList: function() {
      var a = this.$store.getters.getMatchList
      if(a != null) {
        return JSON.parse(JSON.stringify(a)).reverse()
      }
      return []
		},
  },
  methods: {
    matchInFuture(date) {
      var now = new Date()
      var matchDate = new Date(date)
      return matchDate - now > 0 ? true : false
    },
    
    defaultLogo(event) { 
      event.target.src = require("../assets/kick17_old.png") 
    },
    getTeamName(team) {
      try {
        return team.clubname //+ " " + team.name
      } catch(e) {
        return "Neues Team"
      }
    },
   
    test(image) {
      this.downloadedImages.push(image)
    },
    editProfile() {
      this.$router.push("/myprofile")
    },
    async loadMyProfile() {
      var result = await kick17.getUserProfile()
      if(result.data.team == null) {
        this.$router.push({ path: 'myprofile'})
      } else {
        this.profileReady = true
        this.myteam = result.data.team
        this.matchData.ageband_opponent = this.myteam.ageband
        this.matchData.team_opponent_class = {"id": this.myteam.id, "name": this.myteam.name}
        this.loadMyTeamLogo()
      }
    },

    async createMatchList() {
      await kick17.getMatches()
      .then(function(result) {
          this.$store.commit('setMatchList', result.data)
          this.loaded = true 
        }.bind(this)).catch(function(error) {
          if(error.toJSON().status != 200) {
            this.loaded = true
            //alert("err")
          }
        }.bind(this));
      //console.log(result)
           
    },
    async addMatch() {
        var result = await kick17.createMatch(this.matchData.club_opponent, this.matchData.ageband_opponent, this.matchData.team_opponent_class)
        this.$store.commit('setMatchUuid', result.data.uuid)
        this.$router.push({ path: '/match/'+result.data.uuid })
    },

    showAlert () {
         this.$swal('Hello Vue world!!!');
    },
    showMatchDetails(id) {
      this.$store.commit('setMatchUuid', id)
      this.$router.push({ path: '/match/'+id })
    },
    async getAllClubs() {
      var result = await kick17.getAllClubs()
      this.allClubs = result.data
    },
    selectClub(val) {
      this.matchData.club_opponent = val
    },
    selectOpponentAgeband(val) {
      this.matchData.ageband_opponent = val
    },
    selectOpponentTeamClass(val) {
      this.matchData.team_opponent_class  = val
    },
    async getAllAgebands() {
        //var result = await kick17.getAgebands()
        this.allAgebands = kick17.getAgebands()
    },
    async getAllTeamClasses() {
        var result = await kick17.getAllTeamClasses()
        this.allTeamClasses = result.data
    },
  }
}
</script>


