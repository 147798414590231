<template>


<div class="p-grid  p-ai-center vertical-container">


        <div class="box">
            <Button @click="finishMatch()" icon="pi pi-stop-circle" label="Timer beenden" class="p-button-outlined p-button-danger p-button-sm"></Button>
            <ConfirmDialog :dismissableMask="true"></ConfirmDialog>
        </div>

    <div class="p-col">
        <div class="box box-stretched">
            <h4 class="text-uppercase font-weight-bold">{{time_text}}</h4>
            <!--<span>{{time_additional_text}}</span>-->
        </div>

    </div>

    <div class="p-col box box-stretched" v-click-outside="outsideHalftimeDivClick">
        <div class="">
            <Button label="Beginn 2te Halbzeit" @click="collapse_halftime = !collapse_halftime" v-if="match.second_half == null && isOver45"  class="p-button-outlined p-button-lg p-button-secondary p-col"/>
            
            <div class="p-col mt-5" v-show="collapse_halftime">
                <div class="p-grid p-flex-column p-ai-center vertical-container">                    
                    <ScrollPanel style="width: 100%; height: 50vh;">
                        <Button v-for="(n, i) in 51" :key="i"  :label=" i == 0 ? 'Jetzt' : 'Seit ' + i + ' Min'" class="p-button-rounded p-button-secondary p-button-sm m-auto mt-3" style="width: 80%; display: block;" @click="startsecondhalf(i)"/>
                    </ScrollPanel>

                </div>
               
            </div>
        </div>
    </div>

    <div class="p-col box box-stretched" v-if="!(match.second_half == null && isOver45)" >
        <div class="" v-click-outside="outsiteNewSituationDivClick" >
            
            <Button id="newSit" class="p-button-outlined p-button-secondary fullwidthBtn"  @click="createEmptySituation()">Neue Situation</Button>
            
                    
            <div id="test" class="p-col mt-5" v-show="collapse_newsituation">
                
                
                    <transition-group tag="div" class="div-slider" :name="back? 'slideback' : 'slide'">
                        
                        
                            <div v-if="currentIndex === 0" key="1" class="card">
                                <div v-for="sit in situationlabels" :key="sit" class="mt-2">
                                    <Button class="p-button-rounded p-button-secondary m-auto fullwidthBtn" :value="sit" variant="outline-dark" @click="setSituationLabel(sit)">{{sit}}</Button>
                                </div>
                            </div>

                            <div v-if="currentIndex === 1" key="2" class="card">

                                <div class="mt-2"><Button class="p-button-rounded p-button-secondary m-auto fullwidthBtn" variant="outline-dark" @click="setIsCreatorSituation(true)">{{newsituation.label}} für uns</Button></div>
                                <div class="mt-2"><Button class="p-button-rounded p-button-secondary m-auto fullwidthBtn" variant="outline-dark" @click="setIsCreatorSituation(false)">{{newsituation.label}} für den Gegner</Button></div>
                            </div>

                            <div v-if="currentIndex === 2" key="3" class="card">
                                Beteiligte Spieler
                                

                                <Listbox v-model="newsituation.players" :options="team.players" :multiple="true" :filter="false" optionLabel="name" listStyle="max-height:250px" style="width:15rem" filterPlaceholder="Suche">
                                    <template #option="slotProps">
                                        <div class="country-item">
                                            <div>{{slotProps.option.shirtnumber}} {{slotProps.option.firstname}} {{slotProps.option.lastname}}</div>
                                        </div>
                                    </template>
                                </Listbox>

                                
                              <Button class="p-button-outlined p-button-secondary fullwidthBtn mt-3"  @click="currentIndex++">Weiter</Button>
                            </div>
                            <div v-if="currentIndex === 3" key="4" class="card">
                                
                                <div class="mt-2"><Button  variant="outline-dark" class="p-button-rounded p-button-secondary m-auto fullwidthBtn" @click="setSituationEvaluation('positive')"><i class="far fa-thumbs-up"></i> Gut</Button></div>
                                <div class="mt-2"><Button  variant="outline-dark" class="p-button-rounded p-button-secondary m-auto fullwidthBtn" @click="setSituationEvaluation('neutral')">Neutral</Button></div>
                                <div class="mt-2"><Button  variant="outline-dark" class="p-button-rounded p-button-secondary m-auto fullwidthBtn" @click="setSituationEvaluation('negative')"><i class="far fa-thumbs-down"></i> Schlecht</Button></div>
                              
                            </div>
                            <div v-if="currentIndex === 4" key="5" >
                                <div class="card">
                                    <div class="form-label-group">
                                        <input type="text" id="commentText" class="form-control" placeholder="Email address" v-model="newsituation.comment" required autofocus>
                                        <label for="commentText" class="formlabel">Kommentar (optional)</label>
                                    </div>
                                    <Button :loading="loading" variant="outline-dark" class="p-button-rounded p-button-secondary m-auto fullwidthBtn" @click="addSituation()">Speichern</Button>
                                </div>
                            </div>
                       
                    </transition-group>
                
            </div>
  
        
        </div> 
    </div> 


    <div class="p-col">
        <div class="box box-stretched">
            <SituationList style="width: 100%;" v-if="match.situations != null && match.situations.length != 0"></SituationList>
        </div> 
    </div> 


</div>


</template>

<style scoped>


.slide-leave-active,
.slide-enter-active {
  transition: 0.3s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}

.fullwidthBtn {
    margin: auto!important; 
    display: block!important; 
    width: 100%
}




</style>

<script>

import { kick17 } from '@/services';
import SituationList from './SituationList'



export default {
    components: {
        SituationList
    },
    props: ['team'],
    data() {
        return {
            minutes: 0,
            seconds: 0,
            HALF_LENGTH: 45,
            PAUSE_LENGTH: 15,
            collapse_halftime: false,
            collapse_newsituation: false,
            newsituation: null,
            open: false,
            screenView: "test",
            back: false,
            currentIndex: 0,
            timer: null,
            begin_since: 0,
            loading: false,
            

        }
    },
    mounted() {
        this.timer = window.setTimeout(this.updateDateTime, 1000);
    },
    unmounted() {
        window.clearTimeout(this.timer)
        console.log("timer cleared")
    },
    async created() {
        this.situationlabels = kick17.getSituationLabels()
    },
    computed: {
        match: function() {
            return this.$store.getters.getMatch
        },
        now: function() {
            return this.$store.getters.getNow
        },
        nachspielzeit: function() {
            if (this.minutes >= 45 && this.minutes < 52) {
                return true
            } else if(this.minutes >= 45 && this.minutes < 52) {
                console.log("hier")
            }
            return false
        },

        firsthalf: function() {
            if(this.match != null && this.match.second_half == null && this.match.isFinished != true && this.match.match_date != null) {
                return true
            }
            return false
        },
        secondhalf: function() {
            if(this.match != null && this.match.second_half != null && this.match.isFinished != true && this.match.match_date != null) {
                return true
            }
            return false
        },
        played_minute: function() {
            if(this.firsthalf) {
                return this.minutes
            }
            if(this.is_halftime_or_extension) {
                return this.HALF_LENGTH
            }
            else {
                return this.minutes - this.PAUSE_LENGTH
            }
           
            //return this.minutes
        },
        is_halftime_or_extension: function() {
            if(!this.firsthalf && this.minutes >= this.HALF_LENGTH && this.minutes < (this.HALF_LENGTH + this.PAUSE_LENGTH)) {
                return true
            }
            return false
        },
        isOver90: function() {
            return (this.minutes - (this.PAUSE_LENGTH + 2 * this.HALF_LENGTH) > 0) ? true : false
        },
        isOver45: function() {
            return this.minutes >= this.HALF_LENGTH ? true : false
        },
        time_text: function() {
            var text = ""
            if(this.firsthalf && this.isOver45) {
                text = "45'"
            }
            else if(this.firsthalf) {
                text = String(this.minutes).padStart(2, '0') + ":" + String(this.seconds).padStart(2, '0')
            }
            else if(this.secondhalf) {
                text = String(this.minutes + 45).padStart(2, '0') + ":" + String(this.seconds).padStart(2, '0')
            } else if(this.secondhalf && this.isOver90) {
                text = "90'"
            }
            else {
                text = String(this.minutes).padStart(2, '0') + ":" + String(this.seconds).padStart(2, '0')
            }
            return text
        },
        time_additional_text: function() {
            var text = ""
            if(this.is_halftime_or_extension && (this.HALF_LENGTH + this.PAUSE_LENGTH - this.minutes) <= 6 ) {
                text = "Nachspielzeit oder Halbzeit"
            } else if(this.is_halftime_or_extension  || this.match.second_half == null) {
                text = "Halbzeit"
            } else if(this.isOver90) {
                text = "Nachspielzeit oder Ende"
            } else {
                text = "Spiel läuft"
            }
            return text
        }

    },
    methods: {    
        async sendFinishMatch() {
            var result = await kick17.finishMatch(this.match.uuid)
            this.$store.commit('setMatch', result.data)
        },
        async finishMatch() {
            this.$confirm.require({
                message: 'Möchtest Du den Timer wirklich beenden?',
                header: 'Timer beenden',
                icon: 'pi pi-question-circle',
                acceptLabel: "Beenden",
                rejectLabel: "Abbrechen",
                accept: () => {
                    this.$toast.add({severity:'info', summary:'Confirmed', detail:'You have accepted', life: 3000});
                    this.sendFinishMatch()  
                },
                reject: () => {
                    this.$toast.add({severity:'error', summary:'Rejected', detail:'You have rejected', life: 3000});
                }
            });
        }, 
        next(){
            this.back = false;
            this.currentIndex++;
        },
        collapseAndClear() {
            this.newsituation = null
            this.collapse_newsituation = false
            this.currentIndex = 0
        },
    
        createEmptySituation() {
            if(this.collapse_newsituation) {
                this.collapseAndClear()
            } else {
                this.newsituation = {situation_timestamp: this.now, label: "", evaluation: "", comment: "", players: [], isCreatorSituation: false}
                this.collapse_newsituation = true
            }
        },

        updateDateTime() {
            this.$store.commit('setNow')
            var matchbegin = this.match.second_half == null ? new Date(this.match.match_date) : new Date(new Date(this.match.second_half))
            let totalSeconds = ((this.now - matchbegin)/1000).toFixed(0)
            totalSeconds %= 3600;
            this.minutes = Math.floor(totalSeconds / 60);
            this.seconds = totalSeconds % 60;
            this.timer = window.setTimeout(this.updateDateTime, 1000);
        },
        async startsecondhalf(value) {
            let secondHalfDate = new Date(this.now.getTime() - (value * 60 * 1000))
            this.collapse_halftime = false
            var result = await kick17.startsecondhalf(this.match.uuid, secondHalfDate)
            this.$store.commit('setMatch', result.data)
        },
        outsideHalftimeDivClick() {
            console.log("outsideHalftimeDivClick")
            if(this.collapse_halftime) {
                this.collapse_halftime = false
            }
        },
        outsiteNewSituationDivClick() {
            console.log("outsiteNewSituationDivClick")
            this.collapseAndClear()
        },
        setSituationLabel(value) {
            this.newsituation.label = value
            this.next()
        },
        setIsCreatorSituation(isCreatorSituation){
            this.newsituation.isCreatorSituation = isCreatorSituation
            this.next()
        },
        setSituationEvaluation(value) {
            this.newsituation.evaluation = value
            this.next()
        },
        async addSituation() {
            var playerList = []
            this.newsituation.players.forEach(p => playerList.push(p.uuid))
            
            this.loading = true
            var now = new Date(this.newsituation.situation_timestamp)
            var matchBegin = new Date(this.match.match_date)
            var diff = ((now.getTime() - matchBegin.getTime())/1000).toFixed(0)
            //console.log(diff)
            var result = await kick17.addCustomSituation(this.match.uuid, diff, this.newsituation.label, this.newsituation.evaluation, this.newsituation.comment, playerList, this.newsituation.isCreatorSituation)
            this.collapseAndClear()
            this.$store.commit('setMatch', result.data)
            this.loading = false
        }
    }
}

</script>