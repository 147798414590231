<template>
	<div class="layout-menu-container" style="height: 95%;">
		<AppSubmenu :items="model" class="layout-menu" :root="true" @onMenuItemClick="onMenuItemClick" />
	</div>
	<div class="layout-topbar-logo" style="height: 3rem; bottom: 3em; position: absolute; width: 85%; text-align: center;">
			<img style="height: -webkit-fill-available;" alt="Logo" src="kick17.png" />
		</div>
</template>

<script>
import AppSubmenu from './AppSubmenu';

export default {
	props: {
		model: Array
	},
	emits: ["onMenuItemClick"],
    methods: {
        onMenuItemClick(event) {
            this.$emit('onMenuItemClick', event);
        }
    },
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		}
	},
	components: {
		'AppSubmenu': AppSubmenu
	}
}
</script>

<style scoped>

</style>