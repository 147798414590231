<template>
    


    <div class="cornerContainer"   v-if="matchList != null">
        <div class="row justify-content-center">
            
            <div  class="col-6" ref="cornerContainer" style="position: relative;" v-bind:style="{height: showSize + 'px'}">
                <Pitch ref="cornerPitch" style="left: 0%;" :pitchColor="'#059EC4'" :showSize="showSize" @pitchresized="pitchresized" :myPitchHeight="mainDivRectangle == null ? 0 : mainDivRectangle.width" :horizontal="false"></Pitch>
            </div>
        </div>
        <div class="row justify-content-center py-3">
            <div style="width: fit-content;">
                <SelectButton v-model="selectedCreatorTeam" :options="teamSelectOptions" optionLabel="name"/>
            </div>
        </div>

        

        <div >
            <table class="table borderless">
                
                <tbody>

                    <tr>
                        <th>Linke Seite</th>
                        <th scope="row"></th>
                        <th>Rechte Seite</th>
                    </tr>
                    
                    <tr>
                        <td>{{leftCornerSituations.length}}</td>
                        <th scope="row">Eckstöße</th>
                        <td>{{rightCornerSituations.length}}</td>
                    </tr>

                    <tr>
                        <td>{{leftShotSituations.length}} <span v-if="leftCornerSituations.length != 0">({{Number(leftShotSituations.length * 100  / leftCornerSituations.length).toFixed(0) }}%)</span></td>
                        <th scope="row">Abschlüsse</th>
                        <td>{{rightShotSituations.length}} <span v-if="rightCornerSituations.length != 0">({{Number(rightShotSituations.length * 100 / rightCornerSituations.length).toFixed(0)}}%)</span></td>
                    </tr>

                    <tr>
                        <td>{{leftTargetSituations.length}}  <span v-if="leftCornerSituations.length != 0">({{Number(leftTargetSituations.length * 100  / leftCornerSituations.length).toFixed(0) }}%)</span></td>
                        <th scope="row">Abschlüsse aufs Tor</th>
                        <td>{{rightTargetSituations.length}}  <span v-if="rightCornerSituations.length != 0">({{Number(rightTargetSituations.length * 100 / rightCornerSituations.length).toFixed(0)}}%)</span></td>
                    </tr>

                    <tr>
                        <td>{{leftHeadSituations.length}}  <span v-if="leftCornerSituations.length != 0">({{Number(leftHeadSituations.length * 100  / leftCornerSituations.length).toFixed(0) }}%)</span></td>
                        <th scope="row">Kopfbälle</th>
                        <td>{{rightHeadSituations.length}}  <span v-if="rightCornerSituations.length != 0">({{Number(rightHeadSituations.length * 100 / rightCornerSituations.length).toFixed(0)}}%)</span></td>
                    </tr>

                    <tr>
                        <td>{{leftHeadTargetSituations.length}}  <span v-if="leftCornerSituations.length != 0">({{Number(leftHeadTargetSituations.length * 100  / leftCornerSituations.length).toFixed(0) }}%)</span></td>
                        <th scope="row">Kopfbälle aufs Tor</th>
                        <td>{{rightHeadTargetSituations.length}}  <span v-if="rightCornerSituations.length != 0">({{Number(rightHeadTargetSituations.length * 100 / rightCornerSituations.length).toFixed(0)}}%)</span></td>
                    </tr>
                    <tr>
                        <td>{{leftGoalSituations.length}}  <span v-if="leftCornerSituations.length != 0">({{Number(leftGoalSituations.length * 100  / leftCornerSituations.length).toFixed(0) }}%)</span></td>
                        <th scope="row">Tore</th>
                        <td>{{rightGoalSituations.length}}  <span v-if="rightCornerSituations.length != 0">({{Number(rightGoalSituations.length * 100  / rightCornerSituations.length).toFixed(0) }}%)</span></td>
                    </tr>
                    
                    <tr>
                        <td>{{leftHeadGoalSituations.length}}  <span v-if="leftCornerSituations.length != 0">({{Number(leftHeadGoalSituations.length * 100  / leftCornerSituations.length).toFixed(0) }}%)</span></td>
                        <th scope="row">Kopfballtore</th>
                        <td>{{rightHeadGoalSituations.length}}  <span v-if="rightCornerSituations.length != 0">({{Number(rightHeadGoalSituations.length * 100  / rightCornerSituations.length).toFixed(0) }}%)</span></td>
                    </tr>
                    
                    
                </tbody>
            </table>
        </div>
        
    </div>


</template>


<style scoped lang="scss">
@import '~bootstrap/scss/bootstrap.scss';
    .clublogo {
        width: 40px;
        height: 40px;
    }

    .table td{
        text-align: center;   
    }

    .table th{
        text-align: center;   
    }

    .borderless td, .borderless th {
        border: none;
    }

</style>

<script>
import { ref, defineComponent, onMounted, computed, watch } from 'vue'

import Pitch from '../Basics/Pitch'
import { useStore } from 'vuex'
//import { analytics } from '@/services';

export default defineComponent({
    components: {Pitch},
    setup() {

        var showSize = ref(0)
        const cornerPitch = ref(null)
        var mainDivRectangle = ref(null)
        const cornerContainer = ref(null)
        var configKonva = ref([])
        var selectedCreatorTeam = ref(null)

        const store = useStore()
        onMounted(() => {
            window.addEventListener("resize", initContainer);
            initContainer()
            
        }) 

        configKonva.value = {
            width: 0,
            height: 0,
        }

        const initContainer = function() {

            if(cornerContainer.value != null) {

                mainDivRectangle.value = cornerContainer.value.getBoundingClientRect()
                configKonva.value.width = mainDivRectangle.value.width
                configKonva.value.height = mainDivRectangle.value.width * cornerPitch.value.getWidthHeightProportion()
                //cornerContainer.value.style.width = mainDivRectangle.value.width + 'px'
            }
        }

        var situations = computed(() => {
            return store.getters.getAllSituations 
        })

        var convertedSituations = computed(() => {
            var newConvertedSituations = JSON.parse(JSON.stringify(situations.value))
            if(situations.value) {
                for( var i = 0; i < situations.value.length; i++) {
                    var sitpos = newConvertedSituations[i].situationPositions
                    for(var j = 0; j < sitpos.length; j++) {
                        
                        if(sitpos[j].action_labels != null) {
                            var curX = newConvertedSituations[i].direction == "CREATOR_LEFT_TO_RIGHT" ? 100 - newConvertedSituations[i].situationPositions[j].y : newConvertedSituations[i].situationPositions[j].y
                            var curY = newConvertedSituations[i].direction == "CREATOR_LEFT_TO_RIGHT" ? newConvertedSituations[i].situationPositions[j].x : 100 - newConvertedSituations[i].situationPositions[j].x
                            curX = newConvertedSituations[i].situationPositions[j].isInCreatorTeam == true ? 100 - curX : curX
                            curY = newConvertedSituations[i].situationPositions[j].isInCreatorTeam == true ? 100 - curY : curY
                            newConvertedSituations[i].situationPositions[j].x = curX
                            newConvertedSituations[i].situationPositions[j].y = curY
                        }
                    }
                }
            }

           return newConvertedSituations
        })

        const pitchresized = function() {
            showSize.value = Number(cornerPitch.value.pitchHeight * 0.21).toFixed(0)
        }

        var cornerSituations = computed(() => {
            if(selectedCreatorTeam.value == null) {return []}
			return convertedSituations.value.filter(sit => sit.situationPositions.some(s => s.action_labels.includes("Eckstoß") == true && s.isInCreatorTeam == selectedCreatorTeam.value.value))
        })

        var matchList = computed(() => {
            return store.getters.getMatchList
        })

        var leftCornerSituations = computed(() => {
            var newSits = []
            cornerSituations.value.forEach(cSit => {
                newSits = cSit.situationPositions.some(s => (s.x < 10 == true && s.action_labels.includes("Eckstoß"))) == true? newSits.concat(cSit) : newSits
            })
            return newSits
        })
        var rightCornerSituations = computed(() => {
            var newSits = []
            cornerSituations.value.forEach(cSit => {
                newSits = cSit.situationPositions.some(s => (s.x > 90 == true && s.action_labels.includes("Eckstoß"))) == true ? newSits.concat(cSit) : newSits
            })
            return newSits
        })

        var leftShotSituations = computed(() => {
            var newSits = []
            leftCornerSituations.value.forEach(cSit => {
                newSits = cSit.situationPositions.some(s => s.action_labels.includes("Schuss") || s.action_labels.includes("Kopfball")) == true? newSits.concat(cSit) : newSits
            })
            return newSits
        })

        var rightShotSituations = computed(() => {
            var newSits = []
            rightCornerSituations.value.forEach(cSit => {
                newSits = cSit.situationPositions.some(s => s.action_labels.includes("Schuss") || s.action_labels.includes("Kopfball")) == true? newSits.concat(cSit) : newSits
            })
            return newSits
        })

        var leftTargetSituations = computed(() => {
            var newSits = []
            leftShotSituations.value.forEach(cSit => {
                newSits = cSit.situationPositions.some(s => s.action_labels.includes("Torschuss")) == true? newSits.concat(cSit) : newSits
            })
            return newSits
        })

        var rightTargetSituations = computed(() => {
            var newSits = []
            rightShotSituations.value.forEach(cSit => {
                newSits = cSit.situationPositions.some(s => s.action_labels.includes("Torschuss")) == true? newSits.concat(cSit) : newSits
            })
            return newSits
        })

        var leftGoalSituations = computed(() => {
            var newSits = []
            leftShotSituations.value.forEach(cSit => {
                newSits = cSit.situationPositions.some(s => s.action_labels.includes("Tor")) == true? newSits.concat(cSit) : newSits
            })
            return newSits
        })

        var rightGoalSituations = computed(() => {
            var newSits = []
            rightShotSituations.value.forEach(cSit => {
                newSits = cSit.situationPositions.some(s => s.action_labels.includes("Tor")) == true? newSits.concat(cSit) : newSits
            })
            return newSits
        })

        var leftHeadSituations = computed(() => {
            var newSits = []
            leftCornerSituations.value.forEach(cSit => {
                newSits = cSit.situationPositions.some(s => s.action_labels.includes("Schuss") && s.action_labels.includes("Kopfball")) == true? newSits.concat(cSit) : newSits
            })
            return newSits
        })

        var rightHeadSituations = computed(() => {
            var newSits = []
            rightCornerSituations.value.forEach(cSit => {
                newSits = cSit.situationPositions.some(s => s.action_labels.includes("Schuss") && s.action_labels.includes("Kopfball")) == true? newSits.concat(cSit) : newSits
            })
            return newSits
        })

        var leftHeadTargetSituations = computed(() => {
            var newSits = []
            leftHeadSituations.value.forEach(cSit => {
                newSits = cSit.situationPositions.some(s => s.action_labels.includes("Torschuss")) == true? newSits.concat(cSit) : newSits
            })
            return newSits
        })

        var rightHeadTargetSituations = computed(() => {
            var newSits = []
            rightHeadSituations.value.forEach(cSit => {
                newSits = cSit.situationPositions.some(s => s.action_labels.includes("Torschuss")) == true? newSits.concat(cSit) : newSits
            })
            return newSits
        })

        var leftHeadGoalSituations = computed(() => {
            var newSits = []
            leftHeadSituations.value.forEach(cSit => {
                newSits = cSit.situationPositions.some(s => s.action_labels.includes("Tor")) == true? newSits.concat(cSit) : newSits
            })
            return newSits
        })

        var rightHeadGoalSituations = computed(() => {
            var newSits = []
            rightHeadSituations.value.forEach(cSit => {
                newSits = cSit.situationPositions.some(s => s.action_labels.includes("Tor")) == true? newSits.concat(cSit) : newSits
            })
            return newSits
        })

        var myteam = computed(() => {
            return store.getters.getTeam 
        })

        watch(myteam, () => {
            selectedCreatorTeam.value = teamSelectOptions.value[0]
        })


        var teamSelectOptions = computed(() => {
            let options = [
                {name: myteam.value != null ? myteam.value.clubname : "", value: true},
                {name: matchList.value.length > 1 ? "Gegner" : matchList.value[0].opponent_team.clubname, value: false}
            ]
            return options
        })
        



        /*var mySuccessfulCornerSituations = computed(() => {
            return myCornerSituations.value.filter(sit => sit.situationPositions.some(sitpo => sitpo.isInCreatorTeam == true && sitpo.action_labels.includes("Tor")))
        })

        var myCornerSituations = computed(() => {
            return allCornerSituations.value.filter(sit => sit.situationPositions.some(sitpo => sitpo.isInCreatorTeam == true && sitpo.action_labels.includes("Eckstoß")))
        })

        var theirSuccessfulCornerSituations = computed(() => {
            return theirCornerSituations.value.filter(sit => sit.situationPositions.some(sitpo => sitpo.isInCreatorTeam == false && sitpo.action_labels.includes("Tor")))
        })

        var theirCornerSituations = computed(() => {
            return allCornerSituations.value.filter(sit => sit.situationPositions.some(sitpo => sitpo.isInCreatorTeam == false && sitpo.action_labels.includes("Eckstoß")))
        })

        var allCornerSituations = computed(() => {
            var CornerSituations = []
            CornerSituations = dangerousSituations.value.filter(sit => {
                
                var sitpos = sit.situationPositions.filter(sitpo => sitpo.action_labels.includes("Eckstoß"))
                return sitpos.length > 0
            })
            return CornerSituations
        })*/



        return {
            matchList,
            leftCornerSituations,
            rightCornerSituations,
            leftShotSituations,
            rightShotSituations,
            rightTargetSituations,
            leftTargetSituations,
            leftHeadSituations,
            rightHeadSituations,
            leftHeadTargetSituations,
            rightHeadTargetSituations,
            leftGoalSituations,
            rightGoalSituations,
            leftHeadGoalSituations,
            rightHeadGoalSituations,
            pitchresized,
            cornerPitch,
            cornerContainer,
            showSize,
            mainDivRectangle,
            initContainer,
            selectedCreatorTeam,
            teamSelectOptions
        }


    }
});


</script>