<template>
<div>
    <Toast />
    {{this.editors}}
    <DataTable :value="match_list" responsiveLayout="scroll" dataKey="id">
        <Column field="creator_team.clubname" header="Creator Team"></Column>
        <Column field="opponent_team.clubname" header="Opponent"></Column>


        <Column header="Editor">
            <template #body="slotProps">
                current: {{slotProps.data.editor != null ? getEmail(slotProps.data.editor) : "None"}}
                <Dropdown v-model="editorHelper[slotProps.index]" :options="editors" optionLabel="email" placeholder="None"/>
                <Button icon="pi pi-save" class="p-button-rounded p-button-outlined" @click="assignEditor(slotProps.data.uuid, editorHelper[slotProps.index])"/>
            </template>
            
        </Column>

    </DataTable>

     
</div> 

</template>


<script>
import {
    admin
} from '@/services';


export default {


    name: 'app',
    components: {},
    data() {
        return {
            editors: [],
            match_list: [],
            loaded: false,
            editorHelper: []
        }
    },
    async mounted() {

    },
    async created() {
        this.loadUsers()
        var result = await admin.allmatches()
        //this.match_list = result.data
        this.match_list = result.data.sort(function(a, b){
            return new Date(a.match_date) - new Date(b.match_date);
        }).reverse()
        this.loaded = true
        this.fillRoleHelper()
    },
    async beforeMount() {


    },

    methods: {
        getEmail(editor) {
            var res = this.editors.find(e => e != null && e.uid == editor)
            return res != null ? res.email : "None"
        },
        async loadUsers() {
            var result = await admin.getAllEditors()
            this.editors = result.data
            this.editors.push(null)
        },
        async assignEditor(matchUuid, editorUuid) {
            var res = await admin.assigneditor(matchUuid, editorUuid)
            if (res.status == 200) {
                this.$toast.add({
                    severity: 'success',
                    summary: 'Editor Changed',
                    detail: 'Editor Changed',
                    life: 3000
                });
            } else {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Editor not changed',
                    detail: 'Editor not changed',
                    life: 3000
                });
            }
        },

        fillRoleHelper() {
            this.editorHelper = []
            for (var i = 0; i < this.match_list.length; i++) {


                var editor = this.match_list[i].editor != null ? this.editors.find(e => e.uid == this.match_list[i].editor) : null
                console.log(editor)
                this.editorHelper = this.editorHelper.concat(editor)
                //console.log(this.roleHelper)
            }

        },


    }
}
</script>

<style>



</style>

