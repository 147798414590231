import VueJwtDecode from 'vue-jwt-decode'
import { usermanager } from '@/services';
import axios from "axios";

const domain = process.env.VUE_APP_USERPLATFORM_URL

const url = domain + "/api/v1/"

async function findRole(userRoles, pageRoles){
    for(var i = 0; i < userRoles.length; i++) {
        for(var j = 0; j < pageRoles.length; j++) {
            if(userRoles[i] == pageRoles[j]) {
                return true
            }
        }
    }
    return false
}

async function getUserName() {
    try {
        const currentUserAccessToken = getAccessToken()
        const decodedAccessToken = VueJwtDecode.decode(currentUserAccessToken)
        const firstname = decodedAccessToken.firstname
        const lastname = decodedAccessToken.lastname
        const email = decodedAccessToken.sub
        //alert(JSON.stringify(decodedAccessToken))
        return {firstname: firstname, lastname: lastname, email: email}
    }catch(error) {
        //alert("error")
        return ""
    }
}

async function getAccessToken() {
    var res = null
    await usermanager.getAccessToken()
        .then(function(idToken) {
            res = idToken
        })
    return res;
}

async function getUserRoles() {
    try {
        const currentUserAccessToken = getAccessToken()
        const decodedAccessToken = VueJwtDecode.decode(currentUserAccessToken)
        const userRoles = decodedAccessToken.groups
        return userRoles
    }catch(error) {
        return false
    }
}


async function isUserAuthorized(pageRoles) {
    if(pageRoles === undefined || pageRoles.length == 0) {
        return true
    }
    const userRoles = getUserRoles() 
    return findRole(userRoles, pageRoles)
}

function getVideoURL() {
    return process.env.VUE_APP_USERPLATFORM_URL + "/videos/"
    //return process.env.VUE_APP_API_URL + "/videos/"
}

function getSituationVideoURL() {
    return "https://api.kick17.de/situationvideos/"
    //return process.env.VUE_APP_USERPLATFORM_URL + "/situationvideos/"
}

async function getUserProfile () {
    var jwt = await getAccessToken()
    var options = {
        method: "GET", 
        "url": url + "myprofile",
        headers: {
            Authorization: jwt
        }
    }
    return makeURLRequest(options)     
}

async function downloadVideo(videoname) {
    var jwt = await getAccessToken()
    var options = {
        method: "GET", 
        "url": url + "video/downloadvideo",
        responseType: 'arraybuffer',
        headers: {
            Authorization: jwt,
            videoname: videoname
        }
    }
    await axios.request(options)
    .then((response) => {

        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
     
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'file.mp4');
        document.body.appendChild(fileLink);
      
        fileLink.click();
   });
}

/*async function uploadProfileImage () {
    var jwt = await getAccessToken()
    var options = {
        method: "POST", 
        "url": url + "uploadImage",
        responseType: 'arraybuffer',
        headers: {
            Authorization: jwt,
            'Content-Type': 'multipart/form-data'
        }
    }
    return makeURLRequest(options, null)
}*/

/*async function getImage(imagedata) {
    var buffer = new Buffer(imagedata, 'binary').toString('base64')
    let mimetype="image/jpeg"
    return "data:"+mimetype+";base64," + buffer
}*/


async function loadProfileImage() {
    var jwt = await getAccessToken()
    
    var options = {
        method: "GET", 
        "url": url + "myprofileimage",
        responseType: 'arraybuffer',
        headers: {
            Authorization: jwt
        }
    }
    return makeURLRequest(options)
}

async function getFirstVideoFrame(videoname) {
    var jwt = await getAccessToken()
    
    var options = {
        method: "GET", 
        "url": url + "getFirstVideoFrame?videoName=" + videoname,
        responseType: 'arraybuffer',
        headers: {
            Authorization: jwt
        }
    }
    return makeURLRequest(options)
}

async function saveVideoConfig(videoName, config) {
    var jwt = await getAccessToken()
    var options = {
        method: "POST", 
        "url": url + "saveconfig",
        headers: {
            Authorization: jwt
        }, 
        data: 
            {
                "videoName": videoName,
                "config": config
             }
        
    }
    return makeURLRequest(options)
}


async function makeURLRequest(options) {
    var result = await axios.request(options)
    return result
}

async function getClubIcon(imageName) {
    /*if(imageName == null) {

    }*/
    var jwt = await getAccessToken()
    var options = {
        method: "GET", 
        "url": url + "clublogo?imageName=" + imageName,
        responseType: 'arraybuffer',
        headers: {
            Authorization: jwt
        },
        data: {
            image: imageName
        }
    }
    return makeURLRequest(options)
}

async function createMatch() {
    var jwt = await getAccessToken()
    var options = {
        method: "POST", 
        "url": url + "mynewmatch",
        headers: {
            Authorization: jwt
        }        
    }
    return makeURLRequest(options)
}

async function setmatchdate(matchUuid, matchDate) {
    var jwt = await getAccessToken()
    var options = {
        method: "PUT", 
        "url": url + "match/setmatchdate",
        headers: {
            Authorization: jwt,
            matchUuid: matchUuid,
            matchDate: matchDate
        }        
    }
    return makeURLRequest(options)
}

async function setcolors(matchUuid, creatorcolor, opponentcolor) {
    var jwt = await getAccessToken()
    var options = {
        method: "PUT", 
        "url": url + "match/colors",
        headers: {
            Authorization: jwt,
            matchUuid: matchUuid,
            creatorcolor: creatorcolor,
            opponentcolor: opponentcolor
        }        
    }
    return makeURLRequest(options)
}

async function saveSituationPositions(matchUuid, situationUuid, positions, second, direction) {
    var jwt = await getAccessToken()
    var options = {
        method: "POST", 
        "url": url + "match/situationpositions",
        headers: {
            Authorization: jwt,
            matchUuid: matchUuid,
            situationUuid: situationUuid,
            positions: JSON.stringify(positions),
            second: second,
            direction: direction
        },
        data: JSON.stringify({p: positions})
    }
    return makeURLRequest(options)
}

async function getMatches() {
    var jwt = await getAccessToken()
    var options = {
        method: "GET", 
        "url": url + "matcheswithclubs",
        headers: {
            Authorization: jwt
        }
    }
    return makeURLRequest(options)
}

async function getMatch(matchUuid) {
    var jwt = await getAccessToken()
    var options = {
        method: "GET", 
        "url": url + "match",
        headers: {
            Authorization: jwt,
            matchUuid: matchUuid
        }
    }
    return makeURLRequest(options)
}

async function getallmatches() {
    var jwt = await getAccessToken()
    var options = {
        method: "GET", 
        "url": url + "getallmatches" ,
        headers: {
            Authorization: jwt
        }
    }
    return makeURLRequest(options)
}

async function uploadProfileImage(formData) {
    var jwt = await getAccessToken()
    var options = { 
        method: "POST", 
        "url": url + "uploadProfileImage",
        headers: {
            Authorization: jwt,
            'Content-Type': 'multipart/form-data'
        },
        data: formData
    }
    return makeURLRequest(options)
}

async function addVideoLink(matchUuid, videolink) {
    var jwt = await getAccessToken()
    var options = { 
        method: "PUT", 
        "url": url + "match/addVideoLink",
        headers: {
            Authorization: jwt,
            matchUuid: matchUuid,
            videolink: videolink,
            linksource: "SPORTTOTAL"
        }
    }
    return makeURLRequest(options)
}

/*async function uploadMatchVideo(formData, id) {
    var jwt = await getAccessToken()
    var options = { 
        method: "POST", 
        "url": url + "uploadVideo",
        headers: {
            Authorization: jwt,
            'Content-Type': 'multipart/form-data'
        },
        videoFile: formData,
        matchUuid: id
    }
    return makeURLRequest(options)
}*/

async function getAllCVTables() {
    var jwt = await getAccessToken()
    var options = { 
        method: "GET", 
        "url": url + "getallCVOutputTables",
        headers: {
            Authorization: jwt
        }
    }
    return makeURLRequest(options)
}

async function deleteCVTable(name) {
    var jwt = await getAccessToken()
    var options = { 
        method: "GET", 
        "url": url + "deleteCVTable",
        headers: {
            Authorization: jwt,
            tablename: name
        }
    }
    return makeURLRequest(options)
}

function getMatchVideoUploadURL() {
    return url + "video/uploadmatchvideo"
}
function getSituationVideoUploadURL() {
    
    return "https://api.kick17.de/api/v1/video/uploadsituationvideo" //url + "video/uploadsituationvideo"
}

async function getCVTableUploadURL() {
    return url + "uploadCvOutputTable"
}

async function getProfileImageUploadURL() {
    return process.env.VUE_APP_API_URL + "uploadProfileImage"
}

function getClubLogoUploadURL() {
    //return "https://api.kick17.de/api/v1/uploadClubLogo" // url +
    return process.env.VUE_APP_API_URL + "/api/v1/uploadClubLogo"
}

function getClubLogoReadURL() {
    //return process.env.VUE_APP_USERPLATFORM_URL + "/club_icons/"
    return process.env.VUE_APP_API_URL + "/club_icons/"
}

async function getUploadHeaders() {
    var jwt = await getAccessToken()
    return {"Authorization": jwt}
}

async function setUserName(firstname, lastname) {
    var jwt = await getAccessToken()
    var options = { 
        method: "POST", 
        "url": url + "myname",
        headers: {
            Authorization: jwt
        },
        data: {
            "firstName": firstname,
            "lastName": lastname
        }
    }
    return makeURLRequest(options)
}

async function setShirtNumber(number) {
    var jwt = await getAccessToken()
    var options = { 
        method: "POST", 
        "url": url + "myshirtnumber",
        headers: {
            Authorization: jwt
        },
        data: {
            "shirtnumber": number
        }
    }
    return makeURLRequest(options)
}

async function setMyTeam(clubID, team, age_id) {
    var jwt = await getAccessToken()
    var options = { 
        method: "POST", 
        "url": url + "setmyteam",
        headers: {
            Authorization: jwt
        },
        data: {
            "teamname": team,
            "clubID": clubID.toString(),
            "age_group_id": age_id.toString()
        }
    }
    return makeURLRequest(options)
}

async function getMyTeam() {
    var jwt = await getAccessToken()
    var options = { 
        method: "GET", 
        "url": url + "myteam",
        headers: {
            Authorization: jwt
        }
    }
    return makeURLRequest(options)
}

async function getTeam(teamUuid) {
    var jwt = await getAccessToken()
    var options = { 
        method: "GET", 
        "url": url + "team",
        headers: {
            Authorization: jwt,
            teamUuid: teamUuid
        }
    }
    return makeURLRequest(options)
}

async function addPlayer(player) {
    var jwt = await getAccessToken()
    var options = { 
        method: "POST", 
        "url": url + "player",
        headers: {
            Authorization: jwt,
            firstname: player.firstname,
            lastname: player.lastname,
            shirtnumber: player.shirtnumber
        }
    }
    return makeURLRequest(options)
}

async function savePlayer(player) {
    var jwt = await getAccessToken()
    var options = { 
        method: "PUT", 
        "url": url + "player",
        headers: {
            Authorization: jwt,
            firstname: player.firstname,
            lastname: player.lastname,
            shirtnumber: player.shirtnumber,
            playerUuid: player.uuid
        }
    }
    return makeURLRequest(options)
}

async function getUnconfiguredVideoList() {
    var jwt = await getAccessToken()
    var options = { 
        method: "GET", 
        "url": url + "getVideosForConfig",
        headers: {
            Authorization: jwt
        }
    }
    return makeURLRequest(options)
}

async function getAllClubs() {
    var jwt = await getAccessToken()
    var options = { 
        method: "GET", 
        "url": url + "allTeams",
        headers: {
            Authorization: jwt
        }
    }
    return makeURLRequest(options)
}


async function getAllAgebands() {
    var jwt = await getAccessToken()
    var options = { 
        method: "GET", 
        "url": url + "allagebands",
        headers: {
            Authorization: jwt
        }
    }
    return makeURLRequest(options)
}

function getAllTeamClasses() {
    var classes = {"data": [
        {"id": 0, "name": "I", value: 1},
        {"id": 1, "name": "II", value: 2},
        {"id": 2, "name": "III", value: 3},
        {"id": 3, "name": "IV", value: 4}
        ]
    }
    return classes
}

function getAgebands() {
    var classes = {"data": [
        {"id": 0, "name": "Herren"},
        {"id": 1, "name": "U19"},
        {"id": 2, "name": "U17"},
        {"id": 3, "name": "U15"},
        {"id": 3, "name": "U13"}
        ]
    }
    return classes
}

async function addClub(name) {
    var jwt = await getAccessToken()
    var options = {
        method: "POST", 
        "url": url + "newclub",
        headers: {
            Authorization: jwt,
            clubname: name
        }    }
    return makeURLRequest(options)
}

async function setopponent(matchUuid, clubname, rank, ageband) {
    
    var jwt = await getAccessToken()
    var options = {
        method: "PUT", 
        "url": url + "match/setopponent",
        headers: {
            Authorization: jwt,
            matchUuid: matchUuid,
            clubname: clubname,
            rank: getAllTeamClasses().data.find(i => i.name == rank).value,
            ageband: ageband
        }    
    }
    
    return makeURLRequest(options)
}

async function saveMatchVideoFrame(matchUuid, frame) {
    var jwt = await getAccessToken()
    var options = {
        method: "PUT", 
        "url": url + "match/matchVideoFrame",
        headers: {
            Authorization: jwt,
            matchUuid: matchUuid,
            frame: frame
        }    
    }
    
    return makeURLRequest(options)
}

async function firsthalfdirection(matchUuid, firsthalfdirection) {
    var jwt = await getAccessToken()
    var options = {
        method: "PUT", 
        "url": url + "match/firsthalfdirection",
        headers: {
            Authorization: jwt,
            matchUuid: matchUuid,
            firsthalfdirection: firsthalfdirection
        }    
    }
    
    return makeURLRequest(options)
}

async function getHeatmap(matchUuid) {
    var jwt = await getAccessToken()
    var options = {
        method: "GET", 
        "url": url + "statistics/getHeatmap",
        headers: {
            Authorization: jwt,
            matchUuid: matchUuid,
            playerID: 1
        }
    }
    return makeURLRequest(options)
}

async function getXCenters(matchUuid) {
    var jwt = await getAccessToken()
    var options = {
        method: "GET", 
        "url": url + "statistics/getXCenters",
        headers: {
            Authorization: jwt,
            matchUuid: matchUuid
        }
    }
    return makeURLRequest(options)
}

async function getPlayerVelocity(matchUuid) {
    var jwt = await getAccessToken()
    var options = {
        method: "GET", 
        "url": url + "statistics/getPlayerVelocity",
        headers: {
            Authorization: jwt,
            matchUuid: matchUuid,
            playerID: 426
        }
    }
    return makeURLRequest(options)
}

async function getBallContacts(matchUuid) {
    var jwt = await getAccessToken()
    var options = {
        method: "GET", 
        "url": url + "statistics/getBallContacts",
        headers: {
            Authorization: jwt,
            matchUuid: matchUuid
        }
    }
    return makeURLRequest(options)
}

async function getMoveVectors(matchUuid) {
    var jwt = await getAccessToken()
    var options = {
        method: "GET", 
        "url": url + "statistics/getMoveVectors",
        headers: {
            Authorization: jwt,
            matchUuid: matchUuid
        }
    }
    return makeURLRequest(options)
}

async function getFormation(matchUuid) {
    var jwt = await getAccessToken()
    var options = {
        method: "GET", 
        "url": url + "statistics/getFormation",
        headers: {
            Authorization: jwt,
            matchUuid: matchUuid,
            notBefore: 0,
            notAfter: -1
        }
    }
    return makeURLRequest(options)
}

async function getTotalDistance(matchUuid) {
    var jwt = await getAccessToken()
    var options = {
        method: "GET", 
        "url": url + "statistics/getDistance",
        headers: {
            Authorization: jwt,
            matchUuid: matchUuid
        }
    }
    return makeURLRequest(options)
}

async function getSprints(matchUuid) {
    var jwt = await getAccessToken()
    var options = {
        method: "GET", 
        "url": url + "statistics/getSprints",
        headers: {
            Authorization: jwt,
            matchUuid: matchUuid
        }
    }
    return makeURLRequest(options)
}

async function getAllPositions(matchUuid) {
    var jwt = await getAccessToken()
    var options = {
        method: "GET", 
        "url": url + "statistics/getAllPositions",
        headers: {
            Authorization: jwt,
            matchUuid: matchUuid
        }
    }
    return await makeURLRequest(options)
}

async function getAPIVersion() {
    var options = {
        method: "GET", 
        "url": url + "getbuildversion"
    }
    return makeURLRequest(options)
}

/*async function getAllTeams() {
    var jwt = await getAccessToken()
    var options = {
        method: "GET", 
        "url": url + "clubteams",
        headers: {
            Authorization: jwt,
            matchUuid: matchUuid
        }
    }
    return makeURLRequest(options)
}*/

async function assignclub(uid, clubID) {
    var jwt = await getAccessToken()
    var options = {
        method: "PUT", 
        "url": url + "assignclub",
        headers: {
            Authorization: jwt,
            uid: uid,
            clubID: clubID
        } 
    }
    return makeURLRequest(options)
}



async function assignTeam(uid, teamName) {
    var jwt = await getAccessToken()
    var options = {
        method: "PUT", 
        "url": url + "assignteam",
        headers: {
            Authorization: jwt,
            uid: uid,
            teamname: teamName
        } 
    }
    return makeURLRequest(options)
}

async function getmyclub() {
    var jwt = await getAccessToken()
    var options = {
        method: "GET", 
        "url": url + "myclub",
        headers: {
            Authorization: jwt
        } 
    }
    return makeURLRequest(options)
}

async function addSituationFrame(matchUuid, start, end, label, isCreatorSituation, videoNeeded) {
    var jwt = await getAccessToken()
    var options = {
        method: "POST", 
        "url": url + "video/situationframe",
        headers: {
            Authorization: jwt,
            matchUuid: matchUuid,
            situationStart: start,
            situationEnd: end,
            label: label,
            isCreatorSituation: isCreatorSituation,
            videoNeeded: videoNeeded
        } 
    }
    return makeURLRequest(options)
}

async function deleteSituation(matchUuid, situationUuid) {
    var jwt = await getAccessToken()
    var options = {
        method: "DELETE", 
        "url": url + "video/deletesituation",
        headers: {
            Authorization: jwt,
            matchUuid: matchUuid,
            situationUuid: situationUuid,
        } 
    }
    return makeURLRequest(options)
}

async function addCustomSituation(matchUuid, timestamp, label, evaluation, comment, players, isCreatorSituation) {
    var jwt = await getAccessToken()
    var options = {
        method: "POST", 
        "url": url + "match/customsituation",
        headers: {
            Authorization: jwt,
            matchUuid: matchUuid,
            time: timestamp,
            label: [label],
            comment: comment,
            evaluation: evaluation,
            players: players,
            isCreatorSituation: isCreatorSituation
        } 
    }
    return makeURLRequest(options)
}

async function getSituations(matchUuid) {
    var jwt = await getAccessToken()
    var options = {
        method: "GET", 
        "url": url + "video/situations",
        headers: {
            Authorization: jwt,
            matchUuid: matchUuid
        } 
    }
    return makeURLRequest(options)
}

async function getMatchSituations(matchUuid) {
    var jwt = await getAccessToken()
    var options = {
        method: "GET", 
        "url": url + "match/matchsituations",
        headers: {
            Authorization: jwt,
            matchUuid: matchUuid
        } 
    }
    return makeURLRequest(options)
}

async function finishMatch(matchUuid) {
    var jwt = await getAccessToken()
    var options = {
        method: "PUT", 
        "url": url + "match/finishmatch",
        headers: {
            Authorization: jwt,
            matchUuid: matchUuid
        } 
    }
    return makeURLRequest(options)
}

async function finishshortvideos(matchUuid) {
    var jwt = await getAccessToken()
    var options = {
        method: "PUT", 
        "url": url + "match/finishshortvideos",
        headers: {
            Authorization: jwt,
            matchUuid: matchUuid
        } 
    }
    return makeURLRequest(options)
}

async function finishsituationpositions(matchUuid) {
    var jwt = await getAccessToken()
    var options = {
        method: "PUT", 
        "url": url + "match/finishsituationpositions",
        headers: {
            Authorization: jwt,
            matchUuid: matchUuid
        } 
    }
    return makeURLRequest(options)
}

async function startsecondhalf(matchUuid, timestamp) {
    var jwt = await getAccessToken()
    var options = {
        method: "PUT", 
        "url": url + "match/startsecondhalf",
        headers: {
            Authorization: jwt,
            matchUuid: matchUuid,
            timestamp: timestamp
        } 
    }
    return makeURLRequest(options)
}

async function addfeedback(text) {
    var jwt = await getAccessToken()
    var options = {
        method: "POST", 
        "url": url + "addfeedback",
        headers: {
            Authorization: jwt,
            text: text
        } 
    }
    return makeURLRequest(options)
}

function getVideoUploadDropzoneOptions() {
    var dropzoneOptions = { 
        url: getMatchVideoUploadURL(),
        thumbnailWidth: 200,
        addRemoveLinks: true,
        autoProcessQueue: true,
        timeout: -1,
        headers: getUploadHeaders(),
        dictDefaultMessage: "<i class='fas fa-cloud-upload-alt'></i> UPLOAD VIDEO",
        maxFilesize: 4000,
        acceptedFiles: ".mp4, .AVI, .WMV, .AVCHD"
    }
    return dropzoneOptions
}

async function setmatchresult(matchUuid, mygoals, opponentgoals) {
    var jwt = await getAccessToken()
    var options = {
        method: "PUT", 
        "url": url + "match/setmatchresult",
        headers: {
            Authorization: jwt,
            matchUuid: matchUuid,
            mygoals: Number(mygoals),
            opponentgoals: Number(opponentgoals)
        } 
    }
    return makeURLRequest(options)
}

async function setPlayerToSituationPosition(matchUuid, situationUuid, situationPositionUuid, playerUuid) {
    var jwt = await getAccessToken()
    var options = {
        method: "PUT", 
        "url": url + "match/setPlayerToSituationPosition",
        headers: {
            Authorization: jwt,
            matchUuid: matchUuid,
            situationUuid: situationUuid,
            situationPositionUuid: situationPositionUuid,
            playerUuid: playerUuid
        } 
    }
    return makeURLRequest(options)
}


function getVideoOptions() {
    var videoOptions = {
        muted: true,
        responsive: true,
        notSupportedMessage: "Dein Browser wird leider nicht unterstützt",
        language: 'en',
        //fluid: true,
        playbackRates: [0.7, 1.0, 1.5, 2.0, 3.0],
        sources: [{
        type: "video/mp4",
        src: ""
        }],
    }
    return videoOptions
}

function getSituationLabels(lang) {
    var arr = []
    var key
    if(lang == "EN") {
        for (key in SITUATIONS_ENGLISH) {
            arr.push(SITUATIONS_ENGLISH[key])
        }
    } else {
        for (key in SITUATIONS) {
            arr.push(SITUATIONS[key])
        }
    }
    
    return arr
}

var SITUATIONS = {
    GOAL: "Tor",
    ATTEMPT: "Schuss",
    //ATTEMPT_ON_GOAL: "Torschuss",
    FREEKICK: "Freistoß",
    PENALTY: "Elfmeter",
    COUNTER: "Konter",
    CORNER: "Eckstoß",
    BALL_LOSS: "Ballverlust",
    //FLANKE: "Flanke",
    DEFAULT: "Interessante Situation"
}

var SITUATIONS_ENGLISH = {
    GOAL: "Goal",
    ATTEMPT: "Attempt",
    //ATTEMPT_ON_GOAL: "Attempt on Goal",
    FREEKICK: "Freekick",
    PENALTY: "Penalty",
    COUNTER: "Counter Attack",
    CORNER: "Corner",
    BALL_LOSS: "Ball-Loss",
    //FLANKE: "Flanke",
    DEFAULT: "Interesting Situation"
}


function getAllSitPos(match) {
    if(match == null) {return null}
    var sitpos = []
    for(var i = 0; i < match.situations.length; i++) {
        for(var j = 0; j < match.situations[i].situationPositions.length; j++) {
            sitpos.push(match.situations[i].situationPositions[j])
        }
    }
    return sitpos
}

function  sitpoHasActions(sitpo) {
    return sitpo.action_labels != null && sitpo.action_labels.length > 0
}

/*function getAllActionLabelsOfSitpos(sitpos) {
    var allLabels = []
    sitpos.forEach(sitpo => {
        allLabels.concat(sitpo.action_labels)
    })
    return allLabels
}*/

function test() {
    console.log("test called")
}

async function changeFavorite(matchUuid, situationUuid, isFavorite) {
    var jwt = await getAccessToken()
    var options = {
        method: "PUT", 
        "url": url + "match/changeFavorite",
        headers: {
            Authorization: jwt,
            matchUuid: matchUuid,
            situationUuid: situationUuid,
            isFavorite: isFavorite
        } 
    }
    return makeURLRequest(options)
}

async function setComment(matchUuid, situationUuid, comment) {
    var jwt = await getAccessToken()
    var options = {
        method: "PUT", 
        "url": url + "match/setSituationComment",
        headers: {
            Authorization: jwt,
            matchUuid: matchUuid,
            situationUuid: situationUuid,
            comment: comment
        } 
    }
    return makeURLRequest(options)
}

//tmp
/*async function tmpChangeCommentStringToEvaluationBoolean(matchUuid) {
    var jwt = await getAccessToken()
    var options = {
        method: "PUT", 
        "url": url + "match/tmpChangeCommentStringToEvaluationBoolean",
        headers: {
            Authorization: jwt,
            matchUuid: matchUuid
        } 
    }
    return makeURLRequest(options)
}*/

function getMinuteOfSituation(situation, match) {
    if(situation == null) {
        return null
    }
    if(situation.isCustom != true) {
        return Math.floor(situation.situation_start > match.matchVideoFrame[2] ? ((situation.situation_start - match.matchVideoFrame[2])/(60) + 45) : ((situation.situation_start - match.matchVideoFrame[0])/(60)))
        
    } else {
        var matchBegin = new Date(match.match_date)
        var diffBetweenHalfs = (new Date(match.second_half) - matchBegin) / 1000
        console.log("situation.situation_start: " + situation.situation_start)
        console.log("diffBetweenHalfs: " + diffBetweenHalfs)
        var seconds = situation.situation_start > diffBetweenHalfs ? (situation.situation_start - diffBetweenHalfs) + (45 * 60) : situation.situation_start
        return Math.floor(seconds/(60))
    }    
}

function isInMatch(match, second) {
    if(match.matchVideoFrame.length != 4) {return false}
    if ( (second > match.matchVideoFrame[0] && second < match.matchVideoFrame[1]) || (second > match.matchVideoFrame[2] && second < match.matchVideoFrame[4])) {
        return true
    }
    return false
}


export const kick17 = {
    test,
    isInMatch,
    getMinuteOfSituation,
    //tmpChangeCommentStringToEvaluationBoolean,
    getAllSitPos,
    sitpoHasActions,
    //getAllActionLabelsOfSitpos,
    getAccessToken,
    //getAllTeams,
    getAgebands,
    getSituationLabels,
    addSituationFrame,
    deleteSituation,
    addCustomSituation,
    getSituations,
    getMatchSituations,
    getmyclub,
    assignclub,
    assignTeam,
    isUserAuthorized,
    getUserRoles,
    getUserProfile,
    loadProfileImage,
    getClubIcon,
    getMatches,
    //getImage,
    createMatch,
    uploadProfileImage,
    setUserName,
    setShirtNumber,
    setMyTeam,
    getUserName,
    //uploadMatchVideo,
    getMatch,
    setmatchdate,
    setcolors,
    getUploadHeaders,
    getMatchVideoUploadURL,
    getCVTableUploadURL,
    getProfileImageUploadURL,
    getClubLogoUploadURL,
    getClubLogoReadURL,
    getUnconfiguredVideoList,
    getFirstVideoFrame,
    saveVideoConfig,
    getAllClubs,
    getAllAgebands,
    getAllTeamClasses,
    addClub,
    setopponent,
    getHeatmap,
    getXCenters,
    getPlayerVelocity,
    getBallContacts,
    getMoveVectors,
    getFormation,
    getTotalDistance,
    getSprints,
    getAllPositions,
    getVideoURL,
    getAllCVTables,
    deleteCVTable,
    getallmatches,
    //firstHalfFinishedTimestamp,
    getAPIVersion,
    finishMatch,
    startsecondhalf,
    setmatchresult,
    getVideoUploadDropzoneOptions,
    getVideoOptions,
    addfeedback,
    downloadVideo,
    getSituationVideoUploadURL,
    getSituationVideoURL,
    finishshortvideos,
    finishsituationpositions,
    getMyTeam,
    getTeam,
    addPlayer,
    savePlayer,
    saveSituationPositions,
    saveMatchVideoFrame,
    addVideoLink,
    setPlayerToSituationPosition,
    changeFavorite,
    setComment,
    firsthalfdirection
};