<template>


    
    
    <span v-if="newsituationFrame != null && newsituationFrame.situation_end == 0" class="sitEndBtn" style="color: white; margin-right: 10px; cursor: pointer;" @click="setEnd()">d: End Situation</span>

    <div v-else-if="newsituationFrame != null" style="border-style: solid; border-color: black; border-width: medium;">
        <span v-if="newsituationFrame.isCreatorSituation == null && newsituationFrame.situation_end != 0" style="color: white; margin-right: 10px; cursor: pointer;" @click="setSituationOwner(true)"><Badge value="1" v-bind:style="{'background-color': '#' + match.creatorcolor}" class="mr-2"></Badge> {{match.creator_team.clubname}}</span>
        <span v-if="newsituationFrame.isCreatorSituation == null && newsituationFrame.situation_end != 0" style="color: white; margin-right: 10px; cursor: pointer;" @click="setSituationOwner(false)"><Badge value="2" v-bind:style="{'background-color': '#' + match.opponentcolor}" class="mr-2"></Badge> {{match.opponent_team.clubname}}</span>
        
        <div v-if="newsituationFrame.isCreatorSituation != null && newsituationFrame.label.length == 0">
            <span  v-for="(sit, index) in situationlabels" :value="sit" :key="sit" :label="sit" style="color: white; margin-right: 10px; cursor: pointer;" @click="setSituationLabel(index)">{{index + 1}}:{{sit}}</span>
        </div>
        <div v-if="newsituationFrame.label.length != 0 && newsituationFrame.label.includes('Elfmeter')">
            <span style="color: white; margin-right: 10px; cursor: pointer;" @click="addAttemptPosition(['Torschuss', 'Tor'], [], true)">1: Goal</span>
            <span style="color: white; margin-right: 10px; cursor: pointer;" @click="addAttemptPosition(['Schuss'], [], true)">2: Miss Target</span>
            <span style="color: white; margin-right: 10px; cursor: pointer;" @click="addAttemptPosition(['Torschuss'], ['Block'], true)">3: Keeper Block</span>
            <span style="color: white; margin-right: 10px; cursor: pointer;" @click="addAttemptPosition(['Torschuss'], ['Ballbesitz'], true)">4: Keeper Hold</span>
        </div>

        <div v-else-if="newsituationFrame.label.length != 0 && newsituationFrame.label.includes('Tor')">
            <span style="color: white; margin-right: 10px;"><Checkbox @click="toggleActionLabel('Ballannahme')" v-model="situationPlayerList[0].action_labels" value="Ballannahme"/>1: Assisted</span>
            <span style="color: white; margin-right: 10px; cursor: pointer;" @click="addGoalPositions([])">2: Regular</span>
            <span style="color: white; margin-right: 10px; cursor: pointer;" @click="addGoalPositions(['Kopfball'])">3: Header</span>
            <span v-if="!situationPlayerList[0].action_labels.includes('Ballannahme')" style="color: white; margin-right: 10px; cursor: pointer;" @click="addGoalPositions(['Elfmeter'])">4: Penalty</span>
            <span v-if="!situationPlayerList[0].action_labels.includes('Ballannahme')" style="color: white; margin-right: 10px; cursor: pointer;" @click="addGoalPositions(['Freistoß'])">5: Direct Freekick</span>
            <span v-if="!situationPlayerList[0].action_labels.includes('Ballannahme')" style="color: white; margin-right: 10px; cursor: pointer;" @click="addGoalPositions(['Eigentor'])">6: Own Goal</span>
        </div>

        <div v-else-if="newsituationFrame.label.length != 0 && newsituationFrame.label.includes('Schuss') && situationPlayerList!= null && situationPlayerList.length != 0">
            <span style="color: white; margin-right: 10px;"><Checkbox @click="toggleActionLabel('Kopfball')" v-model="situationPlayerList[0].action_labels" value="Kopfball"/>1: Header</span>
            <span style="color: white; margin-right: 10px;"><Checkbox @click="toggleActionLabel('Ballannahme')" v-model="situationPlayerList[0].action_labels" value="Ballannahme"/>2: Assisted</span>
            <span style="color: white; margin-right: 10px;"><Checkbox @click="newsituationFrame.videoNeeded = newsituationFrame.videoNeeded != true ? true : false" :binary="true" v-model="newsituationFrame.videoNeeded"/>3: Dangerous</span>
            <span style="color: white; margin-right: 10px; cursor: pointer;" @click="addAttemptPosition(['Torschuss'], ['Block'], false)">4: On Target</span>
            <span style="color: white; margin-right: 10px; cursor: pointer;" @click="addAttemptPosition([], [], false)">5: Missed Target</span>
            <span style="color: white; margin-right: 10px; cursor: pointer;" @click="addAttemptPosition([], ['Block'], false)">6: Blocked far from Goal</span>
            <span style="color: white; margin-right: 10px; cursor: pointer;" @click="addAttemptPosition(['Latte', 'Pfosten', 'Torschuss'], [], false)">7: Post / Cross Bar</span>
        </div>

        <div v-else-if="newsituationFrame.label.length != 0 && newsituationFrame.label.includes('Freistoß') && situationPlayerList!= null && situationPlayerList.length != 0">
            <span style="color: white; margin-right: 10px;"><Checkbox @click="toggleActionLabel('Schuss')" v-model="situationPlayerList[0].action_labels" value="Schuss"/>1: Attempt</span>
            <span v-if="!situationPlayerList[0].action_labels.includes('Schuss')"  style="color: white; margin-right: 10px; cursor: pointer;" @click="addFrekickPosition(['Assist', 'Flanke', 'Pass'], [], ['Ballannahme', 'Torschuss', 'Schuss', 'Tor'], true)">2: Goal Assist</span>
            <span v-if="!situationPlayerList[0].action_labels.includes('Schuss')"  style="color: white; margin-right: 10px; cursor: pointer;" @click="addFrekickPosition(['Angekommen', 'Assist', 'Flanke', 'Pass'], [], ['Ballannahme', 'Schuss'], true)">3: Shot Assist</span>
            <span v-if="!situationPlayerList[0].action_labels.includes('Schuss')"  style="color: white; margin-right: 10px; cursor: pointer;" @click="addFrekickPosition(['Angekommen', 'Flanke', 'Pass'], [], ['Ballannahme'], true)">4: Successful Pass</span>
            <span v-if="!situationPlayerList[0].action_labels.includes('Schuss')"  style="color: white; margin-right: 10px; cursor: pointer;" @click="addAttemptPosition(['Flanke', 'Pass'], [], true)">5: Bad Pass</span>

            <span v-if="situationPlayerList[0].action_labels.includes('Schuss')" style="color: white; margin-right: 10px; cursor: pointer;" @click="addAttemptPosition([], [], false)">2: Missed Target</span>
            <span v-if="situationPlayerList[0].action_labels.includes('Schuss')" style="color: white; margin-right: 10px; cursor: pointer;" @click="addAttemptPosition([], ['Block'], false)">3: Blocked</span>
            <span v-if="situationPlayerList[0].action_labels.includes('Schuss')" style="color: white; margin-right: 10px; cursor: pointer;" @click="addAttemptPosition(['Torschuss'], ['Block'], true)">4: On Target</span>
            <span v-if="situationPlayerList[0].action_labels.includes('Schuss')" style="color: white; margin-right: 10px; cursor: pointer;" @click="addAttemptPosition(['Torschuss', 'Tor'], [], true)">5: Goal</span>
        </div>

        <div v-else-if="newsituationFrame.label.length != 0 && newsituationFrame.label.includes('Eckstoß') && situationPlayerList!= null && situationPlayerList.length != 0">
            <span v-if="situationPlayerList.find(s => s.role == 'corner').y == 50"  style="color: white; margin-right: 10px; cursor: pointer;" @click="setCorner(0)">1: Top Corner</span>
            <span v-if="situationPlayerList.find(s => s.role == 'corner').y == 50"  style="color: white; margin-right: 10px; cursor: pointer;" @click="setCorner(100)">2: Bottom Corner</span>

            <span v-if="situationPlayerList.find(s => s.role == 'corner').y != 50 && situationPlayerList[0].action_labels.length < 2"  style="color: white; margin-right: 10px; cursor: pointer;" @click="addCornerPositions(['Angekommen', 'Assist', 'Flanke', 'Pass'], [], ['Ballannahme', 'Schuss']); newsituationFrame.videoNeeded = true">1: Assist</span>
            <span v-if="situationPlayerList.find(s => s.role == 'corner').y != 50 && situationPlayerList[0].action_labels.length < 2"  style="color: white; margin-right: 10px; cursor: pointer;" @click="addCornerPositions(['Angekommen', 'Flanke', 'Pass'], [], ['Ballannahme'])">2: Next Contact by same team</span>
            <span v-if="situationPlayerList.find(s => s.role == 'corner').y != 50 && situationPlayerList[0].action_labels.length < 2"  style="color: white; margin-right: 10px; cursor: pointer;" @click="addCornerPositions(['Flanke', 'Pass'], ['Ballgewinn'], []); setVideoNeeded(false)">3: Other Team win the ball</span>
            <span v-if="situationPlayerList.find(s => s.role == 'corner').y != 50 && situationPlayerList[0].action_labels.length < 2"  style="color: white; margin-right: 10px; cursor: pointer;" @click="addCornerPositions(['Flanke', 'Pass'], [], []); setVideoNeeded(false)">3: Bad Pass</span>
        
            <span v-if="situationPlayerList.length > 1 && situationPlayerList.find(s => s.role == 'corner').y != 50 && situationPlayerList.find(s => s.role == 'offender').action_labels.includes('Schuss')" style="color: white; margin-right: 10px;"><Checkbox @click="toggleCornerHeaderLabel('Kopfball')" v-model="situationPlayerList.find(s => s.role == 'offender').action_labels" value="Kopfball"/>1: Header {{offenderIndex}}</span>
            <span v-if="situationPlayerList.length > 1 && situationPlayerList.find(s => s.role == 'corner').y != 50 && situationPlayerList.find(s => s.role == 'offender').action_labels.includes('Schuss')" style="color: white; margin-right: 10px; cursor: pointer;" @click="addCornerAttemptPosition(['Torschuss'], ['Block'], true)">2: On Target</span>
            <span v-if="situationPlayerList.length > 1 && situationPlayerList.find(s => s.role == 'corner').y != 50 && situationPlayerList.find(s => s.role == 'offender').action_labels.includes('Schuss')" style="color: white; margin-right: 10px; cursor: pointer;" @click="addCornerAttemptPosition([], [], true)">3: Missed Target</span>
            <span v-if="situationPlayerList.length > 1 && situationPlayerList.find(s => s.role == 'corner').y != 50 && situationPlayerList.find(s => s.role == 'offender').action_labels.includes('Schuss')" style="color: white; margin-right: 10px; cursor: pointer;" @click="addCornerAttemptPosition([], ['Block'], true)">4: Blocked far from Goal</span>
            <span v-if="situationPlayerList.length > 1 && situationPlayerList.find(s => s.role == 'corner').y != 50 && situationPlayerList.find(s => s.role == 'offender').action_labels.includes('Schuss')" style="color: white; margin-right: 10px; cursor: pointer;" @click="addCornerAttemptPosition(['Latte', 'Pfosten', 'Torschuss'], [], true)">5: Post / Cross Bar</span>
        </div>
        
        <span v-if="newsituationFrame.label.length != 0 && newsituationFrame.videoNeeded == null" style="color: white; margin-right: 10px; cursor: pointer;" @click="setVideoNeeded(true)">1: Dangerous / Relevant</span>
        <span v-if="newsituationFrame.label.length != 0 && newsituationFrame.videoNeeded == null" style="color: white; margin-right: 10px; cursor: pointer;" @click="setVideoNeeded(false)">2: Not Dangerous / only for Statistics</span>


    </div>
    <span v-if="newsituationFrame != null" style="color: white; margin-right: 10px; cursor: pointer;" @click="cancel()">c: Cancel</span>

</template>

<script>
import { kick17 } from '@/services';
import editorStore from '../../editorStore'
export default {
    components: {
        
    },
    props: ['match', 'currentVideoTimestamp'],
    emits: ['addSitVisible', 'newSituationEdit','addSituation'],
    data() {
        return {
            //sitEndVisible: false,
            situationlabels: null,
            collapse_newsituationFrame: false,
            newsituationFrame: null,
            back: false,
            currentIndex: 0,
            displayBasic: false,
            /*showSituationTypes: false,
            showEvalBtn: false,
            showVideoNeededBtn: false*/
        }
    },
    mounted() {




    },
    async created () {
        this.situationlabels = kick17.getSituationLabels("EN")
    },
    computed: {
        secondBtnDisabled: function() {
            if(this.currentVideoTimestamp <= this.newsituationFrame.situation_start || this.newsituationFrame.first_fixed == false) {
                return true
            }
            return false
        },
        situationPlayerList: function() {
            return editorStore.getters.getSituationPlayersList
        },
        offenderIndex: function() {
            var t = this.situationPlayerList.findIndex(s => s.role == 'offender')
            return t
        },
        selectedDirection: function() {
            var a = this.newsituationFrame.situation_start > this.match.matchVideoFrame[0] && this.newsituationFrame.situation_start < this.match.matchVideoFrame[1] ? this.match.firstHalfDirection : (this.newsituationFrame.situation_start > this.match.matchVideoFrame[2] && this.newsituationFrame.situation_start < this.match.matchVideoFrame[3] ? (this.match.firstHalfDirection == "CREATOR_LEFT_TO_RIGHT" ? "CREATOR_RIGHT_TO_LEFT" : "CREATOR_LEFT_TO_RIGHT") : null)
            console.log("dir: " + a)
            return a
        }
    },
    methods: {
        cancel() {
            
            this.newsituationFrame = null
            this.$emit('addSitVisible')
        },
        createBallLossSituation() {
            this.sitEndVisible = true
            this.newsituationFrame = {"uuid": null, "match_uuid": this.match.uuid, "custom_marker": null, "situation_start": 0, "situation_end": 0, "label": "", "evaluation": "", "comment": "", "inShortVideo": false, "first_fixed": false, "second_fixed": false, "videoNeeded": null}
            this.newsituationFrame.first_fixed = true
            this.newsituationFrame.situation_start = Number(this.currentVideoTimestamp.toFixed(0))
            this.newsituationFrame.situation_end = this.newsituationFrame.situation_start + 5
            this.newsituationFrame.videoNeeded = false
            this.newsituationFrame.label = "ballloss"
            this.newsituationFrame.second_fixed = true
            this.emitSituation()
        },
        createEmptySituation() {
            this.sitEndVisible = true
            this.newsituationFrame = {"uuid": null, "match_uuid": this.match.uuid, "custom_marker": null, "situation_start": 0, "situation_end": 0, "label": "", "evaluation": "", "comment": "", "inShortVideo": false, "first_fixed": false, "second_fixed": false, "videoNeeded": null}
            this.newsituationFrame.first_fixed = true
            this.newsituationFrame.situation_start = Number(this.currentVideoTimestamp.toFixed(0))
            this.emitSituation()
        },
        setEnd() {
            //this.displayBasic = true
            if(this.newsituationFrame != null) {
                this.newsituationFrame.second_fixed = !this.newsituationFrame.second_fixed 
                this.newsituationFrame.situation_end = Number(this.currentVideoTimestamp.toFixed(0)) 
            }
        },
        outsitenewsituationFrameDivClick() {
            this.collapseAndClear()
        },
        next(){
            this.back = false;
            this.currentIndex++;
        },
        collapseAndClear() {
            this.newsituationFrame = null
            this.collapse_newsituationFrame = false
            this.currentIndex = 0
        },
        
        setSituationLabel(index) {
            var gerLabels = kick17.getSituationLabels()
            this.newsituationFrame.label =  gerLabels[index]
            var goalkeeper, strikerPosition

            switch(gerLabels[index]) {
                case 'Tor':
                    this.newsituationFrame.videoNeeded = true
                    strikerPosition = {player_uuid: (Math.random() + 1).toString(36).substring(7), x: 50, y: 40, second: Number(this.newsituationFrame.situation_end).toFixed(0), number: 0, action_labels: ['Schuss', 'Torschuss', 'Tor'], isInCreatorTeam: this.newsituationFrame.isCreatorSituation}
                    strikerPosition.x = this.selectedDirection == "CREATOR_RIGHT_TO_LEFT" ? (this.newsituationFrame.isCreatorSituation == true ? 15 : 85) : (this.newsituationFrame.isCreatorSituation == true ? 85 : 15)
                    goalkeeper = {player_uuid: (Math.random() + 1).toString(36).substring(7), number: 1, shirtnumber: 1, action_labels: [], second: Number(this.newsituationFrame.situation_end).toFixed(0), isInCreatorTeam: !strikerPosition.isInCreatorTeam, y: 50, x: this.selectedDirection == "CREATOR_RIGHT_TO_LEFT" ? (strikerPosition.isInCreatorTeam == true ? 1 : 99) : (strikerPosition.isInCreatorTeam == true ? 99 : 1)}
                    editorStore.commit('setSituationPlayersList', [strikerPosition, goalkeeper])
                    break;
                case 'Elfmeter':
                    this.newsituationFrame.videoNeeded = true
                    var penaltyPosition = {player_uuid: (Math.random() + 1).toString(36).substring(7), x: 50, y: 50, second: Number(this.newsituationFrame.situation_end).toFixed(0), number: 0, action_labels: ['Elfmeter', 'Schuss'], isInCreatorTeam: this.newsituationFrame.isCreatorSituation}
                    var penaltyPointLeft = 10.476190476190476

                    penaltyPosition.x = this.selectedDirection == "CREATOR_RIGHT_TO_LEFT" ? (this.newsituationFrame.isCreatorSituation == true ? penaltyPointLeft : 100 - penaltyPointLeft) : (this.newsituationFrame.isCreatorSituation == true ? 100 - penaltyPointLeft : penaltyPointLeft)
                    goalkeeper = {player_uuid: (Math.random() + 1).toString(36).substring(7), number: 1, shirtnumber: 1, action_labels: [], second: Number(this.newsituationFrame.situation_end).toFixed(0), isInCreatorTeam: !penaltyPosition.isInCreatorTeam, y: 50, x: this.selectedDirection == "CREATOR_RIGHT_TO_LEFT" ? (penaltyPosition.isInCreatorTeam == true ? 1 : 99) : (penaltyPosition.isInCreatorTeam == true ? 99 : 1)}
                    goalkeeper.x = this.selectedDirection == "CREATOR_RIGHT_TO_LEFT" ? (this.newsituationFrame.isCreatorSituation == true ? 1 : 99) : (this.newsituationFrame.isCreatorSituation == true ? 99 : 1)
                    editorStore.commit('setSituationPlayersList', [penaltyPosition, goalkeeper])
                    break;
                case 'Schuss':
                    this.newsituationFrame.videoNeeded = false
                    strikerPosition = {player_uuid: (Math.random() + 1).toString(36).substring(7), x: 50, y: 40, second: Number(this.newsituationFrame.situation_end).toFixed(0), number: 0, action_labels: ['Schuss'], isInCreatorTeam: this.newsituationFrame.isCreatorSituation}
                    strikerPosition.x = this.selectedDirection == "CREATOR_RIGHT_TO_LEFT" ? (this.newsituationFrame.isCreatorSituation == true ? 15 : 85) : (this.newsituationFrame.isCreatorSituation == true ? 85 : 15)
                    goalkeeper = {player_uuid: (Math.random() + 1).toString(36).substring(7), number: 1, shirtnumber: 1, action_labels: [], second: Number(this.newsituationFrame.situation_end).toFixed(0), isInCreatorTeam: !strikerPosition.isInCreatorTeam, y: 50, x: 50}
                    goalkeeper.x = this.selectedDirection == "CREATOR_RIGHT_TO_LEFT" ? (strikerPosition.isInCreatorTeam == true ? 1 : 99) : (strikerPosition.isInCreatorTeam == true ? 99 : 1)
                    editorStore.commit('setSituationPlayersList', [strikerPosition, goalkeeper])
                    break;
                case 'Freistoß':
                    this.newsituationFrame.videoNeeded = false
                    strikerPosition = {player_uuid: (Math.random() + 1).toString(36).substring(7), x: 50, y: 40, second: Number(this.newsituationFrame.situation_start).toFixed(0), number: 0, action_labels: ['Freistoß'], isInCreatorTeam: this.newsituationFrame.isCreatorSituation}
                    strikerPosition.x = this.selectedDirection == "CREATOR_RIGHT_TO_LEFT" ? (this.newsituationFrame.isCreatorSituation == true ? 15 : 85) : (this.newsituationFrame.isCreatorSituation == true ? 85 : 15)
                    goalkeeper = {player_uuid: (Math.random() + 1).toString(36).substring(7), number: 1, shirtnumber: 1, action_labels: [], second: Number(this.newsituationFrame.situation_end).toFixed(0), isInCreatorTeam: !strikerPosition.isInCreatorTeam, y: 50, x: this.selectedDirection == "CREATOR_RIGHT_TO_LEFT" ? (strikerPosition.isInCreatorTeam == true ? 1 : 99) : (strikerPosition.isInCreatorTeam == true ? 99 : 1)}
                    editorStore.commit('setSituationPlayersList', [strikerPosition, goalkeeper])
                    break;
                case 'Eckstoß':
                    this.newsituationFrame.videoNeeded = false
                    strikerPosition = {role: "corner", player_uuid: (Math.random() + 1).toString(36).substring(7), x: 50, y: 50, second: Number(this.newsituationFrame.situation_start).toFixed(0), number: 0, action_labels: ['Eckstoß'], isInCreatorTeam: this.newsituationFrame.isCreatorSituation}
                    if(this.selectedDirection == "CREATOR_LEFT_TO_RIGHT") {
                        strikerPosition.x = this.newsituationFrame.isCreatorSituation ? 100 : 0
                    } else {
                        strikerPosition.x = this.newsituationFrame.isCreatorSituation ? 0 : 100
                    }
                    
                    editorStore.commit('setSituationPlayersList', [strikerPosition])
                    break;
                case 'Ballverlust':
                    this.newsituationFrame.videoNeeded = false
                    strikerPosition = {player_uuid: (Math.random() + 1).toString(36).substring(7), x: 50, y: 40, second: Number(this.newsituationFrame.situation_end).toFixed(0), number: 0, action_labels: ['Ballverlust'], isInCreatorTeam: this.newsituationFrame.isCreatorSituation}
                    strikerPosition.x = this.selectedDirection == "CREATOR_RIGHT_TO_LEFT" ? (this.newsituationFrame.isCreatorSituation == true ? 15 : 85) : (this.newsituationFrame.isCreatorSituation == true ? 85 : 15)
                    editorStore.commit('setSituationPlayersList', [strikerPosition])
                    
                    //this.setVideoNeeded(false)
                    break;
            }
        },
        addGoalPositions(labels) {
            var playerlist = JSON.parse(JSON.stringify(editorStore.getters.getSituationPlayersList))
            if (!labels.includes("Eigentor")) {
                playerlist[0].action_labels = playerlist[0].action_labels.concat(labels)
            } else {
                playerlist[0].action_labels = labels
                playerlist[0].isInCreatorTeam = !playerlist[0].isInCreatorTeam
            }
            if(labels.includes("Elfmeter")) {
                playerlist[0].y = 50
                var penaltyPointLeft = 10.476190476190476
                playerlist[0].x = this.selectedDirection == "CREATOR_RIGHT_TO_LEFT" ? (this.newsituationFrame.isCreatorSituation == true ? penaltyPointLeft : 100 - penaltyPointLeft) : (this.newsituationFrame.isCreatorSituation == true ? 100 - penaltyPointLeft : penaltyPointLeft)
            }
            
            editorStore.commit('setSituationPlayersList', playerlist)
            if(playerlist[0].action_labels.includes("Ballannahme")) {
                this.addAssist(playerlist[0])
            }
            this.setVideoNeeded(true)
        },
        toggleCornerHeaderLabel(relevantLabel) {

            var index = this.offenderIndex
            var playerlist = JSON.parse(JSON.stringify(this.situationPlayerList))
            playerlist[index].action_labels = playerlist[index].action_labels.includes(relevantLabel) ? JSON.parse(JSON.stringify(playerlist[index].action_labels)).filter(e => e !== relevantLabel) : playerlist[index].action_labels.concat([relevantLabel])
            console.log(playerlist)
            editorStore.commit('setSituationPlayersList', playerlist)
        },
        toggleActionLabel(relevantLabel) {
            var index = 0
            var playerlist = JSON.parse(JSON.stringify(this.situationPlayerList))
            playerlist[index].action_labels = playerlist[index].action_labels.includes(relevantLabel) ? JSON.parse(JSON.stringify(playerlist[index].action_labels)).filter(e => e !== relevantLabel) : playerlist[index].action_labels.concat([relevantLabel])
            editorStore.commit('setSituationPlayersList', playerlist)
        },
        addAssist(strikerPosition) {
            var assistPosition = {player_uuid: (Math.random() + 1).toString(36).substring(7), x: strikerPosition.x, y: strikerPosition.y - 15, second: strikerPosition.second, number: 0, action_labels: ['Angekommen'], isInCreatorTeam: strikerPosition.isInCreatorTeam}
            if(strikerPosition.action_labels.includes("Kopfball")) {
                assistPosition.action_labels = assistPosition.action_labels.concat(["Flanke"])
            } else {
                assistPosition.action_labels = assistPosition.action_labels.concat(["Pass"])
            }
            if(strikerPosition.action_labels.includes("Tor")) {
                assistPosition.action_labels = assistPosition.action_labels.concat(["Assist"])
            }
            
            var playerlist = JSON.parse(JSON.stringify(this.situationPlayerList))
            console.log(playerlist)
            playerlist = playerlist.concat([assistPosition])
            console.log(playerlist)
            editorStore.commit('setSituationPlayersList', playerlist)
        },
        addFrekickPosition(freekickLabels, defenderLabels, additionalLabels, videoNeeded) {
            var playerlist = JSON.parse(JSON.stringify(this.situationPlayerList))
            playerlist[0].action_labels = playerlist[0].action_labels.concat(freekickLabels)
            playerlist[1].action_labels = playerlist[1].action_labels.concat(defenderLabels)
            if(additionalLabels.length != 0) {
                var additionalPosition = {player_uuid: (Math.random() + 1).toString(36).substring(7), x: playerlist[0].x, y: playerlist[0].y - 15, second: playerlist[0].second, number: 0, action_labels: [], isInCreatorTeam: playerlist[0].isInCreatorTeam}
                additionalPosition.action_labels = additionalPosition.action_labels.concat(additionalLabels)
                playerlist = playerlist.concat([additionalPosition])
            }
            
            editorStore.commit('setSituationPlayersList', playerlist)

            if(videoNeeded) {
                this.setVideoNeeded(true)
            } else {
                this.addSituationFrame()
            }
        },
        addCornerAttemptPosition(strikerLabels, defenderLabels) {
            var playerlist = JSON.parse(JSON.stringify(this.situationPlayerList))
            console.log(playerlist)
            var offenderIndex = playerlist.findIndex(p => p.role == "offender")
            playerlist[offenderIndex].action_labels = playerlist[offenderIndex].action_labels.concat(strikerLabels)

            var defenderIndex = playerlist.findIndex(p => p.role == "defender")
            if(defenderLabels.length > 0 && defenderIndex == -1) {
                var defenderPosition = {role: "defender", player_uuid: (Math.random() + 1).toString(36).substring(7), number: 0, shirtnumber: 0, action_labels: defenderLabels, second: Number(this.newsituationFrame.situation_end).toFixed(0), isInCreatorTeam: !playerlist.find(p => p.role == "corner").isInCreatorTeam, y: 45, x: this.selectedDirection == "CREATOR_RIGHT_TO_LEFT" ? (playerlist.find(p => p.role == "corner").isInCreatorTeam != true ? 5 : 95) : (playerlist.find(p => p.role == "corner").isInCreatorTeam != true ? 95 : 5)}
                playerlist = playerlist.concat([defenderPosition])
            }
            if(defenderLabels.length > 0 && defenderIndex != -1) {
                playerlist[defenderIndex].action_labels = playerlist[defenderIndex].action_labels.concat(defenderLabels)
            }
            editorStore.commit('setSituationPlayersList', playerlist)
            this.setVideoNeeded(true)
        },
        addAttemptPosition(strikerLabels, defenderLabels, videoNeeded) {
            var playerlist = JSON.parse(JSON.stringify(this.situationPlayerList))
            playerlist[0].action_labels = playerlist[0].action_labels.concat(strikerLabels)
            playerlist[1].action_labels = playerlist[1].action_labels.concat(defenderLabels)
            editorStore.commit('setSituationPlayersList', playerlist)

            if(playerlist[0].action_labels.includes("Ballannahme")) {
                this.addAssist(playerlist[0])
            }
            if(videoNeeded) {
                this.setVideoNeeded(true)
            } else {
                this.addSituationFrame()
            }
        },
        setCorner(y) {
            console.log(this.situationPlayerList)
            var playerlist = JSON.parse(JSON.stringify(this.situationPlayerList))
            console.log("playerlist")
            console.log(playerlist)
            playerlist.find(p => p.role == "corner").y = Number(y)
            console.log(playerlist)
            editorStore.commit('setSituationPlayersList', playerlist)
        },
        addCornerPositions(cornerLabels, defenderLabels, offenderLabels) {
            var playerlist = JSON.parse(JSON.stringify(this.situationPlayerList))
            playerlist[0].action_labels = playerlist[0].action_labels.concat(cornerLabels)
            
            if(defenderLabels.length != 0) {
                var defenderPosition =  {role: "defender", player_uuid: (Math.random() + 1).toString(36).substring(7), x: playerlist[0].x == 0 ? 10 : 90, y: 50, second: playerlist[0].second, number: 0, action_labels: defenderLabels, isInCreatorTeam: !playerlist[0].isInCreatorTeam}
                playerlist = playerlist.concat([defenderPosition])
            }
            if(offenderLabels.length != 0) {
                var offenderPosition = {role: "offender", player_uuid: (Math.random() + 1).toString(36).substring(7), x: playerlist[0].x == 0 ? 15 : 85, y: 50, second: playerlist[0].second, number: 0, action_labels: offenderLabels, isInCreatorTeam: playerlist[0].isInCreatorTeam}
                playerlist = playerlist.concat([offenderPosition])
            }
            editorStore.commit('setSituationPlayersList', playerlist)
            this.newsituationFrame.videoNeeded = null
        },

        setSituationOwner(value) {
            this.newsituationFrame.isCreatorSituation = value
            //this.showEvalBtn = false
            //this.showVideoNeededBtn = true
            //this.next()
        },
        setVideoNeeded(val) {
            this.newsituationFrame.videoNeeded = val
            this.addSituationFrame()
        },
        emitSituation() {
            this.$emit('newSituationEdit', this.newsituationFrame)
        },
        setBegin() {
            this.newsituationFrame.first_fixed = !this.newsituationFrame.first_fixed
            this.newsituationFrame.situation_start = Number(this.currentVideoTimestamp.toFixed(0))
            if(!this.newsituationFrame.first_fixed && this.newsituationFrame.second_fixed) {
                this.newsituationFrame.second_fixed = !this.newsituationFrame.second_fixed
            }
            this.emitSituation()
        },

        async savePositions(situationUuid) {
            var playerList = editorStore.getters.getSituationPlayersList.filter(s => s != null)
            if(situationUuid == null || playerList.length == 0 || playerList[0].second == null) {return}
            var selectedDirection = playerList[0].second > this.match.matchVideoFrame[0] && playerList[0].second < this.match.matchVideoFrame[1] ? this.match.firstHalfDirection : (playerList[0].second > this.match.matchVideoFrame[2] && playerList[0].second < this.match.matchVideoFrame[3] ? (this.selectedDirection == "CREATOR_LEFT_TO_RIGHT" ? "CREATOR_RIGHT_TO_LEFT" : "CREATOR_LEFT_TO_RIGHT") : null)
            if(selectedDirection != null) {
                var timestamp = playerList[0].second - this.newsituationFrame.situation_start
                var res = await kick17.saveSituationPositions(this.match.uuid, situationUuid, playerList, Number(timestamp).toFixed(0), this.selectedDirection)
                if(res.status == 200) {
                    this.$toast.add({severity:'success', summary: 'Positions saved', detail:'Positions saved', life: 3000});
                    editorStore.commit('setSituationPlayersList', [])
                    editorStore.commit('setMatch', res.data)
                } else {
                    this.$toast.add({severity:'error', summary: 'Positions not saved', detail:'Positions not saved', life: 3000});
                }
            }
            
        },
        
        
        async addSituationFrame() {

            var result = await kick17.addSituationFrame(this.match.uuid, this.newsituationFrame.situation_start, this.newsituationFrame.situation_end, this.newsituationFrame.label, this.newsituationFrame.isCreatorSituation, this.newsituationFrame.videoNeeded)

            var tmpMatch = JSON.parse(JSON.stringify(this.match))
            this.$emit('addSituation', result.data.match)
            this.$emit('addSitVisible')
            tmpMatch.situations = result.data.match.situations
            editorStore.commit('setMatch', tmpMatch)
            this.savePositions(result.data.situationUuid)
            this.newsituationFrame = null
        },
        getBeginnTimestamp() {
            return this.newsituationFrame.first_fixed == false ? this.currentVideoTimestamp.toFixed(0) : this.newsituationFrame.situation_start
        },
        getEndTimestamp() {
            return this.newsituationFrame.second_fixed == false ? this.currentVideoTimestamp.toFixed(0) : this.newsituationFrame.situation_end
        },
        
    }
}

</script>

<style scoped lang="scss">
@import '~bootstrap/scss/bootstrap.scss';
.video-js {
    position: relative !important;
    width: 100% !important;
    height: auto !important;
}
.vjs-tech {
    position: relative !important;
}
.vjs-poster {
    position: absolute !important;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.vjs-marker
{
  position:absolute;
  background: black;
  width:5px;
  height:110%;
  top:-5%;
  z-index:30;
  margin-left:-3px;
}

.yellow-card {
    background: yellow;
}

.vjs-marker:hover span 
{
  opacity:1;
}

.vjs-marker span
{
  position:absolute;
  bottom:15px;
  opacity:0;
  margin-left:-20px;
  z-index:90;
  background:rgba(0,0,0,.8);
  padding:8px;
  font-size:15px;
}
</style>