<template>
<div>
    <div>
        <div class="container py-5">
                <div class="row py-5">
                    <div class="col-lg-7 mx-auto">

                        <div >
                            <div class="text-center">
                                <h4 class="text-center">Etwas ist schiefgelaufen <i style="color: red;" class="far fa-times-circle"></i></h4>
                                <span>Bitte kontaktiere uns über info@kick17.de</span>
                            </div>
                        </div>
                    </div>
                </div>        
            </div>

    </div>
</div>
</template>


