<template>
<div>

    <div class="layout-main-container ">
        <div class="layout-main m-auto md:col-7" style="padding-left: 2rem; padding-right: 2rem; padding-top: 2rem;">
            
            <h5 class="card-title text-center">{{datepicker_title}}</h5>
            <div class="grid " v-if="pickervisible == false">
                <MatchDayButton :title="'Genau jetzt'" :icon="'pi-play'" @click="now()"/>
                <MatchDayButton :title="sincetext" :icon="'pi-clock'" v-styleclass="{ selector: '.box', enterClass: 'p-d-none', enterActiveClass: 'my-fadein', leaveClass: 'my-fadeout', leaveToClass: 'p-d-none' }" @click="collapse_spielbeginn_vor = !collapse_spielbeginn_vor"/>
                <div class="box p-d-none w-100">
                    <div class="p-grid p-flex-column">
                        <div class="p-col m-auto">
                        <InputNumber class="m-auto" id="vertical" v-model="begin_since" mode="decimal" showButtons buttonLayout="vertical" style="width:4rem"
                            decrementButtonClass="p-button-secondary" incrementButtonClass="p-button-secondary" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" :min="0"/>
                        </div>
                        <div class="p-col">
                            <button style="margin-bottom: 5em; margin-top: 1.5em;" class="form-label-group btn btn-sm btn-outline-dark" @click="saveRunningMatch()">Weiter</button>
                        </div>
                    </div>
                </div>
                <MatchDayButton :title="'Bereits stattgefunden'" :icon="'pi-replay'" @click="togglePickerVisibility(true)"/>
                <MatchDayButton :title="'Wird stattfinden'" :icon="'pi-refresh'" @click="togglePickerVisibility(false)"/>
            </div>
            <div class="row py-5" v-else>
                <Calendar class="w-100" id="time24" v-model="fullDateTime" :showTime="true" :showSeconds="false" touchUI="true" dateFormat="dd.mm.yy" />
            </div>
            <div v-if="pickervisible == true" class="row">
               <button style="margin-left: 15px; margin-right: 15px;" class="form-label-group col btn btn-lg btn-outline-dark" @click="togglePickerVisibility(false)">Zurück</button>
               <button style="margin-left: 15px; margin-right: 15px;" class="form-label-group col btn btn-lg btn-outline-dark" @click="saveMatchDate()">Speichern</button>
        </div>
        </div>
    </div>

    <div class="container">

        <h5 class="card-title text-center">{{datepicker_title}}</h5>

        <div class="row py-5" v-if="pickervisible == false">
                <Button label="Genau jetzt" class="p-button-outlined p-button-lg p-button-secondary" @click="now()"/>
              
                <Button label="Spiel läuft bereits" class="p-button-outlined p-button-lg p-button-secondary" v-styleclass="{ selector: '.box', enterClass: 'p-d-none', enterActiveClass: 'my-fadein', leaveClass: 'my-fadeout', leaveToClass: 'p-d-none' }" />
                
                
                <div class="box p-d-none">
                    <InputNumber id="vertical" v-model="begin_since" mode="decimal" showButtons buttonLayout="vertical" style="width:4rem"
                decrementButtonClass="p-button-secondary" incrementButtonClass="p-button-secondary" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                    <button style="margin-bottom: 5em; margin-top: 1.5em;" class="form-label-group btn btn-sm btn-outline-dark" @click="saveRunningMatch()">Weiter</button>
                </div>

                <Button label="Bereits stattgefunden" class="p-button-outlined p-button-lg p-button-secondary" @click="togglePickerVisibility(true)"/>
                <Button label="Wird stattfinden" class="p-button-outlined p-button-lg p-button-secondary" @click="togglePickerVisibility(false)"/>


                <button v-if="showMatchSelector" style="margin-top: 2em; margin-left: 15px; margin-right: 15px;" class="form-label-group btn btn-lg btn-dark" @click="abbrechen">zurück</button>
                
        </div>
 
        <div  class="row">
            <div class="col-md-6 mx-auto">
                <div class="py-4 text-center"><i class="far fa-calendar-alt fa-5x"></i></div>

                
                <!--<div class="form-group mb-4">
                    <b-form-datepicker placeholder="Spielbeginn Datum" id="example-datepicker" v-model="datum" class="mb-2"></b-form-datepicker>
                    <b-form-timepicker placeholder="Uhrzeit" v-model="time" locale="de"></b-form-timepicker>
                </div>-->

            </div>
        </div>



        <div v-if="pickervisible == true" class="row py-5">
               <button style="margin-left: 15px; margin-right: 15px;" class="form-label-group col btn btn-lg btn-outline-dark" @click="togglePickerVisibility(false)">Zurück</button>
               <button style="margin-left: 15px; margin-right: 15px;" class="form-label-group col btn btn-lg btn-outline-dark" @click="saveMatchDate()">Speichern</button>
        </div>
    </div>
    


</div>
</template>

<style scoped lang="scss">
@import '~bootstrap/scss/bootstrap.scss'; 


.number-input--inline {
    width: 100%;
}

.number-input--controls>input {
    width: 100%!important;
    color: red;
}

.mynumber input:first-child {
    width: 100%!important;
    color: red;
}

.signup-form {
  width: 100%;
}

.datepicker td, .datepicker th {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 0.85rem;
}

.datepicker {
    margin-bottom: 3rem;
}




.input-group {
    border-radius: 30rem;
}

input.form-control {
    border-radius: 30rem 0 0 30rem;
    border: none;
}

input.form-control:focus {
    box-shadow: none;
}

input.form-control::placeholder {
    font-style: italic;
}

.input-group-text {
    border-radius: 0 30rem 30rem 0;
    border: none;
}

.datepicker-dropdown {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.center{
width: 150px;
  margin: 40px auto;
  
}

#collapse-collapse_spielbeginn_vor {
    margin: auto;
    width: 60%;
}

</style>

<script>
import { kick17 } from '@/services';
import MatchDayButton from './MatchDayButton'

export default {
    components: {
        MatchDayButton
    },
    props: ['showMatchSelector'],
    data() {
        return {
            datetime: null,
            pickervisible: false,
            collapse_spielbeginn_vor: false,
            date_in_past: false,
            begin_since: 1,
        }
    },
    computed: {
        match: function() {
            return this.$store.getters.getMatch
        },
        datepicker_title: function() {
            return this.date_in_past == true ? "Wann war Spielbeginn?" : "Wann beginnt das Spiel?"
        },
        sincetext: function() {
            var text = this.collapse_spielbeginn_vor == true ? " seit " +  this.begin_since + " Minuten" : ""
            return "Spiel läuft bereits" + text
        },
        fullDateTime: {
            get: function () {
                return this.datetime
            },
            set: function (newValue) {
                var newDate = new Date(newValue)
                //this.datum = newDate.getFullYear()+'-'+(newDate.getMonth()+1)+'-'+newDate.getDate();
                //this.time = newDate.getHours() + ":" + newDate.getMinutes() + ":00";
                this.datetime = newDate
            }
        }

    },
    mounted() {




    },
    async created () {
        this.fullDateTime = new Date()
    },
    methods: {
        abbrechen() {
            this.$emit('toggleShowMatchSelector')
        },
        async now() {
            this.datetime = new Date()
            this.saveMatchDate()
            this.$store.commit('setNow')
            this.$emit('toggleShowMatchSelector')
        },
        async saveMatchDate() {
            var result = await kick17.setmatchdate(this.match.uuid, this.datetime)
            if(this.date_in_past == true) {
                result = await kick17.finishMatch(this.match.uuid)
            }
            this.$store.commit('setMatch', result.data)
            this.$store.commit('setMatchUuid', result.data.uuid)
            this.$store.commit('setNow')
        },
        togglePickerVisibility(is_in_past) {
            this.collapse_spielbeginn_vor = false
            this.date_in_past = is_in_past
            this.pickervisible = !this.pickervisible
        },
        async saveRunningMatch() {
            var today = new Date()
            var relevant_timestamp = today.getTime() - (this.begin_since * 60000)
            var matchstart = new Date(relevant_timestamp)
            var result = await kick17.setmatchdate(this.match.uuid, matchstart)
            this.$store.commit('setMatch', result.data)
            this.$store.commit('setMatchUuid', result.data.uuid)
        }
        
    }
}

</script>