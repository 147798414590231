<template>
<div>
      <div id="loginBanner" class="bg-cover py-5 text-center" style="border-radius: 0;"> 
    <div class="container py-5 text-center text-white">
        <img class="center" style="width: 7%;" src="../../assets/kick17.png">

        <div class="font-weight-bold mb-0 text-uppercase" style="display: inline-block; text-align: left;">Kick17 <br>
          <small style="font-size: 65%; float: left;">Be professional!</small>
        </div> 

    </div>
  </div>

    <div class="container">
        <div class="row justify-content-center">
            <div class="signup-form" >
                <h5 class="card-title text-center">Wer ist Dein Gegner?</h5>
                <div class="form-signin">

                <div class="form-label-group mt-2" >
                  <span class="p-float-label">
                    <InputText id="registerinputClub" class="w-100" type="text" v-model="clubname" />
                    <label for="registerinputClub">Gegnerischer Club</label>
                  </span>
                </div>

                <div class="form-label-group" >
                  <Dropdown class="w-100" v-model="selectedAgeband" :options="agebands" optionLabel="name" optionValue="name" placeholder="Wähle eine Altersklasse" />
                </div>

                <div class="form-label-group" >
                  <Dropdown class="w-100" v-model="selectedTeamRang" :options="teams" optionLabel="name" optionValue="name" placeholder="Wähle ein Team" />
                </div>
             
                
                </div>   
            </div>
        </div>   
        <div class="row py-5">
               <button style="margin-left: 15px; margin-right: 15px;" class="form-label-group col btn btn-lg btn-outline-dark" @click="save()">Speichern</button>
        </div>
    </div>
    


</div>
</template>

<style scoped lang="scss">
@import '~bootstrap/scss/bootstrap.scss'; 
.signup-form {
  width: 100%;
}
</style>

<script>
import { kick17 } from '@/services';

export default {
    components: {
        
    },
    data() {
        return {
        agebands: kick17.getAgebands().data,
        teams: kick17.getAllTeamClasses().data,
        selectedAgeband: "",
        selectedTeamRang: "",
        clubname: ""
    }
    },
    mounted() {




    },
    async created () {
    },
    computed: {
        match: function() {
            return this.$store.getters.getMatch
        }
    },
    methods: {
      selectAltersKlasse(age) {
        this.selectedAgeband = age
      },
      selectTeam(teamrang) {
          this.selectedTeamRang = teamrang
      },
       getAgeGroupModalText() {
          return this.selectedAgeband == "" ? "Altersklasse" : this.selectedAgeband
      },
      getTeamRangText() {
          return this.selectedTeamRang == "" ? "Team" : this.selectedTeamRang
      },
      async save() {
          var result = await kick17.setopponent(this.match.uuid, this.clubname, this.selectedTeamRang, this.selectedAgeband)
          //this.$emit('refreshMatch', match)
          this.$store.commit('setMatch', result.data)
      }
        
    }
}

</script>