<template>
<div>

    <div class="container">

        <div class="row justify-content-center py-5">
            <div class="text-center">
                <div class="">
                    <h4 class="text-uppercase font-weight-bold">Video hinzufügen</h4>
                    <span>Bitte lade alle Videos des Spiels hoch. <br>Wir erstellen für Dich eine Zusammenfassung der interessantesten Situationen.</span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-lg-5 flex align-items-center justify-content-center">
                <div class="p-fluid w-100">
                    <div class="field w-100">
                        <label for="link">Link</label>
                        <InputText id="link" type="text" v-model="videolink"/>
                    </div>
                    <Button label="Speichern" @click="addVideoLink()"></Button>
                    </div>
            </div>
            <div class="col-12 col-lg-2 py-5 d-xl-none">
                <Divider layout="horizontal" align="center">
                    <b>ODER</b>
                </Divider>
            </div>

            <div class="col-12 col-lg-2 py-5 d-none d-xl-block">
                <Divider layout="vertical" align="center">
                    <b>ODER</b>
                </Divider>
            </div>
            <div class="col-12 col-lg-5 flex align-items-center justify-content-center">
                <FileUpload mode="advanced" :fileLimit="1"  accept=".mp4, .AVI, .WMV, .AVCHD" @upload="uploadDone" @before-send="beforeSend" @progress="progress" :auto="true" :url="uploadURL" :multiple="false" :showUploadButton="false" :showCancelButton="false" :chooseLabel="'Video Hochladen'">
                    <template #empty>
                        <p>Hochladen durch Drag and drop.</p>
                    </template>
                </FileUpload>
            </div>
        </div>

        <div v-if="videos != null && videos.length != 0" class="row justify-content-center">
            <div class="text-center">
                <div class="">
                    <h4 class="text-uppercase font-weight-bold">Bereits hochgeladene Videos</h4>
                </div>
            </div>
        </div>

        
    </div>

</div>
</template>

<style scoped lang="scss">

@import '~bootstrap/scss/bootstrap.scss';

.p-fileupload {
    width: 100% !important;
}
</style>

<script>
import { kick17 } from '@/services';



export default {
    components: {
        //vueDropzone: vue2Dropzone,
    },
    props: ['videos'],
    data() {
        return {
            videolink: "",
            uploadURL: "",
            headers: null
        }
    },
    async mounted() {
        
    },
    async created() {
        
        this.uploadURL = kick17.getMatchVideoUploadURL()
        this.accesstoken = await kick17.getAccessToken()
    },
    computed: {
        match: function() {
            return this.$store.getters.getMatch
        }
    },
    methods: {     

        async addVideoLink() {
            var res = await kick17.addVideoLink(this.match.uuid, this.videolink)
            this.$store.commit('setMatch', res.data)
        },
        progress(a) {
            console.log(a)
        },

        async beforeSend(request) {
            request.xhr.setRequestHeader("matchUuid", this.match.uuid);
            request.xhr.setRequestHeader("Authorization", this.accesstoken);
            return request
        },

        uploadDone(response) {

            if(response.xhr.status==200) {
                this.$store.commit('addVideoToMatch', response.xhr.responseText)
            }
        }
    }
}

</script>