<template>
<div>
  <div v-if="srcTmp != null"><vue3-video-player ref="myvideoPlayer" :src="videoSrc" :disablepictureinpicture="true" :config="tmp" title="" :muted="false" :logo="logo" @loadeddata="onPlayerLoadeddata" @timeupdate="onPlayerTimeupdate">
    
    <template #cusControls>
        <!--<div v-if="match.videos.length > 1 && match.shortVideosReady != true">
            <span v-for="(v, index) in match.videos" v-bind:key="index" style="color: white; margin-right: 10px; cursor: pointer;" @click="selectedVideoIndex = index">
                Video {{index + 1}}
            </span>
        </div>-->

        <span style="color: white; margin-right: 10px; cursor: pointer;" @click="setTime(Number(Number(curTime) - 3))"><i class="pi pi-backward"></i></span>
        <span style="color: white; margin-right: 10px;">3s</span>
        <span style="color: white; margin-right: 10px; cursor: pointer;" @click="setTime(Number(Number(curTime) + 3))"><i class="pi pi-forward"></i></span>

        <!--<div >
            <span style="color: white; margin-right: 10px; cursor: pointer;" @click="toggleBirdPerspective()">
                Board <i v-if="showBoard" class="pi pi-check-circle"></i> <i v-else class="pi pi-circle"></i>
            </span>
        </div>-->
        

        <!--<span v-if="match.videos.length < 2 && match.shortVideosReady != true" style="color: white; margin-right: 10px; cursor: pointer;" @click="addVideo">Video hinzufügen</span>-->
    </template>
  
  </vue3-video-player></div>
</div>
</template>

<script>
//https://github.com/LarchLiu/vue3-video-player
//https://core-player.github.io/vue-core-video-player/events.html

import { kick17 } from '@/services';


export default {
    components: {
        
    },
    
    emits: ["ready", "playing", "toggleBirdPerspective"],
    props: ['video_uuid', 'situations', 'showBoard'],
    data() {
        return {
            srcTmp: "",
            markers: [],
            logo: require('@/assets/kick17.png'),
            selectedVideoIndex: 0,
            videoLengthArr: [],
            curTime: 0,
            tmp: null
        }
    },
    watch: {
        situations: function (newVal) {
            if(newVal != null) {
                this.onPlayerLoadeddata()
            }
        },
        selectedSituation: function(newVal) {
            if(newVal != null) {
                this.setTime(0)
            }
        },
        selectedVideoIndex: function() {
            this.refreshAllMarkers()
        },

    },
    mounted() {},
    unmounted() {
      window.removeEventListener('keyup', this.keyUpHandler);
    },
    async created() {
        this.srcTmp = this.match.shortVideosReady == true ? kick17.getVideoURL() + this.match.situations[this.selectedVideoIndex].video : kick17.getVideoURL() + this.match.videos[this.selectedVideoIndex] //"http://localhost:8080/videos/20211124192233_V8YY9hD75Aa4XcwfAPxIQ5HV5xl2.mp4"
        window.addEventListener('keyup', this.keyUpHandler);
    },
    computed: {

        selectedSituation: function() {
            return this.$store.getters.getSelectedSituation
        },
        match: function() {
            return this.$store.getters.getMatch
        },
        player() {
            return this.$refs.myvideoPlayer
        },
        videoSrc: function() {
            if(this.match == null) {
                return ""
            }
            else if(this.match.shortVideosReady != true) {
                try {
                    let url = new URL(this.match.videos[this.selectedVideoIndex])
                    return url.href
                } catch (_) {
                    return kick17.getVideoURL() + this.match.videos[this.selectedVideoIndex]
                }
            } else {
                return this.selectedSituation != null ? (this.match != null ? kick17.getSituationVideoURL() + this.match.situations.find(s => s.uuid == this.selectedSituation.uuid).video : "") : kick17.getSituationVideoURL() + this.match.situations.find(s => s.video != null).video
            }
            
        },
        previousVideosDurations: function() {
            var result = 0
            if(this.selectedVideoIndex == 0 || this.match.shortVideosReady == true) {
                return result
            }
            else {
                for(var i = 0; i < this.selectedVideoIndex; i++) {
                    var lastIndex = i
                    result = result + this.videoLengthArr[lastIndex]
                }
            }
            return result
        },
        
        
    },
    methods: {
        keyUpHandler(event) {
            switch(event.code) {
                case 'ArrowRight':
                    this.setTime(this.curTime + 3)
                    break;
                case 'ArrowLeft':
                    this.setTime(this.curTime - 3)
                    break;
                default:
                    //break;
            }
        },
        toggleBirdPerspective() {
            this.$emit('toggleBirdPerspective')
        },
        selectVideo(index) {
            this.selectedVideoIndex = index
            this.srcTmp = this.match.shortVideosReady == true ? kick17.getVideoURL() + this.match.situations[this.selectedVideoIndex].video : kick17.getVideoURL() + this.match.videos[this.selectedVideoIndex]
        },
        getSituationVideoTimestamp(sit) {
            if(sit.situation_start != 0) {return sit.situation_start}
            var begin = new Date(this.match.match_date)
            var curTime = new Date(sit.custom_marker)
            return curTime.getDate() - begin.getDate()
        },
        removeMarkers() {
            var elements = document.getElementsByClassName("vjs-marker");
            while(elements.length > 0){
                elements[0].parentNode.removeChild(elements[0]);
            }
        },
        showExcludedFrames() {
            var player = this.$refs.myvideoPlayer.$player
            var p = player.$el.querySelector('.vcp-progress')
            var length = player.$el.querySelector('.vcp-progress').clientWidth
            var widthArr = []
            var timestamps = []
            if(this.match.matchVideoFrame == null || this.match.matchVideoFrame.length == 0) {return}
            //timestamps = this.match.videos.length > 1 ? this.match.matchVideoFrame.slice((this.selectedVideoIndex * 2), (this.selectedVideoIndex * 2) + 2) : this.match.matchVideoFrame
            if(this.match.videos.length > 1) {
                timestamps = this.match.matchVideoFrame.slice((this.selectedVideoIndex * 2), (this.selectedVideoIndex * 2) + 2)
                widthArr = [timestamps[0] - this.previousVideosDurations, timestamps[1] == 0 ? 0 : Number(this.getVideoDuration() - (timestamps[1] - this.previousVideosDurations)).toFixed(0)]
            } else {
                timestamps = this.match.matchVideoFrame
                widthArr = [timestamps[0], timestamps[2] - timestamps[1], this.getVideoDuration() - timestamps[3]]
            }
            
            for(var i=0;i<widthArr.length;i++){
                
                

                        var markerWidth = ((widthArr[i] / this.getVideoDuration()) * length) + "px"
                        var left = 0

                        if (this.match.videos.length > 1 ) {
                            left = i == 0 ? p.getBoundingClientRect().left : ( (timestamps[i] - this.previousVideosDurations) / this.getVideoDuration() * 100)+'%';
                        } else {
                            left = i == 1 ? ( timestamps[1] / this.getVideoDuration() * 100) : left
                            left = i == 2 ? ( timestamps[3] / this.getVideoDuration() * 100) : left
                            left = left + '%'
                        }

                        var divElement = document.createElement('div')

                        divElement.className = "excludedFrameMarker vjs-marker"
                        var spanElement = document.createElement("span");
                        spanElement.innerHTML = "not relevant"

                        divElement.appendChild(spanElement);
                        divElement.style="width: " + markerWidth + "; left: " + left
                        
                        p.appendChild(divElement);
                    
            }
        },
        refreshAllMarkers() {
            if(this.match.shortVideosReady != true) {
                this.removeMarkers()
                this.showSituationsFrames()
                this.showExcludedFrames()
            }
        },
        showSituationsFrames() {

            var player = this.$refs.myvideoPlayer.$player
            var p = player.$el.querySelector('.vcp-progress')
            
            var length = player.$el.querySelector('.vcp-progress').clientWidth

            if(this.match.situations == null) {return}
            for(var i=0;i<this.match.situations.length;i++){
                var curSituation = this.match.situations[i]
                if(curSituation.situation_start >= this.previousVideosDurations && (curSituation.situation_start - this.previousVideosDurations) < this.getVideoDuration()) {
                    if(curSituation.uuid != null && curSituation.situations_end != 0) {

                        var markerWidth = ((this.situationDuration(curSituation) / this.getVideoDuration()) * length) + "px"
                        var left = ( (curSituation.situation_start - this.previousVideosDurations) / this.getVideoDuration() * 100)+'%';
                        var situation_start = this.getSituationVideoTimestamp(curSituation)

                        var divElement = document.createElement('div')

                        divElement.className = "frameMarker vjs-marker"
                        var spanElement = document.createElement("span");
                        spanElement.innerHTML = curSituation.label[0]

                        divElement.appendChild(spanElement);
                        divElement.style="width: " + markerWidth + "; left: " + left
                        divElement.setAttribute("data-time", situation_start)
                        p.appendChild(divElement);
                    }
                }
            }
        },
        situationDuration(situation) {
            return situation.situation_end - situation.situation_start
        },

        
        onPlayerLoadeddata() {
            this.videoLengthArr[this.selectedVideoIndex] = this.getVideoDuration()
            this.refreshAllMarkers()
            var player = this.$refs.myvideoPlayer.$player

            var total = player.$video.duration
            var p = player.$el.querySelector('.vcp-progress')
            var markers = this.situations
            if(markers == null) {return}
            for(var i=0;i<markers.length;i++){
            
                var left =( markers[i].situation_start/ total * 100)+'%';
                var situation_start = this.getSituationVideoTimestamp(markers[i]) //markers[i].situation_start;

                var divElement = document.createElement('div')

                divElement.className = "vjs-marker " + markers[i].situationType 
                var spanElement = document.createElement("span");
                spanElement.innerHTML = markers[i].label


                divElement.appendChild(spanElement);
                divElement.style="left: " + left
                divElement.setAttribute("data-time", situation_start)
        
                p.appendChild(divElement);
            }
        },
        onPlayerTimeupdate(a) {
            this.curTime = Number(this.$refs.myvideoPlayer.$player.$video.currentTime)
            this.$emit('playing', a.target.currentTime)
        },

        onPlayerCanplay() {

        },
        onPlayerCanplaythrough() {

        },
        setTime(time) {
            this.$refs.myvideoPlayer.$player.$video.currentTime = time
        },
        getVideoDuration() {
            return this.$refs.myvideoPlayer.$player.$video.duration
        },
        addVideo() {
            this.$emit('addVideo')
        },
        getVideoLengthArr() {
            return this.videoLengthArr
        }
      
    }
}

</script>

<style >
.video-js {
    position: relative !important;
    width: 100% !important;
    height: auto !important;
}
.vjs-tech {
    position: relative !important;
}
.vjs-poster {
    position: absolute !important;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.vjs-marker
{
  position:absolute;
  background: black;
  width:5px;
  height:110%;
  top:-5%;
  z-index:30;
  /*margin-left:-3px;*/
}

.yellow-card {
    background: yellow;
}

.vjs-marker:hover span 
{
  opacity:1;
}

.vjs-marker span
{
  position:absolute;
  bottom:15px;
  opacity:0;
  margin-left:-20px;
  z-index:90;
  /*background:rgba(255,255,255,.8);*/
  padding:8px;
  font-size:15px;
  font-weight: bold;
}

.btn-pip {
    display: none!important;
}

.frameMarker {
    background: yellow !important;
} 
.play-pause-layer .btn-control {
    display: none!important;
}
</style>