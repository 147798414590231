<template>

<div class="konvaContainer" id="positionsettercanvas" ref="konvaContainer">
    <Dialog :header="'Add Corner ' " v-model:visible="addCornerVisible" :dismissableMask="true" :style="{width: '50vw'}">
        <div class="grid">
            <div class="col-1">Team</div> <div class="col-1"><ColorPicker v-model="selectedColor" :disabled="true" @click="selectedPlayer.isInCreatorTeam = !selectedPlayer.isInCreatorTeam"/></div>
            <div class="col-2">    <div class="p-field p-md-3">
                    <label for="integeronly">Number</label>
                    <InputNumber id="integeronly" v-model="selectedPlayer.number"/>
                </div>
            </div>
            <!--{{selectedPlayer != null ? selectedPlayer.player_uuid : ''}}-->
         
            <div v-if="selectedPlayer.isInCreatorTeam == true"><Dropdown :options="showPlayers" optionLabel="uuid" placeholder="Select a Player" @change="setPlayer">
                <template #option="slotProps">
                    <div class="country-item">
                        <div>{{slotProps.option.shirtnumber}} {{slotProps.option.lastname}} </div>
                    </div>
                </template>
            </Dropdown>
            </div>
            {{selectedPlayer.action_labels}}

            <div class="col-6">
                <div class="p-field-checkbox">
                    <Checkbox id="flanke" name="actionLabel" value="Flanke" v-model="selectedPlayer.action_labels"/>
                    <label for="flanke">Flanke</label>
                </div>
                <div class="p-field-checkbox">
                    <Checkbox id="pass" name="actionLabel" value="Pass" v-model="selectedPlayer.action_labels"/>
                    <label for="pass">Pass</label>
                </div>
            </div>
            <div class="col-6">
                {{selectedPlayer.y}}
                <div class="field-radiobutton">
                    <RadioButton id="cornerTop" name="cornerTop" value="0" v-model="selectedPlayer.y" />
                    <label for="cornerTop">Top</label>
                </div>
                <div class="field-radiobutton">
                    <RadioButton id="cornerBottom" name="cornerBottom" value="100" v-model="selectedPlayer.y" />
                    <label for="cornerBottom">Bottom</label>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Delete" class="p-button-outlined p-button-danger" @click="deletePlayer()"/>
            <Button label="Close" icon="pi pi-times" @click="addCornerVisible = false" class="p-button-text"/>
            <Button label="Save" icon="pi pi-check" @click="changePlayer()" autofocus />
        </template>
    </Dialog>

    <Dialog :header="'Edit Player ' " v-model:visible="displayBasic" :dismissableMask="true" :style="{width: '50vw'}">
            <div class="grid">
                <div class="col-1">Team</div> <div class="col-1"><ColorPicker v-model="selectedColor" :disabled="true" @click="selectedPlayer.isInCreatorTeam = !selectedPlayer.isInCreatorTeam"/></div>
                <div class="col-2">    <div class="p-field p-md-3">
                        <label for="integeronly">Number</label>
                        <InputNumber id="integeronly" v-model="selectedPlayer.number"/>
                    </div>
                </div>

                <Dropdown v-if="selectedPlayer.isInCreatorTeam == true" :options="showPlayers" optionLabel="uuid" placeholder="Select a Player" @change="setPlayer">
                    <template #option="slotProps">
                        <div class="country-item">
                            <div>{{slotProps.option.shirtnumber}} {{slotProps.option.lastname}} </div>
                        </div>
                    </template>
                </Dropdown>
                

                <div class="col-12">
                    <div v-for="(item, index) in JSON.parse(JSON.stringify(actionLabels)).sort((a, b) => a.localeCompare(b))" :key="index" class="p-field-checkbox">
                        <Checkbox :id="index" name="actionLabel" :value="item" v-model="selectedPlayer.action_labels"/>
                        <label :for="index">{{getTranslated(item)}}</label>
                    </div>

                 
                </div>
            </div>
            <template #footer>
                <Button label="Delete" class="p-button-outlined p-button-danger" @click="deletePlayer()"/>
                <Button label="Close" icon="pi pi-times" @click="displayBasic = false" class="p-button-text"/>
                <Button label="Save" icon="pi pi-check" @click="changePlayer()" autofocus />
            </template>
        </Dialog>    
    <Pitch ref="editorPitch" @pitchresized="pitchresized" :myPitchHeight="mainDivRectangle == null ? 0 : mainDivRectangle.width" :horizontal="true"></Pitch>
    <v-stage v-bind:style="editorPitch != null ? 'margin-left: ' + editorPitch.goalHeight + 'px;' : ''" 
        ref="stage"
        :config="configKonva">

        <v-layer ref="layer">
            <v-group v-for="item in playerList" 
                :key="item.id" 
                @dragend="handleDragend"
                @click="clickPlayer"
                :config="{
                        x: item.x,
                        y: item.y,
                        id: item.id,
                        radius: item.radius,
                        fill: item.fill,
                        opacity: 0.8,
                        draggable: true,
                    }"> 
                <v-circle
                    :config="{
                    
                        id: item.id,
                        radius: item.radius,
                        fill: item.fill,
                        opacity: 0.8,
            
                        
                        
                        shadowBlur: 10,
                        shadowOffsetX: dragItemId === item.player_uuid ? 15 : 5,
                        shadowOffsetY: dragItemId === item.player_uuid ? 15 : 5,
                        shadowOpacity: 0.6
                    }"
                ></v-circle>
                <v-text :text="item.number == null ? '?' : item.number" :config="{x: -4, y: -3, id: item.id}"></v-text>
            </v-group>

            <!--<v-circle
                    v-for="item in corners"
                    :key="item.id" 
                    @click="clickCorner"
                    @dragend="handleCornerDragend"
                    :config="{
                        x: Number(item.x), // * configKonva.width / 100,
                        y: Number(item.y), // * configKonva.height / 100,
                        id: item.id,
                        radius: item.selected == true? 7 : 4,
                        fill: item.videopoint == null ? '#195760' : '#4cfc00',
                        //draggable: true,
                    }"
                ></v-circle>-->

            <!--<v-group @click="clickArrows">-->
                <v-arrow v-if="direction != null"
                    :config="{
                        id: 'arrow1',
                        points: [45 * configKonva.width / 100, 3 * configKonva.height / 100, 55 * configKonva.width / 100, 3 * configKonva.height / 100],
                        pointerLength: 10,
                        pointerWidth: 10,
                        fill: direction == 'CREATOR_LEFT_TO_RIGHT' ? '#' + match.creatorcolor : '#' + match.opponentcolor,
                        stroke: direction == 'CREATOR_LEFT_TO_RIGHT' ? '#' + match.creatorcolor : '#' + match.opponentcolor,
                        strokeWidth: 4
                    }"
                ></v-arrow>
                <v-arrow v-if="direction != null"
                    :config="{
                        id: 'arrow2',
                        points: [55 * configKonva.width / 100, 5 * configKonva.height / 100, 45 * configKonva.width / 100, 5 * configKonva.height / 100],
                        pointerLength: 10,
                        pointerWidth: 10,
                        fill: direction == 'CREATOR_LEFT_TO_RIGHT' ? '#' + match.opponentcolor : '#' + match.creatorcolor,
                        stroke: direction == 'CREATOR_LEFT_TO_RIGHT' ? '#' + match.opponentcolor : '#' + match.creatorcolor,
                        strokeWidth: 4
                    }"
                ></v-arrow>
           <!--</v-group>-->
      </v-layer>
    </v-stage>
    
    </div>
</template>


<style>
#positionsettercanvas .konvajs-content {
    position: absolute !important;
    top: 0px;
}
</style>
<script>
import { ref, onMounted, computed, toRefs, watch } from 'vue'
import { defineComponent } from "vue";
import Pitch from '../Basics/Pitch'
//import { useStore } from 'vuex'
import editorStore from '../../editorStore'
//https://github.com/jlouthan/perspective-transform


export default defineComponent({
  props:['frames_data', 'match', 'direction', 'players'],
  emits: ['playerchanged', 'deletePlayer', 'addNewPlayer', 'switchSides'],
  components: {Pitch},
  setup(props, { emit }) {
        //const { current_second } = toRefs(props)
        const { frames_data } = toRefs(props)
        const { match } = toRefs(props)
        const { players } = toRefs(props)
        const displayBasic = ref(false);
        const addCornerVisible = ref(false)
        const PerspT = require('perspective-transform')
        const heightWidthRatio = ref(0)
        var mainDivRectangle = ref(null)
        const editorPitch = ref(null)
        const konvaContainer = ref(null)
        //const store = useStore()
        // videoPoints in prozent dstPoints in prozent
        var corners = ref([{"id":"0","x":"3","y":"2","selected":false},{"id":"1","x":"3","y":"22","selected":false},{"id":"2","x":"3","y":"37","selected":false},{"id":"3","x":"3","y":"63","selected":false},{"id":"4","x":"3","y":"79","selected":false},{"id":"5","x":"3","y":"98","selected":false},{"id":"6","x":"18","y":"22","selected":false},{"id":"7","x":"18","y":"40","selected":false},{"id":"8","x":"18","y":"60","selected":false},{"id":"9","x":"18","y":"78","selected":false},{"id":"10","x":"50","y":"2","selected":false},{"id":"11","x":"50","y":"37","selected":false},{"id":"12","x":"50","y":"50","selected":false},{"id":"13","x":"50","y":"63","selected":false},{"id":"14","x":"50","y":"98","selected":false},{"id":"15","x":"82","y":"22","selected":false},{"id":"16","x":"82","y":"40","selected":false},{"id":"17","x":"82","y":"60","selected":false},{"id":"18","x":"82","y":"78","selected":false},{"id":"19","x":"97","y":"2","selected":false},{"id":"20","x":"97","y":"22","selected":false},{"id":"21","x":"97","y":"37","selected":false},{"id":"22","x":"97","y":"63","selected":false},{"id":"23","x":"97","y":"79","selected":false},{"id":"24","x":"97","y":"98","selected":false}])
        //var corners = ref([{"id":"0","x":"3","y":"2","selected":false},{"id":"1","x":"3","y":"20","selected":false},{"id":"2","x":"3","y":"35","selected":false},{"id":"3","x":"3","y":"59","selected":false},{"id":"4","x":"3","y":"74","selected":false},{"id":"5","x":"3","y":"92","selected":false},{"id":"6","x":"18","y":"20","selected":false},{"id":"7","x":"18","y":"38","selected":false},{"id":"8","x":"18","y":"57","selected":false},{"id":"9","x":"18","y":"74","selected":false},{"id":"10","x":"50","y":"2","selected":false},{"id":"11","x":"50","y":"35","selected":false},{"id":"12","x":"50","y":"47","selected":false},{"id":"13","x":"50","y":"59","selected":false},{"id":"14","x":"50","y":"92","selected":false},{"id":"15","x":"82","y":"20","selected":false},{"id":"16","x":"82","y":"38","selected":false},{"id":"17","x":"82","y":"57","selected":false},{"id":"18","x":"82","y":"74","selected":false},{"id":"19","x":"97","y":"2","selected":false},{"id":"20","x":"97","y":"20","selected":false},{"id":"21","x":"97","y":"35","selected":false},{"id":"22","x":"97","y":"59","selected":false},{"id":"23","x":"97","y":"74","selected":false},{"id":"24","x":"97","y":"92","selected":false}])
        
        const selectedPlayer = ref(null)
        //var canvasWidth = ref(0)
        var configKonva = ref([])
        var playerList = ref([])
        var actionLabels = ref(["Schuss", "Abseits", "Torschuss", "Tor", "Ballbesitz", "Ballverlust", "Ballgewinn", "Pass", "Flanke", "Eckstoß", "Assist", "Kopfball", "Freistoß", "Elfmeter", "Einwurf", "Latte", "Pfosten", "Block", "Ballannahme", "Eigentor"])
        var actionLabels_EN = ref(["Attempt", "Offside", "Attempt on Target", "Goal", "Ball Possession", "Losing Possession", "Win the Ball", "Pass (low)", "Pass (high)", "Corner", "Assist", "Header", "Freekick", "Penalty", "Throw-in", "Crossbar", "Post", "Block", "Receive The Ball", "Own Goal"])
        var transformer = ref(null)

        const getTranslated = function(label) {
            console.log("To translate: " + label)
            var gerLabelIndex = actionLabels.value.findIndex(l => l == label)
            console.log("Index: " + gerLabelIndex)
            console.log("Translated: " + actionLabels_EN.value[gerLabelIndex])
            return actionLabels_EN.value[gerLabelIndex]
        }
        
        configKonva.value = {
            width: 0,
            height: 0,
        }

        

        /*watch(frames_data, (newV) => {
            
            if(newV != null) {
                makePlayerList(newV)
            }
            //alert("new: " + newV.length + " old: " + old)
            
        })*/

        watch(corners.value, () => {
            createTransformer()
        })

        onMounted(() => {
            window.addEventListener("resize", resize);
         
        }) 
        const setPlayer = function( val) {
            console.log(val)
            selectedPlayer.value.player_uuid = val.value.uuid
            selectedPlayer.value.number = val.value.shirtnumber
        }

        const setcorners = function() {
            
            corners.value[0].x = 0
            corners.value[0].y = 0
            corners.value[1].x = corners.value[0].x
            corners.value[1].y = (editorPitch.value.pitchWidth - editorPitch.value.sechzehnerWidth) / 2
            corners.value[2].x = corners.value[0].x
            corners.value[2].y = (editorPitch.value.pitchWidth - editorPitch.value.fuenferWidth) / 2
            corners.value[3].x = corners.value[0].x
            corners.value[3].y = corners.value[2].y + editorPitch.value.fuenferWidth
            corners.value[4].x = corners.value[0].x
            corners.value[4].y = corners.value[1].y + editorPitch.value.sechzehnerWidth
            corners.value[5].x = corners.value[0].x
            corners.value[5].y = editorPitch.value.pitchWidth

            corners.value[6].x = editorPitch.value.sechzehnerHeight
            corners.value[6].y = corners.value[1].y
            corners.value[7].x = editorPitch.value.fuenferHeight
            corners.value[7].y = (editorPitch.value.pitchWidth - editorPitch.value.fuenferWidth) / 2
            corners.value[8].x = corners.value[7].x
            corners.value[8].y = corners.value[7].y + editorPitch.value.fuenferWidth
            corners.value[9].x = corners.value[6].x
            corners.value[9].y = corners.value[4].y

            corners.value[10].x =  editorPitch.value.pitchHeight / 2
            corners.value[10].y = corners.value[0].y
            corners.value[11].x = corners.value[10].x
            corners.value[11].y = (editorPitch.value.pitchWidth - 2 * editorPitch.value.circleRadius) / 2
            corners.value[12].x = corners.value[10].x
            corners.value[12].y = corners.value[11].y + editorPitch.value.circleRadius * 2
            corners.value[13].x = corners.value[10].x
            corners.value[13].y = corners.value[5].y

            corners.value[14].x = (editorPitch.value.pitchHeight - editorPitch.value.sechzehnerHeight)
            corners.value[14].y = corners.value[1].y
            corners.value[15].x = editorPitch.value.pitchHeight - editorPitch.value.fuenferHeight
            corners.value[15].y = corners.value[7].y
            corners.value[16].x = corners.value[15].x
            corners.value[16].y = corners.value[8].y
            corners.value[17].x = corners.value[14].x
            corners.value[17].y = corners.value[4].y

            corners.value[18].x = editorPitch.value.pitchHeight
            corners.value[18].y = corners.value[0].y
            corners.value[19].x = editorPitch.value.pitchHeight
            corners.value[19].y = (editorPitch.value.pitchWidth - editorPitch.value.sechzehnerWidth) / 2
            corners.value[20].x = editorPitch.value.pitchHeight
            corners.value[20].y = (editorPitch.value.pitchWidth - editorPitch.value.fuenferWidth) / 2
            corners.value[21].x = editorPitch.value.pitchHeight
            corners.value[21].y = corners.value[2].y + editorPitch.value.fuenferWidth
            corners.value[22].x = editorPitch.value.pitchHeight
            corners.value[22].y = corners.value[1].y + editorPitch.value.sechzehnerWidth
            corners.value[23].x = editorPitch.value.pitchHeight
            corners.value[23].y = editorPitch.value.pitchWidth

            corners.value[24].x = editorPitch.value.pitchHeight / 2
            corners.value[24].y = editorPitch.value.pitchWidth / 2
            //console.table(corners.value)
        }

        const pitchresized = function() {
            resize()
            setcorners()
        }

        var img = require("../../assets/rawPitch.jpg") 
        const imgTmp = new Image();

        imgTmp.onload = function() {
            heightWidthRatio.value = this.height / this.width
        }
        imgTmp.src = img;

        const createTransformer = function() {
            var a = corners.value.filter(c => c.videopoint != null )
            if(a.length == 4) {
                var srcCorners = []
                var dstCorners = []
                a.forEach(c => {
                    //dstCorners.push(Number(c.x * configKonva.value.width / 100).toFixed)
                    //dstCorners.push(Number(c.y * configKonva.value.height / 100).toFixed)
                    dstCorners.push(c.x)
                    dstCorners.push(c.y)
                    srcCorners.push(c.videopoint.x)
                    srcCorners.push(c.videopoint.y)
                })
                transformer.value = PerspT(srcCorners, dstCorners);
                
            }
        }

        const setVideoPoint = function(evt) {

            var dotIndex = corners.value.findIndex(dot => dot.selected == true)
            if(dotIndex >= 0) {
                let newVidPoint = {x: Number(evt.offsetX * 100 / evt.target.offsetWidth).toFixed(0), y: Number(evt.offsetY * 100 / evt.target.offsetHeight).toFixed(0)}
                corners.value[dotIndex].videopoint = newVidPoint
                corners.value[dotIndex].selected = false
            } else {
                createTransformer()
                var srcPt = [Number(evt.offsetX * 100 / evt.target.offsetWidth).toFixed(0), Number(evt.offsetY * 100 / evt.target.offsetHeight).toFixed(0)];
                var t = transformPoint(srcPt)
                var newPl = {x: Number(t[0]).toFixed(0), y: Number(t[1]).toFixed(0), number: 0}
                
                emit('addNewPlayer', newPl)
            }
            
        }
        const clickArrows = function() {
            emit('switchSides')
        }

        const resize = function() {
            //var mainDIV = document.getElementById("positionsettercanvas")
            //canvasWidth.value = mainDIV.offsetWidth
            initContainer()      
            makePlayerList(situationSecondPlayers.value)      
        }

        var showPlayers = computed(() => {
            var playerArr = players.value
            return playerArr.sort((a, b) => parseFloat(a.shirtnumber) - parseFloat(b.shirtnumber));
        })

        var canvasSize = computed(() => {
            var mainDIV = document.getElementById("positionsettercanvas")
            return {width: mainDIV.offsetWidth, height: Number(mainDIV.offsetWidth * heightWidthRatio.value).toFixed(2)}
        })

        var selectedColor = computed(() => {
            return selectedPlayer.value.isInCreatorTeam == true ? "#" + match.value.creatorcolor : match.value.opponentcolor
        })



        const transformPoint = function(point) {
            var srcPt = transformer.value.transform(point[0], point[1]);            
            return srcPt
        }

        const initContainer = function() {      
            //var konvaContainer = document.getElementById("positionsettercanvas")
            
            mainDivRectangle.value = konvaContainer.value.getBoundingClientRect()      
            console.log(JSON.stringify(canvasSize.value))
            configKonva.value.width = mainDivRectangle.value.width
            configKonva.value.height = mainDivRectangle.value.width * editorPitch.value.getWidthHeightProportion()
            konvaContainer.value.style.height = configKonva.value.height + "px"
        }

        /*const getAllSeconds = function() {
            let allSeconds = []
            frames_data.value.forEach(item => {
                allSeconds.push(item.second)
            })
            allSeconds = [...new Set(allSeconds)];
            return allSeconds
        }

        const getclosest = function(nr, arr) {
            var closest = arr.reduce(function(prev, curr) {
                return (Math.abs(curr - nr) < Math.abs(prev - nr) ? curr : prev);
            });


        }*/

        var situationSecondPlayers = computed(() => {
            return editorStore.getters.getSituationPlayersList
        })

        watch(situationSecondPlayers, (newV) => {
            
            if(newV != null) {
                makePlayerList(newV)
            }
            //alert("new: " + newV.length + " old: " + old)
            
        })

        /*var playerList = computed(() => {
            var mainDIV = document.getElementById("positionsettercanvas")
            if(mainDIV == null) { return [] }
            var mainDivRectangle = mainDIV.getBoundingClientRect()
            var result = []
            if(situationSecondPlayers.value != null) {
                let currentFrameObject = null
                for(var i = 0; i < situationSecondPlayers.value.length; i++) {
                    currentFrameObject = situationSecondPlayers.value[i]
                    var curColor = currentFrameObject.isInCreatorTeam == true ? match.value.creatorcolor : match.value.opponentcolor
                    result.push({
                        id: String(currentFrameObject.player_uuid),
                        x: currentFrameObject.x * mainDivRectangle.width / 100,
                        y: currentFrameObject.y * mainDivRectangle.height / 100,
                        radius: 8,
                        fill: "#" + curColor
                    });
                }
                
            }
            return result
        })*/

        const makePlayerList = function(players) {
            //alert(frames_data.value.length)
            var mainDIV = document.getElementById("positionsettercanvas")
            var mainDivRectangle = mainDIV.getBoundingClientRect()
            playerList.value = []
            players = players.filter(p => p != null)
            if(players != null) {
                let currentFrameObject = null
                for(var i = 0; i < players.length; i++) {
                    currentFrameObject = players[i]
                    var curColor = currentFrameObject.isInCreatorTeam == true ? match.value.creatorcolor : match.value.opponentcolor
                    playerList.value.push({
                        id: String(currentFrameObject.player_uuid),
                        x: currentFrameObject.x * mainDivRectangle.width / 100,
                        y: currentFrameObject.y == null ? 0 : currentFrameObject.y * mainDivRectangle.height / 100,
                        radius: currentFrameObject.action_labels != null && currentFrameObject.action_labels.includes("Eckstoß") ? 20 : 8,
                        fill: "#" + curColor
                    });
                }
                
            }
            console.log(playerList.value)
        }

        const addCorner = function(uuid) {
            var dotIndex = frames_data.value.findIndex(dot => dot.player_uuid == uuid)
            selectedPlayer.value = frames_data.value[dotIndex]
            addCornerVisible.value = true
        }



        const clickPlayer = function (event) {
            displayBasic.value = true

            var dotIndex = frames_data.value.findIndex(dot => dot.player_uuid == event.target.attrs.id)
            selectedPlayer.value = frames_data.value[dotIndex]
            console.log(frames_data.value[dotIndex])
        }

        var dragItemId = null

        const handleCornerDragend = function(e) {
            var dotIndex = corners.value.findIndex(dot => dot.id == e.target.attrs.id)
            corners.value[dotIndex].x = Number(e.target.attrs.x * 100 / configKonva.value.width).toFixed(0)
            corners.value[dotIndex].y = Number(e.target.attrs.y * 100 / configKonva.value.height).toFixed(0)
            console.log(JSON.stringify(corners.value))
        }
        
        const handleDragend = function(e) {
            var dotIndex = frames_data.value.findIndex(dot => dot.player_uuid == e.target.attrs.id)
            var curPlayer = frames_data.value[dotIndex]
            curPlayer.x = Number(e.target.attrs.x * 100 / configKonva.value.width).toFixed(2)
            curPlayer.y = Number(e.target.attrs.y * 100 / configKonva.value.height).toFixed(2)
            emit('playerchanged', dotIndex, curPlayer)
        }

        const clickCorner = function(event) {
            
            var dotIndex = corners.value.findIndex(dot => dot.id == event.target.attrs.id)
            if(corners.value[dotIndex].selected) {
                corners.value[dotIndex].selected = false
            } else {
                corners.value.forEach(dot => dot.selected = false)
                corners.value[dotIndex].selected = true
            }
            
        }

        /*const switchColor = function() {
            var dotIndex = frames_data.value.findIndex(dot => dot.player_uuid == selectedPlayer.value.player_uuid)
            selectedPlayer.value.isInCreatorTeam = !selectedPlayer.value.isInCreatorTeam
            
            emit('playerchanged', dotIndex, selectedPlayer.value)
        }*/

        const changePlayer = function() {
            var dotIndex = frames_data.value.findIndex(dot => dot.player_uuid == selectedPlayer.value.player_uuid)
            emit('playerchanged', dotIndex, selectedPlayer.value)
            displayBasic.value = false
            addCornerVisible.value = false
        }

        const deletePlayer = function() {
            var dotIndex = frames_data.value.findIndex(dot => dot.player_uuid == selectedPlayer.value.player_uuid)
            //alert(dotIndex)
            emit('deletePlayer', dotIndex)
            displayBasic.value = false
            addCornerVisible.value = false
        }

        


        return {
            handleDragend,
            configKonva,
            dragItemId,
            playerList,
            resize,
            //makePlayerList,
            clickPlayer,
            clickCorner,
            displayBasic,
            addCornerVisible,
            addCorner,
            selectedColor,
            selectedPlayer,
            changePlayer,
            actionLabels,
            deletePlayer,
            corners,
            setVideoPoint,
            clickArrows,
            handleCornerDragend,
            canvasSize,
            mainDivRectangle,
            pitchresized,
            editorPitch,
            setcorners,
            setPlayer,
            showPlayers,
            konvaContainer,
            situationSecondPlayers,
            getTranslated
           // creatorTeamPlayers
        }


  }









  
    
    });


</script>