<template>
    <!--<OverlayPanel ref="opanel">
        <h5>{{panelTitle}}</h5>
        <div v-html="panelText"></div>
    </OverlayPanel>-->
<div ref="overlayContainer"> </div>
<div class="konvaContainer" ref="konvaContainer" id="shotsdashboardcanvas" style="display: flex; flex-direction: column;"> 
    
    <div ref="shotDetails" v-if="panelText != ''" style="position: relative; z-index: 100; left: 1%; margin-top: auto;" >
        <div v-html="panelText" style="backdrop-filter: blur(3px); color: white; font-size: smaller; width: fit-content;"></div>
    </div>

    <Pitch ref="shotHeatmapPitch" :pitchColor="'#059EC4'" :showSize="showSize" @pitchresized="pitchresized" :myPitchHeight="mainDivRectangle == null ? 0 : mainDivRectangle.width" :horizontal="false"></Pitch>
    <v-stage v-bind:style="shotHeatmapPitch != null ? 'margin-top: ' + shotHeatmapPitch.goalHeight + 'px; position: absolute;' : ''" 
        ref="stage"
        @mousedown="clickStage($event)"
        @mousemove="mousemove"
        :config="configKonva">

        <v-layer ref="layer">
            
                <v-circle 
                    v-for="item in relevantList"
                    :key="item.id"
                    @mousedown="clickPlayer()"
                    @mouseover="hoverPlayer($event, item.player_uuid, item.situation_uuid, item.situation_position_uuid, item.second, item.index)"
                    @mouseout="unhover"
                    @mouseleave="unhover"
                    :config="{
                        x: item.x,
                        y: item.y,
                        id: item.id,
                        radius: item.radius,
                        fill: item.fill,
                        opacity: 0.8,
                    }"
                ></v-circle>

                <v-arrow v-if="arrowPoints != null"
                    @mouseout="unhover"
                    @mouseleave="unhover"
                    :config="{
                        points: arrowPoints,
                        fill: 'black',
                        strokeWidth: 4,
                        pointerLength: 4,
                        pointerWidth: 4,
                        stroke: 'black',
                    }"
                ></v-arrow>

                <v-arrow 
                    v-for="(item, index) in furtherArrows"
                    :key=index
                    :config="{
                        points: item,
                        fill: 'black',
                        strokeWidth: 4,
                        pointerLength: 4,
                        pointerWidth: 4,
                        stroke: 'black',
                    }"
                ></v-arrow>

                <v-circle v-if="helperPoint != null"
                    :config="{
                        x: helperPoint.x,
                        y: helperPoint.y,
                        id: 'helperPoint',
                        radius: 4,
                        fill: 'black',
                        opacity: 0.8,
                    }"
                ></v-circle>

                <v-circle v-for="(item, index) in furtherDots"
                    :key=index
                    :config="{
                        x: item.x,
                        y: item.y,
                        radius: 4,
                        fill: 'black',
                        opacity: 0.8,
                    }"
                ></v-circle>
               
      </v-layer>
    </v-stage>

    </div>
</template>


<style>
#shotsdashboardcanvas .konvajs-content {
    position: absolute !important;
    top: 0px;
    width: 100% !important;
}
</style>
<script>
import { ref, onMounted, computed, toRefs } from 'vue'
import { defineComponent } from "vue";
import Pitch from '../Basics/Pitch'
import { useStore } from 'vuex'
import { analytics } from '@/services';
import { inject } from 'vue'


export default defineComponent({
  props:['creator_team', 'showAction', 'selectedPlayer'],
  components: {Pitch},
  setup(props) {
        const store = useStore()
        const displayBasic = ref(false);
        var { creator_team } = toRefs(props)
        var { showAction } = toRefs(props)
        var { selectedPlayer } = toRefs(props)
        var configKonva = ref([])
        var actionLabels = ref(["Schuss", "Torschuss", "Tor", "Ballbesitz", "Ballverlust", "Ballgewinn", "Pass", "Flanke", "Assist", "Kopfball", "Freistoß", "Elfmeter", "Einwurf"])
        var creatorImage = ref()
        var opponentImage = ref()
        var mainDivRectangle = ref(null)
        const shotHeatmapPitch = ref(null)
        var showSize = ref(0)
        const konvaContainer = ref(null)
        const shotDetails = ref(null)
        const overlayContainer = ref(null)
        const opanel = ref(null)
        const panelTitle = ref("panelTitle")
        const panelText = ref("")
        const hoveredIndex = ref(null)
        const playerSelected = ref(false)
        const helperPoint = ref(null)

        const arrowPoints = ref(null)
        const furtherDots = ref([])
        const shotDetailsPosition = ref(null)
        const emitter = inject('emitter');   // Inject `emitter`
        //console.log(emitter)
        emitter.on('resize', () => {   // param "value" optional
            //console.log('myevent received!', `value: ${value}`);
            initContainer()
            setTimeout(function(){
               initContainer()
                
            }.bind(this), 200);
            //https://stackoverflow.com/questions/66537320/vue-3-event-bus-with-composition-api
        });

        configKonva.value = {
            width: 0,
            height: 0,
        }

        
        
        onMounted(() => {
            window.addEventListener("resize", initContainer);
            initContainer()

        }) 

        /*const windowResizeHandler = function() {
            console.log("resized")
        }*/

        const pitchresized = function() {
            showSize.value = Number(shotHeatmapPitch.value.pitchHeight * 0.51).toFixed(0)
            konvaContainer.value.style.height = showSize.value + 'px'
        }

        var selectedColor = computed(() => {
            return selectedPlayer.value.isInCreatorTeam == true ? "#" + matchList.value.creatorcolor : matchList.value.opponentcolor
        })

        var situations = computed(() => {
            return store.getters.getAllSituations 
        })

        var matchList = computed(() => {
            return store.getters.getMatchList
        })

        var myteam = computed(() => {
            return store.getters.getTeam
        })


        const initContainer = function() {

            if(konvaContainer.value != null) {

                mainDivRectangle.value = konvaContainer.value.parentElement.getBoundingClientRect()
                configKonva.value.width = mainDivRectangle.value.width
                configKonva.value.height = mainDivRectangle.value.width * shotHeatmapPitch.value.getWidthHeightProportion()
                konvaContainer.value.style.width = mainDivRectangle.value.width + 'px'
            }
        }
        
        const clickPlayer = function() {
            //playerSelected.value = true
            console.log("clickplayer")
        }

        var furtherArrows = computed(() => {
            if(furtherDots.value.length == 0) {return []}
            var arr = []
            if(furtherDots.value.length != 1) {
                for(var i = 1; i < furtherDots.value.length - 1; i++) {
                    arr.push([furtherDots.value[i-1].x, furtherDots.value[i-1].y, furtherDots.value[i].x, furtherDots.value[i].y])
                }
            }
            arr.push([furtherDots.value[furtherDots.value.length - 1].x, furtherDots.value[furtherDots.value.length - 1].y, arrowPoints.value[0], arrowPoints.value[1]])
            return arr
        })

        const hoverPlayer = function(e, player_uuid, situation_uuid, situation_position_uuid, second, itemindex) {

            hoveredIndex.value = itemindex
            var sit = convertedSituations.value.find(c => situation_uuid == c.uuid)
            var sitpo = sit.situationPositions.find(s => s.uuid == situation_position_uuid && s.player_uuid == player_uuid && s.second == second)
            //console.log("sit: " + sit.uuid)
            //console.log("Schuss: " + sitpo.second)
            let rows = []
            let table = document.createElement("table");
            let tbody = document.createElement("tbody");
            var keyPlayer = myteam.value.players.find(p => p.uuid == sitpo.player_uuid)
            var assistPlayer = null

            if(sitpo.isInCreatorTeam == true) {
                panelTitle.value = sitpo.action_labels.includes("Torschuss") ? "Torschuss" : (sitpo.action_labels.includes("Tor") ? "Tor" : "Schuss")
                
                rows = [
                    {title: "Spieler", value: keyPlayer == undefined ? (sitpo.number != 0 ? "Nummer " + sitpo.number : "unbekannt") : keyPlayer.firstname + " " + keyPlayer.lastname },
                    {title: "xG-Lite", value: sitpo.xG.toFixed(2)},
                    {title: "Distanz", value: "ca. " + analytics.getGoalDistance(sitpo.y, sitpo.x) + " m"},
                    {title: "Schuss-Typ", value: sitpo.action_labels.includes("Kopfball") ? "Kopfball": "Schuss"},
                ]
                if(analytics.getSituationLabels(sit).includes("Assist")) {
                    var curLabel =  "Assist" //analytics.getSituationLabels(sit).includes("Assist") ? "Assist" : "Angekommen"
                    var assistSitpo = sit.situationPositions.find(s => s.action_labels.includes(curLabel) == true)
                   // console.log("Assist: " + sitpo.second)
                    assistPlayer = myteam.value.players.find(p => p.uuid == assistSitpo.player_uuid)
                    rows = rows.concat({title: "Vorlage: ", value: assistPlayer == undefined ? (assistSitpo.number != 0 ? "Nummer " + assistSitpo.number : "unbekannt") : assistPlayer.firstname + " " + assistPlayer.lastname })
                    /*rows = rows.concat({title: "Vorlage Distanz", value: "ca. " + analytics.getPlayerDistance(assistSitpo.y, assistSitpo.x, sitpo.y, sitpo.x) + " m"})*/
                    arrowPoints.value = [assistSitpo.x * shotHeatmapPitch.value.pitchWidth / 100, assistSitpo.y * shotHeatmapPitch.value.pitchHeight / 100, sitpo.x * shotHeatmapPitch.value.pitchWidth / 100, sitpo.y * shotHeatmapPitch.value.pitchHeight / 100]
                    helperPoint.value = {x: assistSitpo.x * shotHeatmapPitch.value.pitchWidth / 100, y: assistSitpo.y * shotHeatmapPitch.value.pitchHeight / 100}
                    if(assistPlayer != null && assistPlayer.action_labels != null && !assistPlayer.action_labels.includes("Ballgewinn")) {
                        var furtherPasses = sit.situationPositions.filter(s => s.isInCreatorTeam && (s.action_labels.includes("Pass") || s.action_labels.includes("Flanke")) && s.second < assistSitpo.second)
                        if(furtherPasses.length > 0) {
                            furtherDots.value = []
                            furtherPasses.forEach(p => {
                                //console.log("further: " + p.second)
                                furtherDots.value = furtherDots.value.concat({x: p.x * shotHeatmapPitch.value.pitchWidth / 100, y: p.y * shotHeatmapPitch.value.pitchHeight / 100})
                            })
                        }

                    }
                    
                }
                
            }


            rows.forEach(r => {
                    var tableRow = document.createElement("tr")
                    var td_title = document.createElement("th");
                    var td_value = document.createElement("td");
                    td_title.innerHTML = r.title
                    td_title.setAttribute("style", "text-align: left");
                    td_value.innerHTML = r.value
                    td_value.setAttribute("style", "text-align: right");
                    tableRow.append(td_title)
                    tableRow.append(td_value)
                    tbody.append(tableRow);
                })
                table.append(tbody);
                panelText.value = table.outerHTML
                
                if(rows.length > 0) {
                    //konvaContainer.value.style.height
                    panelText.value = table.outerHTML
                    if(shotDetails.value != null) {
                        //var relation = shotDetails.value.getBoundingClientRect().height / konvaContainer.value.getBoundingClientRect().height 
                        shotDetailsPosition.value = konvaContainer.value.getBoundingClientRect().height  - (shotDetails.value.getBoundingClientRect().height * 1.05) //relation * 100 + 5
                        //console.log(relation)
                    }
                }
                
        }


        /*const toggle = function(event) {
            opanel.value.show(event, konvaContainer.value);
        }*/

        const clickStage = function(event) {
            console.log(event.target)
        }

        const unhover = function() {
            if(playerSelected.value == false) {
                //opanel.value.hide(event)
                hoveredIndex.value = null
                arrowPoints.value = null
            }
            helperPoint.value = null
            furtherDots.value = []
            panelText.value = ""
        }

        const getTargetClassName = function(target) {
            var blob = JSON.stringify(target)
            let parsed = JSON.parse(blob);
            if (typeof parsed === 'string') {
                parsed = JSON.parse(parsed);
            }
            return parsed.className;
        }

        const mousemove = function(e) {
            var targetClassName = getTargetClassName(e.target)
            if(targetClassName !== undefined && targetClassName != "Circle" && targetClassName != "Arrow") {
                unhover()
            }
        }
        

        var relevantList = computed(() => {
            var schuesse = ["Schuss", "Torschuss", "Tor"]
            if(showAction.value != null) {
                if(schuesse.includes(showAction.value)) {
                    return shotsList.value
                } else {
                    return passList.value
                }
            }
            return []
        })

        var convertedSituations = computed(() => {
            var newConvertedSituations = JSON.parse(JSON.stringify(situations.value))
            if(situations.value) {
                for( var i = 0; i < situations.value.length; i++) {
                    var sitpos = newConvertedSituations[i].situationPositions
                    for(var j = 0; j < sitpos.length; j++) {
                        
                        if(sitpos[j].action_labels != null) {
                            var curX = newConvertedSituations[i].direction == "CREATOR_LEFT_TO_RIGHT" ? 100 - newConvertedSituations[i].situationPositions[j].y : newConvertedSituations[i].situationPositions[j].y
                            var curY = newConvertedSituations[i].direction == "CREATOR_LEFT_TO_RIGHT" ? newConvertedSituations[i].situationPositions[j].x : 100 - newConvertedSituations[i].situationPositions[j].x
                            curX = newConvertedSituations[i].situationPositions[j].isInCreatorTeam == true ? 100 - curX : curX
                            curY = newConvertedSituations[i].situationPositions[j].isInCreatorTeam == true ? 100 - curY : curY
                            newConvertedSituations[i].situationPositions[j].x = curX
                            newConvertedSituations[i].situationPositions[j].y = curY
                        }
                    }
                }
            }

           return newConvertedSituations
        })

        var shotsList = computed(() => {
            var tmp = []
            let newItem = null
            var itemIndex = 0
            if(convertedSituations.value != null && mainDivRectangle.value != null) {
                for( var i = 0; i < convertedSituations.value.length; i++) {
                    for(var j = 0; j < convertedSituations.value[i].situationPositions.length; j++) {
                        if(selectedPlayer.value == null || convertedSituations.value[i].situationPositions[j].player_uuid == selectedPlayer.value.uuid){
                            if(convertedSituations.value[i].situationPositions[j].action_labels != null && convertedSituations.value[i].situationPositions[j].action_labels.includes("Schuss") && convertedSituations.value[i].situationPositions[j].isInCreatorTeam == creator_team.value) {
                            var curColor = convertedSituations.value[i].situationPositions[j].action_labels.includes("Torschuss") ? '#0b5394' : '#cc0000'
                            curColor = convertedSituations.value[i].situationPositions[j].action_labels.includes("Tor") ? '#38761d' : curColor
                            newItem = {index: itemIndex, id: String((Math.random() + 1).toString(15).substring(7)), situation_position_uuid: convertedSituations.value[i].situationPositions[j].uuid, situation_uuid: convertedSituations.value[i].uuid, player_uuid: String(convertedSituations.value[i].situationPositions[j].player_uuid), x: convertedSituations.value[i].situationPositions[j].x * shotHeatmapPitch.value.pitchWidth / 100, y: convertedSituations.value[i].situationPositions[j].y * shotHeatmapPitch.value.pitchHeight / 100, radius: itemIndex == hoveredIndex.value ? 7 : 5, fill: curColor, second: convertedSituations.value[i].situationPositions[j].second}
                            if(convertedSituations.value[i].situationPositions[j].action_labels.includes(showAction.value)) {
                                tmp.push(newItem);
                                itemIndex = itemIndex + 1
                            }
                        }
                        }
                        
                    }
                }
            }
            return tmp
        })

        var passList  = computed(() => {
            var tmp = []
            let newItem = null
            var itemIndex = 0
            if(convertedSituations.value != null && mainDivRectangle.value != null) {
                for( var i = 0; i < convertedSituations.value.length; i++) {
                    
                    for(var j = 0; j < convertedSituations.value[i].situationPositions.length; j++) {
                        
                        if(convertedSituations.value[i].situationPositions[j].action_labels != null && convertedSituations.value[i].situationPositions[j].isInCreatorTeam == creator_team.value && (convertedSituations.value[i].situationPositions[j].action_labels.includes("Pass") || convertedSituations.value[i].situationPositions[j].action_labels.includes("Flanke") || convertedSituations.value[i].situationPositions[j].action_labels.includes("Assist"))) {
                            var curColor = convertedSituations.value[i].situationPositions[j].action_labels.includes("Angekommen") ? '#0b5394' : '#cc0000'
                            curColor = convertedSituations.value[i].situationPositions[j].action_labels.includes("Assist") ? '#38761d' : curColor
                            newItem = {index: itemIndex, id: String((Math.random() + 1).toString(15).substring(7)), situation_position_uuid: convertedSituations.value[i].situationPositions[j].uuid, situation_uuid: convertedSituations.value[i].uuid, player_uuid: String(convertedSituations.value[i].situationPositions[j].player_uuid), x: convertedSituations.value[i].situationPositions[j].x * shotHeatmapPitch.value.pitchWidth / 100, y: convertedSituations.value[i].situationPositions[j].y * shotHeatmapPitch.value.pitchHeight / 100, radius: itemIndex == hoveredIndex.value ? 7 : 5, fill: curColor, second: convertedSituations.value[i].situationPositions[j].second}
                            if(showAction.value == "Zuspiel" || (showAction.value == "Angekommen" && convertedSituations.value[i].situationPositions[j].action_labels.includes("Angekommen")) || (showAction.value == "Assist" && convertedSituations.value[i].situationPositions[j].action_labels.includes("Assist")) ) {
                                tmp.push(newItem);
                                itemIndex = itemIndex + 1
                            }
                        }
                    }
                }
            }
            return tmp
        })


        return {
            configKonva,
            relevantList,
            passList,
            displayBasic,
            selectedColor,
            actionLabels,
            creatorImage,
            opponentImage,
            mainDivRectangle,
            shotHeatmapPitch,
            pitchresized,
            showSize,
            matchList,
            initContainer,
            clickPlayer,
            hoverPlayer,
            //toggle,
            opanel,
            unhover,
            mousemove,
            konvaContainer,
            overlayContainer,
            arrowPoints,
            panelTitle,
            panelText,
            clickStage,
            helperPoint,
            furtherDots,
            furtherArrows,
            shotDetailsPosition,
            shotDetails
        }


  }

    
});


</script>