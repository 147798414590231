import { createRouter, createWebHashHistory } from 'vue-router';
import Dashboard from '@/components/Dashboard/Dashboard';
import Home from '@/components/Home'
import DataPrivacy from '@/components/DataPrivacy'
import Impressum from '@/components/Impressum'
import MatchList from '@/components/MatchList'
import Signup from '@/components/Signup/Signup'
import ResetPassword from '@/components/Signup/ResetPassword'
import Match from '@/components/MatchDetails/Match'
import AGBs from '@/components/Legal/AGBs'
import AdminDashboard from '@/components/Admin/AdminDashboard'
import Clubs from '@/components/Admin/Clubs'
import AdminUsers from '@/components/Admin/AdminUsers'
import AdminVideos from '@/components/Admin/AdminVideos'
import EditorAssigner from '@/components/Admin/EditorAssigner'
import CreatorTeamAssigner from '@/components/Admin/CreatorTeamAssigner'
import Uploads from '@/components/Admin/Uploads'
import Editor from '@/components/Editor/Editor'
import Success from '@/components/Confirmation/Success'
import Fail from '@/components/Confirmation/Fail'
import Feedback from '@/components/Feedback/Feedback'
import Team from '@/components/Team/Team'

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth'; 

const routes = [
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
          requiresAuth: true
        }
    },
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/login',
      name: 'Signup',
      component: Signup,
      /*meta: {
        requiresAuth: false
      }*/
    },
    {
      path: '/privacy',
      name: 'DataPrivacy',
      component: DataPrivacy,
    },
    {
      path: '/impressum',
      name: 'Impressum',
      component: Impressum,
    },
    {
      path: '/agbs',
      name: 'AGBs',
      component: AGBs,
    },
    {
      path: '/team',
      name: 'Team',
      component: Team,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/begegnungen',
      name: 'MatchList',
      component: MatchList,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/match/:match_uuid',
      name: 'Match',
      component: Match,
      meta: {
        requiresAuth: true,
        authorizedRoles: []
      }
    },
    {
      path: '/admindashboard',
      name: 'AdminDashboard',
      component: AdminDashboard,
      meta: {
        requiresAuth: true,
        authorizedRoles: ["admin"]
      }
    },
    {
      path: '/adminuploads',
      name: 'Uploads',
      component: Uploads,
      meta: {
        requiresAuth: true,
        authorizedRoles: ["admin"]
      }
    },
    {
      path: '/allclubs',
      name: 'Clubs',
      component: Clubs,
      meta: {
        requiresAuth: true,
        authorizedRoles: ["admin"]
      }
    },
    {
      path: '/editorassigner',
      name: 'EditorAssigner',
      component: EditorAssigner,
      meta: {
        requiresAuth: true,
        authorizedRoles: ["admin"]
      }
    },
    {
      path: '/creatorteamassigner',
      name: 'CreatorTeamAssigner',
      component: CreatorTeamAssigner,
      meta: {
        requiresAuth: true,
        authorizedRoles: ["admin"]
      }
    },
    {
      path: '/allusers',
      name: 'AdminUsers',
      component: AdminUsers,
      meta: {
        requiresAuth: true,
        authorizedRoles: ["admin"]
      }
    },
    {
      path: '/editor/:match_uuid',
      name: 'Editor',
      component: Editor,
      meta: {
        requiresAuth: true,
        authorizedRoles: ["admin"]
      }
    },
    {
      path: '/adminvideos',
      name: 'AdminVideos',
      component: AdminVideos,
      meta: {
        requiresAuth: true,
        authorizedRoles: ["admin", "editor"]
      }
    },
    {
      // path: "*",
      path: "/:catchAll(.*)",
      name: "NotFound",
      component: Home,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/registration-successful',
      name: 'Success',
      component: Success,
      /*meta: {
        requiresAuth: false
      }*/
    },
    {
      path: '/registration-failed',
      name: 'Fail',
      component: Fail,
      /*meta: {
        requiresAuth: false
      }*/
    },
    {
      path: '/resetpassword/:token',
      name: 'ResetPassword',
      component: ResetPassword,
      meta: {
        authRequired: false,
      }
    },
    {
      path: '/feedback',
      name: 'Feedback',
      component: Feedback,
      meta: {
        requiresAuth: true
      }
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});


router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth); //to.meta.requiresAuth //
  var user = await firebase.getCurrentUser()
  if (requiresAuth && (!user || !user.emailVerified)){
    next('/login');
  }else{

    next();
  }
});

export default router;
