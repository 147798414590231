<template>
<div>


<ul class="list-group">

    <li v-for="(sit, index) in sortedSituations.slice().reverse()" v-bind:key="index" v-bind:style=" sit.video != null ? 'background-color: #b5ffb5;' : '' " class="list-group-item" style="cursor: pointer;" @click="situationClick(sit)">
        <div>
        <i v-bind:class="{

            'fas fa-futbol': sit.label=='Tor',
            'fas fa-arrow-up': sit.label=='Schuss',
            'fas fa-arrows-alt-h': sit.label=='Konter',
            'fas fa-exclamation': sit.label=='Freistoß',
            'fas fa-exclamation': sit.label=='Elfmeter',
            'fas fa-exclamation': sit.label=='Eckstoß',
            'fas fa-exclamation': sit.label=='Interessante Situation',

            'text-danger': sit.isCreatorSituation==false,
            'text-success': sit.isCreatorSituation==true,
            
        }" class="mr-2"></i>
        <i v-bind:class="{

            'fas pi-flag-fill': sit.custom_marker != null
            
        }" class="mr-2 text-danger"></i>
        <span>{{sit.label [0]}} {{Number(getMinute(sit)) + Number(1)}}'</span>
        <span> &nbsp; {{sit.uuid}}</span>
        </div>
    </li>

</ul>

</div>
</template>

<style scoped lang="scss">
@import '~bootstrap/scss/bootstrap.scss';
</style>

<script>
import { kick17 } from '@/services';
export default {
    components: {
        
    },
    props: ['match', 'onlyVideosNeeded'],
    data() {
        return {

        }
    },
    mounted() {

    },
    async created () {
    },
    computed: {
        sortedSituations: function() {
            var situations = this.onlyVideosNeeded ==  null || this.onlyVideosNeeded == false ? this.match.situations : this.match.situations.filter(s => s.videoNeeded == true)
            situations = situations.sort(function(a, b){
                return a.situation_start - b.situation_start;
            });
            return situations
        }
    },
    methods: {
        
        getMinute(situation) {
            return kick17.getMinuteOfSituation(situation, this.match)
            /*var matchBegin = new Date(this.match.match_date)
            if(situation.custom_marker == null) {
                return (situation.situation_start/(60)).toFixed(0)
                
            } else {

                var diff = situation.custom_marker - matchBegin.getTime()
                return (diff/(1000*60)).toFixed(0)
            }*/
            
            
            
        },
        situationClick(situation) {
            this.$emit('situationClick', situation)
        }
    }
}

</script>