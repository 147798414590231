<template>
<div>

    <Dialog :modal="true" header="Neuer Spieler" v-model:visible="addplayerVisible" >
        <div class="container">
            <div class="row justify-content-center">
                <div class="form-signin form-inline">
                    <div class="form-label-group py-2" >
                        <span class="p-float-label">
                            <InputText id="firstname" class="w-100" type="text" v-model="newPlayer.firstname" />
                            <label for="firstname">Vorname</label>
                        </span>
                    </div>
                    <div class="form-label-group py-2" >
                        <span class="p-float-label w-100">
                            <InputText id="lastname" class="w-100" v-model="newPlayer.lastname"  inputStyle="width: 100%;"></InputText>
                            <label for="lastname">Nachname</label>
                        </span>
                    </div>
                    <div class="form-label-group py-2" >
                        <span class="p-float-label w-100">
                            <InputNumber id="shirtnumber" class="w-100" v-model="newPlayer.shirtnumber"  inputStyle="width: 100%;"></InputNumber>
                            <label for="shirtnumber">Nummer</label>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Abbrechen" icon="pi pi-times" @click="addplayerVisible = !addplayerVisible" class="p-button-text"/>
            <Button label="Speichern" icon="pi pi-check" @click="saveNewPlayer()" autofocus />
        </template>
    </Dialog>

    <Dialog :modal="true" header="Spieler bearbeiten" v-model:visible="editplayerVisible" >
        <div class="container">
            <div class="row justify-content-center">
                <div class="form-signin form-inline">
                    <div class="form-label-group py-2" >
                        <span class="p-float-label">
                            <InputText id="firstname" class="w-100" type="text" v-model="selectedPlayer.firstname" />
                            <label for="firstname">Vorname</label>
                        </span>
                    </div>
                    <div class="form-label-group py-2" >
                        <span class="p-float-label w-100">
                            <InputText id="lastname" class="w-100" v-model="selectedPlayer.lastname"  inputStyle="width: 100%;"></InputText>
                            <label for="lastname">Nachname</label>
                        </span>
                    </div>
                    <div class="form-label-group py-2" >
                        <span class="p-float-label w-100">
                            <InputNumber id="shirtnumber" class="w-100" v-model="selectedPlayer.shirtnumber"  inputStyle="width: 100%;"></InputNumber>
                            <label for="shirtnumber">Nummer</label>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="Abbrechen" icon="pi pi-times" @click="editplayerVisible = !editplayerVisible" class="p-button-text"/>
            <Button label="Speichern" icon="pi pi-check" @click="saveEditedPlayer()" autofocus />
        </template>
    </Dialog>

    <div class="layout-main-container ">
        <div class="layout-main m-auto md:col-7" style="padding-left: 2rem; padding-right: 2rem; padding-top: 2rem;">


 <div class="row">
        <div class="p-5" v-if="myteam != null && myteam.players != null && myteam.players.length > 0">
          <!--  Bootstrap table-->
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Vorname</th>
                  <th scope="col">Nachname</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(player, index) in myteam.players" v-bind:key="index" >
                 
                  <td>{{player.shirtnumber}}</td>
                  <td>{{player.firstname}}</td>
                  <td>{{player.lastname}}</td>
                  <td><Button icon="pi pi-pencil" class="p-button-rounded p-button-secondary p-button-outlined" @click="editPlayer(player)"/></td>
                </tr>
              </tbody>
            </table>
          </div>

        <!-- Add rows button-->
        
      </div>
    </div>  

    

 <div class="row justify-content-center">
    <Button label="Spieler Hinzufügen" class="p-button-outlined p-button-secondary" @click="addplayer()"/>
</div> 



        </div>
    </div>
 
</div>
</template>

<style scoped lang="scss">
@import '~bootstrap/scss/bootstrap.scss';



</style>

<script>
import { kick17 } from '@/services';

export default {
  name: 'app',
    components: {
      
    },
    data() {
      return {
        loaded: false,
        myteam: null,
        addplayerVisible: false,
        editplayerVisible: false,
        newPlayer: null,
        selectedPlayer: null,
        loading: false
      }
    },
  async created () {
      var res = await kick17.getMyTeam()
      this.myteam = res.data
  },
  methods: {

    addplayer() {
        this.newPlayer = {firstname: "", lastname: "", shirtnumber: "", position: "", direction: "", isStammspieler: false}
        this.addplayerVisible = true
    },
    async saveNewPlayer() {
        this.loading = true
        await kick17.addPlayer(this.newPlayer)
        this.loading = false
        this.myteam.players.push(this.newPlayer)
        this.newPlayer = null
        this.addplayerVisible = false
        
    },
    editPlayer(player) {
        this.selectedPlayer = player
        this.editplayerVisible = true
    },
    async saveEditedPlayer() {
        this.loading = true
        await kick17.savePlayer(this.selectedPlayer)
        this.loading = false
        this.selectedPlayer = null
        this.editplayerVisible = false
    }
  }
}
</script>


