<template>
<div>
    <div class="container">
        <div class="row justify-content-center py-5">
          <div v-if="!passwortResetSuccessfulFormVisible" style="width: 100%;">
            <h5 class="card-title text-center">Passwort Zurücksetzen</h5>
                <div class="form-signin">

                

                    <div class="form-label-group" >
                  <span class="p-float-label">
                    <Password id="password" class="w-100" v-model="registerform.password" toggleMask :feedback="false" inputStyle="width: 100%;"></Password>
                    <label for="password">Passwort</label>
                  </span>
                  <InlineMessage severity="warn" v-if='passwordValidation.errors.length > 0 && !submitted'>
                    <div class="card-title" style="font-weight: bolder; font-size: x-small;">Passwortregeln:</div>
                    <ul>
                      <li style="font-size: x-small;" v-for='(error, index) in passwordValidation.errors' v-bind:key="index">{{error}}</li>  
                    </ul>
                  </InlineMessage>
                </div>

                <div class="form-label-group" >
                  <span class="p-float-label">
                    <Password id="password2" class="w-100" v-model="registerform.password_2" toggleMask :feedback="false" inputStyle="width: 100%;"></Password>
                    <label for="password2">Passwort Wiederholen</label>
                  </span>
                </div>

                
                <div class="matches" style="color: red;" v-if='notSamePasswords'>
                  <p>Die Passwörter müssen übereinstimmen!</p>
                </div>

               

                  
                  <Button :disabled="!samePasswords || this.passwordValidation.valid == false" label="Passwort Zurücksetzen" class="p-button-raised w-100 mb-3" @click="updatepassword()" :loading="loading"/>
                </div> 
                </div>

                <div class="signup-form" v-if="passwortResetSuccessfulFormVisible" style="width: 100%;">
                    <div class="row justify-content-center">
                      <div class="signup-form" >
                        <h5 class="card-title text-center">Dein Passwort wurde zurückgesetzt
                            <i class="far fa-check-circle" style="color: green;"></i>      
                        </h5>
                        <div class="text-center py-3">
                            <button type="button" class="btn btn-outline-dark" @click="$router.push({ path: '/login' })" >Login</button>
                            
                        </div> 
                      </div>
                    </div>
                </div>

            </div> 
        </div> 

</div>
</template>


<script>
import { usermanager } from '@/services';



export default {
  name: 'app',
  data() {
    
    return {

        passwortResetSuccessfulFormVisible: false,
        loading: false,
        registerform: {
          password: '',
          password_2: ''
        },
        passwortrules: [
          { message:'Kleine Buchstaben', regex:/[a-z]+/ },
          { message:"Große Buchstaben",  regex:/[A-Z]+/ },
          { message:"Mindestens 8 Zeichen.", regex:/.{8,}/ },
          { message:"Mindestens 1 Ziffer", regex:/[0-9]+/ }
        ],
        passwordVisible:false,
        submitted:false,
        password:'',

        passwordFormatOK: true,
        reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
      
    }
  },
  async created() {
  },
  computed: {
    samePasswords: function () {
      var filled = this.registerform.password !== '' && this.registerform.password_2 !== ''
			if (filled) {
        var res = this.registerform.password == this.registerform.password_2
				return (res)
			} else {
				return false
			}
		},

		
		
		passwordValidation: function() {
			let errors = []
			for (let condition of this.passwortrules) {
				if (!condition.regex.test(this.registerform.password)) {
					errors.push(condition.message)
				}
			}
			if (errors.length === 0) {
				return { valid:true, errors }
			} else {
				return { valid:false, errors }
			}
		}
	},

  methods: {


    async updatepassword() {
      this.loading = true
      var result = await usermanager.updatepassword(this.$route.params.token, this.registerform.password)
      if(result.status == 200) {
        this.passwortResetSuccessfulFormVisible = true
      } else {
        console.log(result)
        //Swal.fire("Fehler!", result.data.message, "error")
      }
      this.loading = false
    },



  }
}
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/bootstrap.scss'; 

@import './signup.css';


.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.signup-form {
  width: 100%;
}


</style>