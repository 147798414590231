<template>
	<div :class="containerClass" @click="onWrapperClick">
        <div class="mywrapper">
        {{this.isDesktop}}
            <AppTopBar  @menu-toggle="onMenuToggle" @setmenu="setmenu"/>
            
        
            <div class="layout-sidebar" @click="onSidebarClick">
                <AppMenu :model="menu" @onMenuItemClick="onMenuItemClick" />
            </div>
            <div class="spacer">
                &nbsp;
            </div>

            
            <router-view />
            
        
            
        </div>
        
        <vue-cookie-comply id="romancookie"
            :preferences="cookiepreferences"
            preferencesLabel="Einstellungen"
            acceptAllLabel="Zustimmen & Schließen"
            @on-save-cookie-preferences="onSavePreferences"
            >
            <template v-slot:header>
                <header><h5>Cookie Einstellungen</h5></header>
                Während der Nutzung der Website werden Cookies (auch von Drittanbietern) eingesetzt. Hierfür benötigen wir Ihre Zustimmung.
            </template>

            <template v-slot:modal-header>
                <h3 style="margin-left: 2em;">&nbsp;&nbsp; Cookie Einstellungen</h3>
            </template>

        </vue-cookie-comply>
        <AppFooter />
		
        <transition name="layout-mask">
            <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
        </transition>
	</div>
</template>

<script>
import AppTopBar from './AppTopbar.vue';
//import Header from './components/Header.vue';
import AppMenu from './AppMenu.vue';

import AppFooter from './AppFooter.vue';
import { usermanager } from '@/services';

export default {
    data() {
        return {
            loggedin: true,
            layoutMode: 'static',
            layoutColorMode: 'light',
            staticMenuInactive: true,
            overlayMenuActive: false,
            mobileMenuActive: false,
            menu: [],
            cookiepreferences: [
                {
                    title: 'Notwendig',
                    description:
                    'Technisch erforderliche Cookies ermöglichen die Navigation auf unserer Website. Grundfunktionen wie die Darstellung und Auswahl von Produkten oder der Login sind ohne sie nicht möglich.',
                    items: [{ label: 'Google Firebase', value: 'firebase', isRequired: true }],
                },
                {
                    title: 'Analytics',
                    description:
                    'Wir setzen Cookies ein, um Ihr Nutzerverhalten besser zu verstehen und Sie bei Ihrer Navigation auf unserer Internetseite zu unterstützen. Wir nutzen diese Daten außerdem, um die Website noch besser an Ihre Bedürfnisse anzupassen. Mit Ihrer Zustimmung akzeptieren Sie die Übermittlung Ihrer Daten in Drittländer',
                    items: [
                    { label: 'Google Analytics', value: 'ga', isEnable: true, isRequired: true }
                    ],
                },
                {
                    title: '',
                    description: ''
                },
            ]
           
        }
    },
    watch: {
        $route() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
        }
    },
    async mounted() {
        this.isAdmin = await usermanager.isAdmin()
    },
    async created() {
        this.isAdmin = await usermanager.isAdmin()
    },
    methods: {
        onSavePreferences() {
            alert("hi")
        },
        setmenu(menu) {
            this.menu = menu
        },
        onWrapperClick() {
            
            if (!this.menuClick) {
                this.staticMenuInactive = true
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
                
            }
            this.menuClick = false;
      
        },
        onMenuToggle() {
            this.menuClick = true;

            if (this.isDesktop()) {
                if (this.layoutMode === 'overlay') {
                    if(this.mobileMenuActive === true) {
                        this.overlayMenuActive = true;
                    }

                    this.overlayMenuActive = !this.overlayMenuActive;
                    this.mobileMenuActive = false;
                } else if (this.layoutMode === 'static') {
                    this.staticMenuInactive = !this.staticMenuInactive;
                }
            }
            else {
                this.mobileMenuActive = !this.mobileMenuActive;
            }

            event.preventDefault();
        },
        onSidebarClick() {
            this.menuClick = true;
        },
        async onMenuItemClick(event) {
            if(event.item.label == "Logout") {
                await usermanager.logout()
                this.$router.push('/login');
            }
            if (event.item && !event.item.items) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
                this.staticMenuInactive = true
            }
        },
		onLayoutChange(layoutMode) {
			this.layoutMode = layoutMode;
		},
		onLayoutColorChange(layoutColorMode) {
			this.layoutColorMode = layoutColorMode;
		},
        addClass(element, className) {
            if (element.classList)
                element.classList.add(className);
            else
                element.className += ' ' + className;
        },
        removeClass(element, className) {
            if (element.classList)
                element.classList.remove(className);
            else
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        },
        isDesktop() {
            return window.innerWidth >= 992;
        },
        isSidebarVisible() {
            
            if (this.isDesktop()) {
                if (this.layoutMode === 'static')
                    return !this.staticMenuInactive;
                else if (this.layoutMode === 'overlay')
                    return this.overlayMenuActive;
            }

            return true;
        }
    },
    computed: {
        containerClass() {
            return ['layout-wrapper', {
                'layout-overlay': this.layoutMode === 'overlay',
                'layout-static': this.layoutMode === 'static',
                'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
                'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
                'layout-mobile-sidebar-active': this.mobileMenuActive,
				'p-input-filled': this.$primevue.config.inputStyle === 'filled',
				'p-ripple-disabled': this.$primevue.config.ripple === false,
                'layout-theme-light': this.$appState.theme.startsWith('saga')
            }];
        },
        logo() {
            return 'kick17.png';
        }
    },
    beforeUpdate() {
        if (this.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    },
    components: {
        'AppTopBar': AppTopBar,
        'AppMenu': AppMenu,
        'AppFooter': AppFooter,
        
    }
}
</script>

<style lang="scss">
@import './App.scss';

.spacer
{
    width: 100%;
    height: 3.7rem;
}

.mywrapper {
  min-height: 100vh; /* will cover the 100% of viewport */
 overflow: hidden;
 display: block;
 position: relative;
}

html,
body {
 height: 100%;
 position: relative;
}
.cookie-comply__modal {
    position: fixed !important;
}
.cookie-comply__modal-inner {
    width: 80%!important;
}
.cookie-comply {
    position: fixed !important;
}

body.cookie-comply__modal {
    overflow-y: hidden;
    height: 100vh;
}

.cookie-comply__modal-header {
    /*margin-left: 2em;*/
}

</style>
