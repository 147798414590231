<template>
<div>
    <Toast />
    <DataTable :value="match_list" responsiveLayout="scroll" dataKey="id">
        <Column field="uuid" header="Match"></Column>
        <Column field="creator_team.clubname" header="Creator Team"></Column>
        <Column field="creator_team.uuid" header="Creator uuid"></Column>
        <Column field="opponent_team.clubname" header="Opponent Team"></Column>
        <Column header="New Creator Team">
            <template #body="slotProps">
                <Dropdown v-model="creatorHelper[slotProps.index]" :options="teams" optionLabel="clubname" placeholder="None"/>
                <Button icon="pi pi-save" class="p-button-rounded p-button-outlined" @click="assignCreatorTeam(slotProps.data.uuid, creatorHelper[slotProps.index])"/>
            </template>
            
        </Column>

    </DataTable>

     
</div> 

</template>


<script>
import {
    admin, kick17
} from '@/services';


export default {


    name: 'app',
    components: {},
    data() {
        return {
            editors: [],
            match_list: [],
            loaded: false,
            creatorHelper: []
        }
    },
    async mounted() {

    },
    async created() {
        this.loadTeams()
        var result = await admin.allmatches()
        this.match_list = result.data.sort(function(a, b){
            return new Date(a.match_date) - new Date(b.match_date);
        }).reverse()
        this.loaded = true
        this.fillCreatorHelper()
    },
    async beforeMount() {


    },

    methods: {
        async loadTeams() {
            var result = await kick17.getAllClubs()
            this.teams = result.data.sort((a, b) => a.clubname.localeCompare(b.clubname))
        },
        async assignEditor(matchUuid, editorUuid) {
            var res = await admin.assigneditor(matchUuid, editorUuid)
            if (res.status == 200) {
                this.$toast.add({
                    severity: 'success',
                    summary: 'Editor Changed',
                    detail: 'Editor Changed',
                    life: 3000
                });
            } else {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Editor not changed',
                    detail: 'Editor not changed',
                    life: 3000
                });
            }
        },
        fillCreatorHelper() {
            this.creatorHelper = []
            for (var i = 0; i < this.match_list.length; i++) {


                var editor = this.teams.find(e => e.uuid == this.match_list[i].creator_team.uuid)
                this.creatorHelper = this.creatorHelper.concat(editor)
                //console.log(this.roleHelper)
            }

        },
        async assignCreatorTeam(matchUuid, team) {
            var res = await admin.changecreatorteam(matchUuid, team.uuid)
            if (res.status == 200) {
                this.$toast.add({
                    severity: 'success',
                    summary: 'Editor Changed',
                    detail: 'Editor Changed',
                    life: 3000
                });
            } else {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Editor not changed',
                    detail: 'Editor not changed',
                    life: 3000
                });
            }

        }

    }
}
</script>

<style>



</style>

