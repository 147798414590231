import axios from "axios";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const domain = process.env.VUE_APP_USERPLATFORM_URL

const url = domain + "/api/v1/"



async function getAccessToken() {
    var res = null
    await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true)
        .then(function(idToken) {
            res = idToken
        })
        
    return res;
}

async function makeURLRequest(options) {
    var result = null
    await axios.request(options).then(response => {
        result = response
    })
    . catch ((err) => {
        result = err.response
    })
    return result
}

async function updateToken(cookie, csrfmiddlewaretoken) {
    var jwt = await getAccessToken()
    var options = {
        method: "PUT", 
        "url": url + "updatetoken",
        headers: {
            Authorization: jwt,
            token: cookie,
            csrfmiddlewaretoken: csrfmiddlewaretoken
        } 
    }
    return makeURLRequest(options) 
}

async function getAllEditors() {
    var jwt = await getAccessToken()
    var options = {
        method: "GET", 
        "url": url + "getAllEditors",
        headers: {
            Authorization: jwt
        } 
    }
    return makeURLRequest(options)
}

async function getallusers() {
    var jwt = await getAccessToken()
    var options = {
        method: "GET", 
        "url": url + "getallusers",
        headers: {
            Authorization: jwt
        } 
    }
    return makeURLRequest(options)
}

async function allmatches() {
    var jwt = await getAccessToken()
    var options = {
        method: "GET", 
        "url": url + "allmatches",
        headers: {
            Authorization: jwt
        } 
    }
    return makeURLRequest(options)
}

async function allvideofiles() {
    var jwt = await getAccessToken()
    var options = {
        method: "GET", 
        "url": url + "video/allvideofiles",
        headers: {
            Authorization: jwt
        } 
    }
    return makeURLRequest(options)
}

async function allSituationVideofiles() {
    var jwt = await getAccessToken()
    var options = {
        method: "GET", 
        "url": url + "allvideofiles",
        headers: {
            Authorization: jwt
        } 
    }
    return makeURLRequest(options)
}


async function changeRoles(user, role) {
    var jwt = await getAccessToken()
    var options = {
        method: "GET", 
        "url": url + "changeroles",
        headers: {
            Authorization: jwt,
            uid: user.uid,
            role: role
        } 
    }
    return makeURLRequest(options)
}

async function getallroles() {
    var jwt = await getAccessToken()
    var options = {
        method: "GET", 
        "url": url + "getallroles",
        headers: {
            Authorization: jwt
        } 
    }
    return makeURLRequest(options)
}

async function getTeam(teamUuid) {
    var jwt = await getAccessToken()
    var options = { 
        method: "GET", 
        "url": url + "team",
        headers: {
            Authorization: jwt,
            teamUuid: teamUuid
        }
    }
    return makeURLRequest(options)
}

async function assigneditor(matchUuid, editorUuid) {
    var jwt = await getAccessToken()
    var options = { 
        method: "PUT", 
        "url": url + "match/assigneditor",
        headers: {
            Authorization: jwt,
            matchUuid: matchUuid,
            editorUuid: editorUuid != null ? editorUuid.uid : ""
        }
    }
    return makeURLRequest(options)
}

async function changecreatorteam(matchUuid, creatorTeamUuid) {
    var jwt = await getAccessToken()
    var options = { 
        method: "PUT", 
        "url": url + "match/changecreatorteam",
        headers: {
            Authorization: jwt,
            matchUuid: matchUuid,
            creatorTeamUuid: creatorTeamUuid
        }
    }
    return makeURLRequest(options)
}

async function verifyuser(uid) {
    var jwt = await getAccessToken()
    var options = { 
        method: "PUT", 
        "url": url + "verifyuser",
        headers: {
            Authorization: jwt,
            uid: uid,
            active: true
        }
    }
    return makeURLRequest(options)
}

export const admin = {
    getallusers,
    getAllEditors,
    assigneditor,
    changecreatorteam,
    changeRoles,
    verifyuser,
    getallroles,
    updateToken,
    allmatches,
    allvideofiles,
    allSituationVideofiles,
    getTeam
};