<template>
<div>

    <ul class="list-none">
        <li v-for="(vid, index) in videolist" v-bind:key="index">
            {{vid}}
        </li>
    </ul>

</div>
</template>



<script>
import { admin } from '@/services';

export default {
  name: 'app',
  components: {},
  data () {
    return {
     videolist: []
    }
  },
  mounted() {
        
    },
  async created () {

        this.loadList()
        
  },

  methods: {
    async loadList() {
        var result = await admin.allSituationVideofiles()
        this.videolist = result.data
      }
    }
  }

</script>