<template>
    
    <Dialog :modal="true" header="Ergebnis" v-model:visible="editResult" :breakpoints="{'960px': '75vw'}" :style="{width: '50vw'}">
        <div class="row">
            <div class="col">  {{match.creator_team.clubname }} </div>
    
                <div class="col">
                    {{match.opponent_team.clubname}}
                </div>
            </div>
            <div class="row">
                <div class="col">
                    
                <InputNumber class="ml-auto" id="vertical" v-model="creator_team_goals" mode="decimal" showButtons buttonLayout="vertical" style="width:4rem" decrementButtonClass="p-button-secondary" incrementButtonClass="p-button-secondary" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                </div>
                <div class="col">
                    <InputNumber class="ml-auto" id="vertical" v-model="opponent_team_goals" mode="decimal" showButtons buttonLayout="vertical" style="width:4rem" decrementButtonClass="p-button-secondary" incrementButtonClass="p-button-secondary" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                </div>
            </div>
        <template #footer>
            <Button label="Abbrechen" icon="pi pi-times" @click="editResult = !editResult" class="p-button-text"/>
            <Button label="Speichern" icon="pi pi-check" @click="saveResult()" autofocus />
        </template>
    </Dialog>


    <div class="row" v-if="match != null">
        <div class="col-12 col-sm-12 col-md-12 col-lg-8 offset-lg-2 col-xl-8 offset-xl-2">
            <table class="table borderless" style="table-layout: fixed;">
                
                <tbody>
                    <tr>
                        <td><img v-if="match!=null  && match.creator_team.icon != ''"  class="clublogo" :src="imagePath + match.creator_team.icon" @error="defaultLogo" alt="" data-holder-rendered="true"></td>
                        <th scope="row"></th>
                        <td><img v-if="match!=null  && match.opponent_team.icon != ''"  class="clublogo" :src="imagePath + match.opponent_team.icon" @error="defaultLogo" alt="" data-holder-rendered="true"></td>
                    </tr>

                    <tr>
                        <th>{{match.creator_team.clubname}}</th>
                        <th scope="row"></th>
                        <th>{{match.opponent_team.clubname}}</th>
                    </tr>
                    <tr>
                        <td>{{match.creator_team_goals}}</td>
                        <th scope="row">Tore (Ist)
                            <div class="col text-center">
                                <a @click="editResult = !editResult" onmouseover="" style="cursor: pointer;">
                                    <i class="far fa-edit" ></i>
                                </a>
                            </div>
                        </th>
                        <td>{{match.opponent_team_goals}}</td>
                    </tr>

                     <tr v-if="stats != null && match.situationsReady == true">
                        <td>{{Number(stats.xG.creator_team).toFixed(2)}}</td>
                        <th scope="row">Tore (Soll)</th>
                        <td>{{Number(stats.xG.opponent_team).toFixed(2)}}</td>
                    </tr>

                    <tr v-if="stats != null && match.situationsReady == true">
                        <td>{{stats.shots.creator_team}}</td>
                        <th scope="row">Schüsse</th>
                        <td>{{stats.shots.opponent_team}}</td>
                    </tr>
                    <tr v-if="stats != null && match.situationsReady == true">
                        <td>{{stats.shotsongoal.creator_team}}</td>
                        <th scope="row">Torschüsse</th>
                        <td>{{stats.shotsongoal.opponent_team}}</td>
                    </tr>

                    <tr v-if="stats != null && match.situationsReady == true">
                        <td>{{stats.corners.creator_team}}</td>
                        <th scope="row">Eckstöße</th>
                        <td>{{stats.corners.opponent_team}}</td>
                    </tr>

                    <!--<tr v-if="stats != null && match.situationsReady == true && (stats.throwins.creator_team != 0 && stats.throwins.opponent_team != 0)">
                        <td>{{stats.throwins.creator_team}}</td>
                        <th scope="row">Einwürfe</th>
                        <td>{{stats.throwins.opponent_team}}</td>
                    </tr>-->

                    <!--<tr v-if="stats != null && match.situationsReady == true">
                        <td>{{stats.freekicks.creator_team}}</td>
                        <th scope="row">Freistöße</th>
                        <td>{{stats.freekicks.opponent_team}}</td>
                    </tr>-->

                    <!--<tr v-if="stats != null && match.situationsReady == true">
                        <td>{{stats.ballgewinn.creator_team}}</td>
                        <th scope="row">Zweikämpfe</th>
                        <td>{{stats.ballgewinn.opponent_team}}</td>
                    </tr>-->

                    <!--<tr v-if="stats != null && match.situationsReady == true">
                        <td>{{stats.ballverlust.creator_team}}</td>
                        <th scope="row">Ballverlust</th>
                        <td>{{stats.ballverlust.opponent_team}}</td>
                    </tr>-->
                    
                </tbody>
            </table>
        </div>
    </div>


</template>


<style scoped lang="scss">
@import '~bootstrap/scss/bootstrap.scss';
    .clublogo {
        width: 40px;
        height: 40px;
    }

    .table td{
        text-align: center;   
    }

    .table th{
        text-align: center;   
    }

    .borderless td, .borderless th {
        border: none;
    }

</style>

<script>

import { ref, onMounted, computed } from 'vue'
import { defineComponent } from "vue";
import { kick17 } from '@/services';
import { useStore } from 'vuex'


export default defineComponent({
    setup() {
        const store = useStore()
        
        const stats = ref(null)
        const editResult = ref(false)
        const opponent_team_goals = ref(0)
        const creator_team_goals = ref(0)
        const imagePath = ref("") 

        onMounted(() => {
            imagePath.value = kick17.getClubLogoReadURL()
            opponent_team_goals.value = match.value.opponent_team_goals
            creator_team_goals.value = match.value.creator_team_goals
            calculateStats()
        }) 
        var match = computed(() => {
            return store.getters.getMatch
        })

        

        const calculateStats = function() {
            stats.value = {
                
                shots: {creator_team: 0, opponent_team: 0},
                shotsongoal: {creator_team: 0, opponent_team: 0},
                ballgewinn: {creator_team: 0, opponent_team: 0},
                ballverlust: {creator_team: 0, opponent_team: 0},
                assists: {creator_team: 0, opponent_team: 0},
                blocks: {creator_team: 0, opponent_team: 0},
                corners: {creator_team: 0, opponent_team: 0},
                freekicks: {creator_team: 0, opponent_team: 0},
                xG: {creator_team: 0, opponent_team: 0},
                throwins: {creator_team: 0, opponent_team: 0}
            }

            let allSitPos = kick17.getAllSitPos(match.value)
            allSitPos = allSitPos.filter(kick17.sitpoHasActions)
            

            stats.value.shots.creator_team = allSitPos.filter(s => s.isInCreatorTeam == true && s.action_labels.indexOf("Schuss") != -1).length
            stats.value.shots.opponent_team = allSitPos.filter(s => s.isInCreatorTeam == false && s.action_labels.indexOf("Schuss") != -1).length

            stats.value.shotsongoal.creator_team = allSitPos.filter(s => s.isInCreatorTeam == true && s.action_labels.indexOf("Torschuss") != -1).length
            stats.value.shotsongoal.opponent_team = allSitPos.filter(s => s.isInCreatorTeam == false && s.action_labels.indexOf("Torschuss") != -1).length

            stats.value.corners.creator_team = allSitPos.filter(s => s.isInCreatorTeam == true && s.action_labels.indexOf("Eckstoß") != -1).length
            stats.value.corners.opponent_team = allSitPos.filter(s => s.isInCreatorTeam == false && s.action_labels.indexOf("Eckstoß") != -1).length

            stats.value.freekicks.creator_team = allSitPos.filter(s => s.isInCreatorTeam == true && s.action_labels.indexOf("Freistoß") != -1).length
            stats.value.freekicks.opponent_team = allSitPos.filter(s => s.isInCreatorTeam == false && s.action_labels.indexOf("Freistoß") != -1).length

            stats.value.ballgewinn.creator_team = allSitPos.filter(s => s.isInCreatorTeam == true && s.action_labels.indexOf("Ballgewinn") != -1).length
            stats.value.ballgewinn.opponent_team = allSitPos.filter(s => s.isInCreatorTeam == false && s.action_labels.indexOf("Ballgewinn") != -1).length

            stats.value.ballverlust.creator_team = allSitPos.filter(s => s.isInCreatorTeam == true && s.action_labels.indexOf("Ballverlust") != -1).length
            stats.value.ballverlust.opponent_team = allSitPos.filter(s => s.isInCreatorTeam == false && s.action_labels.indexOf("Ballverlust") != -1).length

            allSitPos.filter(sitpo => sitpo.isInCreatorTeam == true).forEach(sitpo => stats.value.xG.creator_team += sitpo.xG)
            allSitPos.filter(sitpo => sitpo.isInCreatorTeam == false).forEach(sitpo => stats.value.xG.opponent_team += sitpo.xG)
            
            stats.value.throwins.creator_team = match.value.situations.filter(sit => sit.isCreatorSituation == true && sit.label.includes("Einwurf")).length
            stats.value.throwins.opponent_team = match.value.situations.filter(sit => sit.isCreatorSituation == false && sit.label.includes("Einwurf")).length

            stats.value.throwins.creator_team += allSitPos.filter(s => s.isInCreatorTeam == true && s.action_labels.includes("Einwurf")).length
            stats.value.throwins.opponent_team += allSitPos.filter(s => s.isInCreatorTeam == false && s.action_labels.includes("Einwurf")).length
        }


        const defaultLogo = function(event) { 
           event.target.src = require("../../assets/kick17_old.png") 
        }
        
        const saveResult = async function() {
            var result = await kick17.setmatchresult(match.value.uuid, creator_team_goals.value, opponent_team_goals.value)
            editResult.value = false
            this.$store.commit('setMatch', result.data)
        }
        



        return {
            defaultLogo,
            stats,
            editResult,
            saveResult,
            creator_team_goals,
            opponent_team_goals,
            imagePath,
            match
        }


    }
});


</script>