<template>
    
    <div class=" mb-5">
        <h4>Wir bitten um etwas Geduld.</h4>
        <!--<p class="font-italic text-muted">Damit Deine gespeicherten Situationen im Video korrekt markiert werden können, benötigen wir (optional) Deine Hilfe:</p>
    
        

        <p> <i class="far" v-bind:class="calibration[0] != null ? 'fa-check-circle' : 'fa-clock'" v-bind:style="calibration[0] != null ? 'color: green;' : 'color: blue;'" ></i> Spule das Video zum Anpfiff der ersten Halbzeit und klicke <a class="font-medium no-underline ml-2 text-blue-500 cursor-pointer" @click="setCalibrationFirstTimestamp">hier</a></p>

        <p v-if="calibration[0] != null"> <i class="far" v-bind:class="calibration[2] != null ? 'fa-check-circle' : 'fa-clock'" v-bind:style="calibration[2] != null ? 'color: green;' : 'color: blue;'" ></i> Spule das Video zum Anpfiff der zweiten Halbzeit und klicke <a class="font-medium no-underline ml-2 text-blue-500 cursor-pointer" @click="setCalibrationSecondTimestamp">hier</a></p>
        <Button label="Kalibrierung abschließen" :loading="loading" v-if="calibration[2] != null" class="p-button-outlined p-button-secondary" @click="saveMatchVideoFrame()"/>-->
        <p><i class="far fa-clock" style="color: blue;"></i> Die interessantesten Szenen des Videos werden derzeit ermittelt und analysiert. Das Ergebnis erhälst Du spätestens in 24h.<br></p>

                                        

        
        
    </div>

</template>


<style scoped lang="scss">
@import '~bootstrap/scss/bootstrap.scss';
    .clublogo {
        width: 40px;
        height: 40px;
    }

    .table td{
        text-align: center;   
    }

    .table th{
        text-align: center;   
    }

    .borderless td, .borderless th {
        border: none;
    }

</style>

<script>
import { ref, onMounted, toRefs, computed } from 'vue'
import { defineComponent } from "vue";
import { kick17 } from '@/services';
import { useStore } from 'vuex'

export default defineComponent({
    props:['timestamp'],
    setup(props) {
        
        const { timestamp } = toRefs(props)
        const calibration = ref([])
        const loading = ref(false)
        const store = useStore()
        calibration.value = [null, null, null, null]

        onMounted(() => {
            calibration.value[0] = match.value.matchVideoFrame != null ? match.value.matchVideoFrame[0] : null
            
            calibration.value[2] = match.value.matchVideoFrame != null ? match.value.matchVideoFrame[2] : null
            calibration.value[1] = match.value.matchVideoFrame != null && match.value.matchVideoFrame[1] != null ? match.value.matchVideoFrame[1] : 0
            calibration.value[3] = 0
        }) 

        var match = computed(() => {
            return store.getters.getMatch
        })


        const setCalibrationFirstTimestamp = function() {
            calibration.value[0] = Number(timestamp.value).toFixed(0)
        }

        const saveMatchVideoFrame = async function() {
            loading.value = true
            var result = await kick17.saveMatchVideoFrame(match.value.uuid, Object.values(calibration.value))
            this.$store.commit('setMatch', result.data)
            loading.value = false
        }

        const setCalibrationSecondTimestamp = function() {
            calibration.value[2] = Number(timestamp.value).toFixed(0)
        }

        return {
            calibration,
            setCalibrationFirstTimestamp,
            setCalibrationSecondTimestamp,
            saveMatchVideoFrame,
            match
        }


    }
});


</script>