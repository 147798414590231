<template>
<div>
  <div v-if="srcTmp != null">
 


    <vue3-video-player ref="videoPlayer" :src="videoSrc" :disablepictureinpicture="true" :muted="false" :visible="false" :controls="'fixed'" :autoplay="false" :logo="logo" @loadeddata="onPlayerLoadeddata" @timeupdate="onPlayerTimeupdate" @play="onPlayerPlay" @fullscreenchange="resize()" @resize="resize()">
  
    <template #cusControls>
    
        <div v-if="match.matchVideoFrame != undefined">
        <span v-if="match.matchVideoFrame != undefined && curtime < match.matchVideoFrame[0]" style="color: white; margin-right: 10px; cursor: pointer;" @click="setTime(match.matchVideoFrame[0])">l: 1 Halftime</span>
        <span v-else-if="match != null && match.matchVideoFrame.length > 0 && curtime > match.matchVideoFrame[1] && curtime < match.matchVideoFrame[2]" style="color: white; margin-right: 10px; cursor: pointer;" @click="setTime(match.matchVideoFrame[2])">l: 2 Halftime</span>
        <span v-else-if="curtime < latestSituationEnd" style="color: white; margin-right: 10px; cursor: pointer;" @click="setTime(latestSituationEnd)">l: Last Situation</span>
        <span v-if="match != null && match.matchVideoFrame != null && match.matchVideoFrame[3] == 0" style="color: white; margin-right: 10px; cursor: pointer;" @click="skipMinutes()">h: {{(match.matchVideoFrame[1] == 0 || (match.matchVideoFrame[3] == 0 && match.matchVideoFrame[2] != 0)) ? '+ 45 min' : '+ 15 min'}}</span>
        </div>
        <span v-if="addSitVisible && match.creatorcolor != null && match.opponentcolor != null && match.matchVideoFrame != null && match.matchVideoFrame.length == 4" style="color: white; margin-right: 10px; cursor: pointer;" @click="newSit()">s: Add Situation</span>
        <AddSituationControl ref="addsitControl" :currentVideoTimestamp="curtime + previousVideosDurations" :match="match" @addSitVisible="addSitVisible = true" @addSituation="addSituation" @newSituationEdit="addSituation"></AddSituationControl>
        
        <!--<span style="color: white; margin-right: 10px; cursor: pointer;">Bookmark</span>-->

        <span style="color: white; margin-right: 10px; cursor: pointer;" @click="setTime(curtime - 3)"><i class="pi pi-backward"></i></span>
        <span style="color: white; margin-right: 10px;">3s</span>
        <span style="color: white; margin-right: 10px; cursor: pointer;" @click="setTime(curtime + 3)"><i class="pi pi-forward"></i></span>

        <div v-if="match != null && match.videos.length > 1">
            <span v-for="(v, index) in match.videos" v-bind:key="index" style="color: white; margin-right: 10px; cursor: pointer;" @click="selectedVideoIndex = index; refreshAllMarkers()">
                Video {{index + 1}}
            </span>
        </div>
        

        <!--<span v-if="match.videos.length < 2" style="color: white; margin-right: 10px; cursor: pointer;" @click="addVideo">Video upload</span>-->
    </template>
    
  
  </vue3-video-player></div>
</div>
</template>

<script>
import { kick17 } from '@/services';
import AddSituationControl from "./AddSituationControl.vue";
import editorStore from '../../editorStore'

export default {
    components: {AddSituationControl},
    props: ['options', 'newSituation', 'selectedSecond'],
    data() {
        return {
            markers: [],
            logo: require('@/assets/kick17.png'),
            selectedVideoIndex: 0,
            srcTmp: "",
            videoLengthArr: [],
            curtime: 0,
            addSitVisible: true
        }
    },
    watch: {
        match: function() {
            this.refreshAllMarkers()
        },
        situations: function (newVal) {
            if(newVal != null) {
                this.onPlayerLoadeddata()
            }
        },
        selectedVideoIndex: function () {
            //this.videoLengthArr[newVal] = this.getVideoDuration()
            //alert(this.getVideoDuration())
        },
        selectedSecond: function() {
            this.refreshAllMarkers()
        }
    },
    mounted() {
        
        document.querySelector('.vcp-container').addEventListener('click', function(evt) { 
            this.$emit('videoclick', evt)
        }.bind(this))
        
    },
    unmounted() {
      window.removeEventListener('keyup', this.keyUpHandler);
    },
    async created() {
        this.srcTmp = kick17.getVideoURL() + this.match.videos[this.selectedVideoIndex] //"http://localhost:8080/videos/20211124192233_V8YY9hD75Aa4XcwfAPxIQ5HV5xl2.mp4"
        
        window.addEventListener('keyup', this.keyUpHandler);
    },
    computed: {
        isPlayEditing: function() {
            return editorStore.getters.isPlayEditing
        },
        match: function() {
            return editorStore.getters.getMatch
        },
        situations: function() {
            return editorStore.getters.getAllSituations
        },
        player() {
            return this.$refs.videoPlayer
        },
        
        videoSrc: function() {
            if(this.match != null) {
                try {
                    let url = new URL(this.match.videos[this.selectedVideoIndex])
                    return url.href
                } catch (_) {
                    return kick17.getVideoURL() + this.match.videos[this.selectedVideoIndex]
                }
            }
            return ""
        },
        videoName: function() {
            if(this.match == null) {
                return "not loaded"
            }
            return this.match.videos[this.selectedVideoIndex]
        },
        previousVideosDurations: function() {
            var result = 0
            if(this.selectedVideoIndex == 0) {
                return result
            }
            else {
                for(var i = 0; i < this.selectedVideoIndex; i++) {
                    var lastIndex = i
                    result = result + this.videoLengthArr[lastIndex]
                }
            }
            return result
        },
        latestSituationEnd: function() {
            var ts = 0
            this.match.situations.forEach(s => {
                ts = s.situation_end > ts ? s.situation_end : ts
            })
            return ts
        }
        
    },
    methods: {
        newBallLoss() {
            this.addSitVisible = false
            this.$refs.addsitControl.createEmptySituation()
        },
        handleNumber(nr) {
            if(this.$refs.addsitControl.newsituationFrame == null) {return}
            if(this.$refs.addsitControl.newsituationFrame.isCreatorSituation == null && this.$refs.addsitControl.newsituationFrame.situation_end != 0 && (nr == 1 || nr == 2)) {
                this.$refs.addsitControl.setSituationOwner(nr == 1 ? true : false)
            }
            
            else if(this.$refs.addsitControl.newsituationFrame.isCreatorSituation != null && this.$refs.addsitControl.newsituationFrame.label.length == 0) {
                this.$refs.addsitControl.setSituationLabel(nr - 1)
            } else if(this.$refs.addsitControl.newsituationFrame.label.length != 0 && this.$refs.addsitControl.newsituationFrame.label.includes('Elfmeter')) {
                if(nr == 1) {
                    this.$refs.addsitControl.addAttemptPosition(['Torschuss', 'Tor'], [], true)
                } else if(nr == 2) {
                    this.$refs.addsitControl.addAttemptPosition(['Schuss'], [], true)
                } else if(nr == 3) {
                    this.$refs.addsitControl.addAttemptPosition(['Torschuss'], ['Block'], true)
                } else if(nr == 4) {
                    this.$refs.addsitControl.addAttemptPosition(['Torschuss'], ['Ballbesitz'], true)
                }
                
            } else if(this.$refs.addsitControl.newsituationFrame.label.length != 0 && this.$refs.addsitControl.newsituationFrame.label.includes('Tor')) {
                if(nr == 1) {
                    this.$refs.addsitControl.toggleActionLabel("Ballannahme")
                } else if(nr == 2) {
                    this.$refs.addsitControl.addGoalPositions([])
                } else if(nr == 3) {
                    this.$refs.addsitControl.addGoalPositions(['Kopfball'])
                } else if(nr == 4 && !this.$refs.addsitControl.situationPlayerList[0].action_labels.includes('Ballannahme')) {
                    this.$refs.addsitControl.addGoalPositions(['Elfmeter'])
                } else if(nr == 5 && !this.$refs.addsitControl.situationPlayerList[0].action_labels.includes('Ballannahme')) {
                    this.$refs.addsitControl.addGoalPositions(['Freistoß'])
                } else if(nr == 6 && !this.$refs.addsitControl.situationPlayerList[0].action_labels.includes('Ballannahme')) {
                    this.$refs.addsitControl.addGoalPositions(['Eigentor'])
                } 
                
            } else if(this.$refs.addsitControl.newsituationFrame.label.length != 0 && this.$refs.addsitControl.newsituationFrame.label.includes('Schuss')) {
                if(nr == 1) {
                    this.$refs.addsitControl.toggleActionLabel("Kopfball")
                } else if(nr == 2) {
                    this.$refs.addsitControl.toggleActionLabel("Ballannahme")
                } else if(nr == 3) {
                    this.$refs.addsitControl.newsituationFrame.videoNeeded = !this.$refs.addsitControl.newsituationFrame.videoNeeded
                } else if(nr == 4) {
                    this.$refs.addsitControl.addAttemptPosition(['Torschuss'], ['Block'], false)
                } else if(nr == 5) {
                    this.$refs.addsitControl.addAttemptPosition([], [], false)
                } else if(nr == 6) {
                    this.$refs.addsitControl.addAttemptPosition([], ['Block'], false)
                } else if(nr == 7) {
                    this.$refs.addsitControl.addAttemptPosition(['Latte', 'Pfosten', 'Torschuss'], [], false)
                }  
            } else if(this.$refs.addsitControl.newsituationFrame.label.length != 0 && this.$refs.addsitControl.newsituationFrame.label.includes('Freistoß')) {
                if(nr == 1) {
                    this.$refs.addsitControl.toggleActionLabel("Schuss")
                } 
                if (!this.$refs.addsitControl.situationPlayerList[0].action_labels.includes('Schuss')) {
                    if(nr == 2) {
                        this.$refs.addsitControl.addFrekickPosition(['Assist', 'Flanke', 'Pass'], [], ['Ballannahme', 'Torschuss', 'Schuss', 'Tor'], true)
                    } else if(nr == 3) {
                        this.$refs.addsitControl.addFrekickPosition(['Angekommen', 'Assist', 'Flanke', 'Pass'], [], ['Ballannahme', 'Schuss'], true)
                    } else if(nr == 4) {
                        this.$refs.addsitControl.addFrekickPosition(['Angekommen', 'Flanke', 'Pass'], [], ['Ballannahme'], true)
                    } else if(nr == 5) {
                        this.$refs.addsitControl.addAttemptPosition(['Flanke', 'Pass'], [], true)
                    } 
                } else {
                    if (nr == 2) {
                        this.$refs.addsitControl.addAttemptPosition([], [], false)
                    } else if(nr == 3) {
                        this.$refs.addsitControl.addAttemptPosition([], ['Block'], false)
                    } else if(nr == 4) {
                        this.$refs.addsitControl.addAttemptPosition(['Torschuss'], ['Block'], true)
                    } else if(nr == 5) {
                        this.$refs.addsitControl.addAttemptPosition(['Torschuss', 'Tor'], [], true)
                    } 
                }
            } else if(this.$refs.addsitControl.newsituationFrame.label.length != 0 && this.$refs.addsitControl.newsituationFrame.label.includes('Eckstoß') && this.$refs.addsitControl.situationPlayerList!= null && this.$refs.addsitControl.situationPlayerList.length != 0) {
                if(this.$refs.addsitControl.situationPlayerList.find(s => s.role == 'corner').y == 50) {
                    if(nr == 1) {
                        this.$refs.addsitControl.setCorner(0)
                    } else if(nr == 2) {
                        this.$refs.addsitControl.setCorner(100)
                    }
                } else if(this.$refs.addsitControl.situationPlayerList.find(s => s.role == 'corner').y != 50 && this.$refs.addsitControl.situationPlayerList[0].action_labels.length < 2) {
                    if(nr == 1) {
                        this.$refs.addsitControl.addCornerPositions(['Angekommen', 'Assist', 'Flanke', 'Pass'], [], ['Ballannahme', 'Schuss'])
                        this.$refs.addsitControl.newsituationFrame.videoNeeded = true
                    } else if(nr == 2) {
                        this.$refs.addsitControl.addCornerPositions(['Angekommen', 'Flanke', 'Pass'], [], ['Ballannahme'])
                    } else if(nr == 3) {
                        this.$refs.addsitControl.addCornerPositions(['Flanke', 'Pass'], ['Ballgewinn'], [])
                        this.$refs.addsitControl.setVideoNeeded(false)
                    } else if(nr == 4) {
                        this.$refs.addsitControl.addCornerPositions(['Flanke', 'Pass'], [], [])
                        this.$refs.addsitControl.setVideoNeeded(false)
                    }
                } else if(this.$refs.addsitControl.situationPlayerList.length > 1 && this.$refs.addsitControl.situationPlayerList.find(s => s.role == 'corner').y != 50 && this.$refs.addsitControl.situationPlayerList.find(s => s.role == 'offender').action_labels.includes('Schuss')) {
                        
                    if(nr == 1) {
                        this.$refs.addsitControl.toggleCornerHeaderLabel('Kopfball')
                    } else if(nr == 2) {
                        this.$refs.addsitControl.addCornerAttemptPosition(['Torschuss'], ['Block'], true)
                    } else if(nr == 3) {
                        this.$refs.addsitControl.addCornerAttemptPosition([], [], true)
                    } else if(nr == 4) {
                        this.$refs.addsitControl.addCornerAttemptPosition([], ['Block'], true)
                    } else if(nr == 5) {
                        this.$refs.addsitControl.addCornerAttemptPosition(['Latte', 'Pfosten', 'Torschuss'], [], true)
                    }
                }
                
            } 
            
            
            
            else if(this.$refs.addsitControl.newsituationFrame.label.length != 0 && this.$refs.addsitControl.newsituationFrame.videoNeeded == null && (nr == 1 || nr == 2)) {
                this.$refs.addsitControl.setVideoNeeded(nr == 1 ? true : false)
            }

        },

        skipMinutes() {
            if(this.match.matchVideoFrame != null) {
                if(this.match.matchVideoFrame[1] == 0) {
                    this.setTime(Number(this.match.matchVideoFrame[0]) + Number(45*60))
                } else if(this.match.matchVideoFrame[2] == 0) {
                    this.setTime(Number(this.match.matchVideoFrame[1]) + Number(15*60))
                } else if(this.match.matchVideoFrame[3] == 0) {
                    this.setTime(Number(this.match.matchVideoFrame[2]) + Number(45*60))
                }
            }
        },
        keyUpHandler(event) {
            if(this.isPlayEditing == true) {
                return
            }
            if (event.key == " " || event.code == "Space" || event.keyCode == 32) {
                this.$refs.videoPlayer.isPlaying ? this.$refs.videoPlayer.pause() : this.$refs.videoPlayer.play()
                return
            }

            if(isFinite(event.key)) {
                this.handleNumber(event.key)
                return
            }
            

            switch(event.code) {
                case 'KeyQ':
                    console.log(this.$refs.videoPlayer.fullscreen)
                    if(!this.$refs.videoPlayer.fullscreen) {
                        this.$refs.videoPlayer.enterFullscreen()
                    } else {
                        this.$refs.videoPlayer.cancelFullscreen()
                    }
                    
                    break;
                case 'KeyH':
                        if(this.match.matchVideoFrame) {
                            this.skipMinutes()
                        }
                    break;
                case 'ArrowRight':
                    this.setTime(this.curtime + 3)
                    break;
                case 'ArrowLeft':
                    this.setTime(this.curtime - 3)
                    break;
                case 'KeyS':
                    if(this.addSitVisible && this.match.creatorcolor != null && this.match.opponentcolor != null && this.match.matchVideoFrame.length == 4) {
                        this.newSit()
                    }
                    
                    break;
                case 'KeyC':
                    this.$refs.addsitControl.cancel()
                    break;
                case 'KeyD':
                    this.$refs.addsitControl.setEnd()
                    break;
                case 'KeyL':
                    if(this.match.matchVideoFrame != undefined || this.match.matchVideoFrame != null) {
                        if(this.curtime < this.match.matchVideoFrame[0]) {
                            this.setTime(this.match.matchVideoFrame[0])
                        } 
                        else if(this.curtime > this.match.matchVideoFrame[1] && this.curtime < this.match.matchVideoFrame[2]) {
                            this.setTime(this.match.matchVideoFrame[2])
                        } else if(this.curtime < this.latestSituationEnd) {
                            this.setTime(this.latestSituationEnd)
                        } 
                    
                    }
                    break;
                case 'KeyF':
                    this.$refs.videoPlayer.player.$video.playbackRate = this.$refs.videoPlayer.player.$video.playbackRate == 1 ? 2 : 1
                    break;
                default:
                    //break;
            }
        },
        resize() {
            console.log("resize")
        },
        addSituation(sit) {
            this.$emit('newSituationEdit', sit)
        },
        newSit() {
            this.addSitVisible = false
            this.$refs.addsitControl.createEmptySituation()
        },
        selectVideo(index) {
            this.selectedVideoIndex = index
            this.srcTmp = kick17.getVideoURL() + this.match.videos[this.selectedVideoIndex]
        },
        addMarkers() {

        },
        onPlayerPlay() {
            this.refreshAllMarkers()
        },


        getSituationVideoTimestamp(sit) {
            //if(sit.situation_start != 0) {return sit.situation_start}
            var begin = new Date(this.match.match_date)
            var curTime = new Date(sit.custom_marker)
            return curTime.getDate() - begin.getDate()
        },
        showSelectedSecond() {
            if(this.match.situations == null) {return}
            var player = this.$refs.videoPlayer.$player
            var p = player.$el.querySelector('.vcp-progress')
            //alert(this.selectedSecond)
            var left =( this.selectedSecond / this.getVideoDuration() * 100)+'%';
            var divElement = document.createElement('div')

            divElement.className = "vjs-marker text-pink-400"
            var spanElement = document.createElement("span");
            spanElement.innerHTML = "Selected Second"

            var iconElement = document.createElement('i')
            iconElement.className = "pi pi-arrow-up text-black font-bold"
            divElement.appendChild(iconElement)
           
            divElement.appendChild(spanElement);
            divElement.style="left: " + left
        

            //divElement.setAttribute("data-time", situation_start)
            //divElement.addEventListener( 'onclick', this.setTime(this.match.situations[i].situation_start) );
            
    
            p.appendChild(divElement);
        },
        showCustomMarkers() {
            var player = this.$refs.videoPlayer.$player
            var p = player.$el.querySelector('.vcp-progress')
            if(this.match.situations == null) {return}
            for(var i=0;i<this.match.situations.length;i++){
                var curSituation = this.match.situations[i]
                if(curSituation.custom_marker != null) {
                    var situation_start = this.getSituationVideoTimestamp(curSituation)
                    var left =( (situation_start - this.previousVideosDurations) / this.getVideoDuration() * 100)+'%';

                    var divElement = document.createElement('div')

                    divElement.className = "vjs-marker " + curSituation.situationType 
                    var spanElement = document.createElement("span");
                    spanElement.innerHTML = curSituation.label[0]

                    /*if(markers[i].situationType == "yellow-card") {
                        var iconElement = document.createElement('i')
                        iconElement.className = "fas fa-futbol"
                        divElement.appendChild(iconElement)
                    }*/
                    divElement.appendChild(spanElement);
                    divElement.style="left: " + left
                

                    //divElement.setAttribute("data-time", situation_start)
                    //divElement.addEventListener( 'onclick', this.setTime(this.match.situations[i].situation_start) );
                    
            
                    p.appendChild(divElement);
                }
                
                
            }
        },
        situationDuration(situation) {
            return situation.situation_end - situation.situation_start
        },
        showExcludedFrames() {
            var player = this.$refs.videoPlayer.$player
            var p = player.$el.querySelector('.vcp-progress')
            var length = player.$el.querySelector('.vcp-progress').clientWidth
            var widthArr = []
            var timestamps = []
            if(this.match.matchVideoFrame == null || this.match.matchVideoFrame.length == 0) {return}
            //timestamps = this.match.videos.length > 1 ? this.match.matchVideoFrame.slice((this.selectedVideoIndex * 2), (this.selectedVideoIndex * 2) + 2) : this.match.matchVideoFrame
            if(this.match.videos.length > 1) {
                timestamps = this.match.matchVideoFrame.slice((this.selectedVideoIndex * 2), (this.selectedVideoIndex * 2) + 2)
                widthArr = [timestamps[0] - this.previousVideosDurations, timestamps[1] == 0 ? 0 : Number(this.getVideoDuration() - (timestamps[1] - this.previousVideosDurations)).toFixed(0)]
            } else {
                timestamps = this.match.matchVideoFrame
                widthArr = [timestamps[0], timestamps[2] != null && timestamps[1] != null && timestamps[2] != 0 && timestamps[1] != 0 ? timestamps[2] - timestamps[1] : (timestamps[1] != 0 ? 0 : null), timestamps[3] != null && timestamps[3] != 0 ? this.getVideoDuration() - timestamps[3] : null]
                widthArr = widthArr.filter(w => w != null)
            }
            
            for(var i=0;i<widthArr.length;i++){
                
                if(widthArr[i] == 0 && !this.match.videos.length == 1) {return}
                

                var markerWidth = ((widthArr[i] / this.getVideoDuration()) * length) + "px"
                var left = 0

                if (this.match.videos.length > 1 ) {
                    left = i == 0 ? p.getBoundingClientRect().left : ( (timestamps[i] - this.previousVideosDurations) / this.getVideoDuration() * 100)+'%';
                } else {
                    left = i == 1 ? ( timestamps[1] / this.getVideoDuration() * 100) : left
                    left = i == 2 ? ( timestamps[3] / this.getVideoDuration() * 100) : left
                    left = left + '%'
                }
                
                var divElement = document.createElement('div')
                divElement.className = "excludedFrameMarker vjs-marker"
                var spanElement = document.createElement("span");
                spanElement.innerHTML = "not relevant"
                divElement.appendChild(spanElement);

                divElement.style = (i == 1 &&  widthArr.length == 2 && timestamps[2] == 0) ? ("left: " + left) : ("width: " + markerWidth + "; left: " + left)
                p.appendChild(divElement);
                    
            }
        },
        
        showSituationsFrames() {
            var player = this.$refs.videoPlayer.$player
            var p = player.$el.querySelector('.vcp-progress')
            
            var length = player.$el.querySelector('.vcp-progress').clientWidth

            if(this.match.situations == null) {return}
            for(var i=0;i<this.match.situations.length;i++){
                var curSituation = this.match.situations[i]
                if(curSituation.situation_start >= this.previousVideosDurations && (curSituation.situation_start - this.previousVideosDurations) < this.getVideoDuration()) {
                    if(curSituation.uuid != null && curSituation.situations_end != 0) {

                        var markerWidth = ((this.situationDuration(curSituation) / this.getVideoDuration()) * length) + "px"
                        var left = ( (curSituation.situation_start - this.previousVideosDurations) / this.getVideoDuration() * 100)+'%';
                        var situation_start = this.getSituationVideoTimestamp(curSituation)

                        var divElement = document.createElement('div')

                        divElement.className = "frameMarker vjs-marker"
                        var spanElement = document.createElement("span");
                        spanElement.innerHTML = curSituation.label[0]

                        divElement.appendChild(spanElement);
                        divElement.style="width: " + markerWidth + "; left: " + left
                        divElement.setAttribute("data-time", situation_start)
                        p.appendChild(divElement);
                    }
                }
            }
        },
        makeMarker(left, situation_start) {
            var player = this.$refs.videoPlayer.$player
            var p = player.$el.querySelector('.vcp-progress')
            var divElement = document.createElement('div')

                divElement.className = "newFrameMarker vjs-marker"
                var spanElement = document.createElement("span");
                spanElement.innerHTML = this.newSituation.label

                /*if(markers[i].situationType == "yellow-card") {
                    var iconElement = document.createElement('i')
                    iconElement.className = "fas fa-futbol"
                    divElement.appendChild(iconElement)
                }*/
                divElement.appendChild(spanElement);
                divElement.style="left: " + left
              

                divElement.setAttribute("data-time", situation_start)
                //divElement.addEventListener( 'onclick', this.setTime(this.match.situations[i].situation_start) );
                
        
                p.appendChild(divElement);
        },
        showCurrentEdit() {
            var curSituation = this.newSituation
            var left = null
            var situation_start = 0
            if(curSituation == null || (!curSituation.first_fixed && !curSituation.second_fixed )) {
                //do nothing
            }
            else if(!curSituation.first_fixed) {
                
                left =( curSituation.situation_end/ this.getVideoDuration() * 100)+'%';
                situation_start = curSituation.situation_end
                this.makeMarker(left, situation_start)
                
            }
            else if(!curSituation.second_fixed) {
                left =( curSituation.situation_start/ this.getVideoDuration() * 100)+'%';
               
                situation_start = curSituation.situation_start
                this.makeMarker(left, situation_start)
            }
            else {
                var player = this.$refs.videoPlayer.$player
                var p = player.$el.querySelector('.vcp-progress')
                var length = player.$el.querySelector('.vcp-progress').clientWidth
                var markerWidth = ((this.situationDuration(curSituation) / this.getVideoDuration()) * length) + "px"
                        left = ( (curSituation.situation_start - this.previousVideosDurations) / this.getVideoDuration() * 100)+'%';
                        situation_start = this.getSituationVideoTimestamp(curSituation)

                        var divElement = document.createElement('div')

                        divElement.className = "newFrameMarker vjs-marker"
                        var spanElement = document.createElement("span");
                        spanElement.innerHTML = curSituation.label[0]

                        divElement.appendChild(spanElement);
                        divElement.style="width: " + markerWidth + "; left: " + left
                        divElement.setAttribute("data-time", situation_start)
                        p.appendChild(divElement);
            }
            
        },
        onPlayerLoadeddata() {
            this.videoLengthArr[this.selectedVideoIndex] = this.getVideoDuration()
            this.refreshAllMarkers()
        },
        onPlayerTimeupdate(a) {
            this.$emit('playing', a.target.currentTime + this.previousVideosDurations)
            this.curtime = a.target.currentTime
        },
        onPlayerCanplay() {

        },
        onPlayerCanplaythrough() {

        },
        setTime(time, vidIndex = this.selectedVideoIndex) {
            if(time == Infinity) {return}
            this.selectedVideoIndex = vidIndex
            this.$refs.videoPlayer.$player.$video.currentTime = time
        },
        getVideoDuration() {
            return this.$refs.videoPlayer.$player.$video.duration
        },
        refreshAllMarkers() {
            this.removeNewSituationEdit()
            this.showSituationsFrames()
            this.showCustomMarkers()
            this.showCurrentEdit()
            if(this.match.shortVideosFinished) {
                this.showSelectedSecond()
            }
            this.showExcludedFrames()

        },
        removeNewSituationEdit() {
            var elements = document.getElementsByClassName("vjs-marker");
            while(elements.length > 0){
                elements[0].parentNode.removeChild(elements[0]);
            }
        },
        getVideoLengthArr() {
            return this.videoLengthArr
        }
      
    }
}

</script>

<style scoped>

.play-pause-layer {
    z-index: -5 !important;
}
.play-pause-layer .btn-control {
    display: none!important;
}

.video-js {
    position: relative !important;
    width: 100% !important;
    height: auto !important;
}
.vjs-tech {
    position: relative !important;
}
.vjs-poster {
    position: absolute !important;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.frameMarker {
    background: yellow !important;
} 

.excludedFrameMarker {
    background: black !important;
} 

.newFrameMarker {
    background: green !important;
}

.vjs-marker
{
  position:absolute;

  width:5px;
  height:110%;
  top:-5%;
  z-index:30;
  margin-left: 0px;
}

.yellow-card {
    background: yellow;
}

.vjs-marker:hover span 
{
  opacity:1;
}

.vjs-marker span
{
  position:absolute;
  bottom:15px;
  opacity:0;
  margin-left:-20px;
  z-index:90;
  background:rgba(0,0,0,.8);
  padding:8px;
  font-size:15px;
}
</style>