<template>
<div>


<ul class="list-group">

    <li v-for="(sit, index) in match.situations.slice().reverse()" v-bind:key="index" class="list-group-item" @click="situationClick(sit)">
        <!--<i v-if="sit.label=='Schuss'" class="fas fa-futbol text-info mx-2"></i>-->
        <i v-bind:class="{

            'fas fa-futbol': sit.label=='Tor',
            'fas fa-arrow-up': sit.label=='Schuss',
            'fas fa-arrows-alt-h': sit.label=='Konter',
            'fas fa-exclamation': sit.label=='Freistoß',
            'fas fa-exclamation': sit.label=='Elfmeter',
            'fas fa-exclamation': sit.label=='Eckstoß',
            'fas fa-exclamation': sit.label=='Interessante Situation',

            'text-danger': sit.isCreatorSituation==false,
            'text-success': sit.isCreatorSituation==true,
            
        }"></i>
        {{sit.label[0]}} {{Number(getMinute(sit)) + Number(1)}}'
    </li>

</ul>

</div>
</template>

<style scoped lang="scss">
@import '~bootstrap/scss/bootstrap.scss'; 

</style>

<script>
import { kick17 } from '@/services';

export default {
    components: {
        
    },
    data() {
        return {

        }
    },
    mounted() {

    },
    async created () {
    },
    computed: {
        match: function() {
            return this.$store.getters.getMatch
        }
    },
    methods: {
        getMinute(situation) {
            return Number(kick17.getMinuteOfSituation(situation, this.match))
            /*var matchBegin = new Date(this.match.match_date)
            var diffBetweenHalfs = (new Date(this.match.second_half) - matchBegin) / 1000
            var seconds = situation.situation_start > diffBetweenHalfs ? (situation.situation_start - diffBetweenHalfs) + (45 * 60) : situation.situation_start
            return (seconds/(60)).toFixed(0)*/
        },
        situationClick(situation) {
            this.$emit('situationClick', situation)
        }
    }
}

</script>