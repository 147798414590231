import { createStore } from 'vuex'
import { analytics } from '@/services';

// Create a new store instance.
const store = createStore({
  state () {
    return {
      count: 0,
      //match: null,
      matchList: [],
      now: null,
      team: null,
      match_uuid: null,
      situationUuid: null,
      menuOpened: false,
      dateRange: [],
      dateList: []
    }
  },

  mutations: {
    setMenuOpened (state, val) {
      state.menuOpened = val
    },
    setRange(state, range) {
      state.dateRange = range
      
    },
    setNow (state) {
      state.now = new Date()
    },
    setTeam (state, team) {
      state.team = team
    },
    increment (state) {
      state.count++
    },
    setMatch (state, match) {
      if(match.situations != null && match.situations.length > 1) {
        
        match.situations = match.situations.sort(function(a, b){
            return a.situation_start - b.situation_start;
        })
      }
      this.commit('setMatchList', [match])
    },
    setMatchList (state, matchList) {
      var tmpMatchList = matchList
      matchList.forEach(function(match, matchIndex) {

        match.situations.forEach(function(sit, sitIndex) {
        
            sit.situationPositions.forEach(function(sitpo, sitPoIndex) {
              if(sitpo.action_labels.includes("Pass") || sitpo.action_labels.includes("Flanke")) {
                tmpMatchList[matchIndex].situations[sitIndex].situationPositions[sitPoIndex].action_labels =  sitpo.action_labels.concat("Zuspiel")
                var passSuccessful = analytics.isPassSuccessful(sit, sitpo)
                tmpMatchList[matchIndex].situations[sitIndex].situationPositions[sitPoIndex].action_labels = passSuccessful && !sitpo.action_labels.includes("Angekommen") ? sitpo.action_labels.concat("Angekommen") : sitpo.action_labels
                tmpMatchList[matchIndex].situations[sitIndex].situationPositions[sitPoIndex].action_labels = passSuccessful && analytics.isPassAssist(sit, sitpo) ? sitpo.action_labels.concat("Assist") : sitpo.action_labels
              }
            })
            tmpMatchList[matchIndex].situations[sitIndex].situationPositions = tmpMatchList[matchIndex].situations[sitIndex].situationPositions.sort(function(a, b){
              return a.second - b.second;
            })
        })
      })
      state.matchList = tmpMatchList.sort(function(a, b){
        return new Date(a.match_date) - new Date(b.match_date);
      })
      var dates = []
      state.matchList.forEach(m => {
        var d = new Date(m.match_date)
				dates.push(d)
      })
      //state.dateList = [...new Set(dates)]
      state.dateList  = dates
      .map(function (date) { return date.getTime() })
      .filter(function (date, i, array) {
          return array.indexOf(date) === i;
       })
      .map(function (time) { return new Date(time); });
      console.log("state.dateList", state.dateList)
			//return [...new Set(dates)]
    },
    setMatchUuid (state, match_uuid) {
      state.match_uuid = match_uuid
    },
    addVideoToMatch (state, video) {
      state.match.videos.push(video)
    },
    selectSituationUuid (state, situationUuid) {
      state.situationUuid = situationUuid
    }
  },
  getters: {
    getMenuOpened(state) {
      return state.menuOpened
    },
    getMatch(state) {
      var m = state.matchList.find(m => m.uuid == state.match_uuid)
      return m
    },
    getMatchList(state) {
      var res = state.dateRange.length == 0 ? state.matchList : state.matchList.filter(m => {
        var mDate = new Date(m.match_date)
        return mDate.getTime() >= state.dateRange[0].getTime() && mDate.getTime() <= state.dateRange[1].getTime()
      })
      return res //state.matchList
    },
    getSelectedSituation(state, getters) {
      var m = getters.getMatch
      return m.situations.find(s => s.uuid == state.situationUuid)
    },
    getAllSituations(state, getters) {
      var situations = []
      var match = getters.getMatch
      if(match) {
        return match.situations
      }
      //var matchList = match != null ? getters.getMatchList : [match]
      //console.log(match)
			getters.getMatchList.forEach(m => {
        if(m.situationsReady == true){
          situations = situations.concat(m.situations)
        } 
      })
      return situations
    },
    getAllSituationPositions(state, getters) {
        var allSituationPositions = []
        getters.getAllSituations.forEach(sit => allSituationPositions = allSituationPositions.concat(sit.situationPositions))
        return allSituationPositions
    },
    getNow(state){
      return state.now
    },
    getTeam(state) {
      return state.team
    },
    getPlayersWithShots(state, getters) {
      var situationPositions = getters.getAllSituationPositions.filter(sitpo => sitpo.isInCreatorTeam == true && sitpo.action_labels != null && sitpo.action_labels.length > 0 && sitpo.action_labels.includes("Schuss"))
      var playerUUIDs = situationPositions.map(sitpo => sitpo.player_uuid)
      return state.team.players.filter(p => playerUUIDs.includes(p.uuid))
    },
    getPlayersWithShotsOnTarget(state, getters) {
      var situationPositions = getters.getAllSituationPositions
      situationPositions = situationPositions.filter(sitpo => sitpo.isInCreatorTeam == true && sitpo.action_labels != null && sitpo.action_labels.length > 0 && sitpo.action_labels.includes("Torschuss"))
      var playerUUIDs = situationPositions.map(sitpo => sitpo.player_uuid)
      return state.team.players.filter(p => playerUUIDs.includes(p.uuid))
    },
    getPlayersWithGoals(state, getters) {
      var situationPositions = getters.getAllSituationPositions

      situationPositions = situationPositions.filter(sitpo => sitpo.isInCreatorTeam == true && sitpo.action_labels != null && sitpo.action_labels.length > 0 && sitpo.action_labels.includes("Tor"))
      var playerUUIDs = situationPositions.map(sitpo => sitpo.player_uuid)
      return state.team.players.filter(p => playerUUIDs.includes(p.uuid))
    },
    getPlayersWithPass(state, getters) {
      var situationPositions = getters.getAllSituationPositions

      situationPositions = situationPositions.filter(sitpo => sitpo.isInCreatorTeam == true && sitpo.action_labels != null && sitpo.action_labels.length > 0 && (sitpo.action_labels.includes("Pass") || sitpo.action_labels.includes("Flanke")))
      var playerUUIDs = situationPositions.map(sitpo => sitpo.player_uuid)
      return state.team.players.filter(p => playerUUIDs.includes(p.uuid))
    },
    getPlayersWithAssist(state, getters) {
      var situationPositions = getters.getAllSituationPositions

      situationPositions = situationPositions.filter(sitpo => sitpo.isInCreatorTeam == true && sitpo.action_labels != null && sitpo.action_labels.length > 0 && sitpo.action_labels.includes("Assist"))
      var playerUUIDs = situationPositions.map(sitpo => sitpo.player_uuid)
      return state.team.players.filter(p => playerUUIDs.includes(p.uuid))
    },
    getDateList(state) {
      return state.dateList
      
    }
  }
})

export default store