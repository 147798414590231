<template>
<div>

    <Dialog header="Club hinzufügen" v-model:visible="teamModalShow" :style="{width: '50vw'}" :modal="true">

        <Dropdown v-model="selectedAgeband" :options="agebands" optionLabel="name"  placeholder="Altersklasse" />
        <Dropdown v-model="selectedTeamRang" :options="teamranks" optionLabel="name"  placeholder="Team" />
        

        <template #footer>
            <Button label="Abbrechen" icon="pi pi-times" @click="teamModalShow = false" class="p-button-text"/>
            <Button label="Hinzufügen" icon="pi pi-check" @click="addTeam" autofocus />
        </template>
    </Dialog>

<Toast />

    <DataTable :value="users" responsiveLayout="scroll" dataKey="id">
        <Column field="email" header="user"></Column>
        <Column field="uid" header="uid"></Column>
        <Column field="emailVerified" header="verified">
            <template #body="user">
                <div v-if="user.data.emailVerified == true">true</div>
                <div v-else><Button @click="userVerify(user.data.uid)" label="Verify" class="p-button-rounded"/></div>
            </template>
        </Column>
        <Column header="Club">
            <template #body="user">
                {{getClubName(user.data)}}
            </template>
        </Column>

        <Column header="Role">
            <template #body="slotProps">
                current: {{slotProps.data.customClaims.role != null ? slotProps.data.customClaims.role : "None"}}
                <Dropdown v-model="roleHelper[slotProps.index]" :options="roles" optionLabel="name"/>
                <Button icon="pi pi-save" class="p-button-rounded p-button-outlined" @click="changeRoles(users[slotProps.index], roleHelper[slotProps.index])"/>
            </template>
            
        </Column>
        <!--<Column header="Teams">
            <template #body="user">
                <a @click="teamModalShow = !teamModalShow; selectedUser = user.data" onmouseover="" style="cursor: pointer;">
                    <i class="far fa-edit" ></i>
                </a>
            </template>
        </Column>-->
    </DataTable>


     
</div> 

</template>


<script>
import {
    admin
} from '@/services';
import {
    kick17
} from '@/services';

export default {


    name: 'app',
    components: {},
    data() {
        return {
            users: [],
            roles: [],
            roleHelper: [],
            teamlist: [],
            teamModalShow: false,
            modal: {
                firstName: "",
                lastName: "",
                shirtnumber: "",
                altersklasse: {
                    name: "Altersklasse"
                },
                team: "Team",
                club: {
                    name: "Verein"
                }
            },
            agebands: kick17.getAgebands().data,
            teamranks: kick17.getAllTeamClasses().data,
            selectedAgeband: null,
            selectedTeamRang: null,
            selectedUser: null,
            selectedrole: null
        }
    },
    async mounted() {

    },
    async created() {
        var rolesResult = await admin.getallroles()
        this.roles = [{
            name: "None"
        }]
        this.roles = this.roles.concat(rolesResult.data)
        //this.roles = this.roles.concat({name: "None"})
        //var res = await admin.makeadmin()
        await this.loadUsers()
        this.fillRoleHelper()

        var result = await kick17.getAllClubs()
        this.teamlist = result.data



    },
    async beforeMount() {


    },

    methods: {
        async loadUsers() {
            var result = await admin.getallusers()
            this.users = result.data
        },
        async userVerify(userid) {
            admin.verifyuser(userid)
        },
        fillRoleHelper() {
            this.roleHelper = []
            for (var i = 0; i < this.users.length; i++) {


                var role = this.users[i].customClaims["role"] != null ? this.users[i].customClaims["role"] : "None"
                var newRole = {
                    "name": role
                }
                this.roleHelper = this.roleHelper.concat(newRole)
                //console.log(this.roleHelper)
            }

        },
        isRoleInClaims(user, role) {
            var myRoles = user.customClaims
            const keys = Object.keys(myRoles)
            for (var i = 0; i < keys.length; i++) {
                if (keys[i] == role) {
                    return myRoles[keys[i]];
                }
            }
            return false
        },
        async changeRoles(user, role) {
            //console.log(user)
            //console.log(roles)
            var res = await admin.changeRoles(user, role.name)
            if (res.status == 200) {
                this.$toast.add({
                    severity: 'success',
                    summary: 'Role Changed',
                    detail: 'Role Changed',
                    life: 3000
                });
            } else {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Role not changed',
                    detail: 'Role not changed',
                    life: 3000
                });
            }
        },
        async changeclub(userIndex, club) {
            var tmp = JSON.parse(JSON.stringify(this.users[userIndex]))
            await kick17.assignclub(tmp.uid, club.uuid)
            await this.loadUsers()

        },
        getClubName(user) {

            //var claims = JSON.parse(JSON.Stringify(user.customClaims))
            var team_id = user.customClaims.team_id === undefined ? null : user.customClaims.team_id
            if (team_id == null) {
                return "Vereinslos"
            }
            var name = ""
            for (var i = 0; i < this.teamlist.length; i++) {
                name = this.teamlist[i].uuid == team_id ? this.teamlist[i].clubname : name
            }

            return name
        },

        addTeam() {
            alert(JSON.stringify(this.selectedUser))
            var teamName = this.selectedAgeband + " " + this.selectedTeamRang
            kick17.assignTeam(this.selectedUser.uid, teamName)
            this.selectedAgeband = null
            this.selectedTeamRang = null
        },
        getAgeGroupModalText() {
            return this.selectedAgeband == "" ? "Altersklasse" : this.selectedAgeband
        },
        getTeamRangText() {
            return this.selectedTeamRang == "" ? "Team" : this.selectedTeamRang
        }


    }
}
</script>

<style>



</style>

