<template>
<div>

    <div id="feedbackBanner" class="bg-cover py-5 text-center" style="border-radius: 0;"></div>
        <div class="">
            <div class="layout-main m-auto md:col-7" style="padding-left: 2rem; padding-right: 2rem; padding-top: 2rem;">
                <div class="grid">

                    <div style="width: 100%;" v-if="feedbackFormVisible" >
                    
                        <h5 class="card-title text-center">{{$t('feedback.title')}}</h5>
                        <div class="text-center" v-html="$t('feedback.description')"></div>
                    </div>

                    <div class="col-12 py-5 md:col-6 lg:col-6 xl:col-3" style="width: 100%;" v-if="feedbackFormVisible">  
                        <div class="" >
                            <h5 class="card-title text-left">{{$t('feedback.your_feedback')}}</h5>
                            <div class="form-signin form-inline">

                                <div class="">
                                    <Textarea id="input" v-model="input" :autoResize="true" rows="6" style="width: 100%;"/>
                                    <Button class="p-button-outlined p-button-secondary" @click="senden()">{{$t('feedback.send')}}</Button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 py-5 md:col-6 lg:col-6 xl:col-3" style="width: 100%;"  v-else>
                        <div class="" >
                            <h5 class="card-title text-center">{{$t('feedback.thanks')}}
                                <i class="far fa-paper-plane" style="color: green;"></i>      
                            </h5>
                            <div class="text-center">{{$t('feedback.positive_negative_feedback')}}</div>
                        
                            <div class="text-center py-3">
                                <Button class="p-button-outlined p-button-secondary" @click="feedbackFormVisible=true">{{$t('feedback.more_feedback')}}</Button>
                            </div> 
                        </div>

                    </div>
                </div>

      
    
      
        
        


        </div>
      </div>
		</div>






</template>


<script>
import { kick17 } from '@/services';

  export default {
    components: {
      
    },
    
    data () {
      return {
          feedbackFormVisible: true,
          input: ""
      }
    },
    async created () {


    },
    computed: {

    },
    methods: {
        async senden() {
            if(this.input == null || this.input.replace(/\s/g, "") == "") {
                //Swal.fire("Fehler", "Bitte Text eingeben", "error")
            } else {
                await kick17.addfeedback(this.input)
                this.feedbackFormVisible = false
            }
            
        }
    }
  }
</script>

<style>


#feedbackBanner {
  background: url('../../assets/feedback-banner.jpg') black no-repeat center center scroll;
  height: 200px;
}
</style>