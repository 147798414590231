export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добрый день"])},
  "dictionary": {
    "goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ворота"])},
    "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цель"])},
    "shots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выстрелы"])},
    "shot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["выстрелил"])},
    "shots_on_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выстрелы"])},
    "shot_on_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выстрелил"])},
    "corners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Угловые удары"])},
    "corner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["угол"])},
    "attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["градусы"])},
    "attempts_on_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завершение на воротах"])},
    "headers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Головные шары"])},
    "headers_on_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Головные шарики на воротах"])},
    "header_goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заголовок"])},
    "opponent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Противник"])},
    "assists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помогает"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить на компьютере"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прервать"])},
    "all_opponents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все противники"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дом"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обратная связь"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выйти"])},
    "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["отпечаток"])},
    "dataprivacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конфиденциальность"])},
    "agbs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общие положения и условия"])},
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все права защищены"])}
  },
  "sidemenu": {
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приборная доска"])},
    "matches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Встречи"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["команда"])}
  },
  "home": {
    "registrationBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрируйтесь бесплатно"])},
    "header": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получите лучшее из своей команды"])},
      "line1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Планируйте и проанализируйте свою игру, как профессионалы."])},
      "line2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш"])},
      "line2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["С новой точки зрения"])},
      "rotation": [
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["команда"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видео -анализ"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Особенности"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прогресс"])},
        (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["команда"])}
      ]
    },
    "benefits": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что вам предлагает Kick17?"])},
      "summary_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы хотели бы перейти к следующей лиге с вами и вашей командой."])},
      "summary_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kick17 предлагает вам современные средства для каждого этапа - до, во время и после игры."])},
      "video": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваше видео -краткое изложение"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нарисуйте игру с помощью произвольной камеры </b> и загрузите видео. \n Наш алгоритм создает краткое изложение самых захватывающих сцен для вас. \n Таким образом, вы можете добраться до своей команды и не нужно долго искать. \n  В дополнение к автоматически распознанным ситуациям, можно интегрировать ситуации, которые вы определили в резюме. Используйте <b> Kick17-timer </b> на краю поля и все еще используйте маркер, пока ситуация происходит вживую."])}
      },
      "report": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш игровой отчет"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В дополнение к сводке вы получаете статистику о выстрелах и возможностях, значениях XG-LITE в прямом сравнении с командой противника. Вот как вы узнаете, у кого были лучшие шансы и где ваша команда догоняет."])}
      },
      "progress": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анализ и прогресс в команде"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хотели бы вы знать, правильно ли ваше интуитивное чувство о производительности команды? \n  Панель инструментов Kick17 показывает ваши успехи в виде статистики и тепловых карт."])}
      }
    },
    "howto": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как работает Kick17?"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Письма «ki» от «Kick17» обозначают <b> искусственный интеллект </b>. \n Ваша видеозапись последней встречи автоматически сокращается и проанализирована. <br/> Вам нужно только сделать следующее:"])},
      "camera": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка Action Cam"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создайте <strong> произвольный Action Cam </strong> (например, GoPro) в стороне и начните записывать игру очень удобно. Камера не нужна. Kick17 работает с каждой камерой, так что вы пощадили высокие инвестиционные затраты."])}
      },
      "upload": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вверх видео"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вот и все. Kick17 делает для вас все! Ваше видео -краткое изложение и анализ будут разработаны автоматически."])}
      }
    },
    "prices": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цены"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сколько это вам стоит?"])},
      "subscriptions": {
        "amateur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новичок"])},
        "basis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["База"])},
        "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["профессионал"])},
        "month_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<Span class = \"Текст-мрачный шрифт-нормный ML-2\">/ месяц </ span>"])},
        "individual_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<Span class = \"Текстовый шрифт-нормный масса ML-2\"> отдельное предложение </span>"])},
        "teams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Команда"])},
        "matches_per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Игры / месяц"])},
        "situation_marker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша ситуация маркер"])},
        "video_summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видео резюме"])},
        "match_statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Играйте статистику"])},
        "saison_statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статистика сезона"])},
        "player_statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статистика игрока"])},
        "competitor_analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конкурентный анализ"])}
      },
      "tryout": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Попробуйте бесплатно"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы хотите узнать больше? Для получения более подробной информации свяжитесь с нами. \n Мы рады показать более подробную информацию и проанализировать первое видео для вас бесплатно."])}
      },
      "abo_details": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробная информация"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Годовые подписки могут быть завершены в течение 10 или 12 месяцев."])}
      }
    }
  },
  "matchlist": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добро пожаловать"])},
    "nomatches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До сих пор у вас не было встреч."])},
    "opponent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Противник"])},
    "createfirstmatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создайте первую встречу"])},
    "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подать мяч"])},
    "at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Около"])},
    "startunknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начало игры неизвестно"])},
    "finished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Игра закончена"])},
    "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Игра работает"])}
  },
  "match": {
    "video_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Встреча с %s на"])},
    "video_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["комментарий"])},
    "video_share": {
      "clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скопировать ссылку в буфер обмена"])},
      "newtab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открыть видео в новой вкладке"])},
      "instagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram доступен скоро"])},
      "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook доступен в ближайшее время"])}
    },
    "situations": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ситуации"])},
      "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цель"])},
      "freekick": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Штрафной удар"])},
      "interesting_situation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интересная ситуация"])},
      "attempt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["выстрелил"])}
    },
    "report": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Игровой отчет"])},
      "goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ворота"])},
      "is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["является"])},
      "should": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["цель"])},
      "attempts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выстрелы"])},
      "on_target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выстрелы"])},
      "corners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Угловые удары"])}
    },
    "corners": {
      "left_side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Левая сторона"])},
      "right_side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["правая сторона"])}
    },
    "attempts": {
      "players_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для этого действия не было найдено ни одного игрока"])}
    }
  },
  "expected_goals": {
    "xg_curve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XG Curve"])},
    "expected_goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидаемые цели"])},
    "minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["минута"])},
    "my_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Моя команда"])}
  },
  "dashboard": {
    "myteam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Моя команда"])},
    "analyzed_matches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Захваченные встречи"])},
    "analyzed_situations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проанализированы ситуации"])},
    "last_matches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последние встречи"])}
  },
  "feedback": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы хотим улучшить"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расскажите нам, что мы можем сделать лучше </strong> или желать вам <strong> новых функций. </Strong> <br> Мы серьезно относимся к вашему запросу и быстро реагируем с улучшениями."])},
    "your_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш отзыв"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправлять"])},
    "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спасибо за ваш отзыв"])},
    "positive_negative_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы с нетерпением ждем как позитивной, так и отрицательной критики."])},
    "more_feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Больше отзывов?"])}
  }
}