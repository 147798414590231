<template>
<div>

    <Dialog :header="(clickedSituation != null ? clickedSituation.label[0] : '') + ' ' + (clickedSituation != null ? Number(getMinute(clickedSituation)) + Number(1) +'\'' : '')" v-model:visible="displayBasic" :dismissableMask="true" :style="{width: '50vw'}" :modal="true">
        <div class="container">
            <div class="row">
                <Dropdown v-if="team != null" v-model="selectedPlayer" emptyMessage="keine Spieler gefunden" :options="team.players" optionLabel="uuid" placeholder="Spieler">
                    <template #option="slotProps">
                        <div class="country-item">
                            <div>{{slotProps.option.firstname.charAt(0)}}. {{slotProps.option.lastname}}</div>
                        </div>
                    </template>
                    <template #value="slotProps" >
                        <div v-if="slotProps.value">{{slotProps.value.firstname.charAt(0)}}. {{slotProps.value.lastname}}</div>
                    </template>
                </Dropdown>

            </div>
            Aktionen:
            <div class="row">
                <div>
                    <Chip v-for="(label, index) in clickedSituationPosition.action_labels" v-bind:key="index">
                        <span style="font-size: smaller;">{{label}}</span>
                    </Chip>
                </div>
            </div>
                
            </div>
        
        <template #footer>
            <Button label="Abbrechen" icon="pi pi-times" @click="unclickAvatar()" class="p-button-secondary p-button-text"/>
            <Button label="Speichern" icon="pi pi-check" @click="updateSitpo()" class="p-button-text" :loading="loading"/>
        </template>
    </Dialog> 

    <div v-for="(sit, index) in sortedSituations" style="cursor: pointer;" v-bind:key="index" class="card" :id="index"  @click="situationClick(sit)" v-bind:style="selectedSituation == null && index == 0 ? 'background-color: aliceblue;' : selectedSituation==sit.uuid ? 'background-color: aliceblue;' : ''">
        <div class="row">
            <div class="col-2 col-sm-2 col-md-2">
                <Avatar class="p-mr-2" size="large" shape="circle" > 
                    <i v-bind:class="{

                        'fas fa-futbol': sit.label=='Tor' || sit.label=='Gegentor',
                        'fab fa-hubspot': sit.label=='Schuss' || sit.label=='Schuss (Gegner)',
                        'fas fa-exchange-alt': sit.label=='Konter',
                        'fas fa-level-up-alt': sit.label=='Freistoß',
                        'fas fa-exclamation': sit.label=='Elfmeter',
                        'fas fa-flag-checkered': sit.label=='Eckstoß',
                        'fas fa-exclamation': sit.label=='Interessante Situation' || sit.label=='Gefährliche Situation' || sit.label=='Chance',
                        'fas fa-flag-checkered text-yellow-500': sit.label=='Abseits',
                        'text-danger': sit.evaluation=='negative',
                        'text-green-300': sit.evaluation=='positive',
                        
                    }" ></i>
                </Avatar>
            </div>
            <div class="col-8 col-sm-8 col-md">
                <h6>{{sit.label[0]}} {{Number(getMinute(sit)) + Number(1)}}'</h6>
                
                <span v-if="sit.situationPositions.length > 0 && team != null">
                    <Chip v-for="(sitpo, index) in getSituationPositionActors(sit.situationPositions)" v-bind:key="index" @click="clickAvatar(sitpo, sit)" v-tooltip.top="sitpo.lastname == null || sitpo.lastname == '' ? sitpo.action_labels[0] + ' durch Unbekannt' : sitpo.action_labels[0] + ' durch ' + sitpo.lastname" >
                        <i v-bind:class="{
                            'fas fa-futbol': sitpo.action_labels.includes('Tor'),
                            'fas fa-th-large': sitpo.action_labels.includes('Block'),
                            'fas fa-bolt': sitpo.action_labels.includes('Schuss') || sitpo.action_labels.includes('Torschuss'),
                            'fas fa-flag-checkered': sitpo.action_labels.includes('Eckstoß'),
                            'fas fa-hand-paper': sitpo.action_labels.includes('Ballbesitz'),
                            'fas fa-exclamation-circle': sitpo.action_labels.includes('Freistoß'),
                            'fas fa-compress-alt': sitpo.action_labels.includes('Pass') || sitpo.action_labels.includes('Flanke'),
                            'fas fa-flag-checkered text-yellow-50': sitpo.action_labels.includes('Abseits'),
                        }"></i> &nbsp;
                        <span style="font-size: smaller;" v-if="sitpo.number != 0">{{sitpo.number}} {{sitpo.lastname == null || sitpo.lastname == "" ? "??" : sitpo.lastname}}</span>
                        <span style="font-size: smaller;" v-else>Unbekannt</span>
                    </Chip>
                    
                </span>
            </div>
            <div class="col-2 col-sm-2 col-md-1">
                <div class="immo_card_icon">
                    <i class="fas fa-star" v-if="sit.isFavorite==true" style="color: gold;" ></i>
                    <i class="fas fa-comment-alt" v-if="sit.comment != null && sit.comment != ''"></i>
                </div>
                <!--<Button icon="pi pi-pencil" class="p-button-rounded p-button-secondary p-button-text"/>-->
            </div>
        

        
        </div>
        
    </div>



</div>
</template>

<style scoped lang="scss">
@import '~bootstrap/scss/bootstrap.scss'; 

.card {
    border-radius: 0.5rem;
}
</style>

<script>
import { getAnalytics, logEvent } from "firebase/analytics";
import { kick17 } from '@/services';

export default {
    components: {
        
    },
    props: ['selectedSituation'],
    data() {
        return {
            dialitems: [
                {
                    label: 'Add',
                    icon: 'pi pi-pencil',
                    command: (a) => {
                        alert(JSON.stringify(a))
                        this.$toast.add({ severity: 'info', summary: 'Add', detail: 'Data Added' });
                    }
                },
                {
                    label: 'Update',
                    icon: 'pi pi-trash',
                    command: () => {
                        alert("bye")
                        this.$toast.add({ severity: 'success', summary: 'Update', detail: 'Data Updated' });
                    }
                },
            ],
            displayBasic: false,
            clickedSituationPosition: null,
            clickedSituation: null,
            selectedPlayer: null,
            loading: false
        }
    },
    mounted() {

    },
    async created () {
    },
    computed: {
        sortedSituations: function() {
            var situations = this.match.situations.filter(sit => sit.videoNeeded == true)
            situations = situations.sort(function(a, b){
                return a.situation_start - b.situation_start;
            });
            return situations
        },
        
        match: function() {
            return this.$store.getters.getMatch
        },
        team: function() {
            return this.$store.getters.getTeam
        }
    },
    methods: {
        getChipIcon(sitpo) {
            console.log(sitpo)
            return "pi pi-apple"
        },
        test(event) {
            alert(JSON.stringify(event))
        },
        clickAvatar(sitpo, sit) {
            this.clickedSituationPosition = sitpo
            this.clickedSituation = sit
            this.selectedPlayer = this.team.players.find(p => p.uuid == sitpo.player_uuid)
            this.displayBasic = true
        },
        unclickAvatar() {
            this.clickedSituationPosition = null
            this.clickedSituation = null
            this.selectedPlayer = null
            this.displayBasic = false
        },
        async updateSitpo() {
            this.loading = true
            var res = await kick17.setPlayerToSituationPosition(this.match.uuid, this.clickedSituation.uuid, this.clickedSituationPosition.uuid, this.selectedPlayer.uuid)
            if(res.status == 200) {
                this.$store.commit('setMatch', res.data)
            }
            this.loading = false
            this.unclickAvatar()
        },
        getPlayerFromTeam(player_uuid) {
            //let players = this.match.creator_team.players
            return this.team.players.find(player => player.uuid == player_uuid)
        },
        getSituationPositionActors(situationPosition) {
            let actors = []
            let curPlayer = null
            let sortArr = ["Elfmeter", "Tor", "Torschuss", "Schuss", "Latte", "Pfosten", "Assist", "Kopfball", "Ballannahme", "Abseits", "Einwurf","Block", "Ballbesitz", "Ballverlust", "Ballgewinn", "Eckstoß","Freistoß", "Pass", "Flanke"]
             
            for(var i = 0; i < situationPosition.length; i++) {
                if(situationPosition[i].action_labels != null && situationPosition[i].action_labels.length > 0 && situationPosition[i].isInCreatorTeam == true) {
                    curPlayer = this.getPlayerFromTeam(situationPosition[i].player_uuid)
                    if(curPlayer != null) {
                        situationPosition[i].firstname = curPlayer.firstname == null ? "Unbekannt" : curPlayer.firstname
                        situationPosition[i].lastname = curPlayer.lastname == null ? "Unbekannt" : curPlayer.lastname
                    }
                    
                    var a = actors.some(act => act.player_uuid == situationPosition[i].player_uuid)
                    if(actors.length == 0 || a == false) {
                        actors.push(JSON.parse(JSON.stringify(situationPosition[i])))
                        if(actors.length >0) actors[actors.length - 1].action_labels.sort(function(a, b){  
                            return sortArr.indexOf(a) - sortArr.indexOf(b);
                        })
                    } else if(a == true) {
                        var ind = actors.findIndex(a => a.player_uuid == situationPosition[i].player_uuid)
                        //var a = ind ? actors[ind] : null
                        if(ind) {
                            actors[ind].action_labels = actors[ind].action_labels.concat(situationPosition[i].action_labels)
                            actors[ind].action_labels.sort(function(a, b){  
                                return sortArr.indexOf(a) - sortArr.indexOf(b);
                            }) 
                        }
                    }
                    
                    
                }
            }
            return actors
        },

        getMinute(situation) {
            return kick17.getMinuteOfSituation(situation, this.match)
            /*if(situation == null) {
                return null
            }
            var matchBegin = new Date(this.match.match_date)
            if(situation.custom_marker === undefined || situation.custom_marker == null) {
                return situation.situation_start > this.match.matchVideoFrame[2] ? ((situation.situation_start - this.match.matchVideoFrame[2])/(60) + 45).toFixed(0) : ((situation.situation_start - this.match.matchVideoFrame[0])/(60)).toFixed(0)
                
            } else {

                var diff = situation.custom_marker - matchBegin.getTime()
                return (diff/(1000*60)).toFixed(0)
            }*/
            
            
            
        },
        situationClick(situation) {
            //console.log(situation.label[0])
            const analytics = getAnalytics()
            logEvent(analytics, 'situationClick', {"label": situation.label[0], "situation": situation.uuid})
            this.$emit('situationClick', situation)
        }

    }
}

</script>