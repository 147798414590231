<template>
    


    <div v-if="allPlayersWithPositions != null">
        
            <table class="table borderless">
                
                <tbody>
                    

                    <tr>
                        <th scope="row"></th>
                        <th>Tore (Ist)</th>
                        <th>Tore (Soll)</th>
                        <!--<th>Tor<wbr>beteiligungen</th>-->
                        <th>Tor<wbr>schüsse</th>
                        <th>Assists</th>
                        
                    </tr>
                    <tr v-for=" (player, index) in playersProcessed" v-bind:key="index">
                        <th scope="row">{{player.lastname}}</th>
                        <td>{{player.goals}}</td><!--Tore-->
                        <td>{{player.xG}}</td> <!--xG-Lite-->
                        <!--<td>{{player.goalSituations }}</td>--><!--Torbeteiligungen-->
                        <td>{{player.attempts_on_goal}}</td> <!--Torschüsse-->
                        <td>{{player.assists}}</td><!--Assists-->
                       
                        

                    </tr>
                </tbody>
            </table>
    </div>


</template>


<style scoped lang="scss">
@import '~bootstrap/scss/bootstrap.scss';
    .clublogo {
        width: 40px;
        height: 40px;
    }

    .table td{
        text-align: center;   
    }

    .table th{
        text-align: center;   
    }

    .borderless td, .borderless th {
        border: none;
    }

</style>

<script>
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
    setup() {

        const store = useStore()



        var matchList = computed(() => {
            return store.getters.getMatchList
        })

        var myteam = computed(() => {
            return store.getters.getTeam
        })

        /*var allSituations = computed(() => {
            var situations = []
            matchList.value.forEach(m => situations = situations.concat(m.situations))
            return situations
        })*/

        var allSituations = computed(() => {
            return store.getters.getAllSituations 
        })

        var allMySituations = computed(() => {
            return allSituations.value.filter(sit => {
                var sitpos = sit.situationPositions.filter(sitpo => sitpo.isInCreatorTeam == true && sitpo.action_labels != null && sitpo.action_labels.length > 0)
                return sitpos.length > 0
            })
        })


        var allMySituationPositions = computed(() => {
            var situationPositions = []
            allAttackingSituations.value.forEach(m => {
                var curPositions = m.situationPositions
                curPositions.forEach(c => c.situation_uuid = m.uuid)
                situationPositions = situationPositions.concat(curPositions)
            })
            return situationPositions
        })

        var allPlayersWithPositions = computed(() => {
            if(myteam.value == null) {return []}
            var players = myteam.value.players
            players.forEach(player => {
                player.positions = allMySituationPositions.value.filter(sitpo => sitpo.player_uuid == player.uuid)
            })
            return players.filter(p => p.positions != null && p.positions.length != 0)
        })

        var allAttackingSituations = computed(() => {
            var attackingSituations = allMySituations.value.filter(sit => {
                
                var sitpos = sit.situationPositions.filter(sitpo => sitpo.isInCreatorTeam == true && (sitpo.action_labels.includes("Schuss") || sitpo.action_labels.includes("Torchuss") || sitpo.action_labels.includes("Tor")))
                return sitpos.length > 0
            })
            return attackingSituations
        })

        var goalSituations = computed(() => {

            var goalSits = allAttackingSituations.value.filter(sit => {
                var sitpos = sit.situationPositions.filter(sitpo => sitpo.isInCreatorTeam == true && sitpo.action_labels.includes("Tor"))
                return sitpos.length > 0
            })
            return goalSits
        })

        const countGoalSituationsOfPlayer = function(player_uuid) {
            var count = 0
            goalSituations.value.forEach(sit => {
                var sitpos = sit.situationPositions.filter(sitpo => sitpo.isInCreatorTeam == true &&sitpo.player_uuid == player_uuid)
                count += sitpos.length > 0 ? 1 : 0
            })
            return count
        }

        const getSum = function (total, num) {
			return total + Math.round(num);
		}

        var playersProcessed = computed(() => {
            var pProcessed = []
            allPlayersWithPositions.value.forEach(p => {
                pProcessed = pProcessed.concat(
                    {lastname: p.lastname, 
                    goals: p.positions.filter(p => p.action_labels.includes("Tor")).length,
                    attempts: p.positions.filter(p => p.action_labels.includes("Schuss")).length,
                    attempts_on_goal: p.positions.filter(p => p.action_labels.includes("Torschuss")).length,
                    xG: p.positions.filter(p => p.xG != null).map(p => p.xG).reduce(function(pv, cv) { return pv + cv; }, 0).toFixed(2),
                    assists: p.positions.filter(p => p.action_labels.includes("Assist")).length,
                    goalSituations: countGoalSituationsOfPlayer(p.uuid)
                })
            })
            return pProcessed.filter(p => !(p.attempts_on_goal == 0 && p.assists == 0)).sort(sortByGoals)
        })
        
        const sortByGoals = function(a, b) {
			return  b.goals - a.goals || b.goalSituations - a.goalSituations || b.attempts_on_goal - a.attempts_on_goal || b.attempts - a.attempts  || b.assists - a.assists
		}

        return {
            allAttackingSituations,
            matchList,
            myteam,
            allPlayersWithPositions,
            getSum,
            countGoalSituationsOfPlayer,
            playersProcessed
        }


    }
});


</script>