<template>
    <div >

        <div class="flex justify-content-center flex-wrap card-container yellow-container header-blue bg-cover align-content-center text-white" style="backdrop-filter: blur(7px);">
          

          <div class="flex align-items-center justify-content-centeßr mx-6">
            <div style="backdrop-filter: blur(3px); color: black;">
              <h1 style="font-weight: bold;">{{ $t('home.header.title') }}</h1>
              <h2>{{ $t('home.header.line1') }}</h2>
              <div class="header-text">
                  <h2 class="topText words">
                  <div class="topText">{{ $t('home.header.line2_1') }}</div> 
                          <div class="topText wrapper" style="height:40px; overflow: hidden;">
                          
                            <span class="rotate_word" style="letter-spacing: 5px;">{{ $t('home.header.rotation[0]') }}</span>
                            <span class="rotate_word">{{ $t('home.header.rotation[1]') }}</span>
                            <span class="rotate_word" style="letter-spacing: 5px;">{{ $t('home.header.rotation[2]') }}</span>
                            <span class="rotate_word" style="letter-spacing: 5px;">{{ $t('home.header.rotation[3]') }}</span>
                            <span class="rotate_word" style="letter-spacing: 5px;">{{ $t('home.header.rotation[4]') }}</span>
                          </div>
                      <div class="topText">{{ $t('home.header.line2_2') }}</div>
                  </h2>
              </div>
              <Button class="p-button-success mt-5 p-button-lg" @click="routeToSignIn" id="topBtn">{{$t('home.registrationBtn')}}</Button>
    

              
              
            </div>
         
          </div>
        </div>



        <section class="features-icons bg-gray-50">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-7 text-center">
                  <h3 class="my-3 text-uppercase text-black">{{ $t('home.benefits.title') }}</h3>
                  <p>{{ $t('home.benefits.summary_1') }}<br/>
                  {{ $t('home.benefits.summary_2') }}
                  <br /> 
                  </p>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-lg-5 px-5 mx-auto"><img :src="videoplayerSVG" /></div>
              <div class="col-lg-6">
                <h2 class="font-weight-light">{{ $t('home.benefits.video.title') }}</h2>
                <p v-html="$t('home.benefits.video.description')"></p>

              </div>
            </div>

            <div class="row align-items-center mb-5">
              <div class="col-lg-6 order-2 order-lg-1">
                <h2 class="font-weight-light">{{ $t('home.benefits.report.title') }}</h2>
                <p class="">{{ $t('home.benefits.report.description') }}</p>
              </div>
              <div class="col-lg-5 px-5 mx-auto order-1 order-lg-2"><img :src="lineupSVG" /></div>
            </div>

            <div class="row align-items-center">
              <div class="col-lg-5 px-5 mx-auto"><img :src="dashboardSVG" /></div>
              <div class="col-lg-6">
                <h2 class="font-weight-light">{{ $t('home.benefits.progress.title') }}</h2>
                <p>{{ $t('home.benefits.progress.description') }}</p>
              </div>
            </div>

          </div>    
        </section>

        <video autoplay loop muted playsinline>
          <source :src="videoSource" type="video/mp4">
        </video>


        <section class="features-icons">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-7 text-center">
                  <h3 class="my-3 text-uppercase text-black">{{ $t('home.howto.title') }}</h3>
                  <p v-html="$t('home.howto.description')"></p>
              </div>
            </div>
            <div class="row align-items-center mb-5">
              <div class="col-lg-6 order-2 order-lg-1"><i class="fa fa-bar-chart fa-2x mb-3 text-primary"></i>
                <h2 class="font-weight-light">{{ $t('home.howto.camera.title') }}</h2>
                <p v-html="$t('home.howto.camera.description')"></p>
                
              </div>
              <div class="col-lg-5 px-5 mx-auto order-1 order-lg-2"><img :src="videographerSVG" /></div>
              </div>
              
              <div class="row align-items-center">
              <div class="col-lg-5 px-5 mx-auto"><img :src="uploadSVG" /></div>
              <div class="col-lg-6">
                <h2 class="font-weight-light">{{ $t('home.howto.upload.title') }}</h2>
                <p v-html="$t('home.howto.upload.description')"></p>
              </div>
            </div>

          </div>    
        </section>
    
        

        
        

 
        


    <!--<section class="bg-gray-50 py-5">
        <div class="container px-5 my-5">
            <div class="text-center mb-5">
                <h2 class="fw-bolder">{{ $t('home.prices.title') }}</h2>
                <p class="lead mb-0">{{ $t('home.prices.description') }}</p>
                <p class="lead mb-0"></p>
            </div>

        <div class="row text-center align-items-end">
      
          <div class="col-lg-4 mb-5 mb-lg-0">
            <div class="bg-white p-5 rounded-lg shadow">
              <h1 class="h6 text-uppercase font-weight-bold mb-4">{{ $t('home.prices.subscriptions.amateur') }}</h1>
              <h2 class="h1 font-weight-bold">60€<span class="text-small font-weight-normal ml-2" v-html="$t('home.prices.subscriptions.month_price')"></span></h2>

              <div class="custom-separator my-4 mx-auto bg-primary"></div>

              <ul class="list-unstyled my-5 text-small text-left font-weight-normal">
                <li class="mb-3">
                  <i class="fa fa-check mr-2 text-primary"></i>1 {{ $t('home.prices.subscriptions.teams') }}</li>
                <li class="mb-3">
                  <i class="fa fa-check mr-2 text-primary"></i>4 {{ $t('home.prices.subscriptions.matches_per_month') }}</li>
                <li class="mb-3">
                  <i class="fa fa-check mr-2 text-primary"></i> {{ $t('home.prices.subscriptions.situation_marker') }}</li>
                
                <li class="mb-3">
                  <i class="fa fa-check mr-2 text-primary"></i>{{ $t('home.prices.subscriptions.video_summary') }}</li>
                <li class="mb-3 text-muted">
                  <i class="fa fa-times mr-2"></i>
                  <del>{{ $t('home.prices.subscriptions.match_statistics') }}</del>
                </li>
                <li class="mb-3 text-muted">
                  <i class="fa fa-times mr-2"></i>
                  <del>{{ $t('home.prices.subscriptions.saison_statistics') }}</del>
                </li>
                <li class="mb-3 text-muted">
                  <i class="fa fa-times mr-2"></i>
                  <del>{{ $t('home.prices.subscriptions.player_statistics') }}</del>
                </li>
                <li class="mb-3">
                  <i class="fa fa-times mr-2"></i>
                  <del>{{ $t('home.prices.subscriptions.competitor_analysis') }}</del>
                </li>

              </ul>
              <div class="d-grid"><button type="button" class="btn btn-outline-primary"  @click="routeToSignIn" id="priceBtn1">{{ $t('home.registrationBtn') }}</button></div>
            </div>
          </div>


          <div class="col-lg-4">
            <div class="bg-white p-5 rounded-lg shadow">
              <h1 class="h6 text-uppercase font-weight-bold mb-4">Basis</h1>
              <h2 class="h1 font-weight-bold">120€<span class="text-small font-weight-normal ml-2" v-html="$t('home.prices.subscriptions.month_price')"></span></h2>

              <div class="custom-separator my-4 mx-auto bg-primary"></div>

              <ul class="list-unstyled my-5 text-small text-left font-weight-normal">
                <li class="mb-3">
                  <i class="fa fa-check mr-2 text-primary"></i>1 {{ $t('home.prices.subscriptions.teams') }}</li>
                <li class="mb-3">
                  <i class="fa fa-check mr-2 text-primary"></i>4 {{ $t('home.prices.subscriptions.matches_per_month') }}</li>

                <li class="mb-3">
                  <i class="fa fa-check mr-2 text-primary"></i> {{ $t('home.prices.subscriptions.video_summary') }}</li>
                
                <li class="mb-3">
                  <i class="fa fa-check mr-2 text-primary"></i>{{ $t('home.prices.subscriptions.video_summary') }}</li>
                <li class="mb-3">
                  <i class="fa fa-check mr-2 text-primary"></i>{{ $t('home.prices.subscriptions.match_statistics') }}</li>
                <li class="mb-3">
                  <i class="fa fa-check mr-2 text-primary"></i>{{ $t('home.prices.subscriptions.saison_statistics') }}</li>
                <li class="mb-3">
                  <i class="fa fa-check mr-2 text-primary"></i>{{ $t('home.prices.subscriptions.player_statistics') }}</li>
                <li class="mb-3">
                  <i class="fa fa-times mr-2"></i>
                  <del>{{ $t('home.prices.subscriptions.competitor_analysis') }}</del>
                </li>
              </ul>
              <div class="d-grid"><button type="button" class="btn btn-outline-primary"  @click="routeToSignIn" id="priceBtn2">{{ $t('home.registrationBtn') }}</button></div>
            </div>
          </div>
       

       
          <div class="col-lg-4">
            <div class="bg-white p-5 rounded-lg shadow">
              <h1 class="h6 text-uppercase font-weight-bold mb-4">Profi</h1>
              <h2 class="h1 font-weight-bold"><span class="text-small font-weight-normal ml-2" v-html="$t('home.prices.subscriptions.individual_price')"></span></h2>

              <div class="custom-separator my-4 mx-auto bg-primary"></div>

              <ul class="list-unstyled my-5 text-small text-left font-weight-normal">
                <li class="mb-3">
                  <i class="fa fa-check mr-2 text-primary"></i>X {{ $t('home.prices.subscriptions.teams') }}</li>
                <li class="mb-3">
                  <i class="fa fa-check mr-2 text-primary"></i>X {{ $t('home.prices.subscriptions.matches_per_month') }}</li>
                <li class="mb-3">
                  <i class="fa fa-check mr-2 text-primary"></i>{{ $t('home.prices.subscriptions.situation_marker') }}</li>
                <li class="mb-3">
                  <i class="fa fa-check mr-2 text-primary"></i>{{ $t('home.prices.subscriptions.video_summary') }}</li>
                <li class="mb-3">
                  <i class="fa fa-check mr-2 text-primary"></i>{{ $t('home.prices.subscriptions.match_statistics') }}</li>
                <li class="mb-3">
                  <i class="fa fa-check mr-2 text-primary"></i>{{ $t('home.prices.subscriptions.saison_statistics') }}</li>
                <li class="mb-3">
                  <i class="fa fa-check mr-2 text-primary"></i>{{ $t('home.prices.subscriptions.player_statistics') }}</li>
                <li class="mb-3">
                  <i class="fa fa-check mr-2 text-primary"></i>{{ $t('home.prices.subscriptions.competitor_analysis') }}</li>
              </ul>
              <div class="d-grid"><button type="button" class="btn btn-outline-primary"  @click="routeToSignIn" id="priceBtn2">{{ $t('home.registrationBtn') }}</button></div>
            </div>
          </div>
      

        </div>
        

      </div>
      <div class="text-center mb-5">
          <h2 class="fw-bolder">{{ $t('home.prices.tryout.title') }}</h2>
          <p class="lead mb-0">{{ $t('home.prices.tryout.description') }}</p>
      </div>
  </section>-->

  <!--<section class="team bg-light">
    <div class="container text-center align-items-center">
      <div class="row" style="justify-content:center">
        <h2>Unser Team</h2>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <img class="img-profile" src="@/assets/profile_roman.jpg"/>
          <h4>Roman Pass</h4>
        </div>
        <div class="col-lg-4">
          <img class="img-profile" src="@/assets/profile_max.jpg"/>
          <h4>Max Krautwig</h4>
        </div>
        <div class="col-lg-4">
          <img class="img-profile" src="@/assets/profile_frank.png"/>
          <h4>Frank Lu</h4>
        </div>
      </div>
    </div>
  </section>-->


  


  </div>
</template>


<script>
//import {analytics} from "../main.js"
import { getAnalytics, logEvent } from "firebase/analytics";
//const analytics = getAnalytics();


export default {
  data() {
    return {
        variable: null
    }
  },
  computed: {
    videoSource: function() {
      return require('@/assets/videos/kick17_video.mp4') // process.env.VIDEOS_URL + "/basic_videos/header_video.mp4"
    },
    lineupSVG: function() {
      return require('@/assets/team-lineup-animate.svg')
    },
    dashboardSVG: function() {
      return require('@/assets/dashboard.svg')
    },
    videographerSVG: function() {
      return require('@/assets/videographer.svg')
    },
    uploadSVG: function() {
      return require('@/assets/upload.svg')
    },
    videoplayerSVG: function() {
      return require('@/assets/videoplayer.svg')
    }
  },
  mounted() {
    //console.log(this.$analytics) //.logEvent('mounted');
    //console.log(this.firebase)
    //console.log(firebase)
  },
  created() {
    
    //logEvent(analytics, 'created');
   // analytics.logEvent('created');
  },
  methods: {
    routeToSignIn(evt) {
      const analytics = getAnalytics()
      //console.log(evt.target.id == "" ? evt.target.parentNode.id : evt.target.id)
      logEvent(analytics, 'registerBtnHomepage', {"buttonId": evt.target.id == "" ? evt.target.parentNode.id : evt.target.id})
      this.$router.push({path: '/login', query: { LoginFormVisible: false, RegisterFormVisible: true}})
    }
  }
}
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/bootstrap.scss';

body {
  white-space: pre-line;
}

* {
 white-space: pre-line;
}

.rotate_word {
    display: block;
    height: 100%;
    padding-left: 10px;
    animation: spin_words 6s infinite;
    color: var(--trueblue);
    font-weight: 700;
}



@keyframes spin_words {
    10% { transform: translateY(-112%); }
    25% { transform: translateY(-100%); }
    35% { transform: translateY(-212%); }
    50% { transform: translateY(-200%); }
    60% { transform: translateY(-312%); }
    75% { transform: translateY(-300%); }
    85% { transform: translateY(-412%); }
    100% { transform: translateY(-400%); }
}

.words {
    overflow: hidden;
    height: 40px;
}

.wrapper {
    height:40px; 
    overflow: hidden;
}


.bg-cover {
    background-size: cover !important;
}

.header-blue {
  background-size: cover;
  background:linear-gradient(135deg, #172a74, #21a9af);
  background-color:#184e8e;
  font-family:'Source Sans Pro', sans-serif;
  background-image: url('../assets/soccer-top.jpg');
  height: calc(100vh - 5rem)!important;
  overflow: hidden;
  background-position-x: center;
}

.header-text {
    top: 50%;
    position: inherit;
    word-wrap: break-word;
}



img, video {
        max-width: 100%;
        height: auto;
        width: 100%;
    }


@media (max-width: 768px) {
    .imagecol {
        max-width: 100%;
        flex: none;
        width: 100%;
        margin-top: -7rem;
    }

    img, video {
        max-width: 100%;
        height: auto;
    }



    .header-text {
        width: 100%;
        top: 30%!important;
    }
    .imagewrapper {
        width: 100% !important;
        top: -5rem;
    }
    
}





@media (max-width: 640px) {
    .imagecol {
        max-width: 100%;
        flex: none;
        width: 100%;
        margin-top: -7rem;
    }

    img, video {
        max-width: 100%;
        height: auto;
    }


    .header-text {
        width: 100%;
        top: 30%;
    }
    
    .words {
        height: 100%;
    }
}



@media (min-width: 640px) {
  
}


.imagewrapper {
    position: relative;
}

@media (min-width: 1024px) {
    .imagewrapper {
        width: 300%;
        top: 15rem;
        right: 15em;
    }
}

.topText {
        float: left;
    }

.features-icons {
  padding-top: 7rem;
  padding-bottom: 7rem;
}
.features-icons .features-icons-item {
  max-width: 20rem;
}
.features-icons .features-icons-item .features-icons-icon {
  height: 7rem;
}
.features-icons .features-icons-item .features-icons-icon i {
  font-size: 4.5rem;
}


.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}
.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
section {
  padding-top: 9rem;
  padding-bottom: 9rem;
}

.mycolor {
    color:var(--trueblue);
}


.team{
  padding-top: 4rem;
  padding-bottom: 5rem;
}

.img-profile{
  width: 220px;
  border-radius: 100%;
}

.img-container{
}

.video-header {
  position: relative;
  background-color: black;
  height: 10vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
}

.video-header video {
  position: absolute;
  top: 50%;
  left: 35%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.video-header .container {
  position: relative;
  z-index: 2;
}

.video-header .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(to bottom, #4995c2, #07507b);
  display: block;
  opacity: 0.55;
  z-index: 1;
}

@media (pointer: coarse) and (hover: none) {
  .video-header {
    background: url('/img/man-holding-clipboard-inside-room-1543924.jpg') black no-repeat center center scroll;
  }
  .video-header video {
    left: 200%;
  }
}

.demo-text {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 1);
}

.features-icons {
  padding-top: 4rem;
  padding-bottom: 5rem;
}

.features-icons .features-icons-item {
  max-width: 20rem;
}

.features-icons .features-icons-item .features-icons-icon {
  height: 7rem;
}

.features-icons .features-icons-item .features-icons-icon i {
  font-size: 4.5rem;
}

.features-icons .features-icons-item:hover .features-icons-icon i {
  font-size: 5rem;
}

.showcase .showcase-text {
  padding: 3rem !important;
}

.showcase .showcase-img {
  min-height: 25rem;
  object-fit: cover;
  width: 100%;
  height: 310px;
}

@media (min-width: 600px) {
  .showcase .showcase-text {
    padding: 4rem !important;
  }
}


.rounded-lg {
  border-radius: 1rem !important;
}

.text-small {
  font-size: 0.9rem !important;
}

.custom-separator {
  width: 5rem;
  height: 6px;
  border-radius: 1rem;
}

.text-uppercase {
  letter-spacing: 0.2em;
}
</style>

