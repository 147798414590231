<template>
<div>

    <button style="right: 2em; position: fixed; bottom: 3em;" type="button" class="btn btn-primary btn-circle btn-xl" @click="addClubModal.show= !addClubModal.show"><i class="fas fa-plus"></i></button>

    <Dialog header="Club hinzufügen" v-model:visible="addClubModal.show" :style="{width: '50vw'}" :modal="true">
        
        <span class="p-float-label">
            <InputText id="clubname" type="text" v-model="addClubModal.name" />
            <label for="clubname">Club Name</label>
        </span>

        <template #footer>
            <Button label="No" icon="pi pi-times" @click="addClubModal.show = false" class="p-button-text"/>
            <Button label="Yes" icon="pi pi-check" @click="saveClub" autofocus />
        </template>
    </Dialog>

    <Dialog header="Logo hochladen" v-model:visible="changeLogoModal.show" :style="{width: '50vw'}" :modal="true">
        
        <FileUpload mode="advanced"  :fileLimit="1"  @upload="uploadDone" @before-send="beforeSend" @progress="progress" :auto="true" :url="uploadURL" :multiple="false" :showUploadButton="false" :showCancelButton="false" :chooseLabel="'Logo Hochladen'">
            <template #empty>
                <p>Hochladen durch Drag and drop.</p>
            </template>
        </FileUpload>

        <template #footer>
            
            <Button label="Fertig" icon="pi pi-check" @click="changeLogoModal.show = !changeLogoModal.show" autofocus />
        </template>
    </Dialog>


<div class="row">
            <div class="col-lg-7 mx-auto">
                <div class="card border-0 shadow">
                    <div class="card-body p-5">

                        <!-- Responsive table -->
                        <div class="table-responsive">
                            <table class="table m-0">
                                <thead>
                                    <tr>
                                        <th scope="col">Club</th>
                                        <th scope="col">id</th>
                                        <th scope="col">Logo</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>      
                                    <tr v-for="(m, index) in clubList"  v-bind:key="index">
                                        
                                        <th scope="row" v-if="m.clubname != null">{{m.clubname}}</th>
                                        <th scope="row" v-else>{{m.uuid}}</th>
                                        <th scope="row">{{m.uuid}}</th>
                                        <td>
                                            <div v-if="m.icon!=null" onmouseover="" @click="changeLogo(index)" class="z-depth-2"><img style="cursor: pointer; width: 3em;"  :src="imagePath + m.icon" @error="src='club_logo.png'" data-holder-rendered="true"></div>
                                            <div v-else onmouseover="" style="cursor: pointer;" @click="changeLogo(index)" class="z-depth-2" ><i class="fas fa-jedi fa-2x m-auto"></i></div>
                                        </td>
                                        
                                        <!--<td>
                                           
                                            <ul class="list-inline m-0">
                                                
                                                <li class="list-inline-item">
                                                    <button class="btn btn-success btn-sm rounded-0" type="button" data-toggle="tooltip" data-placement="top" title="Edit" @click="modalShow= !modalShow"><i class="fa fa-edit"></i></button>
                                                </li>
                                                
                                            </ul>
                                        </td>-->
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </div>

</div>
</template>


<style scoped lang="scss">

@import '~bootstrap/scss/bootstrap.scss';

/*.modal-dialog {
    max-width: 100%;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.modal-content {
  height: auto;
  min-height: 100%;
  border-radius: 0;
}*/

.btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    border-radius: 35px;
    font-size: 24px;
    line-height: 1.33;
}

.btn-circle {
    width: 30px;
    height: 30px;
    padding: 6px 0px;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.42857;
}

</style>
<script>
import { kick17 } from '@/services';






export default {
  name: 'app',
  components: {
    
  },
  data () {
    return {
     clubList: null,
     imagePath: '',
     addClubModal: {
         name: null,
         show: false
     },
     changeLogoModal: {
         show: false,
         selectedClub: null
     }
    }
  },
  mounted() {
        this.$toast.add({severity:'success', summary: 'Loaded', detail:'Loaded', life: 3000});
    },
  async created () {
      this.$toast.add({severity:'success', summary: 'Loaded', detail:'Loaded', life: 3000});
      this.uploadURL = kick17.getClubLogoUploadURL()
        this.accesstoken = await kick17.getAccessToken()
        this.loadList()
        this.imagePath = kick17.getClubLogoReadURL()
  },

  methods: {
    async loadList() {
        var result = await kick17.getAllClubs()
        this.clubList = result.data
        for(var i = 0; i < this.clubList.length; i++) {
        /*if(this.clubList[i].icon != null) {
          var response = await kick17.getClubIcon(this.clubList[i].icon)
          var b = kick17.getImage(response.data)
          if(response.data.byteLength > 0) {
            this.downloadedImages.push(b)
          } else {
              this.downloadedImages.push(null)
          }
        } else {
          this.downloadedImages.push(null)
          //this.downloadedImages.push(require("../../img/clublogo.png"))
        }*/
        
      }
    },
    async saveClub() {
        await kick17.addClub(this.addClubModal.name).then(function(response){
            
            this.addClubModal.show = false
            this.clubList.push(response.data)
            this.addClubModal.name = ""
            this.$toast.add({severity:'success', summary: 'Club added', detail:'OK', life: 3000});
        }.bind(this)).catch(function (error) {
            this.$toast.add({severity:'error', summary: 'Club not added', detail: error.response.data.message, life: 3000});
        });
    },
    changeLogo(index) {
        this.changeLogoModal.selectedClub = this.clubList[index]
        this.changeLogoModal.show = true
    },
    async beforeSend(request) {
        console.log("beforeSend")
        console.log(request)
        request.xhr.setRequestHeader("teamId", this.changeLogoModal.selectedClub.uuid);
        request.xhr.setRequestHeader("Authorization", this.accesstoken);
        return request
    },

    uploadDone(response) {

        if(response.xhr.status==200) {
            this.$toast.add({severity:'success', summary: 'Image uploaded', detail:'OK', life: 3000});
        } else {
            this.$toast.add({severity:'error', summary: 'Image not uploaded', detail: response.xhr.status, life: 3000});
        }
    }
  }
}
</script>