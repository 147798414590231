<template>
  <div>
    <footer class="w-100 py-4 flex-shrink-0">
        <div class="container py-4 text-white">
            <div class="row gy-4">
                <div class="col-lg-4 col-md-6">
                    <h5 class="h1 text-white">Kick17</h5>
                    <p class="small text-white">Be a pro</p>
                    
                    <a  href="https://www.instagram.com/kick17.de/" target="_blank"><i class="pi pi-instagram" style="fontSize: 2rem"></i></a>
                </div>
                <div class="col-lg-2 col-md-6">
                    <h5 class="text-white mb-3">Thanks to:</h5>
                    <a class="text-white small mb-4 mb-lg-0" href="https://storyset.com/business" target="_blank">Business illustrations by Storyset</a>
                </div>
                <div class="col-lg-2 col-md-6">
                   
                </div>
                <div class="col-lg-4 col-md-6">
                  <router-link to="/impressum" class="text-white mb-3">Impressum</router-link> <br>
                  <router-link to="/agbs" class="text-white mb-3">AGBs</router-link><br>
                  <router-link to="/privacy" class="text-white mb-3">Datenschutz</router-link>

                </div>
            </div>
            <div class="row mt-5 justify-content-center">
              <p class="small text-white mb-0" style="width: auto;">&copy; Kick17 Alle Rechte vorbehalten.</p>
            </div>
        </div>

    </footer>

  </div>
</template>

<style scoped lang="scss">
@import '~bootstrap/scss/bootstrap.scss'; 


.text-small {
  font-size: 0.9rem;
}

a {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

a:hover, a:focus {
  text-decoration: none;
}

.form-control {
  background: #212529;
  border-color: #545454;
}

.form-control:focus {
  background: #212529;
}

footer {
  background: #212529;
  color: white !important;
}


</style>

<script>


export default {
  name: 'app',

  data () {
    return {}
  }
}
</script>