<template>
<div>
<div class="layout-main-container">
<div class="layout-main">

    
<Toast />
<ConfirmDialog></ConfirmDialog>
    <div class="row py-5 justify-content-center">
        
            <transition-group name="dynamic-box" tag="div" class="grid">
                <div v-for="col of columns" :key="col" v-bind:class="columns.length == 2 && col == 0 ? 'col-6' : col == 0 ?'col-6 col-offset-3' : 'col-6'" class="col">
                    <div v-if="col == 0">
                        <video-player id="cont" v-if="match != null" :match="match" :selectedSecond="selectedSecond" :newSituation="newSituation" v-on:newSituationEdit="newSituationEdit" @videoclick="videoclick" @ready="childVal => {this.videoMounted = childVal}" @playing="childVal => { currentVideoTimestamp = childVal }" :options="videoOptions" ref="myVideoPlayer" />
                        
                        <!--<Accordion :activeIndex="0">
                            <AccordionTab header="How To Edit">
                                <p>1) The colors of the teams are provided to the editor by kick17</p>
                            </AccordionTab>
                            
                        </Accordion>-->
                        
                    </div>
                    <div v-else-if="col == 1">
                        
                <TabView :activeIndex="0" @tab-click="open" :scrollable="true" >
                    <TabPanel header="0 Colors" v-if="isAdmin">
                        <div class="row py-3">
                            
                            <div class="col-3">
                                <ColorPicker v-model="mycolor"/>
                                <span v-if="match!=null">{{match.creator_team.clubname}}</span>
                            </div>
                            <div class="col-3">
                                <ColorPicker v-model="opponentcolor"/>
                                <span v-if="match!=null">{{match.opponent_team.clubname}}</span>
                            </div>
                            <div class="col-auto">
                                <Button label="Save Colors" class="p-button-outlined p-button-secondary" @click="changeTeamColors()"/>
                            </div>
                        </div>
                        
                    </TabPanel>

                    <TabPanel header="1 Match Video Frames">
                        <span>1) Search the beginning of the match (You hear the wistle) <br>2) click on 'Set Start of 1st Half' to add the timestamp. <br>3) repeat for the other moments of the match: End of 1st Half, Start of 2nd Half, End of 2nd Half</span>
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                    
                                    <th scope="col">timestamp</th>
                                    <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(t, index) in newMatchVideoFrame" v-bind:key="index" >
                                        <td style="width: 30%;">{{t}}</td>
                                        <td><Button icon="pi pi-directions-alt" style="width: 60%;" class="p-button-outlined p-button-secondary" @click="refreshNewMatchVideoFrame(index, Number(currentVideoTimestamp).toFixed(0))">{{ index == 0 ? 'Set Start of 1st Half' : (index == 1 ? 'Set End of 1st Half': (index == 2 ? 'Set Start of 2nd Half': 'Set End of 2nd Half'))}}</Button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <Button label="Save Frame" icon="pi pi-save" class="p-button-outlined p-button-secondary" @click="saveMatchVideoFrame()"/>
                    </TabPanel>

                    <TabPanel header="2 First Half Direction" v-if="newMatchVideoFrame != null && newMatchVideoFrame.length == 4 && newMatchVideoFrame[3] != 0">
                    <span>1) Select the direction of the teams in the first half. <br>
                        Example: <br>
                        Team Read vs. Team Blue<br>
                        if the Goalkeeper of team Red stands on the left Goal in the first halftime, the red box must be on the left, the blue one on the right.
                    </span>
                        <div class="row py-3">
                            <span v-bind:style="this.match.firstHalfDirection == 'CREATOR_LEFT_TO_RIGHT' ? 'background-color: #' + match.creatorcolor : (this.match.firstHalfDirection == 'CREATOR_RIGHT_TO_LEFT' ? 'background-color: #' + match.opponentcolor : '')" class="col-1 p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px"></span>
                            <span v-bind:style="this.match.firstHalfDirection == 'CREATOR_LEFT_TO_RIGHT' ? 'background-color: #' + match.opponentcolor : (this.match.firstHalfDirection == 'CREATOR_RIGHT_TO_LEFT' ? 'background-color: #' + match.creatorcolor : '')" class="col-1 p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px"></span>
                        </div>
                        <div class="row py-3">
                            
                            <div class="col-3">
                                <Button class="p-button-outlined p-button-secondary" @click="this.match.firstHalfDirection = this.match.firstHalfDirection != 'CREATOR_LEFT_TO_RIGHT' ? 'CREATOR_LEFT_TO_RIGHT' : 'CREATOR_RIGHT_TO_LEFT'">Switch Direction</Button>
                            </div>
                            <div class="col-auto">
                                <Button label="Save Direction" class="p-button-outlined p-button-secondary" @click="saveFirstHalfDirection()"/>
                            </div>
                        </div>
                    </TabPanel>

                    <TabPanel header="4 Situations" v-if="isAdmin">
                        <ScrollPanel style="width: 100%; height: 600px">
                            <SituationList v-if="match != null" style="width: 100%;" :match="match" @situationClick="situationClick"></SituationList>
                        </ScrollPanel>
                    </TabPanel>
                    <TabPanel header="3 Situations" v-if="!isAdmin && match != null && match.firstHalfDirection != null">
                        Tag the situations in the video. When you are done, proceed with Step 4
                    </TabPanel>

                    <TabPanel header="4 Goals" v-if="match != null && match != null && match.firstHalfDirection != null">
                        detected goals: {{detectedGoals[0]}}:{{detectedGoals[1]}}
                        <div class="row" v-if="match != null && match != null && match.firstHalfDirection != null">
                            <div class="col">  {{match.creator_team.clubname }} 
                                <span v-bind:style=" 'background-color: #' + match.creatorcolor" class="col-1 p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px"></span>
                            </div>
                            <div class="col">
                                {{match.opponent_team.clubname}}
                                <span v-bind:style=" 'background-color: #' + match.opponentcolor" class="col-1 p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px"></span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                
                                <InputNumber class="ml-auto" id="vertical" v-model="creator_team_goals" mode="decimal" showButtons buttonLayout="vertical" style="width:4rem" decrementButtonClass="p-button-secondary" incrementButtonClass="p-button-secondary" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                            </div>
                            <div class="col">
                                <InputNumber class="ml-auto" id="vertical" v-model="opponent_team_goals" mode="decimal" showButtons buttonLayout="vertical" style="width:4rem" decrementButtonClass="p-button-secondary" incrementButtonClass="p-button-secondary" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                            </div>
                        </div>
                        <Button label="Set Result" class="p-button-outlined p-button-secondary" @click="saveResult()"/>
                    </TabPanel>
                    
                    <TabPanel header="5 Positions" v-if="match != null && match != null && match.firstHalfDirection != null && match.situations != null && match.situations.length != 0">
                        <div class="row" v-if="match!= null && match.firstHalfDirection != null">
                            <div id="heatmapContainer" class="col-12 nopadding" style="position: relative">
                                <!--<img src="../../assets/rawPitch.jpg" style="width: 100%;"/>-->
                                <Positionsetter ref="positionsetter" @playerchanged="playerchanged" :players="creatorTeamPlayers" @deletePlayer="deletePlayer" @addNewPlayer="addNewPlayer" :direction="selectedDirection"  :match="match" :frames_data="situationPlayersList"></Positionsetter>

                            </div>
                            <div class="col-12">
                                <div class="row">
                                    <div class="col">
                                        Situations:
                                        
                                        <Dropdown v-if="match != null && match.situations != null" v-model="selectedSituation" :options="sortedSituations" optionLabel="uuid" placeholder="Select a Situation" @change="setSituation">
                                            <template #option="slotProps">
                                                <div class="country-item">
                                                    <div>{{slotProps.index}} {{getTranslated(slotProps.option.label[0])}} <i v-if="slotProps.option.videoNeeded == true" class="pi pi-video"></i> <i v-if="slotProps.option.situationPositions.length > 0" class="pi pi-check text-green-400"></i></div>
                                                    
                                                </div>
                                            </template>
                                            <template #value="slotProps" >
             
                                                <div v-if="slotProps.value">{{curSituationIndex}} {{slotProps.value.label[0]}} <i v-if="slotProps.value.videoNeeded == true" class="pi pi-video"></i> <i v-if="slotProps.value.situationPositions.length > 0" class="pi pi-check text-green-400"></i></div>
                                            </template>
                                        </Dropdown>
               

                                    </div>

                                    <div class="col" v-if="curSituationIndex != null && match.situations[curSituationIndex].situationPositions != null && match.situations[curSituationIndex].situationPositions.length > 0">
                                        saved seconds: <Dropdown :options="allSavedSeconds" placeholder="Select a Second" @change="setSecond"/> 
                                        
                                    </div>
                                </div>
                                

                                <div class="row" v-if="curSituationIndex != null">
                                    <h6>Situation Details</h6>
                                    <Button label="Show" class="p-button-outlined p-button-secondary w-min" v-styleclass="{ selector: '.box', enterClass: 'hidden', enterActiveClass: 'my-fadein' }" />
                                    <Button label="Hide" class="p-button-outlined p-button-secondary w-min" v-styleclass="{ selector: '.box', leaveActiveClass: 'my-fadeout', leaveToClass: 'hidden' }" />
                                    <Button label="Next Situation" class="p-button-outlined p-button-secondary w-min" @click="nextSituation()"/>
                                    <div class="box hidden row">
                                        
                                        <div class="col-12">
                                            <table class="table">

                                                <tbody>
                                                    <tr>
                                                        <th scope="row">Index</th>
                                                        <td>{{curSituationIndex}}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Labels</th>
                                                        <td><span v-for="(label, index) in match.situations[curSituationIndex].label" v-bind:key="index">{{getTranslated(label)}}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Minute</th>
                                                        <td>{{Number(getMinute(match.situations[curSituationIndex])) + Number(1)}}'</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">uuid</th>
                                                        <td>{{match.situations[curSituationIndex].uuid}}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">isCustom</th>
                                                        <td>{{match.situations[curSituationIndex].isCustom}}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">start</th>
                                                        <td>{{ Math.floor(match.situations[curSituationIndex].situation_start / 60) }}min {{ match.situations[curSituationIndex].situation_start - Math.floor(match.situations[curSituationIndex].situation_start / 60) * 60 }}sec (= {{match.situations[curSituationIndex].situation_start}}s)</td>
                                                        
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">end</th>
                                                        <td>{{ Math.floor(match.situations[curSituationIndex].situation_end / 60) }}min {{ match.situations[curSituationIndex].situation_end - Math.floor(match.situations[curSituationIndex].situation_end / 60) * 60 }}sec (= {{match.situations[curSituationIndex].situation_end}}s)</td>
                                                        
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Video needed</th>
                                                        <td>{{match.situations[curSituationIndex].videoNeeded}}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">direction</th>
                                                        <td>{{selectedDirection}}</td>
                                                    </tr>
                                                    
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="col-12">
                                            <Button label="Delete Situation" class="p-button-outlined p-button-danger" @click="deleteSituationConfirm()"/>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div v-if="curSituationIndex != null" class="col" style="font-weight: 700;" v-bind:style="currentVideoTimestamp >= match.situations[curSituationIndex].situation_start &&  currentVideoTimestamp <= match.situations[curSituationIndex].situation_end? 'color: green;' : 'color: red;' ">
                                        current time (video): {{Number(currentVideoTimestamp).toFixed(0)}}
                                    </div>

                                    <!--<div class="col" style="font-weight: 700;" >
                                        selected time: {{selectedSecond}} <Button icon="pi pi-history" class="p-button-rounded p-button-secondary p-button-outlined" @click="setVideoToTimestamp(selectedSecond)" />
                                    </div>-->
 
                                    
                                </div>
                                <div class="row" v-if="curSituationIndex != null">
                                    <div class="col">
                                        <Button label="a: Add Player" class="p-button-outlined p-button-secondary" @click="addPlayerBlank()" v-if="currentVideoTimestamp >= match.situations[curSituationIndex].situation_start && currentVideoTimestamp <= match.situations[curSituationIndex].situation_end"/>
                                        <Button v-if="situationPlayersList.length > 0" label="Delete Players of this Moment" class="p-button-outlined p-button-secondary" @click="deletePlayerList()"/>
                                        
                                        <!--<Button label="Switch Sides" class="p-button-outlined p-button-secondary" @click="switchSides()"/>-->
                                        <Button  label="Save Positions" class="p-button-outlined p-button-secondary" @click="savePositions()"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Button v-if="isAdmin" label="Done" class="p-button-outlined p-button-secondary" @click="situationPositionsFinished()"/>
                    </TabPanel>
                    <TabPanel header="6 Video Cut" v-if="isAdmin">
                        <SplitButton label="1 Video Download" class="p-button-outlined p-button-secondary" icon="pi pi-download" :model="matchItems"></SplitButton>
                        <Button label="2 Download Config" class="p-button-outlined p-button-secondary" @click="saveFile"/>
                        <Button label="3 Upload Situation Videos" class="p-button-outlined p-button-secondary" @click="uploadVisible = !uploadVisible"/>
                        <Button v-if="match != null && (match.shortVideosFinished == null || match.shortVideosFinished != true)" label="4 Videos Done" :loading="loading" class="p-button-outlined p-button-secondary" @click="videosDone"/>
                        <div class="row py-5" v-if="uploadVisible">
                        <div class="col-lg-8 mx-auto">
                            <div class="card shadow mb-4">
                                <div class="card-body">
                                    <FileUpload mode="advanced" accept=".mp4, .AVI, .WMV, .AVCHD" @upload="uploadDone" @before-send="beforeSend" @progress="progress" :auto="true" :url="uploadURL" :multiple="false" :showUploadButton="false" :showCancelButton="false" :chooseLabel="'Videos Hochladen'">
                                        <template #empty>
                                            <p>Upload with Drag and drop.</p>
                                        </template>
                                    </FileUpload>
                                </div>
                            </div>
                        </div>
                        <SituationList v-if="match != null" style="width: 100%;" :onlyVideosNeeded="true" :match="match"></SituationList>
                    </div>
                    </TabPanel>
                </TabView>
            
                    </div>
                </div>
            </transition-group>
        </div>
    
    <div class="container">
        <Dialog :header="'Add new Player: ' + (newPlayer != null && newPlayer.action_labels != null ? newPlayer.action_labels : '')" v-model:visible="displayBasic" :style="{width: '50vw'}">
            <!--<div v-if="newPlayer.isInCreatorTeam == null" class="py-3"> 
                <Button @click="selectedDirection = selectedDirection == this.direction.CREATOR_RIGHT_TO_LEFT ? this.direction.CREATOR_LEFT_TO_RIGHT : this.direction.CREATOR_RIGHT_TO_LEFT ">t: Toggle Direction &nbsp; <i class="pi pi-arrows-h"></i></Button>
            </div>-->
            <div v-if="selectedDirection != null && newPlayer.isInCreatorTeam == null">
                <Button label="1" v-bind:style="'background-color: #' + match.creatorcolor" @click="newPlayer.isInCreatorTeam = true"/>
                <Button label="2" v-bind:style="'background-color: #' + match.opponentcolor" @click="newPlayer.isInCreatorTeam = false"/>
            </div>
            <div v-if="newPlayer.isInCreatorTeam != null && newPlayer.action_labels.length == 0">
                <Button label="1: Corner" class="p-button-outlined p-button-secondary" @click="addcorner()"/>
                <Button label="2: Freekick" class="p-button-outlined p-button-secondary" @click="addfreekick()"/>
                <Button label="3: Penalty" class="p-button-outlined p-button-secondary" @click="addpenalty()"/>
                <Button label="4: Pass" class="p-button-outlined p-button-secondary" @click="addpass()"/>
                <Button label="5: Attempt" class="p-button-outlined p-button-secondary" @click="addattempt()"/>
                <Button label="6: Attempt on Target" class="p-button-outlined p-button-secondary" @click="addattemptontarget()"/>
                <Button label="7: Goal" class="p-button-outlined p-button-secondary" @click="addgoal()"/>
                <Button label="8: Own Goal" class="p-button-outlined p-button-secondary" @click="addowngoal()"/>
                <Button label="9: Other" class="p-button-outlined p-button-secondary" @click="addotheraction()"/>
            </div>

            <div v-if="newPlayer.action_labels.includes('Eckstoß')" class="row">
                <div class="col-6">
                    <div class="p-field-checkbox">
                        <Checkbox id="flanke" name="actionLabel" value="Flanke" v-model="newPlayer.action_labels"/>
                        <label for="flanke">1: Flank (High Pass)</label>
                    </div>
                    <div class="p-field-checkbox">
                        <Checkbox id="pass" name="actionLabel" value="Pass" v-model="newPlayer.action_labels"/>
                        <label for="pass">2: Pass (Low Pass)</label>
                    </div>
                </div>
                <div class="col-6" v-if="newPlayer.action_labels.includes('Flanke') || newPlayer.action_labels.includes('Pass')">
                    <Button label="3: Top" class="p-button-outlined p-button-secondary" @click="newPlayer.y = 0, addPlayer(newPlayer)"/>
                    <Button label="4: Bottom" class="p-button-outlined p-button-secondary" @click="newPlayer.y = 100, addPlayer(newPlayer)"/>
                </div>
            </div>
            <div v-if="newPlayer.action_labels.includes('Freistoß')" class="row">
                <div class="col-6">

                    <div class="p-field-checkbox">
                        <Checkbox id="pass" name="actionLabel" value="Schuss" v-model="newPlayer.action_labels"/>
                        <label for="pass">1: Attempt</label>
                    </div>
                    <div class="p-field-checkbox" v-if="!newPlayer.action_labels.includes('Schuss')">
                        <Checkbox id="pass" name="actionLabel" value="Assist" v-model="newPlayer.action_labels"/>
                        <label for="pass">2: Goal Assist</label>
                    </div>
                    <div class="p-field-checkbox" v-if="!newPlayer.action_labels.includes('Schuss')">
                        <Checkbox id="pass" name="actionLabel" value="Angekommen" v-model="newPlayer.action_labels"/>
                        <label for="pass">3: Successful Pass</label>
                    </div>
                </div>
                <div class="col-6" v-if="newPlayer.action_labels.includes('Schuss')">
                    <Button label="2: Attempt" class="p-button-outlined p-button-secondary" @click="addPlayer(newPlayer, {number: 1, isInCreatorTeam: !newPlayer.isInCreatorTeam, y: 50, x: selectedDirection == this.direction.CREATOR_RIGHT_TO_LEFT ? (newPlayer.isInCreatorTeam == true ? 1 : 99) : (newPlayer.isInCreatorTeam == true ? 99 : 1)})"/>
                    <Button label="3: Attempt on Target" class="p-button-outlined p-button-secondary" @click="newPlayer.action_labels.push('Torschuss'), addPlayer(newPlayer, {number: 1, isInCreatorTeam: !newPlayer.isInCreatorTeam, y: 50, x: selectedDirection == this.direction.CREATOR_RIGHT_TO_LEFT ? (newPlayer.isInCreatorTeam == true ? 1 : 99) : (newPlayer.isInCreatorTeam == true ? 99 : 1)})"/>
                    <Button label="4: Goal" class="p-button-outlined p-button-secondary" @click="newPlayer.action_labels.push('Torschuss'), newPlayer.action_labels.push('Tor'), addPlayer(newPlayer, {number: 1, isInCreatorTeam: !newPlayer.isInCreatorTeam, y: 50, x: selectedDirection == this.direction.CREATOR_RIGHT_TO_LEFT ? (newPlayer.isInCreatorTeam == true ? 1 : 99) : (newPlayer.isInCreatorTeam == true ? 99 : 1)})"/>
                </div>
                <div class="col-6" v-else>
                    <Button label="4: Flank (High Pass)" class="p-button-outlined p-button-secondary" @click="newPlayer.action_labels.push('Flanke'), addPlayer(newPlayer, {number: 1, isInCreatorTeam: !newPlayer.isInCreatorTeam, y: 50, x: selectedDirection == this.direction.CREATOR_RIGHT_TO_LEFT ? (newPlayer.isInCreatorTeam == true ? 1 : 99) : (newPlayer.isInCreatorTeam == true ? 99 : 1)})"/>
                    <Button label="5: Pass (Low Pass)" class="p-button-outlined p-button-secondary" @click="newPlayer.action_labels.push('Pass'), addPlayer(newPlayer, {number: 1, isInCreatorTeam: !newPlayer.isInCreatorTeam, y: 50, x: selectedDirection == this.direction.CREATOR_RIGHT_TO_LEFT ? (newPlayer.isInCreatorTeam == true ? 1 : 99) : (newPlayer.isInCreatorTeam == true ? 99 : 1)})"/>
                    
                </div>
            </div>
            <div v-if="newPlayer.action_labels.includes('Elfmeter')" class="row">
                <div class="col-6">
                    <Button label="1: Goal" class="p-button-outlined p-button-secondary" @click="newPlayer.action_labels.push('Tor'), newPlayer.action_labels.push('Torschuss'), addPlayer(newPlayer, {number: 1, isInCreatorTeam: !newPlayer.isInCreatorTeam, y: 50, x: selectedDirection == this.direction.CREATOR_RIGHT_TO_LEFT ? (newPlayer.isInCreatorTeam == true ? 1 : 99) : (newPlayer.isInCreatorTeam == true ? 99 : 1)})"/>
                    <Button label="2: Miss Target" class="p-button-outlined p-button-secondary" @click="addPlayer(newPlayer, {number: 1, isInCreatorTeam: !newPlayer.isInCreatorTeam, y: 50, x: selectedDirection == this.direction.CREATOR_RIGHT_TO_LEFT ? (newPlayer.isInCreatorTeam == true ? 1 : 99) : (newPlayer.isInCreatorTeam == true ? 99 : 1)})"/>
                </div>
                <div class="col-6">
                    <Button label="3: Keeper Block" class="p-button-outlined p-button-secondary" @click="newPlayer.action_labels.push('Torschuss'), addPlayer(newPlayer, {number: 1, isInCreatorTeam: !newPlayer.isInCreatorTeam, action_labels:['Block'], y: 50, x: selectedDirection == this.direction.CREATOR_RIGHT_TO_LEFT ? (newPlayer.isInCreatorTeam == true ? 1 : 99) : (newPlayer.isInCreatorTeam == true ? 99 : 1)})"/>
                    <Button label="4: Keeper Hold" class="p-button-outlined p-button-secondary" @click="newPlayer.action_labels.push('Torschuss'), addPlayer(newPlayer, {number: 1, isInCreatorTeam: !newPlayer.isInCreatorTeam, action_labels:['Ballbesitz'], y: 50, x: selectedDirection == this.direction.CREATOR_RIGHT_TO_LEFT ? (newPlayer.isInCreatorTeam == true ? 1 : 99) : (newPlayer.isInCreatorTeam == true ? 99 : 1)})"/>
                </div>
            </div>

            <div v-if="newPlayer.action_labels.includes('Schuss') && !newPlayer.action_labels.includes('Torschuss') && !newPlayer.action_labels.includes('Tor') && !newPlayer.action_labels.includes('Elfmeter') && !newPlayer.action_labels.includes('Freistoß')" class="row">
                <div class="col-6">
                    
                    <div class="p-field-checkbox">
                        <Checkbox id="pass" name="actionLabel" value="Kopfball" v-model="newPlayer.action_labels"/>
                        <label for="pass">1: Header</label>
                    </div>
                </div>
                <div class="col-6">
                    <Button label="2: Miss Target" class="p-button-outlined p-button-secondary" @click="addPlayer(newPlayer)"/>
                    <Button label="3: Blocked" class="p-button-outlined p-button-secondary" @click="addPlayer(newPlayer, {number: 0, isInCreatorTeam: !newPlayer.isInCreatorTeam, action_labels:['Block'], y: 50, x: selectedDirection == this.direction.CREATOR_RIGHT_TO_LEFT ? (newPlayer.isInCreatorTeam == true ? 4 : 96) : (newPlayer.isInCreatorTeam == true ? 96 : 4)})"/>
                </div>
            </div>

            <div v-if="newPlayer.action_labels.includes('Torschuss') && !newPlayer.action_labels.includes('Tor') && !newPlayer.action_labels.includes('Elfmeter')" class="row">
                <div class="col-6">
                    
                    <div class="p-field-checkbox">
                        <Checkbox id="pass" name="actionLabel" value="Kopfball" v-model="newPlayer.action_labels"/>
                        <label for="pass">1: Header</label>
                    </div>
                </div>
                <div class="col-6">
                    <Button label="2: Keeper Block" class="p-button-outlined p-button-secondary" @click="newPlayer.action_labels.push('Torschuss'), addPlayer(newPlayer, {number: 1, isInCreatorTeam: !newPlayer.isInCreatorTeam, action_labels:['Block'], y: 50, x: selectedDirection == this.direction.CREATOR_RIGHT_TO_LEFT ? (newPlayer.isInCreatorTeam == true ? 1 : 99) : (newPlayer.isInCreatorTeam == true ? 99 : 1)})"/>
                    <Button label="3: Keeper Hold" class="p-button-outlined p-button-secondary" @click="newPlayer.action_labels.push('Torschuss'), addPlayer(newPlayer, {number: 1, isInCreatorTeam: !newPlayer.isInCreatorTeam, action_labels:['Ballbesitz'], y: 50, x: selectedDirection == this.direction.CREATOR_RIGHT_TO_LEFT ? (newPlayer.isInCreatorTeam == true ? 1 : 99) : (newPlayer.isInCreatorTeam == true ? 99 : 1)})"/>
                    <Button label="4: Crossbar" class="p-button-outlined p-button-secondary" @click="newPlayer.action_labels.push('Latte'), addPlayer(newPlayer)"/>
                    <Button label="5: Post" class="p-button-outlined p-button-secondary" @click="newPlayer.action_labels.push('Pfosten'), addPlayer(newPlayer)"/>
                </div>
            </div>

            <div v-if="(newPlayer.action_labels.includes('Pass') || newPlayer.action_labels.includes('Flanke')) && !newPlayer.action_labels.includes('Eckstoß') && !newPlayer.action_labels.includes('Freistoß')" class="row">
                <div class="col-6">
                    <Button label="1: Flank (High Pass)" class="p-button-outlined p-button-secondary" @click="newPlayer.action_labels.splice(newPlayer.action_labels.indexOf('Pass'), 1); newPlayer.action_labels.push('Flanke'), addPlayer(newPlayer)"/>
                    <Button label="2: Pass (Low Pass)" class="p-button-outlined p-button-secondary" @click="addPlayer(newPlayer)"/>
                </div>
            </div>

            <!--<Button label="Defending"  @click="newPlayer.isInCreatorTeam = true"/>
            <Button label="Goalkeeper"  @click="newPlayer.isInCreatorTeam = true"/>-->

            <template #footer>
                <Button label="Cancel" icon="pi pi-times" @click="deleteNewPlayer()" class="p-button-text"/>
                <!--<Button label="Add Player" icon="pi pi-check" @click="addPlayer(newPlayer)"/>-->
                
            </template>
        </Dialog>
        <Dialog header="Select Team of new Player" v-model:visible="displayBasicCorner" :style="{width: '50vw'}">
            <Button label="" v-bind:style="'background-color: #' + match.creatorcolor" @click="newPlayer.isInCreatorTeam = true, addCornerPlayer(newPlayer)"/> <Button @click="newPlayer.isInCreatorTeam = false, addCornerPlayer(newPlayer)" label="" v-bind:style="'background-color: #' + match.opponentcolor"/>
            <template #footer>
                <Button label="Cancel" icon="pi pi-times" @click="displayBasicCorner = false" class="p-button-text"/>
                
            </template>
        </Dialog>



            <div class="row py-5" v-if="match != null && isAdmin">
                
                Erfasste Situationen: {{markedSituationsLength}}s <br>
                new video length: {{newVideoLength}}s <br>
                short videos: {{match.situations.filter(s => s.videoNeeded == true).length}}
            </div>
    
            

    

        
    </div>

</div></div>
</div>
</template>


<script>
import { kick17, admin, usermanager } from '@/services';
import { saveAs } from 'file-saver';
import VideoPlayer from "./VideoPlayer.vue";
import SituationList from './SituationList'
import Positionsetter from './Positionsetter'
import editorStore from '../../editorStore'


  export default {
    components: {
      VideoPlayer,
      SituationList,
      Positionsetter
    },
    
    data () {
        return {
            direction: {
                CREATOR_LEFT_TO_RIGHT: "CREATOR_LEFT_TO_RIGHT",
                CREATOR_RIGHT_TO_LEFT: "CREATOR_RIGHT_TO_LEFT"
            },
            curSituationIndex: null,
            columns: [0, 1],
            //match: null,
            currentVideoTimestamp: 0,
            videoOptions: kick17.getVideoOptions(),
            newSituation: null,
            loading: false,
            uploadVisible: false,
            isMounted: false,
            mycolor: null,
            opponentcolor: null,
            newMatchVideoFrame: [],
            displayBasic: false,
            displayBasicCorner: false,
            //situationPlayersList: [],
            creator_team_goals: 0,
            opponent_team_goals: 0,
            defaultDots: [
                {player_uuid: 1, x: 10, y: 50, second: 0, isInCreatorTeam: true, direction: "", number: null, action_labels: []},
                {player_uuid: 2, x: 25, y: 15, second: 0, isInCreatorTeam: true, direction: "", number: null, action_labels: []},
                {player_uuid: 3, x: 20, y: 40, second: 0, isInCreatorTeam: true, direction: "", number: null, action_labels: []},
                {player_uuid: 4, x: 20, y: 60, second: 0, isInCreatorTeam: true, direction: "", number: null, action_labels: []},
                {player_uuid: 5, x: 25, y: 85, second: 0, isInCreatorTeam: true, direction: "", number: null, action_labels: []},
                {player_uuid: 6, x: 45, y: 15, second: 0, isInCreatorTeam: true, direction: "", number: null, action_labels: []},
                {player_uuid: 7, x: 40, y: 40, second: 0, isInCreatorTeam: true, direction: "", number: null, action_labels: []},
                {player_uuid: 8, x: 40, y: 60, second: 0, isInCreatorTeam: true, direction: "", number: null, action_labels: []},
                {player_uuid: 9, x: 45, y: 85, second: 0, isInCreatorTeam: true, direction: "", number: null, action_labels: []},
                {player_uuid: 10, x: 65, y: 40, second: 0, isInCreatorTeam: true, direction: "", number: null, action_labels: []},
                {player_uuid: 11, x: 65, y: 60, second: 0, isInCreatorTeam: true, direction: "", number: null, action_labels: []}
            ],

            selectedSecond: null,
            selectedDirection: null,
            newPlayer: null,
            creatorTeamPlayers: [],
            selectedSituation: null,
            isAdmin: false
        }
    },
    unmounted() {
      window.removeEventListener('keyup', this.keyUpHandler);
    },
    async mounted() {     
        window.addEventListener("resize", this.resizedPlayer);
        this.isMounted = true;
        window.addEventListener('keyup', this.keyUpHandler);
        this.isAdmin = await usermanager.isAdmin()
    },
    async created () {
        
        var result = await kick17.getMatch(this.$route.params.match_uuid)
        editorStore.commit('setMatch', result.data)
        this.mycolor = this.match.creatorcolor
        this.opponentcolor = this.match.opponentcolor

        this.refreshVideos()
        this.creator_team_goals = this.match.creator_team_goals != null ? this.match.creator_team_goals : 0
        this.opponent_team_goals = this.match.opponent_team_goals != null ? this.match.opponent_team_goals : 0
        this.accesstoken = await kick17.getAccessToken()
        this.uploadURL = kick17.getSituationVideoUploadURL()
        this.newMatchVideoFrame = this.match.matchVideoFrame != null &&  this.match.matchVideoFrame.length == 4 ? this.match.matchVideoFrame : [0,0,0,0] //Array.from({length: this.match.videos.length * 2}, () => 0)
        this.initTeamDots()
        this.loadCreatorTeam()

        this.isAdmin = await usermanager.isAdmin()
    },
    watch: {
        selectedSituation: function() {
            this.selectedDirection = this.match.situations[this.curSituationIndex].situation_start > this.match.matchVideoFrame[0] && this.match.situations[this.curSituationIndex].situation_start < this.match.matchVideoFrame[1] ? this.match.firstHalfDirection : (this.match.situations[this.curSituationIndex].situation_start > this.match.matchVideoFrame[2] && this.match.situations[this.curSituationIndex].situation_start < this.match.matchVideoFrame[3] ? (this.match.firstHalfDirection == "CREATOR_LEFT_TO_RIGHT" ? "CREATOR_RIGHT_TO_LEFT" : "CREATOR_LEFT_TO_RIGHT") : null)
            //console.log(this.selectedDirection)
            //this.setSecond({value: this.getLowestSecondOfSituationPositions()})
        },
        newPlayer: function(newVal) {
            editorStore.commit('setNewPlayers', [newVal])
        }
    },
    computed: {
        allSavedSeconds: function() {
            
            let allSavedSecondsArr = []
            if(this.match == null || this.match.situations.length == 0 || this.curSituationIndex == null){return allSavedSecondsArr} // || this.match.situations[this.curSituationIndex].situationPositions.length == 0
            this.match.situations[this.curSituationIndex].situationPositions.forEach(item => {
                allSavedSecondsArr.push(Number(item.second))
            })
            allSavedSecondsArr = [...new Set(allSavedSecondsArr)];
            return allSavedSecondsArr
        },
        penaltyPointLeft: function() {
            return (this.$refs.positionsetter.editorPitch.penaltyPointY / this.$refs.positionsetter.editorPitch.pitchHeight) * 100
        },
        match: function() {
            return editorStore.getters.getMatch
        },
        situationPlayersList: function() {
            return editorStore.getters.getSituationPlayersList
        },
        sortedSituations: function() {
            var situations = this.match.situations
            situations = situations.sort(function(a, b){
                return a.situation_start - b.situation_start;
            });
            return situations.filter(s => !(s.label[0] == "Einwurf" && (s.situation_end - s.situation_start < 5)))
        },
        detectedGoals: function() {
            if(this.match == null || this.match.situations == null) {return [0,0]}
            var goals = [0,0]
            goals[0] = this.match.situations.filter(sit => sit.label[0] == "Tor" && sit.isCreatorSituation == true).length
            goals[1] = this.match.situations.filter(sit => sit.label[0] == "Tor" && sit.isCreatorSituation == false).length
            
            return goals
        },
        newVideoLength() {
            var length = 0
            if(this.match == null || this.match.situations == null) {return length}
            for(var i = 0; i < this.match.situations.length; i++) {
                var situation = this.match.situations[i]
                if(situation.uuid != null && situation.situations_end != 0 && situation.videoNeeded != false) {
                    var diff = situation.situation_end - situation.situation_start
                    length += diff
                }
            }
            return length
        },
        markedSituationsLength: function() {
            var length = 0
            if(this.match == null || this.match.situations == null) {return length}
            for(var i = 0; i < this.match.situations.length; i++) {
                var situation = this.match.situations[i]
                if(situation.uuid != null && situation.situations_end != 0) {
                    var diff = situation.situation_end - situation.situation_start
                    length += diff
                }
            }
            return length
        },
        situationTupels: function() {
            if(!this.isMounted || this.match.situations == null || this.match == null) {
                return "noch null"
            }
            var videolaengen = this.$refs.myVideoPlayer.getVideoLengthArr()
            if(this.match.videos.length > videolaengen.length) {
                return "Nicht alle Video-Längen erfasst"
            }
            var all = []
            
            this.match.videos.forEach(function callback(curVideo) {
                var curObj = {"filename": curVideo, "triples": []}
                all.push(curObj)
            }.bind(this)); 

            this.match.situations.forEach(function callback(currentValue) {

                if(currentValue.videoNeeded == true && currentValue.situation_start != currentValue.situation_end) {
                    var curVidIndex = this.getVideoIndex(currentValue.situation_end)
                    var prevLength = this.getPreviousLength(curVidIndex)
                    var curTupel = [currentValue.uuid, currentValue.situation_start - prevLength, currentValue.situation_end - prevLength]
                    
                    all[curVidIndex]["triples"].push(curTupel)
                }
            }.bind(this)); 

            return all
        },
        matchItems: function() {
            if(this.match == null) {
                return null
            }
            var items = []
            for(var i = 0; i < this.match.videos.length; i++) {
                var lbl = this.match.videos[i]
                var item = {
                    label: lbl,
                    
                    command: () => {
                        this.download(lbl)
                    }
                }
                items.push(item)
            }
            
            return items
        },
        videolaengen: function() {
            if(!this.isMounted) {
                return 0
            }
            return this.$refs.myVideoPlayer.getVideoLengthArr()
        } 

    },
    methods: {
        async deleteSituation() {
            var res = await kick17.deleteSituation(this.match.uuid, this.match.situations[this.curSituationIndex].uuid)
           
            if(res.status == 200) {
                var tmpMatch = JSON.parse(JSON.stringify(this.match))

                tmpMatch.situations = res.data.situations
                editorStore.commit('setMatch', tmpMatch)
                this.nextSituation()
                this.$toast.add({severity:'info', summary:'Confirmed', detail:'Situation deleted', life: 3000});
            } else {
                this.$toast.add({severity:'error', summary:'Error', detail: 'Situation not deleted', life: 3000});
            }
        },
        deleteSituationConfirm() {
            this.$confirm.require({
                message: 'Do you want to delete this situation?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    this.deleteSituation()
                },
                reject: () => {
                    //this.$toast.add({severity:'error', summary:'Rejected', detail:'You have rejected', life: 3000});
                }
            });
        },
        getTranslated(label) {
            var gerLabelIndex = kick17.getSituationLabels().findIndex(l => l == label)
            return kick17.getSituationLabels("EN")[gerLabelIndex]
        },
        refreshNewMatchVideoFrame(index, value) {
            this.newMatchVideoFrame[index] = value
            var tmpMatch = JSON.parse(JSON.stringify(this.match))
            tmpMatch.matchVideoFrame = this.newMatchVideoFrame
            editorStore.commit('setMatch', tmpMatch)
            this.$refs.myVideoPlayer.refreshAllMarkers()
        },
        addcorner() {
            this.newPlayer.x = this.selectedDirection == this.direction.CREATOR_RIGHT_TO_LEFT ? (this.newPlayer.isInCreatorTeam == true ? 0 : 100) : (this.newPlayer.isInCreatorTeam == true ? 100 : 0), this.newPlayer.action_labels.push('Eckstoß')
        },
        addfreekick() {
            this.newPlayer.action_labels.push('Freistoß')
        },
        addpenalty() {
            this.newPlayer.y = 50, this.newPlayer.action_labels.push('Elfmeter'), this.newPlayer.action_labels.push('Schuss'), this.newPlayer.x = this.selectedDirection == this.direction.CREATOR_RIGHT_TO_LEFT ? (this.newPlayer.isInCreatorTeam == true ? this.penaltyPointLeft : 100 - this.penaltyPointLeft) : (this.newPlayer.isInCreatorTeam == true ? 100 - this.penaltyPointLeft : this.penaltyPointLeft)
        },
        addpass() {
            this.newPlayer.action_labels.push('Pass')
        },
        addattempt() {
            this.newPlayer.action_labels.push('Schuss')
        },
        addattemptontarget() {
            this.newPlayer.action_labels.push('Schuss'), this.newPlayer.action_labels.push('Torschuss')
        },
        addgoal() {
            this.newPlayer.action_labels.push('Schuss'), this.newPlayer.action_labels.push('Torschuss'), this.newPlayer.action_labels.push('Tor'), this.addPlayer(this.newPlayer)
        },
        addowngoal() {
            this.newPlayer.action_labels.push('Eigentor'), this.addPlayer(this.newPlayer)
        },
        addotheraction() {
            this.addPlayer(this.newPlayer)
        },
        handleNumber(nr) {
            var checkVal = null
            if(this.newPlayer == null) {return}
            if(this.selectedDirection != null && this.newPlayer.isInCreatorTeam == null && (nr == 1 || nr == 2)) {
                this.newPlayer.isInCreatorTeam = nr == 1 ? true : false
            }
            else if(this.newPlayer.isInCreatorTeam != null && this.newPlayer.action_labels.length == 0) {
               if(nr == 1) this.addcorner()
               if(nr == 2) this.addfreekick()
               if(nr == 3) this.addpenalty()
               if(nr == 4) this.addpass()
               if(nr == 5) this.addattempt()
               if(nr == 6) this.addattemptontarget()
               if(nr == 7) this.addgoal()
               if(nr == 8) this.addowngoal()
               if(nr == 9) this.addotheraction()
            }


            else if(this.newPlayer.action_labels.includes('Eckstoß')) {
                if(nr == 1) {
                    checkVal = "Flanke"
                    this.newPlayer.action_labels.includes(checkVal) ? this.newPlayer.action_labels.splice(this.newPlayer.action_labels.indexOf(checkVal), 1) : this.newPlayer.action_labels.push(checkVal)
                }
                if(nr == 2) {
                    checkVal = "Pass"
                    this.newPlayer.action_labels.includes(checkVal) ? this.newPlayer.action_labels.splice(this.newPlayer.action_labels.indexOf(checkVal), 1) : this.newPlayer.action_labels.push(checkVal)
                }
                if(this.newPlayer.action_labels.includes("Pass") || this.newPlayer.action_labels.includes("Flanke")) {
                    if(nr == 3) {
                        this.newPlayer.y = 0
                        this.addPlayer(this.newPlayer)
                    }
                    if(nr == 4) {
                        this.newPlayer.y = 100 
                        this.addPlayer(this.newPlayer)
                    }
                }
            }
            else if(this.newPlayer.action_labels.includes('Freistoß')) {
                if(nr == 1) {
                    checkVal = "Schuss"
                    this.newPlayer.action_labels.includes(checkVal) ? this.newPlayer.action_labels.splice(this.newPlayer.action_labels.indexOf(checkVal), 1) : this.newPlayer.action_labels.push(checkVal)
                }
                 else if(this.newPlayer.action_labels.includes('Schuss')) {
                    if(nr == 2) {
                        this.addPlayer(this.newPlayer, {number: 1, isInCreatorTeam: !this.newPlayer.isInCreatorTeam, y: 50, x: this.selectedDirection == this.direction.CREATOR_RIGHT_TO_LEFT ? (this.newPlayer.isInCreatorTeam == true ? 1 : 99) : (this.newPlayer.isInCreatorTeam == true ? 99 : 1)})
                    }
                    if(nr == 3) {
                        this.newPlayer.action_labels.push('Torschuss')
                        this.addPlayer(this.newPlayer, {number: 1, isInCreatorTeam: !this.newPlayer.isInCreatorTeam, y: 50, x: this.selectedDirection == this.direction.CREATOR_RIGHT_TO_LEFT ? (this.newPlayer.isInCreatorTeam == true ? 1 : 99) : (this.newPlayer.isInCreatorTeam == true ? 99 : 1)})
                    }
                    if(nr == 4) {
                        this.newPlayer.action_labels.push('Torschuss')
                        this.newPlayer.action_labels.push('Tor')
                        this.addPlayer(this.newPlayer, {number: 1, isInCreatorTeam: !this.newPlayer.isInCreatorTeam, y: 50, x: this.selectedDirection == this.direction.CREATOR_RIGHT_TO_LEFT ? (this.newPlayer.isInCreatorTeam == true ? 1 : 99) : (this.newPlayer.isInCreatorTeam == true ? 99 : 1)})
                    }
                    
                } 
                else if(nr == 2) {
                    checkVal = "Assist"
                    this.newPlayer.action_labels.includes(checkVal) ? this.newPlayer.action_labels.splice(this.newPlayer.action_labels.indexOf(checkVal), 1) : this.newPlayer.action_labels.push(checkVal)
                }
                else if(nr == 3) {
                    checkVal = "Angekommen"
                    this.newPlayer.action_labels.includes(checkVal) ? this.newPlayer.action_labels.splice(this.newPlayer.action_labels.indexOf(checkVal), 1) : this.newPlayer.action_labels.push(checkVal)
                }
                else if(nr == 4) {
                   this.newPlayer.action_labels.push('Flanke')
                   this.addPlayer(this.newPlayer, {number: 1, isInCreatorTeam: !this.newPlayer.isInCreatorTeam, y: 50, x: this.selectedDirection == this.direction.CREATOR_RIGHT_TO_LEFT ? (this.newPlayer.isInCreatorTeam == true ? 1 : 99) : (this.newPlayer.isInCreatorTeam == true ? 99 : 1)})
                
                } else if(nr == 5) {
                    this.newPlayer.action_labels.push('Pass')
                    this.addPlayer(this.newPlayer, {number: 1, isInCreatorTeam: !this.newPlayer.isInCreatorTeam, y: 50, x: this.selectedDirection == this.direction.CREATOR_RIGHT_TO_LEFT ? (this.newPlayer.isInCreatorTeam == true ? 1 : 99) : (this.newPlayer.isInCreatorTeam == true ? 99 : 1)})
                }
            }

            else if(this.newPlayer.action_labels.includes('Elfmeter')) {
                if(nr == 1) {
                    this.newPlayer.action_labels.push('Tor')
                    this.newPlayer.action_labels.push('Torschuss')
                    this.addPlayer(this.newPlayer, {number: 1, isInCreatorTeam: !this.newPlayer.isInCreatorTeam, y: 50, x: this.selectedDirection == this.direction.CREATOR_RIGHT_TO_LEFT ? (this.newPlayer.isInCreatorTeam == true ? 1 : 99) : (this.newPlayer.isInCreatorTeam == true ? 99 : 1)})
                } else if(nr == 2) {
                    this.addPlayer(this.newPlayer, {number: 1, isInCreatorTeam: !this.newPlayer.isInCreatorTeam, y: 50, x: this.selectedDirection == this.direction.CREATOR_RIGHT_TO_LEFT ? (this.newPlayer.isInCreatorTeam == true ? 1 : 99) : (this.newPlayer.isInCreatorTeam == true ? 99 : 1)})
                } else if(nr == 3) {
                    this.newPlayer.action_labels.push('Torschuss')
                    this.addPlayer(this.newPlayer, {number: 1, isInCreatorTeam: !this.newPlayer.isInCreatorTeam, action_labels:['Block'], y: 50, x: this.selectedDirection == this.direction.CREATOR_RIGHT_TO_LEFT ? (this.newPlayer.isInCreatorTeam == true ? 1 : 99) : (this.newPlayer.isInCreatorTeam == true ? 99 : 1)})
                } else if (nr == 4) {
                    this.newPlayer.action_labels.push('Torschuss')
                    this.addPlayer(this.newPlayer, {number: 1, isInCreatorTeam: !this.newPlayer.isInCreatorTeam, action_labels:['Ballbesitz'], y: 50, x: this.selectedDirection == this.direction.CREATOR_RIGHT_TO_LEFT ? (this.newPlayer.isInCreatorTeam == true ? 1 : 99) : (this.newPlayer.isInCreatorTeam == true ? 99 : 1)})
                }
            }

            else if((this.newPlayer.action_labels.includes('Pass') || this.newPlayer.action_labels.includes('Flanke')) && !this.newPlayer.action_labels.includes('Eckstoß') && !this.newPlayer.action_labels.includes('Freistoß')) {
                if(nr == 1) {
                    this.newPlayer.action_labels.splice(this.newPlayer.action_labels.indexOf('Pass'), 1)
                    this.newPlayer.action_labels.push('Flanke')
                    this.addPlayer(this.newPlayer)
                    //checkVal = "Flanke"
                    //this.newPlayer.action_labels.includes(checkVal) ? this.newPlayer.action_labels.splice(this.newPlayer.action_labels.indexOf(checkVal), 1) : this.newPlayer.action_labels.push(checkVal)
                }
                if(nr == 2) {
                    this.addPlayer(this.newPlayer)
                    //checkVal = "Pass"
                    //this.newPlayer.action_labels.includes(checkVal) ? this.newPlayer.action_labels.splice(this.newPlayer.action_labels.indexOf(checkVal), 1) : this.newPlayer.action_labels.push(checkVal)
                }
            }

            else if(this.newPlayer.action_labels.includes('Schuss') && !this.newPlayer.action_labels.includes('Torschuss') && !this.newPlayer.action_labels.includes('Tor') && !this.newPlayer.action_labels.includes('Elfmeter') && !this.newPlayer.action_labels.includes('Freistoß')) {
                if(nr == 1) {
                    checkVal = "Kopfball"
                    this.newPlayer.action_labels.includes(checkVal) ? this.newPlayer.action_labels.splice(this.newPlayer.action_labels.indexOf(checkVal), 1) : this.newPlayer.action_labels.push(checkVal)
                }
                if(nr == 2) {
                    this.addPlayer(this.newPlayer)
                }
                if(nr == 3) {
                    this.addPlayer(this.newPlayer, {number: 0, isInCreatorTeam: !this.newPlayer.isInCreatorTeam, action_labels:['Block'], y: 50, x: this.selectedDirection == this.direction.CREATOR_RIGHT_TO_LEFT ? (this.newPlayer.isInCreatorTeam == true ? 4 : 96) : (this.newPlayer.isInCreatorTeam == true ? 96 : 4)})
                }
            }

            else if(this.newPlayer.action_labels.includes('Torschuss') && !this.newPlayer.action_labels.includes('Tor') && !this.newPlayer.action_labels.includes('Elfmeter')) {
                if(nr == 1) {
                    checkVal = "Kopfball"
                    this.newPlayer.action_labels.includes(checkVal) ? this.newPlayer.action_labels.splice(this.newPlayer.action_labels.indexOf(checkVal), 1) : this.newPlayer.action_labels.push(checkVal)
                }
                if(nr == 2) {
                    this.newPlayer.action_labels.push('Torschuss')
                    this.addPlayer(this.newPlayer, {number: 1, isInCreatorTeam: !this.newPlayer.isInCreatorTeam, action_labels:['Block'], y: 50, x: this.selectedDirection == this.direction.CREATOR_RIGHT_TO_LEFT ? (this.newPlayer.isInCreatorTeam == true ? 1 : 99) : (this.newPlayer.isInCreatorTeam == true ? 99 : 1)})
                }
                if(nr == 3) {
                    this.newPlayer.action_labels.push('Torschuss')
                    this.addPlayer(this.newPlayer, {number: 1, isInCreatorTeam: !this.newPlayer.isInCreatorTeam, action_labels:['Ballbesitz'], y: 50, x: this.selectedDirection == this.direction.CREATOR_RIGHT_TO_LEFT ? (this.newPlayer.isInCreatorTeam == true ? 1 : 99) : (this.newPlayer.isInCreatorTeam == true ? 99 : 1)})
                }
                if(nr == 4) {
                    this.newPlayer.action_labels.push('Latte')
                    this.addPlayer(this.newPlayer)
                }
                if(nr == 5) {
                    this.newPlayer.action_labels.push('Pfosten')
                    this.addPlayer(this.newPlayer)
                }
            }



        },
        keyUpHandler(event) {
            if(isFinite(event.key)) {
                this.handleNumber(event.key)
                return
            }
            switch(event.code) {
                case 'KeyA':
                    if(this.currentVideoTimestamp >= this.match.situations[this.curSituationIndex].situation_start && this.currentVideoTimestamp <= this.match.situations[this.curSituationIndex].situation_end) {
                        this.addPlayerBlank()
                    }
                    break;
                case 'KeyC':
                    this.deleteNewPlayer()
                    break;
                /*case 'KeyT':
                    this.selectedDirection = this.selectedDirection == this.direction.CREATOR_RIGHT_TO_LEFT ? this.direction.CREATOR_LEFT_TO_RIGHT : this.direction.CREATOR_RIGHT_TO_LEFT 
                    break;*/
                /*case 'ArrowRight':
                    if(this.curTime == null) {return}
                    this.setTime(this.curTime + 3)
                    break;
                case 'ArrowLeft':
                    if(this.curTime == null) {return}
                    this.setTime(this.curTime - 3)
                    break;*/
                default:
                    //break;
            }
        },
        async loadCreatorTeam() {
            var res = await admin.getTeam(this.match.creator_team.uuid)
            this.creatorTeamPlayers = res.data.players
        },
        resizedPlayer() {
            if(!this.isMounted) {return}
            this.$refs.myVideoPlayer.refreshAllMarkers()
        },
        async saveResult() {
            var result = await kick17.setmatchresult(this.match.uuid, this.creator_team_goals, this.opponent_team_goals)
            this.match = result.data
        },
        async saveFirstHalfDirection() {
            var result = await kick17.firsthalfdirection(this.match.uuid, this.match.firstHalfDirection)
            this.match = result.data
            if(result.status == 200) {
                this.$toast.add({severity:'success', summary: 'Direction Done', detail:'Direction done', life: 3000});
            } else {
                this.$toast.add({severity:'error', summary: 'Direction not Done', detail:'Direction not done', life: 3000});
            }
        },
        async saveMatchVideoFrame() {
            await kick17.saveMatchVideoFrame(this.match.uuid, Object.values(this.newMatchVideoFrame))
            this.$toast.add({severity:'success', summary: 'Frame saved', detail:'Frame saved', life: 3000});
            //console.log(Object.values(this.newMatchVideoFrame))
        },
        videoclick(evt) { //evt
            if(this.curSituationIndex != null && this.selectedSecond != null) {
                this.$refs.positionsetter.setVideoPoint(evt)
            }
        },
        setVideoToTimestamp(ts) {
            this.currentVideoTimestamp = ts
            this.$refs.myVideoPlayer.setTime(this.currentVideoTimestamp)
        },
        async situationPositionsFinished() {
            
                var res = await kick17.finishsituationpositions(this.match.uuid)
                if(res.status == 200) {
                this.$toast.add({severity:'success', summary: 'Positions Done', detail:'Positions done', life: 3000});
                } else {
                    this.$toast.add({severity:'error', summary: 'Positions not Done', detail:'Positions not done', life: 3000});
                }
            
            
        },
        deleteSituationPositions() {
            this.curSituationIndex
            
        },
        nextSituation() {
            this.curSituationIndex = this.curSituationIndex +2 > this.match.situations.length ? this.match.situations.length - 1 : this.curSituationIndex + 1

            editorStore.commit('setSituationPlayersList', []) 
            this.situationClick(this.match.situations[this.curSituationIndex])
            this.selectedSituation = this.match.situations[this.curSituationIndex]
            //this.setSecond({value: this.getLowestSecondOfSituationPositions()})
        },
        setSituation(event) {
            var dotIndex = this.match.situations.findIndex(sit => sit.uuid == event.value.uuid)
            this.curSituationIndex = dotIndex
            editorStore.commit('setSituationPlayersList', []) 
            //this.initTeamDots()
            //this.getAllSavedSeconds()

            this.situationClick(this.match.situations[dotIndex])

           
        },
        setSecond(event) {
            this.selectedSecond = event.value
            var curVidIndex = this.getVideoIndex(this.match.situations[this.curSituationIndex].situation_start + this.selectedSecond)
            var prevLength = this.getPreviousLength(curVidIndex)


           
            
            this.currentVideoTimestamp = this.selectedSecond + this.match.situations[this.curSituationIndex].situation_start - prevLength
            this.setSitPositionsBySecond(this.selectedSecond - prevLength)
            this.$refs.myVideoPlayer.setTime(this.currentVideoTimestamp)
            
        },
        addNewPlayer(player) {
            
            
            var random = (Math.random() + 1).toString(36).substring(7);
            player.player_uuid = random
            
            //player.direction = player.direction == null ? this.selectedDirection : null
            this.newPlayer = player
            this.displayBasic = true
            
        },
        addPlayer(player, additionalPlayer) {
            
            editorStore.commit('setSituationPlayersList', this.situationPlayersList.concat(player)) 
            if(additionalPlayer) {
                let random = (Math.random() + 1).toString(36).substring(7);
                var otherPlayer = {player_uuid: random, second: Number(this.currentVideoTimestamp).toFixed(0), direction: this.selectedDirection, number: 0}
                otherPlayer.x = additionalPlayer.x
                otherPlayer.y = additionalPlayer.y 
                otherPlayer.isInCreatorTeam = additionalPlayer.isInCreatorTeam
                otherPlayer.action_labels = additionalPlayer.action_labels != null ? additionalPlayer.action_labels : []
                additionalPlayer.number = additionalPlayer.number != null ? additionalPlayer.number : 0
                editorStore.commit('setSituationPlayersList', this.situationPlayersList.concat(otherPlayer)) 
            }
            //this.$refs.positionsetter.makePlayerList()
            this.newPlayer = null
            editorStore.commit('isPlayEditing', false)
            this.displayBasic = false
        },
        async savePositions() {
            if(this.curSituationIndex == null || this.situationPlayersList == null) {
                alert("select a situation")
                return 
            }
            if(this.selectedDirection == null) {
                alert("select direction")
                return
            }
            var timestamp = this.currentVideoTimestamp - this.match.situations[this.curSituationIndex].situation_start // this.selectedSecond == null || this.selectedSecond === undefined ? this.currentVideoTimestamp - this.match.situations[this.curSituationIndex].situation_start : this.selectedSecond
 
            var res = await kick17.saveSituationPositions(this.match.uuid, this.match.situations[this.curSituationIndex].uuid, this.situationPlayersList, Number(timestamp).toFixed(0), this.selectedDirection)
            if(res.status == 200) {
                this.$toast.add({severity:'success', summary: 'Positions saved', detail:'Positions saved', life: 3000});
                editorStore.commit('setMatch', res.data)
            } else {
                this.$toast.add({severity:'error', summary: 'Positions not saved', detail:'Positions not saved', life: 3000});
            }
        },
        playerchanged(index, player) {
            //console.log(player)
            var tmpList = JSON.parse(JSON.stringify(this.situationPlayersList))
            editorStore.commit('setSituationPlayersList', tmpList) 
            tmpList[index] = JSON.parse(JSON.stringify(player))
            console.log(player)
            //this.$refs.positionsetter.makePlayerList()
        },
        deletePlayer(index) {
            //this.situationPlayersList.splice(index, 1);
            var tmpList = JSON.parse(JSON.stringify(this.situationPlayersList))
            tmpList.splice(index, 1);
            editorStore.commit('setSituationPlayersList', tmpList) 
            //this.$refs.positionsetter.makePlayerList(this.situationPlayersList)
        },
        /*switchSides() {
            this.selectedDirection = this.selectedDirection == this.direction.CREATOR_RIGHT_TO_LEFT ?  this.direction.CREATOR_LEFT_TO_RIGHT : this.direction.CREATOR_RIGHT_TO_LEFT
        },*/
        getOppositeDots() {
            let oppDots = []
            let curDot = null
            
            for(var i = 0; i < this.defaultDots.length; i++) {
                curDot = JSON.parse(JSON.stringify(this.defaultDots[i]))
                curDot.x = 100 - curDot.x
                curDot.y = 100 - curDot.y
                curDot.isInCreatorTeam = false
                curDot.player_uuid = 12 + i
                oppDots.push(curDot)
            }
            return oppDots
        },
        /*getAllSavedSeconds() {
            
            let allSavedSeconds = []
            if(this.match == null || this.match.situations.length == 0 || this.curSituationIndex == null){return allSavedSeconds} // || this.match.situations[this.curSituationIndex].situationPositions.length == 0
            this.match.situations[this.curSituationIndex].situationPositions.forEach(item => {
                allSavedSeconds.push(Number(item.second))
            })
            this.allSavedSeconds = [...new Set(allSavedSeconds)];
            
   
            //this.selectedSecond = this.selectedSecond == null ? this.allSavedSeconds[0] : this.selected
            return this.allSavedSeconds
        },*/
        getHighestSecondOfSituationPositions() {
            var allSeconds = this.allSavedSeconds
            return Math.max(...allSeconds)
        },
        getLowestSecondOfSituationPositions() {
            var allSeconds = this.allSavedSeconds
            return Math.min(...allSeconds)
        },
        initTeamDots() {
            if(this.curSituationIndex == null || (this.match.situations != null && this.match.situations.length > 0 && this.match.situations[this.curSituationIndex].situationPositions != null && this.match.situations[this.curSituationIndex].situationPositions.length == 0)) {
               return
                //this.currentVideoTimestamp = 0
                //this.$refs.myVideoPlayer.setTime(this.currentVideoTimestamp)
            } else {
                let second = this.getHighestSecondOfSituationPositions()
                this.currentVideoTimestamp = second
                this.$refs.myVideoPlayer.setTime(this.currentVideoTimestamp)
                this.setSitPositionsBySecond(second)
            }
        },
        setSitPositionsBySecond(targetSecond) {
            var tmpList = this.match.situations[this.curSituationIndex].situationPositions.filter(sitpo => sitpo.second == this.selectedSecond)
            editorStore.commit('setSituationPlayersList', tmpList) 
            this.selectedDirection = this.match.situations[this.curSituationIndex].direction != null && this.match.situations[this.curSituationIndex].direction != "null" ? this.match.situations[this.curSituationIndex].direction : this.direction.CREATOR_LEFT_TO_RIGHT
            this.selectedSecond = Number(targetSecond).toFixed(0)
        },
        addCorner() {
            let random = (Math.random() + 1).toString(36).substring(7);
            this.newPlayer = {player_uuid: random, x: 0, y: 0, second: Number(this.currentVideoTimestamp).toFixed(0), isInCreatorTeam: true, direction: this.selectedDirection, number: 0, action_labels: ["Eckstoß"]}
            this.displayBasicCorner = true
        },
        addCornerPlayer(player) {
            if(this.selectedDirection == "CREATOR_LEFT_TO_RIGHT") {
                player.x = player.isInCreatorTeam ? 100 : 0
            } else {
                player.x = player.isInCreatorTeam ? 0 : 100
            }
            
            //this.situationPlayersList = this.situationPlayersList.concat(this.newPlayer)
            editorStore.commit('setSituationPlayersList', this.situationPlayersList.concat(player))
            this.displayBasicCorner = false
            setTimeout(function(){
                this.$refs.positionsetter.addCorner(player.player_uuid)
            }.bind(this), 100);
        },
        deleteNewPlayer() {
            this.newPlayer = null
            editorStore.commit('isPlayEditing', false)
            this.displayBasic = false
        },
        addPlayerBlank() {
            let random = (Math.random() + 1).toString(36).substring(7);
            this.newPlayer = {player_uuid: random, x: 50, y: 50, second: Number(this.currentVideoTimestamp).toFixed(0), direction: this.selectedDirection, number: 0, action_labels: []}
            editorStore.commit('isPlayEditing', true)
            this.displayBasic = true

        },
        /*addTeamDots() {
            this.situationPlayersList = []
            this.situationPlayersList = this.situationPlayersList.concat(this.defaultDots)
            this.situationPlayersList = this.situationPlayersList.concat(this.getOppositeDots())
            //alert(this.situationPlayersList)
            //this.$refs.positionsetter.makePlayerList()
            
        },*/
        loadPositionSetter() {
            setTimeout(function(){
               
                    this.$refs.positionsetter.resize()
                
            }.bind(this), 500);
            
        },
        async open() {
            setTimeout(function(){
                this.$refs.positionsetter.resize()
            }.bind(this), 500);
        },
        getVideoIndex(timeCount) {
            if(!this.isMounted || this.match.situations == null || this.match == null) {
                return "noch null"
            }
            var videolaengen = this.videolaengen
            var curLaenge = 0
            for(var i = 0; i < videolaengen.length; i++) {
                curLaenge += videolaengen[i]
                if(timeCount <= curLaenge) {
                    return i
                }
            }
            return null
        },
        async download(filename) {
            this.loading = true
            await kick17.downloadVideo(filename)//this.match.videos[0])
            .catch(()=>{ 
                alert("Download failed")
            })
            .finally(()=>{ 
                this.loading = false
            });
            
        },
        getFormatedTime(sec) {
            let totalSeconds = sec;
            let hours = Math.floor(totalSeconds / 3600);
            totalSeconds %= 3600;
            let minutes = Math.floor(totalSeconds / 60);
            let seconds = totalSeconds % 60;

            minutes = String(minutes).padStart(2, "0");
            hours = String(hours).padStart(2, "0");
            seconds = String(seconds).padStart(2, "0");
            return hours + ":" + minutes + ":" + seconds + ".000"
        },
        addSituation() {
            this.newSituation = null
            this.$refs.myVideoPlayer.refreshAllMarkers()
        },
        newSituationEdit(sit) {
            
            this.newSituation = sit
            this.$refs.myVideoPlayer.refreshAllMarkers()
            //videocutter.cutvideo(video_url, new_video_name, start, end)
        },
        deletePlayerList() {
            editorStore.commit('setSituationPlayersList', [])
        },

        refreshVideos() {
            //var image = require("../../assets/kick17.png")
            var sourceArr = []
            for(var i = 0; i < this.match.videos.length; i++) {
                var source = {
                    type: "video/mp4",
                    src: kick17.getVideoURL() + this.match.videos[i]
                    //poster: image
                }
                sourceArr.push(source)
            }
            this.videoOptions.sources = sourceArr
           
        },
        situationClick(situation) {
            var curVidIndex = this.getVideoIndex(situation.situation_end)
            var prevLength = this.getPreviousLength(curVidIndex)
            this.$refs.myVideoPlayer.setTime(situation.situation_start - prevLength, curVidIndex)
        },
        createEmptySituation() {
            this.$refs.addsit.createEmptySituation()
        },
        async beforeSend(request) {
            request.xhr.setRequestHeader("matchUuid", this.match.uuid);
            request.xhr.setRequestHeader("Authorization", this.accesstoken);
            return request
        },
        uploadDone(response) {
            if(response.xhr.status==200) {
                console.log(response.xhr)
            }
        },
        saveFile() {
            var videolaengen = this.videolaengen
            if(this.match.videos.length > videolaengen.length) {
                alert("Nicht alle Video-Längen erfasst")
            } 
            else {
                var blob = new Blob([JSON.stringify(this.situationTupels)], {type: "text/plain;charset=utf-8"});
                var filename = "config"
                if (filename== "" || filename == null) {filename = "no_address"}
                saveAs.saveAs(blob, filename + ".kick17")
            }
        },
        async videosDone() {
            this.loading = true
            await kick17.finishshortvideos(this.match.uuid)
            this.loading = false
        },
        getPreviousLength(curIndex) {
            var videolaengen = this.videolaengen
            var result = 0
            if(curIndex == 0) {
                return result
            }
            else {
                for(var i = 0; i < curIndex; i++) {
                    var lastIndex = i
                    result = result + videolaengen[lastIndex]
                }
            }
            return result
        },
        getMinute(situation) {
            return kick17.getMinuteOfSituation(situation, this.match)
            /*var matchBegin = new Date(this.match.match_date)
            if(situation.custom_marker == null) {
                return ((situation.situation_start - this.match.matchVideoFrame[0])/(60)).toFixed(0)
                
            } else {
                var diff = situation.custom_marker - matchBegin.getTime()
                return (diff/(1000*60)).toFixed(0)
            }*/
            
            
            
        },
        async changeTeamColors() {
            await kick17.setcolors(this.match.uuid, this.mycolor, this.opponentcolor)
            this.match.creatorcolor = this.mycolor
            this.match.opponentcolor = this.opponentcolor
            this.$toast.add({severity:'success', summary: 'Colors saved', detail:'Colors saved', life: 3000});
        },
        
    }
  }
</script>

<style scoped lang="scss">
@import '~bootstrap/scss/bootstrap.scss';

.signup-form {
  width: 100%;
}


.mdl-button--fab_flinger-container {
 position: fixed;
 bottom: 10px;
 right: 10px;
}
.mdl-button--fab_flinger-container.is-showing-options > button i {
  transition: transform 0.1s linear;
  transform: translate(-12px, -12px) rotate(45deg);
}
.mdl-button--fab_flinger-container.is-showing-options .mdl-button--fab_flinger-options {
  display: flex;
  flex-direction: column-reverse;
}
.mdl-button--fab_flinger-container.is-showing-options .mdl-button--fab_flinger-options button {
  display: block;
  animation-name: enter;
  animation-fill-mode: forwards;
  animation-duration: 0.1s;
  transform-origin: bottom center;
}
.mdl-button--fab_flinger-container.is-showing-options .mdl-button--fab_flinger-options button:nth-of-type(1) {
  animation-delay: 0.1s;
}
.mdl-button--fab_flinger-container.is-showing-options .mdl-button--fab_flinger-options button:nth-of-type(2) {
  animation-delay: 0.2s;
}
.mdl-button--fab_flinger-container.is-showing-options .mdl-button--fab_flinger-options button:nth-of-type(3) {
  animation-delay: 0.3s;
}
.mdl-button--fab_flinger-container.is-showing-options .mdl-button--fab_flinger-options button:nth-of-type(4) {
  animation-delay: 0.4s;
}
.mdl-button--fab_flinger-container.is-showing-options .mdl-button--fab_flinger-options button:nth-of-type(5) {
  animation-delay: 0.5s;
}
.mdl-button--fab_flinger-container.is-showing-options .mdl-button--fab_flinger-options button:nth-of-type(6) {
  animation-delay: 0.6s;
}
.mdl-button--fab_flinger-container .mdl-button--fab_flinger-options {
  position: absolute;
  bottom: 100%;
  margin-bottom: 10px;
}
.mdl-button--fab_flinger-container .mdl-button--fab_flinger-options button {
  transform: scale(0);
  display: none;
}
@keyframes enter {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(0.8);
  }
}
</style>