<template>
<div class="konvaContainer" id="current-positions-canvas">
    <v-stage
      ref="stage"
      :config="configKonva"
      
    >
      <v-layer ref="layer">
        <v-circle
          v-for="item in playerList"
          @dragstart="handleDragstartPlayer"
          :key="item.id"
          :config="{
            x: item.x,
            y: item.y,
            id: item.id,
            radius: item.radius,
            fill: '#89b717',
            opacity: 0.8,
            draggable: true,
            scaleX: dragItemId === item.id ? item.scale * 1.2 : item.scale,
            scaleY: dragItemId === item.id ? item.scale * 1.2 : item.scale,
            shadowColor: 'black',
            shadowBlur: 10,
            shadowOffsetX: dragItemId === item.id ? 15 : 5,
            shadowOffsetY: dragItemId === item.id ? 15 : 5,
            shadowOpacity: 0.6,
          }"
        ></v-circle>

        <v-line 
          v-for="item in offsideLines"
          @dragmove="dragLine"
          @dragend="dragLineEnd"
          :key="item.id"
          :config="{
            id: item.id,
            points: [item.x1, 0, item.x2, item.y2],
            stroke: 'black',
            draggable: true,
            strokeWidth: 4,
          }"
        ></v-line>
        
      </v-layer>
    </v-stage>

    </div>
</template>


<style>

</style>
<script>
import { ref, onMounted, toRefs, computed, watch } from 'vue'


export default {
  props:['frames_data', 'container', 'colors', 'visibility', 'current_second'],
  setup(props) {
        const { frames_data } = toRefs(props)
        const { container } = toRefs(props)
        const { current_second } = toRefs(props)

        var canvasWidth = ref(0)
        var configKonva = ref([])
        var playerList = ref([])
        var widthRatio = ref([])
        var heightRatio = ref([])
        var offsideLines = ref([])
        var circles = ref([])

        configKonva.value = {
            width: 0,
            height: 0,
        }


        onMounted(() => {
            window.addEventListener("resize", resize);
            var mainDIV = document.getElementById("current-positions-canvas")
            canvasWidth.value = mainDIV.offsetWidth
            initContainer()
            makePlayerList()
        })

        watch(current_second, () => {
            makePlayerList()
        })

      

        const resize = function() {
            console.log("resizeHandler")
            var mainDIV = document.getElementById("current-positions-canvas")
            canvasWidth.value = mainDIV.offsetWidth
            
            initContainer()
            makePlayerList()
            resizeOffsideLines()
            
        }

        const resizeOffsideLines = function() {
            

            // x1_old / canvasWidth_old = x1_new / canvasWidth_new
            offsideLines.value.forEach((item) => {
                var a = (canvasWidth.value * item.x_percentage).toFixed(0)
                console.log("a: " + a)
                    item.x1 = a
                    item.x2 = item.x1
                    item.y2 = canvasHeight.value
            });
            console.log(offsideLines.value)
            console.log(canvasHeight.value)
        }


        var canvasHeight = computed(() => {
            return canvasWidth.value * 2/3
        })


        const initContainer = function() {
            configKonva.value.width = canvasWidth.value
            configKonva.value.height = canvasHeight
            var img = require("../../assets/rawPitch.jpg") 
            var konvaContainer = document.getElementById("current-positions-canvas")
            
            var mainDivRectangle = konvaContainer.getBoundingClientRect()
            konvaContainer.style.top = mainDivRectangle.top + "px"
            konvaContainer.style.left = mainDivRectangle.left + "px"
            konvaContainer.style.height = mainDivRectangle.width * (2 / 3) + "px"
            konvaContainer.setAttribute("style", "background-image: url(" + img + ");background-repeat: no-repeat;background-size: " + konvaContainer.offsetWidth  + "px " + (konvaContainer.offsetWidth * 2 / 3) +"px");
            widthRatio.value = Number((mainDivRectangle.width / frames_data.value.metadata.width)).toFixed(2)
            heightRatio.value = Number((mainDivRectangle.width * (2 / 3) / frames_data.value.metadata.height)).toFixed(2)
            console.log("initContainer done")
        }

        const makePlayerList = function() {
            
            playerList.value = []
            const fps = frames_data.value.metadata.fps
            const mainDIV = document.getElementById(container.value)
            
            var mainDivRectangle = mainDIV.getBoundingClientRect()
            if(frames_data.value != null) {
                
                var currentFrame = Math.ceil(fps * (Math.round(current_second.value * 4) / 4).toFixed(2) )
                
                
                var currentFrameObject = frames_data.value.frames["frame_" + currentFrame]
                if(typeof(currentFrameObject) != 'undefined' || currentFrameObject != null) {
                    for(var i = 0; i < currentFrameObject.positions.length; i++) {
                        var curColor = '#314FCD'
                        playerList.value.push({
                            id: String(currentFrameObject.positions[i].player_id),
                            x: currentFrameObject.positions[i].x * widthRatio.value,
                            y: currentFrameObject.positions[i].y * heightRatio.value + (mainDivRectangle.height * 0.1),
                            radius: 8,
                            fill: curColor
                        });
                    }
                }
            }
        }
        
        const addOffsideLine = function() {
            var newLine = { 
                id: Math.round(Math.random() * 10000).toString(), 
                x1: configKonva.value.width / 2, 
                y1: 0, 
                x2: configKonva.value.width / 2, 
                y2: configKonva.value.height, 
                x_percentage: 0.5,
                x_original: configKonva.value.width / 2
            }
            offsideLines.value.push(newLine)
            
        }

        var dragItemId = null
        const handleDragstartPlayer = function(e) {
            dragItemId = e.target.id();
           
            const item = playerList.value.find((i) => i.id === dragItemId);
            const index = playerList.value.indexOf(item);
            playerList.value.splice(index, 1);
            playerList.value.push(item);
        }
        
        const handleDragend = function() {
            dragItemId = null;
        }

        const dragLineEnd = function(e) {
            console.log(e.target.attrs)
            console.log("abs pos: " + JSON.stringify(e.target.absolutePosition()))
            dragItemId = e.target.id()
            const item = offsideLines.value.find((i) => i.id === dragItemId);
            const lineIndex = offsideLines.value.indexOf(item);
            offsideLines.value[lineIndex].x1 = e.target.attrs.points[0]
            offsideLines.value[lineIndex].x2 = offsideLines.value[lineIndex].x1
            offsideLines.value[lineIndex].y1 = 0
            offsideLines.value[lineIndex].y2 = configKonva.value.height
            offsideLines.value[lineIndex].x_percentage = offsideLines.value[lineIndex].x1 / canvasWidth.value
            dragItemId = null;
            console.log(offsideLines.value[lineIndex].x_percentage)
            dragLine(e)
        }
        const dragLine = function(e) {
            var node = e.target //.absolutePosition());
            node.absolutePosition({
                x: node.absolutePosition().x,
                y: 0
            });
        }

        return {
            handleDragstartPlayer,
            handleDragend,
            configKonva,
            dragItemId,
            playerList,
            offsideLines,
            circles,
            addOffsideLine,
            dragLineEnd,
            dragLine
        }


  }








  /*data () {
    return {
        stage: null,
        transformer: null,
        konvaContainer: null,
        konvaLayer: null,
        velocity_unit: null,
        fps: null,
        lines: [],
        circles: [],
        rectangles:[],
        selectedShapeName: ''
    }
  },
  computed: {
      mainDIV: function() {
          return document.getElementById(this.container)
      },
      mainDivRectangle: function() {
          return this.mainDIV.getBoundingClientRect()
      },
      rectangleHeight: function() {
          return this.mainDivRectangle.width * (2 / 3)
      },
      widthRatio: function() {
          return Number((this.mainDivRectangle.width / this.frames_data.metadata.width)).toFixed(2)
      },
      heightRatio: function() {
          return Number((this.rectangleHeight / this.frames_data.metadata.height)).toFixed(2)
      }
  },
  async created () {
      window.addEventListener("resize", this.resizeContainer); 
    this.initValues()
    
  },
  async mounted () {
      this.initValues()
        this.initContainer()
        this.initStage()
        this.initKonvaLayer()
        this.initTransformer()
        //this.addOffsideLine()   
        this.paintLines()
        this.paintCircles()
        this.paintRectangles()
        
    },
  async updated() {
    this.resizeContainer()
  },
  watch: {
    frames_data: function (newVal) {
       if(newVal != null && newVal != "") {
           this.initValues()
           this.drawPlayers()
           this.paintLines()
           this.paintCircles()
           this.paintRectangles()
       }
    },
    current_second: function (newVal) {
        console.log(newVal)
       if(newVal != null) {
           
               this.clearTransformer()
               this.konvaLayer.destroy()
               this.initKonvaLayer()
               this.initTransformer()
               this.drawPlayers()
               this.paintLines()
               this.paintCircles()
               this.paintRectangles()
           
       }
    },
    visibility: function (newVal) {
       if(newVal != null) {
            this.konvaContainer.style.visibility = newVal
            this.drawPlayers()
            this.paintLines()
       }
    },
    
  },

    methods: {
        initTransformer() {
            this.transformer = new Konva.Transformer()
            this.konvaLayer.add(this.transformer);
            
            this.stage.add(this.konvaLayer);
        },
        resizeContainer() {
            if(this.konvaLayer != null) {
                this.konvaLayer.clear()
                var img = require("../../assets/rawPitch.jpg") 
                this.konvaContainer.style.width = this.mainDIV.offsetWidth
                this.konvaContainer.style.height = this.rectangleHeight + "px"
                this.konvaContainer.setAttribute("style", "background-image: url(" + img + ");background-repeat: no-repeat;background-size: " + this.mainDIV.offsetWidth  + "px " + (this.mainDIV.offsetWidth * 2 / 3) +"px");
                this.initKonvaLayer()
            }
            this.drawPlayers()
        },
        initValues() {
           
            this.velocity_unit = this.frames_data.metadata.distance_unit + " / " + this.frames_data.metadata.time_unit
            this.fps = this.frames_data.metadata.fps
        },
        initStage() {

            this.stage = new Konva.Stage({
                    container: this.konvaContainer,
                    width: this.mainDivRectangle.width,
                    height: this.rectangleHeight,
                });
            //this.stage.on('click', function (e) {alert("click")});
            this.stage.on('mousedown', function (e) {this.handleStageMouseDown(e)}.bind(this));

            //this.stage.on('mousedown', function (e) {alert("circle mousedown")});
        },
   
        initContainer() {

            this.rectangleHeight
            var img = require("../../assets/rawPitch.jpg") 

            this.konvaContainer = document.createElement("div");
            this.konvaContainer.id     = "current-positions-canvas";
            this.konvaContainer.style.position = "absolute"
            this.konvaContainer.style.top = this.mainDivRectangle.top + "px"
            this.konvaContainer.style.left = this.mainDivRectangle.left + "px"
            this.konvaContainer.style.width = this.mainDIV.offsetWidth
            this.konvaContainer.style.height = this.rectangleHeight + "px"
            this.konvaContainer.setAttribute("style", "background-image: url(" + img + ");background-repeat: no-repeat;background-size: " + this.mainDIV.offsetWidth  + "px " + (this.mainDIV.offsetWidth * 2 / 3) +"px");
            this.konvaContainer.style.zIndex = "10"
            this.konvaContainer.style.visibility = this.visibility
            this.mainDIV.appendChild(this.konvaContainer)
        },
        getIndexByUUID(objArr, uuid) {
            for(var i = 0; i < objArr.length; i++){
                if(objArr[i].uuid == uuid) {
                    return i
                }
            }
            return null
        },
        changeLinePosition(uuid, newX) {
            var lineIndex = this.getIndexByUUID(this.lines, uuid)
            console.log("lineIndex: " + lineIndex)
            this.lines = JSON.parse(JSON.stringify(this.lines))
            this.lines[lineIndex].x1 = newX
            this.lines[lineIndex].x2 = newX
            this.lines[lineIndex].y1 = 0
            this.lines[lineIndex].y2  = this.rectangleHeight
            console.log(lineIndex)
            console.log(newX)
        },
        changeRectanglePosition(uuid, newX, newY) {
            var rectIndex = this.getIndexByUUID(this.rectangles, uuid)
            console.log("rectIndex: " + rectIndex)
            this.rectangles = JSON.parse(JSON.stringify(this.rectangles))
            this.rectangles[rectIndex].x = newX
            this.rectangles[rectIndex].y = newY
        },
        changeCirclePosition(uuid, newX, newY) {
            var circleIndex = this.getIndexByUUID(this.circles, uuid)
            console.log("circleIndex: " + circleIndex)
            this.circles = JSON.parse(JSON.stringify(this.circles))
            this.circles[circleIndex].x = newX
            this.circles[circleIndex].y = newY
        },
        getRandomString(len) {
            let text = ""
            let chars = "abcdefghijklmnopqrstuvwxyz"
            for( let i=0; i < len; i++ ) {
                text += chars.charAt(Math.floor(Math.random() * chars.length))
            }
			return text
        },
        async addOffsideLine() {
           var newLine = { "uuid": this.getRandomString(4), "x1": this.mainDivRectangle.width / 2, "y1": 0, "x2": this.mainDivRectangle.width / 2, "y2": this.rectangleHeight, canvasWidth: this.mainDivRectangle.width}
            this.lines = this.lines.concat(newLine)
            let line = new Konva.Line({
                    points: [ newLine.x1, 0, newLine.x2, this.mainDivRectangle.width],
                    stroke: 'black',
                    strokeWidth: 4,
                    lineCap: 'round',
                    lineJoin: 'round',
                    draggable: true,
                    key: newLine.uuid
                })
            line.on('click', function (e) {alert("line" + e)});
            line.on('dragend', function (e) {
                console.log(e)
                this.changeLinePosition(e.currentTarget.attrs.key, e.target.attrs.points[0] + e.target.position().x) //e.evt.x
            }.bind(this))
            this.konvaLayer.add(line);
            this.stage.add(this.konvaLayer);
                       
        },
        transformCircle(uuid, scaleX, scaleY, x, y, rotation) {
            var circleIndex = this.getIndexByUUID(this.circles, uuid)
            this.circles[circleIndex].scaleX = scaleX
            this.circles[circleIndex].scaleY = scaleY
            this.circles[circleIndex].x = x
            this.circles[circleIndex].y = y
            this.circles[circleIndex].rotation = rotation != null ? rotation : 0
        },
        addRectangle() {
            var newRectangle = {"uuid": this.getRandomString(4), "x": this.mainDivRectangle.width / 2, "y": this.mainDivRectangle.width / 2, "width": 50, "height": 50, canvasWidth: this.mainDivRectangle.width, scaleX: 1, scaleY: 1, rotation: 0}
            this.rectangles = this.rectangles.concat(newRectangle)
            let rect = new Konva.Rect({
                x: newRectangle.x,
                y: newRectangle.y,
                width: newRectangle.width,
                height: newRectangle.height,
                scaleX: newRectangle.scaleX,
                scaleY: newRectangle.scaleY,
                stroke: 'black',
                draggable: true,
                name: newRectangle.uuid,
                key: newRectangle.uuid,
                rotation: newRectangle.rotation
            })

            
            rect.on('dragend', function (e) {
                this.changeRectanglePosition(e.currentTarget.attrs.key, e.target.attrs.x, e.target.attrs.y)
            }.bind(this))
            rect.on('transformend', function (e) {
                this.transformRectangle(e.currentTarget.attrs.key, e.currentTarget.attrs.scaleX, e.currentTarget.attrs.scaleY, e.target.attrs.x, e.target.attrs.y, e.target.attrs.rotation)
            }.bind(this));
            this.konvaLayer.add(rect);
            this.stage.add(this.konvaLayer);
        },
        paintRectangles() {
            for(var i = 0; i < this.rectangles.length; i++) {
                let circle = new Konva.Rect({
                    x: this.rectangles[i].x,
                    y: this.rectangles[i].y,
                    scaleX: this.rectangles[i].scaleX,
                    scaleY: this.rectangles[i].scaleY,
                    radius: this.rectangles[i].radius,
                    //fill: 'white',
                    stroke: 'black',
                    draggable: true,
                    name: this.rectangles[i].uuid,
                    key: this.rectangles[i].uuid,
                    rotation: this.rectangles[i].rotation
                })
                
                circle.on('dragend', function (e) {
                    this.changeRectanglePosition(e.currentTarget.attrs.key, e.target.attrs.x, e.target.attrs.y) //e.evt.x
                }.bind(this))
                circle.on('transformend', function (e) {
                    this.transformRectangle(e.currentTarget.attrs.key, e.currentTarget.attrs.scaleX, e.currentTarget.attrs.scaleY, e.target.attrs.x, e.target.attrs.y, e.target.attrs.rotation)
                }.bind(this));
                this.konvaLayer.add(circle);
                this.stage.add(this.konvaLayer);
            }
        },
        addCircle() {
            var newCircle = {"uuid": this.getRandomString(4), "x": this.mainDivRectangle.width / 2, "y": this.mainDivRectangle.width / 2, "radius": 50, canvasWidth: this.mainDivRectangle.width, scaleX: 1, scaleY: 1, rotation: 0}
            this.circles = this.circles.concat(newCircle)
            let circle = new Konva.Circle({
                    x: newCircle.x,
                    y: newCircle.y,
                    radius: newCircle.radius,
                    //fill: 'white',
                    stroke: 'black',
                    draggable: true,
                    name: newCircle.uuid,
                    key: newCircle.uuid,
                    rotation: newCircle.rotation
                })
            //circle.on('click', function (e) {alert("circle")});
            //circle.on('mousedown', function (e) {this.transformer.nodes([circle])}.bind(this));
            
            circle.on('dragend', function (e) {
                this.changeCirclePosition(e.currentTarget.attrs.key, e.target.attrs.x, e.target.attrs.y)
            }.bind(this))
            circle.on('transformend', function (e) {
                this.transformCircle(e.currentTarget.attrs.key, e.currentTarget.attrs.scaleX, e.currentTarget.attrs.scaleY, e.target.attrs.x, e.target.attrs.y, e.target.attrs.rotation)
            }.bind(this));
            this.konvaLayer.add(circle);
            this.stage.add(this.konvaLayer);
            //this.transformer.nodes([circle])
        },
        paintCircles() {
            for(var i = 0; i < this.circles.length; i++) {
                let circle = new Konva.Circle({
                    x: this.circles[i].x,
                    y: this.circles[i].y,
                    scaleX: this.circles[i].scaleX,
                    scaleY: this.circles[i].scaleY,
                    radius: this.circles[i].radius,
                    //fill: 'white',
                    stroke: 'black',
                    draggable: true,
                    name: this.circles[i].uuid,
                    key: this.circles[i].uuid,
                    rotation: this.circles[i].rotation
                })
                
                circle.on('dragend', function (e) {
                    this.changeCirclePosition(e.currentTarget.attrs.key, e.target.attrs.x, e.target.attrs.y) //e.evt.x
                }.bind(this))
                circle.on('transformend', function (e) {
                    this.transformCircle(e.currentTarget.attrs.key, e.currentTarget.attrs.scaleX, e.currentTarget.attrs.scaleY, e.target.attrs.x, e.target.attrs.y, e.target.attrs.rotation)
                }.bind(this));
                this.konvaLayer.add(circle);
                this.stage.add(this.konvaLayer);
            }
        },
        paintLines() {
            
            for(var i = 0; i < this.lines.length; i++) {
                let line = new Konva.Line({
                    points: [ this.lines[i].x1, 0, this.lines[i].x2, this.mainDivRectangle.width],
                    stroke: 'black',
                    strokeWidth: 4,
                    lineCap: 'round',
                    lineJoin: 'round',
                    draggable: true,
                    key: this.lines[i].uuid
                })
                line.on('click', function (e) {alert("line" + e)});
                line.on('dragend', function (e) {
                    this.changeLinePosition(e.currentTarget.attrs.key, e.target.attrs.points[0] + e.target.position().x) //e.evt.x
                }.bind(this))
                this.konvaLayer.add(line);
                this.stage.add(this.konvaLayer);
            }
        },
        initKonvaLayer() {
            this.konvaLayer = new Konva.Layer();
            var img = require("../../assets/rawPitch.jpg")     
            this.konvaContainer.setAttribute("style", "background-image: url(" + img + ");background-repeat: no-repeat;background-size: " + this.mainDIV.offsetWidth  + "px " + this.rectangleHeight +"px");
        },
        drawPlayers() {
            
            if(this.konvaContainer != null && this.frames_data != null) {
                
                var currentFrame = Math.ceil(this.fps * (Math.round(this.current_second * 4) / 4).toFixed(2) )
                console.log(this.fps)
                console.log(this.current_second)

                var currentFrameObject = this.frames_data.frames["frame_" + currentFrame]
                if(typeof(currentFrameObject) != 'undefined' || currentFrameObject != null) {
                    for(var i = 0; i < currentFrameObject.positions.length; i++) {
                        var curColor = '#314FCD'
                        
                        let dot = new Konva.Circle({
                            x: currentFrameObject.positions[i].x * this.widthRatio,
                            y: currentFrameObject.positions[i].y * this.heightRatio + (this.mainDivRectangle.height * 0.1),
                            radius: 5,
                            fill: curColor,
                        })
                        this.konvaLayer.add(dot);
                    }
                }
                
                
                this.stage.add(this.konvaLayer);
            }
            
            
        },
        
        handleStageMouseDown(e) {
            
            console.log(e.target.getStage())
            if (e.target === e.target.getStage()) {
                console.log("stage selected")
                this.selectedShapeName = '';
                this.updateTransformer();
                return;
            }

            // clicked on transformer - do nothing
            const clickedOnTransformer =
                e.target.getParent().className === 'Transformer';
            if (clickedOnTransformer) {
                console.log("transformer selected")
                return;
            }
            // find clicked rect by its name
            const name = e.target.name();
            const circle = this.circles.find((r) => r.uuid === name);
            const rect = this.rectangles.find((r) => r.uuid === name);
            if (circle) {
                console.log("circle selected")
                this.selectedShapeName = name;
            } else if (rect) {
                console.log("rect selected")
                this.selectedShapeName = name;
            }else {
                console.log("nothing selected")
                this.selectedShapeName = '';
            }
            this.updateTransformer();
        },
        updateTransformer() {
            // here we need to manually attach or detach Transformer node
            const stage = this.stage;
            const { selectedShapeName } = this;
            const selectedNode = stage.findOne('.' + selectedShapeName);
            if (selectedNode) {
                console.log("selectedNode: " + JSON.stringify(selectedNode))
                this.transformer.nodes([selectedNode]);
            } else {
                console.log(this.transformer.nodes())
                this.clearTransformer()
            }
        },
        clearTransformer() {
            if(this.transformer.nodes().length != 0) {
                this.transformer.nodes([]);
                //this.transformer.detach()
                this.transformer.getLayer().batchDraw()
            }
        }
    },*/
    
    };
</script>