<template>
<div class="layout-main-container"> 
	<div class="layout-main" style="padding-left: 2rem; padding-right: 2rem; padding-top: 2rem;">
		<div class="grid" >
			
			<div class="col-12 md:col-6 lg:col-6 xl:col-3" >
				<div class="card mb-0" >
					<div class="flex justify-content-between">
						<div>
							<span class="block text-500 font-medium">Mein Team</span>
							<div v-if="myteam != null" class="text-900 font-medium text-xl">{{myteam.clubname}}</div>
							<Skeleton v-else width="10rem" class="mb-2"></Skeleton>
						</div>
						<div class="flex align-items-center justify-content-center  border-round" style="width:2.5rem;height:2.5rem">
							<img v-if="myteam != null" class="z-depth-2 float-right" style="width: 100px; max-width: 100%; background-color: white;"  :src="imagePath + myteam.icon" @error="defaultLogo" alt="" data-holder-rendered="true">
							<Skeleton v-else shape="circle" size="2rem" class="mr-2"></Skeleton>
						</div>
					</div>
					<span v-if="myteam != null" class="text-black-500 font-medium">{{myteam.ageband}} </span>
					<span v-if="myteam != null" class="text-500">-{{myteam.rank}}</span>
					<Skeleton v-if="myteam == null" width="10rem" class="mb-2"></Skeleton>
				</div>
			</div>
			<div class="col-6 md:col-6 lg:col-6 xl:col-3">
				<div class="card mb-0">
					<div class="flex justify-content-between">
						<div>
							<span class="block text-500 font-medium">Erfasste Begegnungen</span>
							<div v-if="loaded == true" class="text-900 font-medium text-xl">{{matchList.length}}</div>
							<Skeleton v-else width="10rem" class="mb-2"></Skeleton>
						</div>
						<div class="flex align-items-center justify-content-center border-round" style="width:2.5rem;height:2.5rem">
							<i v-if="loaded == true" class="fas fa-futbol text-blue-500 text-xl"></i>
							<Skeleton v-else shape="circle" size="2rem" class="mr-2"></Skeleton>
						</div>
					</div>
					<span v-if="loaded == true" class="text-green-500 font-medium">{{matchList.filter(m => m.situationsReady == true).length}} </span>
					<span v-if="loaded == true" class="text-500"> analysiert</span>
					<Skeleton v-if="matchList == null" width="10rem" class="mb-2"></Skeleton>
					
				</div>
			</div>
			<div class="col-6 md:col-6 lg:col-6 xl:col-3">
				<div class="card mb-0">
					<div class="flex justify-content-between">
						<div>
							<span class="block text-500 font-medium">Analysierte Situationen</span>
							<div v-if="loaded == true" class="text-900 font-medium text-xl">{{analyzedSituationsLength}}</div>
							<Skeleton v-else width="10rem" class="mb-2"></Skeleton>
						</div>
						<div class="flex align-items-center justify-content-center border-round" style="width:2.5rem;height:2.5rem">
							<i v-if="loaded == true" class="far fa-chart-bar text-blue-500 text-xl"></i>
							<Skeleton v-else shape="circle" size="2rem" class="mr-2"></Skeleton>
						</div>
					</div>
					<span v-if="loaded == true" class="text-500">&nbsp;</span>
					<Skeleton v-if="matchList == null" width="10rem" class="mb-2"></Skeleton>
					
				</div>
			</div>

			<!--<div class="col-12 md:col-6 lg:col-6 xl:col-3" style="background-color: white; z-index: 200;" v-bind:style="{ position: isFixed ? 'fixed' : 'relative' }" id="sticky">
				<div class="card mb-0">
					<div class="flex justify-content-between">
						
							<div v-if="loaded == true" class="text-900 font-medium text-xl" style="width: 100%;">
								<SelectButton style="width: 100%;" v-model="selectedTeamOption" :options="teamOptions" dataKey="myTeam">
									<template #option="slotProps">
										
										<img v-if="myteam != null && slotProps.option.myTeam == true" class="z-depth-2 float-right" style="width: 16px; max-width: 100%; background-color: white;" :src="imagePath + myteam.icon" @error="defaultLogo" alt="" data-holder-rendered="true"> &nbsp;
										<i v-if="slotProps.option.myTeam != true" class="fa-solid fa-earth-americas"></i><span v-if="myteam != null">&nbsp;{{slotProps.option.myTeam == true ? myteam.clubname : 'Alle Gegner'}}</span> 
									</template>
								</SelectButton>

								<Dropdown v-if="loaded == true && myteam != null && isSelectedMyTeam" v-model="selectedPlayer" emptyMessage="für diese Aktion wurden keine Spieler gefunden" :options="playerSelection" optionLabel="uuid" placeholder="Alle Spieler" :showClear="true">
									<template #option="slotProps">
										<div class="country-item">
											<div>{{slotProps.option.firstname.charAt(0)}}. {{slotProps.option.lastname}}</div>
										</div>
									</template>
									<template #value="slotProps" >
										<div v-if="slotProps.value">{{slotProps.value.firstname.charAt(0)}}. {{slotProps.value.lastname}}</div>
									</template>
								</Dropdown>
							</div>
							<Skeleton v-else width="10rem" class="mb-2"></Skeleton>
						</div>
						
					
					<span v-if="loaded == true" class="text-500">&nbsp;</span>
					<Skeleton v-if="matchList == null" width="10rem" class="mb-2"></Skeleton>
					
				</div>
			</div>-->

			</div>
			<div class="grid" >
			
			<div class="col-12 mt-8" v-if="loaded == true && matchList.length == 0" >
				<h4 class="text-center">Herzlich Willkommen auf Deinem Dashboard!</h4>
				<h5 class="text-center">Du hattest bisher noch keine Begegnungen. <br></h5>
				<h5 class="text-center" ><br>Lade Deine Begegnungen als Videodatei hoch und erhalte als Profi-Mitglied <br> detaillierte Statistiken zu deiner Mannschaft im Vergleich zu allen gegnerischen Teams</h5>
				<div class="text-center py-3">
					<Button label="Erste Begegnung anlegen" class="p-button-outlined p-button-secondary" @click="addMatch()"/>
				</div>
			</div>

			<div class="col-12 mt-8" v-if="loaded == true && matchList.length != 0 && statisticsPossible == false">
				<h4 class="text-center">Herzlich Willkommen auf Deinem Dashboard!</h4>
				<h5 class="text-center">Bisher wurden Deine Begegnungen nicht analysiert.</h5>
				<h5 class="text-center" ><br>Wir bitten um Deine Geduld.<br> Solltest Du keine Profi-Mitgliedschaft haben, kontaktiere uns für Dein Upgrade</h5>
			</div>
                


			<div class="col-12 xl:col-6" v-if="statisticsPossible">
				<div class="card">
					<h5>Letzte Begegnungen</h5>
					<TabView ref="tabview1" :scrollable="true">
						<TabPanel header="Schüsse">
							<Chart type="line" :data="shotsData" :options="lineChartOptions"/>
						</TabPanel>
						<TabPanel header="Torschüsse">
							<Chart type="line" :data="shotsOnTargetData" />
						</TabPanel>
						<TabPanel header="Tore">
							<Chart type="line" :data="goalsData" />
						</TabPanel>
						<TabPanel header="xG-Lite">
							<Chart type="line" :data="xGData" />
						</TabPanel>
						<TabPanel header="Torschüsse Gesamt">
							<h5>Schüsse aufs Tor (Alle Begegnungen)</h5>
							<div class="row">
								<Chart class="col-12 md:col-6 md:col-offset-3" type="doughnut" :data="shotsOnTargetDoughnut"  />
							</div>
						</TabPanel>
					</TabView>


				</div>
				
			</div>
			<div class="col-12 xl:col-3"  v-if="statisticsPossible">
				<div class="card">
					<h5>Schusspositionen</h5>
					<div id="shotPositionsContainer" style="position: relative" v-on:resize="handleResize($event)">
						<ShotsHeatmap v-if="matchList != null && matchList.length != 0" :selectedPlayer="selectedPlayer" :showAction="selectedShotSituationOption.name" :creator_team="isSelectedMyTeam"></ShotsHeatmap>
						<div class="row mt-3">
							<SelectButton v-model="selectedTeamOption" :options="teamOptions" dataKey="myTeam">
								<template #option="slotProps">
									
									<img v-if="myteam != null && slotProps.option.myTeam == true" class="z-depth-2 float-right" style="width: 16px; max-width: 100%; background-color: white;" :src="imagePath + myteam.icon" @error="defaultLogo" alt="" data-holder-rendered="true"> &nbsp;
									<i v-if="slotProps.option.myTeam != true" class="fa-solid fa-earth-americas"></i><span v-if="myteam != null">&nbsp;{{slotProps.option.myTeam == true ? myteam.clubname : 'Alle Gegner'}}</span> 
								</template>
							</SelectButton>
						</div>
						<div class="row mt-3">
							<SelectButton v-model="selectedShotSituationOption" :options="shotSituationOptionsOptions" dataKey="name">
								<template #option="slotProps">
									<i :class="slotProps.option.icon"></i> &nbsp; {{slotProps.option.name}}
								</template>
							</SelectButton>
						</div>
						<!--<div class="row mt-3">
							<SelectButton v-model="selectedShotSituationOption" :options="passSituationOptionsOptions" dataKey="name">
								<template #option="slotProps">
									<i :class="slotProps.option.icon"></i> &nbsp; {{slotProps.option.name}}
								</template>
							</SelectButton>
						</div>-->
						<div class="row mt-3">
							<Dropdown v-if="myteam != null && isSelectedMyTeam" v-model="selectedPlayer" emptyMessage="für diese Aktion wurden keine Spieler gefunden" :options="playerSelection" optionLabel="uuid" placeholder="Spieler" :showClear="true">
								<template #option="slotProps">
									<div class="country-item">
										<div>{{slotProps.option.firstname.charAt(0)}}. {{slotProps.option.lastname}}</div>
									</div>
								</template>
								<template #value="slotProps" >
									<div v-if="slotProps.value">{{slotProps.value.firstname.charAt(0)}}. {{slotProps.value.lastname}}</div>
								</template>
							</Dropdown>
						</div>
					</div> 
				</div> 
			</div>
			<div class="col-12 xl:col-3"  v-if="statisticsPossible">
				<div class="card">
					<h5>Spieler-Details</h5>
					<PlayerStatistics v-if="myteam != null && matchList != null && matchList.length != 0"></PlayerStatistics>
					<!--<ShotDefends v-if="myteam != null && matchList != null && matchList.length != 0"></ShotDefends>-->
				</div> 
			</div>

			<div class="col-12 xl:col-3"  v-if="statisticsPossible">
				<div class="card">
					<h5>Tore in Standardsituationen</h5>
					<StandardGoals v-if="matchList != null && matchList.length != 0"></StandardGoals>
				</div> 
			</div>

			<div class="col-12 xl:col-3"  v-if="statisticsPossible">
				<div class="card">
					<h5>Schüsse nach Eckstößen</h5>
					<Corner2Shot v-if="matchList != null && matchList.length != 0"></Corner2Shot>
				</div> 
			</div>

			<div class="col-12 xl:col-3"  v-if="statisticsPossible">
				<div class="card">
					<h5>90 Minuten im Schnitt</h5>
					<Average90 v-if="matchList != null && matchList.length != 0"></Average90>
				</div> 
			</div>

			

		</div> 
	</div>
	<Config />
</div>
</template>

<script>
import { kick17 } from '@/services';
//import { exampleteam } from '@/helpers';
import ShotsHeatmap from './ShotsHeatmap'
import StandardGoals from './StandardGoals'
import Corner2Shot from './Corner2Shot'
import Average90 from './Average90'
import PlayerStatistics from './PlayerStatistics' 
import Config from './Config.vue';
//import ShotDefends from './ShotDefends' 
//import { getAnalytics, logEvent } from "firebase/analytics";

export default {
	components: {
        ShotsHeatmap,
		StandardGoals,
		Corner2Shot,
		Average90,
		PlayerStatistics,
		Config,
		//ShotDefends
    },
	data() {
		return {
			scrolled: false,
			stickyTop: null,
			isFixed: false,
			loaded: false,
			visibleShots: ["myshots", "myShotsOnTarget", "myGoals", "theitShots", "theirShotsOnTarget", "theirGoals"],
			myColor: "#5A9781",
			opponentcolor: "#3399FF",
			selectedPlayer: null,
			imagePath:"",
			lineChartOptions:{
                stacked: false,
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                       
                        display: true,
                        position: 'left',
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    }
                }
			},
			
			teamOptions: [
                {myTeam: true},
                {myTeam: false}
			],
			selectedTeamOption: {myTeam: true},
			shotSituationOptionsOptions: [
                {name: "Schuss", icon: "fa-brands fa-hubspot "},
                {name: "Torschuss", icon: "fa-solid fa-bolt"},
				{name: "Tor", icon: "fas fa-futbol"}
			],
			selectedShotSituationOption: {name: "Schuss", icon: "fa-brands fa-hubspot "},
			passSituationOptionsOptions: [
                {name: "Zuspiel", icon: "pi pi-arrow-up"},
                {name: "Angekommen", icon: "pi pi-arrow-circle-up"},
				{name: "Assist", icon: "fas fa-futbol"}
			],
			

		}
	},

	computed: {
		playerSelection: function() {
			if (this.selectedShotSituationOption.name == "Schuss") {
				return this.$store.getters.getPlayersWithShots
			}
			else if (this.selectedShotSituationOption.name == "Torschuss") {
				return this.$store.getters.getPlayersWithShotsOnTarget
			}
			else if (this.selectedShotSituationOption.name == "Tor") {
				return this.$store.getters.getPlayersWithGoals
			}
			else if(this.selectedShotSituationOption.name.includes("Pass") || this.selectedShotSituationOption.name == "Angekommen") {
				return this.$store.getters.getPlayersWithPass
			}
			else if(this.selectedShotSituationOption.name.includes("Assist")) {
				return this.$store.getters.getPlayersWithAssist
			}
			return this.$store.getters.getTeam.players
        },
		statisticsPossible: function() {
			return this.matchList.filter(m => m.situationsReady == true).length == 0 ? false : true
		},
		matchList: function() {
          return this.$store.getters.getMatchList.filter(m => m.situationsReady == true)
		},
		myteam: function() {
          return this.$store.getters.getTeam
		},
		isSelectedMyTeam: function() {
			return this.selectedTeamOption.myTeam
		},
		allSituations: function() {
			var allSits = []
			this.matchList.forEach(m => allSits = allSits.concat(m.situations))
			return allSits
		},
		matchListWithSituations: function() {
			if(this.matchList == null || this.matchList.length == 0) {return null}
			return this.matchList.filter(m => m.situationsReady == true && m.situations.length > 0)
		},
		analyzedSituationsLength: function() {
			if(this.matchListWithSituations == null) {return 0}
			var len = 0
			this.matchListWithSituations.forEach(m => len += m.situations.length)
			return len
		},
		dateList: function() {
			if(this.matchListWithSituations == null) {return null}
			var resultList = []
			this.matchListWithSituations.forEach(m => {
				var date = new Date(m.match_date);
				resultList.push(date.getDate().toString().padStart(2, '0') + '.' + (date.getMonth() + 1).toString().padStart(2, '0') + '.' + date.getFullYear())
			})
			return resultList
		},
		relevantData: function() {
			if(this.matchListWithSituations == null) {return null}
			var result = {shots: {mine: [], theirs: []}, shotsOnTarget: {mine: [], theirs: []}, goals: {mine: [], theirs: []}, xG: {mine: [], theirs: []}}

			this.matchListWithSituations.forEach(m => {
				var allSitPos = kick17.getAllSitPos(m)
				allSitPos = allSitPos.filter(kick17.sitpoHasActions)
				result.shots.mine.push(allSitPos.filter(sitpo => sitpo.isInCreatorTeam == true && sitpo.action_labels.some(al => al == "Schuss")).length)
				result.shotsOnTarget.mine.push(allSitPos.filter(sitpo => sitpo.isInCreatorTeam == true && sitpo.action_labels.some(al => al == "Torschuss")).length)
				result.goals.mine.push(allSitPos.filter(sitpo => sitpo.isInCreatorTeam == true && sitpo.action_labels.some(al => al == "Tor")).length)

				result.shots.theirs.push(allSitPos.filter(sitpo => sitpo.isInCreatorTeam == false && sitpo.action_labels.some(al => al == "Schuss")).length)
				result.shotsOnTarget.theirs.push(allSitPos.filter(sitpo => sitpo.isInCreatorTeam == false && sitpo.action_labels.some(al => al == "Torschuss")).length)
				result.goals.theirs.push(allSitPos.filter(sitpo => sitpo.isInCreatorTeam == false && sitpo.action_labels.some(al => al == "Tor")).length)
				var curxGMine = 0, curxGTheirs = 0

				allSitPos.filter(sitpo => sitpo.isInCreatorTeam == true).forEach(sitpo => curxGMine += sitpo.xG)
				allSitPos.filter(sitpo => sitpo.isInCreatorTeam == false).forEach(sitpo => curxGTheirs += sitpo.xG)
				result.xG.mine.push(curxGMine)
				result.xG.theirs.push(curxGTheirs)
			})
			return result
		},
		xGData: function() {
			if(this.relevantData == null || this.myteam == null) {return null}
			var data = {labels: this.dateList, datasets: []}
			
			data.datasets.push({
				label: this.myteam.clubname,
				data: this.relevantData.xG.mine, 
				fill: false,
				backgroundColor: this.myColor,
				borderColor: this.myColor,
				tension: 0.4
			})
			
			
			data.datasets.push({
				label: 'Alle Gegner',
				data: this.relevantData.xG.theirs, 
				fill: false,
				backgroundColor: this.opponentcolor,
				borderColor: this.opponentcolor,
				tension: 0.4
			})
			return data
			
		},
		goalsData: function() {
			if(this.relevantData == null || this.myteam == null) {return null}
			var data = {labels: this.dateList, datasets: []}
			
			data.datasets.push({
				label: this.myteam.clubname,
				data: this.relevantData.goals.mine, 
				fill: false,
				backgroundColor: this.myColor,
				borderColor: this.myColor,
				tension: 0.4
			})
			
			
			data.datasets.push({
				label: 'Alle Gegner',
				data: this.relevantData.goals.theirs, 
				fill: false,
				backgroundColor: this.opponentcolor,
				borderColor: this.opponentcolor,
				tension: 0.4
			})
			return data
			
		},
		shotsOnTargetData: function() {
			if(this.relevantData == null || this.myteam == null) {return null}
			var data = {labels: this.dateList, datasets: []}
			
			data.datasets.push({
				label: this.myteam.clubname,
				data: this.relevantData.shotsOnTarget.mine, 
				fill: false,
				backgroundColor: this.myColor,
				borderColor: this.myColor,
				tension: 0.4
			})
			
			
			data.datasets.push({
				label: 'Alle Gegner',
				data: this.relevantData.shotsOnTarget.theirs, 
				fill: false,
				backgroundColor: this.opponentcolor,
				borderColor: this.opponentcolor,
				tension: 0.4
			})
			return data
			
		},
		shotsData: function() {
			if(this.relevantData == null || this.myteam == null) {return null}
			var data = {labels: this.dateList, datasets: []}
			
			data.datasets.push({
				label: this.myteam.clubname,
				data: this.relevantData.shots.mine, 
				fill: false,
				backgroundColor: this.myColor,
				borderColor: this.myColor,
				tension: 0.4
			})
			
			data.datasets.push({
				label: 'Alle Gegner',
				data: this.relevantData.shots.theirs, 
				fill: false,
				backgroundColor: this.opponentcolor,
				borderColor: this.opponentcolor,
				tension: 0.4
			})
			
			return data
		},
		shotsDoughnut: function() {
			if(this.relevantData == null || this.myteam == null) {return null}
			return {labels: [this.myteam.clubname,'Alle Gegner'],
				datasets: [
					{
						data: [this.relevantData.shots.mine.reduce(this.getSum, 0), this.relevantData.shots.theirs.reduce(this.getSum, 0)],
						backgroundColor: ['#5A9781',"#36A2EB"],
						hoverBackgroundColor: ['#5A9781',"#36A2EB"]
					}
				]}
		},
		shotsOnTargetDoughnut: function() {
			if(this.relevantData == null || this.myteam == null) {return null}
			return {labels: [this.myteam.clubname,'Alle Gegner'],
				datasets: [
					{
						data: [this.relevantData.shotsOnTarget.mine.reduce(this.getSum, 0), this.relevantData.shotsOnTarget.theirs.reduce(this.getSum, 0)],
						backgroundColor: ['#5A9781',"#36A2EB"],
						hoverBackgroundColor: ['#5A9781',"#36A2EB"]
					}
				]}
		},


	},
	async created() {
		this.$store.commit('setMatchList', [])
		this.$store.commit('setMatchUuid', null)
		if(this.matchList.length == 0) {
			await this.loadMatchList()
			this.sortMatchList()
		}
		
		var res = await kick17.getMyTeam()
		//var res = {data: exampleteam}
		this.$store.commit('setTeam', res.data)
		this.imagePath = kick17.getClubLogoReadURL()
		this.createShotChart()
		//const analytics = getAnalytics()
        //logEvent(analytics, 'dashboard_created')
		

	},
	mounted() {
		this.$store.commit('setMatchList', [])
		this.$store.commit('setMatchUuid', null)

		//let sticky = document.getElementById("sticky")
		//this.stickyTop = sticky.getBoundingClientRect().top //offset().top
		//window.addEventListener("scroll", this.scroll);


		/*let timeout = setInterval(function() {

			if (this.scrolled) {
				this.scrolled = false;
				if (window.scrollTop() >= this.stickyTop) {
					this.isFixed = true
				} else {
					this.isFixed = false
				}
			}
		}, 200);*/

	},
	methods: {
		handleResize() {
			console.log("handleResize")
		},
		scroll() {
			this.scrolled = true;
			console.log("window.top.scrollY: " + window.top.scrollY)
			console.log("this.stickyTop: " + this.stickyTop)
			if (window.top.scrollY >= this.stickyTop) {
				this.isFixed = true
			} else {
				this.isFixed = false
			}
		},
		async addMatch() {
			var result = await kick17.createMatch()
			this.$router.push({ path: '/match/'+result.data.uuid })
		},
	
		getSum(total, num) {
			return total + Math.round(num);
		},
		createShotChart() {


			var myShots = []
			var myShotsOnTarget = []
			var myGoals = []

			this.matchListWithSituations.forEach(m => {
				var curMyShots = 0
				var curMyShotsOnTarget = 0
				var curMyGoals = 0
				var allSitPos = kick17.getAllSitPos(m)
				allSitPos = allSitPos.filter(kick17.sitpoHasActions)
				curMyShots = allSitPos.filter(sitpo => sitpo.isInCreatorTeam == true && sitpo.action_labels.some(al => al == "Schuss")).length
				curMyShotsOnTarget = allSitPos.filter(sitpo => sitpo.isInCreatorTeam == true && sitpo.action_labels.some(al => al == "Torschuss")).length
				curMyGoals = allSitPos.filter(sitpo => sitpo.isInCreatorTeam == true && sitpo.action_labels.some(al => al == "Tor")).length

				myShots.push(curMyShots)
				myShotsOnTarget.push(curMyShotsOnTarget)
				myGoals.push(curMyGoals)
			})
		},
		sortMatchList() {
			/*if(this.matchList != null && this.matchList.length > 0) {
				this.matchList.sort(this.sortByDate);
			}*/
		},
		sortByDate(a, b) {
			return new Date(a.match_date).getTime() - new Date(b.match_date).getTime();
		},
		async loadMatchList() {
			await kick17.getMatches()
			.then(function(result) {
				this.$store.commit('setMatchList', result.data)
				this.loaded = true 
				}.bind(this)).catch(function(error) {
				if(error.toJSON().status != 200) {
					this.loaded = true
				}
				}.bind(this));		
		},
		defaultLogo(event) { 
			event.target.src = require("../../assets/kick17_old.png") 
		},

	}
}
</script>

<style lang="scss">


@import '../../assets/styles/layout.scss';



</style>