<template>
	<div id="layout-config" :class="containerClass">
        <div class="layout-config-content-wrapper">
            <a href="#" class="layout-config-button" id="layout-config-button" @click="toggleConfigurator">
                <i class="pi pi-cog"></i>
            </a>
            <Button class="p-button-danger layout-config-close p-button-rounded p-button-text" icon="pi pi-times" @click="hideConfigurator" :style="{'z-index': 1}"></Button>

            <div class="layout-config-content">
                

                <!--<h5 class="mt-0">Component Scale</h5>
                <div class="config-scale">
                    <Button icon="pi pi-minus" @click="decrementScale()" class="p-button-text" :disabled="scale === scales[0]"/>
                    <i class="pi pi-circle-on" v-for="s of scales" :class="{'scale-active': s === scale}" :key="s"/>
                    <Button icon="pi pi-plus"  @click="incrementScale()" class="p-button-text" :disabled="scale === scales[scales.length - 1]" />
                </div>-->

                <h5>Zeitraum</h5>
                <div class="p-formgroup-inline">
                    <div class="field-radiobutton">
                        <RadioButton id="input_outlined" name="inputstyle" :value="true"  v-model="selectAll" />
                        <label for="input_outlined">Alle Begegnungen</label>
                    </div>
                    <div class="field-radiobutton">
                        <RadioButton id="input_filled" name="inputstyle" :value="false"  v-model="selectAll" />
                        <label for="input_filled">{{dateRangeText}}</label>
                        <!-- <label for="input_filled">Begegnungen zwischen {{dateList[dateValues[0]]}} und {{dateList[dateValues[1]]}}</label> -->
                    </div>
                </div>
                <Slider @slideend="slideEnd" :disabled="selectAll==true" v-model="dateValues" :range="true" :step="1" :min="0" :max="dateList.length - 1"/>

            </div>
        </div>
	</div>
</template>

<script>
	export default {
		props: {
			layoutMode: {
				type: String,
				default: null
			},
			layoutColorMode: {
				type: String,
				default: null
			}
		},
		data() {
			return {
				active: false,
				d_layoutMode: this.layoutMode,
				d_layoutColorMode: this.layoutColorMode,
				scale: 16,
				scales: [12,13,14,15,16],
                dateValues: [0,1],
                selectAll: true
			}
		},
		watch: {
			$route() {
				if (this.active) {
					this.active = false;
					this.unbindOutsideClickListener();
				}
			},
			layoutMode(newValue) {
				this.d_layoutMode = newValue;
			},
			layoutColorMode(newValue) {
				this.d_layoutColorMode = newValue;
			},
            dateList(newValue) {
                this.dateValues = [0, newValue.length - 1]
            },
			selectAll(newValue) {

				this.dateValues = newValue == true ? [0, this.dateList.length - 1] : this.dateValues
				if(newValue == true && this.dateList.length != 0) {
					var a = [new Date(this.dateList[this.dateValues[0]]), new Date(this.dateList[this.dateValues[1]])]
					this.$store.commit('setRange', a)
				}
			}	

            
		},
		outsideClickListener: null,
		methods: {
			slideEnd() {
				console.log("sliderend")
				if(this.dateList.length != 0) {
					var a = [new Date(this.dateList[this.dateValues[0]]), new Date(this.dateList[this.dateValues[1]])]
					this.$store.commit('setRange', a)
				}
			},
			toggleConfigurator(event) {
				this.active = !this.active;
				event.preventDefault();

				if (this.active)
					this.bindOutsideClickListener();
				else
					this.unbindOutsideClickListener();
			},
			hideConfigurator(event) {
				this.active = false;
				this.unbindOutsideClickListener();
				event.preventDefault();
			},
			changeInputStyle(value) {
				this.$primevue.config.inputStyle = value;
			},
			changeRipple(value) {
				this.$primevue.config.ripple = value;
			},
			changeLayout(event, layoutMode) {
				this.$emit('layout-change', layoutMode);
				event.preventDefault();
			},
			changeLayoutColor(event, layoutColor) {
				this.$emit('layout-color-change', layoutColor);
				event.preventDefault();
			},
			bindOutsideClickListener() {
				if (!this.outsideClickListener) {
					this.outsideClickListener = (event) => {
						if (this.active && this.isOutsideClicked(event)) {
							this.active = false;
						}
					};
					document.addEventListener('click', this.outsideClickListener);
				}
			},
			unbindOutsideClickListener() {
				if (this.outsideClickListener) {
					document.removeEventListener('click', this.outsideClickListener);
					this.outsideClickListener = null;
				}
			},
			isOutsideClicked(event) {
				return !(this.$el.isSameNode(event.target) || this.$el.contains(event.target));
			},
			decrementScale() {
				this.scale--;
				this.applyScale();
			},
			incrementScale() {
				this.scale++;
				this.applyScale();
			},
			applyScale() {
				document.documentElement.style.fontSize = this.scale + 'px';
			},
			changeTheme(event, theme, dark=false) {
				let themeElement = document.getElementById('theme-link');
				themeElement.setAttribute('href', themeElement.getAttribute('href').replace(this.$appState.theme, theme));
				this.$appState.theme = theme;
				this.$appState.darkTheme = dark;

				if (theme.startsWith('md')) {
					this.$primevue.config.ripple = true;
				}
			}
		},
		computed: {
			containerClass() {
				return ['layout-config', {'layout-config-active': this.active}];
			},
			rippleActive() {
				return this.$primevue.config.ripple;
			},
			inputStyle() {
				return this.$appState.inputStyle;
			},

            matchList: function() {
                return this.$store.getters.getMatchList.filter(m => m.situationsReady == true)
            },
            dateList: function() {
				return this.$store.getters.getDateList
            },
			dateRangeText() {
				if(this.dateList.length == 0) return ""
				return this.dateValues[0] == this.dateValues[1] ? 'Begegnung am ' + this.dateList[this.dateValues[0]] : 'Begegnungen zwischen ' + this.dateList[this.dateValues[0]].getDate().toString().padStart(2, "0") + "." + (this.dateList[this.dateValues[0]].getMonth() + 1).toString().padStart(2, "0") + "." + this.dateList[this.dateValues[0]].getFullYear() + " & " + this.dateList[this.dateValues[1]].getDate().toString().padStart(2, "0") + "." + (this.dateList[this.dateValues[1]].getMonth() + 1).toString().padStart(2, "0") + "." + this.dateList[this.dateValues[1]].getFullYear()
			}
		},
        async created() {
            
        }
        
	}
</script>

<style>
.layout-config.layout-config-active .layout-config-content-wrapper .layout-config-button i {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}

.layout-config .layout-config-content-wrapper .layout-config-button i {
    font-size: 26px;
    line-height: inherit;
    cursor: pointer;
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    -webkit-transition: -webkit-transform 1s;
    transition: -webkit-transform 1s;
    transition: transform 1s;
    transition: transform 1s,-webkit-transform 1s;
}
.layout-config .layout-config-content-wrapper .layout-config-button {
    display: block;
    position: absolute;
    width: 52px;
    height: 52px;
    line-height: 52px;
    background-color: var(--primary-color);
    text-align: center;
    color: var(--primary-color-text);
    top: 8px;
    left: -51px;
    z-index: -1;
    overflow: hidden;
    cursor: pointer;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    -webkit-animation-name: rubberBand;
    animation-name: rubberBand;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-iteration-count: 3;
    animation-iteration-count: 3;
    -webkit-animation-delay: 5s;
    animation-delay: 5s;
    -webkit-box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
    box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
}
</style>