<template>
<div>
 
  <h2 class="display-4 ">Dashboard</h2>
  API-Version: {{apiversion}} <br>
  APP-Version: {{appversion}} <br>


 </div>
</template>

<script>
import { kick17 } from '@/services';
//import { getAuth} from "firebase/auth";
//import { GetPageInfoRequest, PostPagePhotoMediaRequest } from 'instagram-graph-api';


export default {
  name: 'app',
  components: {
    
  },
  data () {
    return {
      apiversion: "gdfg",
      appversion: "zuu"
    }
  },
  async created () {

      var result = await kick17.getAPIVersion()
      this.apiversion = result.data
      this.appversion = process.env.VUE_APP_APP_VERSION
/*
      const auth = getAuth();
      console.log(auth.currentUser)

      //usermanager.unlinkAccountWithFacebook()


      var USER_ACCESS_TOKEN = await usermanager.linkAccountWithFacebook()
      console.log(USER_ACCESS_TOKEN)
      console.log(auth.currentUser.providerData.find(p => p.providerId == "facebook.com"))
      var uid = await usermanager.getFacebookUserID() //auth.currentUser.providerData.find(p => p.providerId == "facebook.com").uid
      console.log("uid: " + uid)

      var facebookpages = await usermanager.getAuthorizedFacebookPages(USER_ACCESS_TOKEN)
      console.log("facebookpages")
      console.log(facebookpages)
      console.log(facebookpages.getAuthorizedFacebookPages()[0].id)
      var instagram_page_id = facebookpages.getAuthorizedFacebookPages()[0].id
      var instagram_access_token = facebookpages.getAuthorizedFacebookPages()[0].access_token

      var linkedInstagramAccount = await usermanager.getLinkedInstagramAccount(instagram_access_token, instagram_page_id)
      console.log("linkedInstagramAccount")
      console.log(linkedInstagramAccount)

      let getPageInfoRequest = new GetPageInfoRequest(instagram_access_token, instagram_page_id)
      getPageInfoRequest.config().params.fields = "followers_count" 

      await getPageInfoRequest.execute().then((response) => {
        console.log("page info")
          console.log(response)
      });*/

      //client = new Client(PAGE_ACCESS_TOKEN, FACEBOOK_PAGE_ID);

      /*let pageInfoRequest = client.newGetPageInfoRequest("name");
      pageInfoRequest.execute().then((response) => {
          console.log(response)
      });*/

      /*let pageMediaRequest = client.newGetPageMediaRequest();
      pageMediaRequest.execute().then((response) => {
          console.log(response)
      });*/



      
      /*var postPagePhotoMediaRequest = new PostPagePhotoMediaRequest(instagram_access_token, instagram_page_id, "https://betterstudio.com/wp-content/uploads/2019/05/4-5-instagram-819x1024.jpg") //client.newPostPagePhotoMediaRequest("https://betterstudio.com/wp-content/uploads/2019/05/4-5-instagram-819x1024.jpg")
      postPagePhotoMediaRequest.execute().then((response) => {
          console.log(response)
      });*/
  },

  methods: {
    statusChangeCallback(r) {
      console.log(r)
    },
    toggle() {
      this.isActive = !this.isActive
    },
  


  }
}
</script>