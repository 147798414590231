<template>


  <div class="container" >
    <div class="row py-5">
      <Spinner v-if="loaded == false"></Spinner>
      <div v-else-if="match_list != null">
        <div class="card shadow mb-4" v-for="(m, index) in match_list_with_videos" v-bind:key="index" onmouseover="" style="cursor: pointer;" @click="showVideoEditor(m)">
          <div class="row" >
            
              
                <div class="col-8">
                  <span style="font-weight: bold;">{{m.creator_team.clubname}} vs. {{m.opponent_team.clubname}}</span>
                </div>
                <div class="col-4">
                  <span style="font-weight: bold; float: right;">{{m.match_date}}</span>
                </div>
                <div class="row" >
                  <div class="col-1">
                    <img class="z-depth-2 float-right" style="width: 100%;" :src="imagePath + m.creator_team.icon" @error="defaultLogo" alt="" data-holder-rendered="true">
                  </div>

                  <div class="col-1">
                    <img class="z-depth-2 float-right" style="width: 100%;" :src="imagePath + m.opponent_team.icon" @error="defaultLogo" alt="" data-holder-rendered="true">
                  </div>
                </div>
              </div>
           
          
        </div>
      </div>
    </div>
  </div>
    



</template>
<style scoped lang="scss">

@import '~bootstrap/scss/bootstrap.scss';
</style>

<script>
import { admin, kick17 } from '@/services';
import Spinner from '../Basics/Spinner'



export default {
  components: {
      Spinner
    },
  data() {
    return {
        match_list: null,
        loaded: false,
        imagePath: ""
    };
  },
  async created() {
    this.imagePath = kick17.getClubLogoReadURL()
    var result = await admin.allmatches()
    this.match_list = result.data.sort(function(a, b){
      return new Date(a.match_date) - new Date(b.match_date);
    }).reverse()
    this.loaded = true   
  },
  computed: {
    match_list_with_videos: function() {
      var listt = this.match_list.filter(this.hasVideos)
      return listt
    }
  },

  methods: {
      hasVideos(match) {
        return match.videos.length > 0
      },

      defaultLogo(event) { 
        event.target.src = require("../../assets/kick17_old.png") 
      },
      showVideoEditor(m) {
        this.$router.push({ path: '/editor/'+m.uuid, query: { match: m } })
        
      }
  
  }
};
</script>