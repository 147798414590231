<template>
	
	<!--<div>
		<Menubar class="layout-topbar" :model="items">
			<template #start>
				<router-link to="/" class="layout-topbar-logo" style="order: 1;">
					<img alt="Logo" :src="topbarImage()" />
					<span>Kick17</span>
				</router-link>
				
			</template>
			<template #item="{item}">
				<button v-if="activeUser" class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle" style=" list-style: none; order: 2;">
					<i :class="item.icon"></i>
				</button>
			</template>
			<template #end>
	

				<div>
					<LanguageMenu class="p-menuitem"/>
					<div class="p-menuitem-link" v-if="!activeUser" @click="login()">
						<i class="pi pi-sign-in p-menuitem-icon"></i>
						<span class="p-menuitem-text">Login</span>
					</div>
				</div>
			</template>
		</Menubar>
	</div>-->


	<div class="layout-topbar">
		<router-link to="/" class="layout-topbar-logo" >
			<img alt="Logo" :src="topbarImage()" />
			<span>Kick17</span>
		</router-link>



		<button v-if="activeUser" class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle" style=" list-style: none;">
			<i class="pi pi-bars"></i>
		</button>

		<div style="order: 3;"></div>

		
		<!--<ul class="lg:flex origin-top" style="order: 4; list-style: none; position: absolute;" v-bind:style="{ right: activeUser ? '1em': '7em' }">
			<li>
				<LanguageMenu />
			</li>
		</ul>-->



		<ul v-if="!activeUser" class="lg:flex origin-top" style="order: 5; list-style: none; right: 2em; position: absolute;">
			<li>
				<button v-if="!activeUser" class="p-link" @click="login()">
					<i class="pi pi-sign-in"></i>
					<span class="ml-2">Login</span>
				</button>
			</li>
		</ul>
		
	</div>
</template>

<script>
import { usermanager } from '@/services';
import { inject } from 'vue'
//import LanguageMenu from './LanguageMenu'

export default {
	components: {
		//LanguageMenu
	},
	data() {
        return {
			activeUser: null,
			role: "None",
			profil: [],
			emitter: null,
			items: [
                {
					label:'',
					icon:'pi pi-bars'
                }]
		}
	},
	async created () {
		this.refreshActiveUser()
		this.emitter = inject('emitter'); // Inject `emitter
	},  
	watch: {
		// everytime a route is changed refresh the activeUser
		'$route': 'refreshActiveUser',
		'$i18n.locale': function() {
			this.refreshMenu()
		}
	},
    methods: {
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
			this.emitter.emit('resize', "randomVal");
        },
		onTopbarMenuToggle(event) {
            this.$emit('topbar-menu-toggle', event);
        },
		topbarImage() {
			return require('./assets/kick17.png');
		},
		async getUserName() {
			this.profil = usermanager.getUserName()
		},

		async refreshActiveUser () {
			this.activeUser = await usermanager.isLoggedIn()
			this.refreshUserRoles()
		},
		async logout() {
			await usermanager.logout()
			this.login()
		},
		login() {
			this.$router.push('/login');
		},
		async refreshUserRoles() {
			this.role = await usermanager.getRole()
			this.refreshMenu()
		},
		refreshMenu() {
			var menu = []
			if(this.role != "editor") {
				menu = [{
                    label: 'Home',
                    items: [
						{
							label: this.$i18n.t('sidemenu.dashboard'), icon: 'pi pi-fw pi-th-large', to: '/dashboard'
						},
						{
							label: this.$i18n.t('sidemenu.matches'), icon: 'pi pi-fw pi-home', to: '/begegnungen'
						},
						{
							label: this.$i18n.t('sidemenu.team'), icon: 'pi pi-fw pi-users', to: '/team'
						}
					]
				}]
			}

			if(this.role == "admin") {
				var adminMenu = {
                    label: 'Admin',
                    items: [
						{label: 'Dashboard', icon: 'pi pi-fw pi-th-large', to: '/admindashboard'},
						{label: 'Users', icon: 'pi pi-fw pi-users', to: '/allusers'},
						{label: 'Editor Assign', icon: 'pi pi-fw pi-box', to: '/editorassigner'},
						{label: 'Team to Match', icon: 'pi pi-fw pi-box', to: '/creatorteamassigner'},
						{label: 'Teams', icon: 'pi pi-fw pi-prime', to: '/allclubs'},
						{label: 'Videos', icon: 'pi pi-fw pi-video', to: '/adminvideos'},
						{label: 'Files', icon: 'pi pi-fw pi-box', to: '/adminuploads'}
					]
				}
				menu.push(adminMenu);
			}

			if(this.role == "editor") {
				var editorMenu = {
                    label: 'Editor',
                    items: [
					{label: 'Videos', icon: 'pi pi-fw pi-video', to: '/adminvideos'}
                ]}
				menu.splice(1, 0, editorMenu);
			}

			if(this.role != "editor") {
				menu.push({
					items: [
						{label: 'Feedback', icon: 'pi pi-fw pi-envelope', to: '/feedback'},
					]
				})
			}

			menu.push({
					items: [
						{label: 'Logout', icon: 'pi pi-fw pi-sign-out'}
					]
				})
			this.$emit('setmenu', menu);
		}
    },
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		}
	}
}
</script>