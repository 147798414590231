
<template>
    <div>
        <Chart v-if="match != null" type="bar" :data="chartData" :options="chartOptions"/>
    </div>
</template>

<script>
var img = require("../../assets/ball.png") 
var yourImage = new Image()
yourImage.src = img
yourImage.width = 15
yourImage.height = 15


export default {
    data() {
        return {
            chartOptions: {
                onResize: this.resizeChart,
                resizeDelay: 200,
                responsive: true,
                maintainAspectRatio: true,
                aspectRatio: 2,
                interaction: {
                    mode: 'index',
                    intersect: false,
                },
                scales: {
                    x: {
                        title: {
                        display: true,
                        text: this.$i18n.t('expected_goals.minute')
                        }
                    },
                    y: {
                        title: {
                        display: true,
                        text: this.$i18n.t('expected_goals.expected_goals')
                        },
                    }
                },
                plugins: {
                    title: {
                        display: true,
                        text: this.$i18n.t('expected_goals.expected_goals')
                    },
                    tooltip: {
                        enabled: true,
                        position: 'nearest',
                        callbacks: {
                            title: this.footer,
                        },
                    },
                    legend: {
                        display: true,
                    }
                }
            }            
        }
    },
    async created () {

    },
    computed: {


        chartData: function() {
            return {labels: this.values.minutes,
            datasets: [{
                type: 'line',
                label: this.$i18n.t('expected_goals.my_team'), //this.match.creator_team.clubname,
                borderColor: '#5A9781',
                borderWidth: 1,
                fill: false,
                data: this.values.creatorXG,
                backgroundColor: 'white',
                pointStyle: this.creatorPointStyle,
                pointRadius: 0.1
            },
            {
                type: 'line',
                label: this.$i18n.t('dictionary.opponent'),//this.match.opponent_team.clubname,
                borderColor: '#3399FF',
                borderWidth: 1,
                fill: false,
                data: this.values.opponentXG,
                backgroundColor: 'white',
                pointStyle: this.opponentPointStyle,
                pointRadius: 0.1
            }
            ] }
        },
        match: function() {
            return this.$store.getters.getMatch
        },
        shotSituations: function() {
            var sits = JSON.parse(JSON.stringify(this.match.situations))
            return sits.filter(s => 
                s.situationPositions.length > 0 && s.situationPositions.filter(sp => sp.xG != 0).length != 0
            ).sort(function(a, b){
                return new Date(a.situation_start) - new Date(b.situation_start);
            })
        },
        xGWithMinutes: function() {
            var res = []
            this.shotSituations.forEach(s => res.push({min: Number((s.situation_start < this.match.matchVideoFrame[2] ? s.situation_start - this.match.matchVideoFrame[0] : (45*60) + s.situation_start - this.match.matchVideoFrame[2])  / 60).toFixed(0), creatorXG: this.getSum(s.situationPositions, true), opponentXG: this.getSum(s.situationPositions, false), creatorGoal: this.situationHasGoal(s, true) == true ? 1: 0, opponentGoal: this.situationHasGoal(s, false) == true ? 1 : 0}))
            return res
        },
        chartVals: function() {
            var res = []
            var mins = []
            var creator = []
            var opponent = []
            var creatorGoals = []
            var opponentGoals = []
            this.xGWithMinutes.forEach(x => mins.push(Number(x.min)))

            this.xGWithMinutes.forEach(x => creator.push(x.creatorXG))
            this.xGWithMinutes.forEach(x => opponent.push(x.opponentXG))

            this.xGWithMinutes.forEach(x => creatorGoals.push(x.creatorGoal))
            this.xGWithMinutes.forEach(x => opponentGoals.push(x.opponentGoal))


            res = {mins: mins, c: creator, o: opponent, creatorGoals: creatorGoals, opponentGoals: opponentGoals}
            return res
        },
        chartValsWithoutDoubles: function() {
            var result = []

            this.chartVals.mins.forEach(val => {
                var indices = this.find(val, this.chartVals.mins)
                var newO = {min: val, creatorXG: 0, opponentXG: 0, creatorGoals: 0, opponentGoals: 0}
                newO.creatorXG += this.chartVals.c[indices[indices.length -1]] // tbd summe bilden
                newO.opponentXG += this.chartVals.o[indices[indices.length -1]] // tbd summe bilden
                newO.creatorGoals += this.chartVals.creatorGoals[indices[indices.length -1]] // tbd summe bilden
                newO.opponentGoals += this.chartVals.opponentGoals[indices[indices.length -1]] // tbd summe bilden
                result.push(newO);
            }, this);
            result = result.reduce((unique, o) => {
                if(!unique.some(obj => obj.min === o.min)) {
                unique.push(o);
                }
                return unique;
            },[]);

            for(var i = 1; i < result.length; i++) {
                result[i].creatorXG = result[i].creatorXG + result[i-1].creatorXG
                result[i].opponentXG = result[i].opponentXG + result[i-1].opponentXG
            }
            return result
        },
        values: function() {
            var creatorGoals = []
            var opponentGoals = []
            var opponentXG = []
            var creatorXG = []
            var minutes = []
            this.chartValsWithoutDoubles.forEach(a => {
                creatorGoals.push(a.creatorGoals == 0 ? null : a.creatorGoals)
                opponentGoals.push(a.opponentGoals == 0 ? null : a.opponentGoals)
                opponentXG.push(a.opponentXG)
                creatorXG.push(a.creatorXG)
                minutes.push(Number(a.min) + 1)
            })
            return {opponentGoals: opponentGoals, creatorGoals: creatorGoals, creatorXG: creatorXG, opponentXG: opponentXG, minutes: minutes}
        }
    },
    methods: {
        resizeChart(chart) {
            this.chartOptions.aspectRatio = window.innerWidth >= 992 ? 2 : 1          
            chart.config._config.data.datasets[0].label = this.$i18n.t('expected_goals.my_team') //this.match.creator_team.clubname
            chart.config._config.data.datasets[1].label = this.$i18n.t('dictionary.opponent'), //this.match.opponent_team.clubname
            chart.update();
        },
        getSum(positions, isCreator) {
            var sum = 0
            positions.forEach(p => sum = p.isInCreatorTeam == isCreator ? sum + p.xG : sum)
            return sum
        },
        find(needle, haystack) {
            var results = [];
            var idx = haystack.indexOf(needle);
            while (idx != -1) {
                results.push(idx);
                idx = haystack.indexOf(needle, idx + 1);
            }
            return results;
        },
        situationHasGoal(sit, isCreator) {
            if(sit === undefined) {return 0}
            return sit.situationPositions.filter(sitPo => (sitPo.action_labels.includes("Tor") && sitPo.isInCreatorTeam == isCreator) || (sitPo.action_labels.includes("Eigentor") && sitPo.isInCreatorTeam == !isCreator)).length > 0 ? true : false
        },
        creatorPointStyle(ctx) {
            return this.values.creatorGoals[ctx.dataIndex] == 1 ? yourImage : 'circle'
        },
        opponentPointStyle(ctx) {
            return this.values.opponentGoals[ctx.dataIndex] == 1 ? yourImage : 'circle'
        },
        footer(ctx) {
            console.log("ctx", ctx)
            ctx[0].dataset.label = "Tore-Ist: " + this.values.creatorGoals.slice(0, ctx[0].dataIndex + 1).map(s => s).reduce(function(pv, cv) { return pv + cv; }, 0) + " - Tore-Soll"
            ctx[1].dataset.label = "Tore-Ist: " + this.values.opponentGoals.slice(0, ctx[0].dataIndex + 1).map(s => s).reduce(function(pv, cv) { return pv + cv; }, 0) + " - Tore-Soll"
            var title = this.values.opponentGoals[ctx[0].dataIndex] == 1 ? "Tor für " + this.match.opponent_team.clubname : (this.values.creatorGoals[ctx[0].dataIndex] == 1 ? "Tor für " + this.match.creator_team.clubname : "Erwartete Tore")
            return title
        }
    }
}
</script>

