<template>
<div>
    <div>
        <div class="container py-5">
                <div class="row py-5">
                    <div class="col-lg-7 mx-auto">

                        <div >
                            <div>
                                <h4 class="text-center">Herzlich Willkommen!
                                     
                                </h4>
                                <div class="text-center">Deine Registrierung war erfolgreich <i style="color: green;" class="far fa-check-circle"></i></div>
                                
                                <div class="text-center py-3">
                                    <Button label="Weiter zum Login" class="p-button-outlined p-button-secondary" @click="$router.push('/login')"/>
                              </div>

                            </div>
                        </div>
                    </div>
                </div>        
            </div>

    </div>
</div>
</template>


