<template>
  <div class="layout-main-container">

    <div class="layout-main">
      <Spinner v-if="match == null"></Spinner>
      <OpponentSelector class="opponentselector" v-else-if="match.opponent_team === undefined"></OpponentSelector>
      <MatchdaySelector v-else-if="match.match_date === undefined || showMatchSelector == true"
        @toggleShowMatchSelector="showMatchSelector = false" :showMatchSelector="showMatchSelector"
        class="opponentselector"></MatchdaySelector>
      <MatchInFuture v-else-if="match_status == matchstates.MATCH_IN_FUTURE"
        @toggleShowMatchSelector="showMatchSelector = true"></MatchInFuture>
      <MatchTimer @addSituation="addSituation" :team="myteam" v-else-if="match_status == matchstates.MATCH_ONGOING"
        class="opponentselector"></MatchTimer>
      <VideoUploader v-else-if="match.videos.length == 0" :videos="matchVideos" class="opponentselector">
      </VideoUploader>
      <!--<CustomSteps  v-else-if="match.videos.length == 0" class="opponentselector"></CustomSteps>-->
      <MatchOverview ref="overview" v-else></MatchOverview>


    </div>
  </div>
</template>


<style>
  @import 'Match.css';

  .opponentselector {

    width: 100%;
    top: 25%;
  }

</style>

<script>

import { kick17, usermanager } from '@/services';

//import { exampleteam } from '@/helpers';




import Spinner from '../Basics/Spinner'
import OpponentSelector from './OpponentSelector'
import MatchdaySelector from './MatchdaySelector'
import MatchTimer from './MatchTimer'
import VideoUploader from './VideoUploader'
import MatchOverview from './MatchOverview'
import MatchInFuture from './MatchInFuture'
//import CustomSteps from './CustomSteps'


export default {
  name: 'app',
  components: {


    
    Spinner,
    OpponentSelector,
    MatchdaySelector,
    MatchTimer,
    VideoUploader,
    MatchOverview,
    MatchInFuture
  },
  data () {
    return {
      showMatchSelector: false,
      videoMounted: false,
      currentVideoTimestamp: 0,
      selectedFile: null,
      matchVideos: null,
      
      clubColors: [],
      heatmapCanvas: {
        width: null,
        height: null
      },
      heatmapData: null,
      xCenters: null,
      availableTeams: [],
      velocityAccelerationData: null,
      ballContactsData: null,
      formationData: null,
      movingVectors: null,
      allSprints: null,
      allPositions: null,
      selectedTeamIDHeatmap: null,
      selectedTeamIDFormation: null,
      showFormation: "hidden",
      showHeatmap: "hidden",
      //showXCenters: "visible",
      showXCenters: {
        active: false, 
        overallXCenter: false, 
        currentXCenter: false
      },
      showSprints: "hidden",
      showCurrentPositions: "visible",
      selectedPlayerID: null,
      videoAnalysisProgressState: null,
      matchstates: {
        NO_MATCH: "NO_MATCH",
        NO_OPPONENT: "NO_OPPONENT",
        NO_DATE: "NO_DATE",
        MATCH_IN_FUTURE: "MATCH_IN_FUTURE",
        MATCH_ONGOING: "MATCH_ONGOING",
        MATCH_FINISHED: "MATCH_FINISHED",
        WAITING_FOR_VIDEO: "WAITING_FOR_VIDEO"

      },
      analysisState: {
        WAITING_FOR_VIDEO: 0,
        WAITING_FOR_CONFIGURATION: 1,
        READY_FOR_ANALYSIS: 2,
        IN_ANALYSIS: 3,
        ANALYSIS_DONE: 4,
        ANALYSIS_FAILED: 5,
        WAITING_FOR_MATCH_DETAILS: 6
      },
      
      totalDistances: null,
      isAdmin: false
    }
  },
  async created () {
      //window.addEventListener("resize", this.windowResizeHandler);
      this.$store.commit('setNow')
      if(this.match == undefined) {
        await this.loadMatch()
      }
      var res = null
      if(!this.isAdmin) {
        res = await kick17.getMyTeam()
      } else {
        res = await kick17.getTeam(this.match.creator_team.uuid)
      }

      //var res = {data: exampleteam}
      this.$store.commit('setTeam', res.data)
  },
  computed: {
      myteam: function() {
          return this.$store.getters.getTeam
      },
      now: function() {
        return this.$store.getters.getNow
      },
      match: function() {
        return this.$store.getters.getMatch
        //var m = this.$store.getters.getMatch
        //return m == undefined ? null : m 
      },
      match_status: function() {
       
        if(this.match != null && this.match.match_date != null) {
          
            var match_date = new Date(this.match.match_date)
            this.$store.commit('setNow')
            var diffMinutes = (this.now - match_date) / (1000 * 60)

            if(diffMinutes < 0) {
              return this.matchstates.MATCH_IN_FUTURE
            }

            if(diffMinutes >= 0 && this.match.isFinished != true) {
              return this.matchstates.MATCH_ONGOING
            }
            return this.matchstates.MATCH_FINISHED 
        }
          return null
      }
  },
  async mounted() {
    this.isAdmin = await usermanager.isAdmin()
    /*if(this.match == null) {
      this.$store.commit('setMatchUuid', this.$route.params.match_uuid)
    } else {
      this.$store.commit('setMatchUuid', this.match.uuid)
    }*/
    
  },

  methods: {
    

    addSituation(situation){
      if(situation != null) {
        this.match.situations.situations.push(situation)
      }
    },


    changeSelectedPlayerID(val) {
      this.selectedPlayerID = val
    },
    changeFormationVisibility(val){
      this.showFormation = val
    },
    changeHeatmapVisibility(val) {
      this.showHeatmap = val
    },
    changeSprintsVisibility(val) {
      this.showSprints = val
    },
    changeCurrentPositionsVisibility(val) {
      this.showCurrentPositions = val
    },
      getRandomInt () {
        return Math.floor(Math.random() * (50 - 5 + 1)) + 5
      },

    /*windowResizeHandler() {
      //this.setCanvasSize()
      //this.resizeHeatmap()
    },*/
    setCanvasSize() {
 
    },
    setAvailableTeams() {
      this.availableTeams = []
      this.clubColors = []
      var tmp_colors = ['#314FCD', '#0DE8C0', '#E80D67', '#e80dc9', '#e8be0d']
      for(var i=0; i< this.heatmapData.teams.length; i++) {
        var tmp_id = this.heatmapData.teams[i].team_id
        var team_name = this.heatmapData.teams[i].team_name
        this.availableTeams.push({"value": tmp_id, "text" : this.getTeamName(team_name, tmp_id)})
        this.clubColors.push({"team_id": tmp_id, "color" : tmp_colors[i]})
      }
      this.selectedTeamIDHeatmap = this.availableTeams[0].value
      this.selectedTeamIDFormation = this.selectedTeamIDHeatmap
    },
    

    async loadMatch() {
      var result = await kick17.getMatch(this.$route.params.match_uuid)
      var match = result.data
      this.$store.commit('setMatch', match)
      this.$store.commit('setMatchUuid', result.data.uuid)
    },
    addVideoToMatch(name) {
      this.$store.commit('addVideoToMatch', name)
      this.$refs.overview.refreshVideos()
    },

     async createMoveVectorChart() {
      var result = await kick17.getMoveVectors(this.$route.params.match_uuid)
       this.movingVectors = result.data
    },
    async createBallContactsChart() {
      var result = await kick17.getBallContacts(this.$route.params.match_uuid)
      this.ballContactsData = result.data

    },
    async createPlayerVelocityChart() {
      var result = await kick17.getPlayerVelocity(this.$route.params.match_uuid)
      this.velocityAccelerationData = result.data
    },

    getShortestTeamArrayIndex(teams) {
      var shortest = null;
      for(var i = 0; i < teams.length; i++) {
        if(shortest == null || teams[i].teamdata.length <  teams[shortest].teamdata.length) {
          shortest = i;
        }
      }
      return shortest;
    },
    get2GreatestTeams(teams) {
      var resultTeams = []
      for(var i = 0; i < teams.length; i++) {
          if(resultTeams.length < 2) {
            resultTeams.push(teams[i])
          } else {
            if(teams[i].teamdata.length > resultTeams[0].teamdata.length || teams[i].teamdata.length > resultTeams[1].teamdata.length) {
              resultTeams.push(teams[i])
              this.$delete(resultTeams, this.getShortestTeamArrayIndex(resultTeams))
            }
          }
      }
      return resultTeams;
    },
    async initHeatmap() {
      var result = await kick17.getHeatmap(this.$route.params.match_uuid)
      var tmp_data = result.data
      tmp_data.teams = this.get2GreatestTeams(tmp_data.teams)
      this.heatmapData = tmp_data
      this.setAvailableTeams()
    },

    async initXCenters() {
      var result = await kick17.getXCenters(this.$route.params.match_uuid)
      this.xCenters = result.data
    },

    async initFormation() {
      var result = await kick17.getFormation(this.$route.params.match_uuid)
      this.formationData = result.data
    },

    async initTotalDistance() {
      var result = await kick17.getTotalDistance(this.$route.params.match_uuid)
      this.totalDistances = result.data
    },

    async getSprints() {
      var result = await kick17.getSprints(this.$route.params.match_uuid)
      this.allSprints = result.data
    },

    async getAllPositions() {

      if(this.match.analytics_id != null) {
        //var result = await kick17.getAllPositions(this.match.analytics_id)
        //this.allPositions = JSON.parse(JSON.stringify(Vue.nonreactive(result.data)));
      }
    },
    getTeamName(teamName, id) {
      var newName = "Team " + id.toString() 
      var result = teamName == "NULL" ? newName : teamName
      result = result == null ? newName : result
      return result
    }

    
    


  }
}
</script>